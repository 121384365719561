import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../../firebase";
import {
  query,
  collection,
  onSnapshot,
  doc,
  Timestamp,
  where,
  setDoc,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  getDocs,
  deleteDoc,
} from "@firebase/firestore";
import Spinner from "../../Reusable/Spinner/Spinner";
import ShowError from "../../Reusable/ShowError/ShowError";
import DocumentMissing from "../../Reusable/DocumentMissing/DocumentMissing";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../../Reusable/Sidebar/Sidebar";
import Navbar from "../../Reusable/Navbar/Navbar";
import { showdate, getErrorObject, getUserDetails, postlog } from "../../../functions/Common";
import SuccessToast from "../../Reusable/Toasts/SuccessToast";
import { useGlobalState } from "../../../GlobalProvider";
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Switch from '@mui/material/Switch';
import ProAlert from "../../Reusable/ProAlert/ProAlert"
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import Card from '@mui/material/Card';
import CreateNew from "../../Reusable/CreateNew/CreateNew";
import Typography from '@mui/material/Typography';
import { TextField, Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const Votings = ({ user }) => {
  const location = useLocation();
  const [spin, setSpin] = useState(false);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [data, setData] = useState([]);
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const [formData, setFormData] = useState({
    votingid: "",
    question: "",
    date: "",
    name: "",
    resulturl: "",
    voteurl: "",
    votingdatetime: "",
    isactive: true,
    canvoteannonymous: false,
    cancomment: false,
  });
  const navigate = useNavigate();
  const { state } = useGlobalState();
  const [Toast, setShowToast] = useState(false);
  const [customStyle, setCustomStyle] = useState({
    backgroundColor: "#61a1fe",
    border: "2px solid #61a1fe",
  });
  const [showSettings, setShowSettings] = useState(false);
  const [showPro, setShowPro] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openSPopup, setOpenSPopup] = useState(false);
  const [deleteBoard, setDeleteBoard] = useState();
  const [showFormValidate, setShowFormValidate] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState();

  useEffect(() => {
    let unsubscribe = null;
    if (location.state && location.state.dashboard) {
      showForm(null)
      navigate(location.pathname, { state: { dashboard: false } });
    }
    async function getVotes() {
      setSpin(true);
      try {
        let q;
        if (state.currentteam.teamid != "" && state.currentteam.teamid != "none") {
          q = await query(
            collection(firestore, "votings"),
            where("users", "array-contains", user.userid),
            where("teamid", "==", state.currentteam.teamid),
            orderBy("updateddate", "desc"),
            limit(5)
          );
        } else {
          q = await query(
            collection(firestore, "votings"),
            where("users", "array-contains", user.userid),
            orderBy("updateddate", "desc"),
            limit(5)
          );
        }
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = await onSnapshot(q, (snapshot) => {
          setData([]);
          snapshot.forEach((doc) => {
            const newdata = doc.data();
            setData((prevMember) => {
              return [...prevMember, newdata];
            });
          });
          // postlog("read votings", { component: "votings", function: "getVotes", collection: "votings", uid: user.userid });
          if (user.userid !== "" && user.userid !== null && user.userid !== undefined) {
            // postlog("read votings", { component: "votings", function: "getVotes", collection: "votings", uid: user.userid });

          }

          setFirstVisible(snapshot.docs[0])
          setLastVisible(snapshot.docs[snapshot.docs.length - 1])
          // After fetching data, set default values
          if (snapshot.docs.length === 0) {
            // Set default values if no data found
            setFormData({
              votingid: "",
              name: "How confident you are with the PI plan of your team?",
              date: new Date().toISOString().slice(0, 10),
              canvoteannonymous: false, // Set the default value to true
              cancomment: false, // Set the default value to true
            });
          }
          setSpin(false);
        },
          (err) => {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "getVotes") });
            setSpin(false);
          });
      } catch (err) {
        setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "getVotes") });
        setSpin(false);
      }
    }
    getVotes();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

  }, [state.currentteam]);

  async function loadNext() {
    setDisablePrevious(false);

    try {
      const nextQuery = query(
        collection(firestore, 'votings'),
        where("users", "array-contains", user.userid),
        orderBy("updateddate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );

      const snapshot = await getDocs(nextQuery);
      // postlog("read votings", { component: "votings", function: "loadNext", collection: "votings", uid: user.userid });


      const newVotingsData = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        newVotingsData.push(newdata);
      });

      if (snapshot.docs.length > 0) {
        setData(newVotingsData);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      if (snapshot.docs.length < 5) {
        setDisableNext(true);
      }

      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Votings", "loadNext")
      });
      setSpin(false);
    }
  }

  async function loadPrevious() {
    setDisableNext(false);

    try {
      const previousQuery = query(
        collection(firestore, 'votings'),
        where("users", "array-contains", user.userid),
        orderBy("updateddate", "desc"),
        endBefore(firstVisible),
        limitToLast(5),
      );

      const snapshot = await getDocs(previousQuery);
      // postlog("read votings", { component: "votings", function: "loadPrevious", collection: "votings", uid: user.userid });


      const newVotingsData = [];
      snapshot.forEach((doc) => {
        const newData = doc.data();
        newVotingsData.push(newData);
      });

      if (snapshot.docs.length > 0) {
        setData(newVotingsData);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setDisablePrevious(!snapshot.docs.length);
      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Votings", "loadPrevious")
      });
      setSpin(false);
    }
  }


  async function saveVote(data) {
    try {
      const docRef = doc(collection(firestore, "votings"));
      await setDoc(docRef, {
        votingid: docRef.id,
        question: data.name,
        resulturl: docRef.id,
        voteurl: docRef.id,
        isactive: true,
        canvoteannonymous: data.canvoteannonymous,
        cancomment: data.cancomment,
        level: "public",
        createddate: Timestamp.fromDate(new Date()),
        updateddate: Timestamp.fromDate(new Date()),
        createdbyuserid: user.userid,
        createdbyusername: user.username,
        createdbyuserprofile:
          user.profilepicture != undefined ? user.profilepicture : "",
        createdbyuseremail: user.useremail,
        updatedbyuserid: user.userid,
        updatedbyusername: user.username,
        updatedbyuserprofile:
          user.profilepicture != undefined ? user.profilepicture : "",
        updatedbyuseremail: user.useremail,
        users: [user.userid],
        usersdetails: { [user.userid]: getUserDetails(user) },
        backgroundimage: "",
        mode: "light",
        teamid: state.currentteam.teamid ? state.currentteam.teamid : "",
        teamname: state.currentteam.teamname ? state.currentteam.teamname : ""
      }).then(() => {
        //   postlog("write votings", { component: "votings", function: "saveVote", collection: "votings" });
        postlog("create votings", { component: "votings", function: "saveVote", collection: "votings" });
        navigate(`/fistoffive/${docRef.id}`, { state: { newdoc: true } });
      });

      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "saveVote") });
      setSpin(false);
    }
  }

  const showForm = (data) => {
    try {
      if (data != null) {
        const dtt = new Date(data.date.seconds * 1000);

        setFormData({
          votingid: data.votingid,
          name: data.question,
          date: dtt.toISOString().slice(0, 10),
          canvoteannonymous: data.canvoteannonymous,
          cancomment: data.cancomment,
        });
      } else {
        setShowFormValidate(false)
        setFormData({
          votingid: "",
          name: "How confident you are with the PI plan of your team?",
          date: new Date().toISOString().slice(0, 10),
          canvoteannonymous: false,
          cancomment: false,
        });
      }
      handleopenSPopup();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "showForm") });
      setSpin(false);
    }
  };

  const hideForm = () => {
    try {
      setFormData({
        ...formData,
        votingid: "",
        question: "",
        date: new Date().toISOString().slice(0, 10),
      });
      handleClosespopup();
      setShowSettings(false);
      setShowFormValidate(false);

    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "hideForm") });
      setSpin(false);
    }
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "handleChange") });
      setSpin(false);
    }
  };

  const handleCheckboxChange = (event) => {
    try {
      if (state.subscriptionglobaldata.subscription == "False") {
        setShowPro(true);
      }
      else {
        const { name, checked } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
      }


    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "handleCheckboxChange") });
      setSpin(false);
    }
  };

  const handleCloseAlert = () => {
    setShowPro(false);
  };

  const handleSubmit = (event) => {

    try {
      const form = event.currentTarget;
      if (formData.name.trim()) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          // Process the form data here (e.g., submit to the server)
          event.preventDefault();
          event.stopPropagation();
          saveVote({
            votingid: formData.votingid,
            name: formData.name,
            isactive: formData.isactive,
            canvoteannonymous: formData.canvoteannonymous,
            cancomment: formData.cancomment,
          });

          hideForm();
          event.preventDefault();
        }
      } else {
        setShowFormValidate(true);
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "handleSubmit") });
      setSpin(false);
    }
  };
  const [isOpen, setIsOpen] = useState(true);

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "handleSidebar") });
      setSpin(false);
    }
  };

  const handleDeleteConfirmation = (docid) => {
    setDeleteBoard(docid);
    setOpen(true);
  }


  const handleDeleteDocument = async () => {
    try {
      setSpin(true);
      await deleteDoc(doc(firestore, "boards", deleteBoard));
      setSpin(false);
      handleClose();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "handleDeleteDocument") });
      setSpin(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleopenSPopup = () => {
    setOpenSPopup(true);
  };

  const handleClosespopup = () => {
    setOpenSPopup(false);
  };

  const copyLink = (votingid) => {
    const dummyInput = document.createElement("input");
    document.body.appendChild(dummyInput);
    dummyInput.value = `${window.location.origin}/fistoffive/vote/${votingid}`;
    dummyInput.select();
    document.execCommand("copy");
    document.body.removeChild(dummyInput);
    setSnackbarOpen(true);
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  }

  return (
    <>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
      <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
        <div className="d-flex gap-2 align-items-center mb-2" >
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', fill: 'rgba(231, 54, 115, 0.8)',border:'solid rgba(231, 54, 115, 0.8) 1px', borderRadius: "20%", padding: "10px",color:'rgba(231, 54, 115, 0.8)' }}>
                    <path class="svg-icon" d="M402-40q-30 0-56-13.5T303-92L48-465l24-23q19-19 45-22t47 12l116 81v-383q0-17 11.5-28.5T320-840q17 0 28.5 11.5T360-800v537L212-367l157 229q5 8 14 13t19 5h278q33 0 56.5-23.5T760-200v-560q0-17 11.5-28.5T800-800q17 0 28.5 11.5T840-760v560q0 66-47 113T680-40H402Zm38-440v-400q0-17 11.5-28.5T480-920q17 0 28.5 11.5T520-880v400h-80Zm160 0v-360q0-17 11.5-28.5T640-880q17 0 28.5 11.5T680-840v360h-80ZM486-300Z" />
                  </svg> */}
          <div>
            <Typography variant="h6">
              Fist of Five {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname != "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Click on + to create new fist of five voting.
            </Typography>
          </div>
        </div>
        {data !== undefined && user !== "" && (
          <div >
            {spin === false && user.role !== "member" && (<div className="mb-2">
              <Card className="row g-0" style={{ padding: '0.2rem 1rem', backgroundColor: '#f8f9fe' }} elevation={0}>



              </Card>
              <div className="row">
                <button
                  onClick={() => showForm(null)}
                  style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
                  className="square-item"
                >
                  <CreateNew title='New Fist of Five' color='#e73673' />
                </button>
              </div>
            </div>)}

            {spin === false && data !== undefined && data.length !== 0 ? (
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: '15%' }}>Created At</TableCell>
                          <TableCell>Creator</TableCell>
                          <TableCell sx={{ width: '50%' }}>Question</TableCell>
                          <TableCell >Copy Voting Link</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((board, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={() => {
                                navigate(`/fistoffive/${board.votingid}`)
                              }}
                              key={board.id}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell>{showdate(board.createddate, user.timezone)}</TableCell>
                              <TableCell>
                                <div className="d-flex gap-2 align-items-center" style={{ padding: '10px' }}>
                                  <Tooltip title={board.createdbyusername}>
                                    {board.createdbyuserprofile && board.createdbyuserprofile != "" ? (
                                      <Avatar alt="Remy Sharp" sx={{ width: 28, height: 28 }} src={board.createdbyuserprofile} />
                                    ) : (
                                      <AccountCircleIcon sx={{ width: 28, height: 28, color: '#5f6368' }} ></AccountCircleIcon>)}
                                  </Tooltip>
                                  <Typography>
                                    {`${board.createdbyusername}`}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell>
                                {board.question}</TableCell>
                              <TableCell>

                                {/* <Button variant="outlined" onClick={(event) => {
                                  event.stopPropagation();
                                  navigate(`/fistoffive/vote/${board.votingid}`)
                                }}>
                                 Vote
                                </Button> */}
                                <Tooltip title="Copy voting link">
                                  <IconButton onClick={(event) => {
                                    event.stopPropagation();
                                    copyLink(board.votingid)
                                  }}
                                    type='button'
                                    disabled={board.level != 'public'}
                                  >
                                    <ContentCopyIcon />
                                  </IconButton>
                                </Tooltip>
                                {/* <IconButton title="Delete" color="error" onClick={(event) => {
                                        event.stopPropagation();
                                        handleDeleteConfirmation(board.votingid);
                                      }}>
                                        <DeleteOutlineOutlinedIcon />
                                      </IconButton> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>) : (
              spin === false && data !== undefined && data.length === 0 && (
                <Card style={{ textAlign: 'center', color: '#a9a3b7', padding: '3rem 0rem' }}>
                  <Typography variant="h5" gutterBottom>
                    You've not created any votings yet!
                  </Typography>
                  <Typography variant="h7" gutterBottom>
                    When you do, they will be show up here.
                  </Typography>
                </Card>
              )
            )}


            {data && data.length !== 0 && (
              <div className="d-flex justify-content-end mb-3">
                <ul className="pagination">
                  <li className="page-item">
                    <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                      <KeyboardArrowLeftIcon />
                    </button>
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={loadNext} disabled={disableNext} >
                      <KeyboardArrowRightIcon />
                    </button>
                  </li>
                </ul>
              </div>
            )}


            <Dialog open={openSPopup} onClose={hideForm} fullWidth={true} maxWidth='sm'>
              <DialogTitle >Fist of Five Confidence Voting {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname != "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}</DialogTitle>
              <Divider />
              <DialogContent >
                <TextField
                  id="questionInput"
                  label="Type Your Question Below"
                  variant="outlined"
                  fullWidth
                  required
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  sx={{ marginTop: '20px' }}
                  error={showFormValidate && !formData.name.trim()}
                  helperText={showFormValidate && !formData.name.trim() ? 'Question is required' : ''}
                  autoFocus
                />
                <Button className="showmorebutton" onClick={() => setShowSettings(!showSettings)} sx={{ mt: 2, textTransform: 'none', marginBottom: '10px' }}>
                  {showSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}    {showSettings ? 'Hide Settings' : 'Show Settings'}
                </Button>
                <div className={`showsettings ${showSettings ? 'show' : ''}`} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Box>
                    <FormControlLabel
                      control={<Switch checked={formData.canvoteannonymous} onChange={handleCheckboxChange} name="canvoteannonymous" />}
                      label="Allow Anonymous Voting"
                    />
                    {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={<Switch checked={formData.cancomment} onChange={handleCheckboxChange} name="cancomment" />}
                      label="Allow Comments"
                    />
                    {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                  </Box>
                </div>
              </DialogContent>
              <Divider />
              <DialogActions className="m-2">
                <Button onClick={hideForm} sx={{ textTransform: 'none' }} >Cancel</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary" size='large' sx={{ textTransform: 'none' }}>Start Fist of Five Voting</Button>
              </DialogActions>
            </Dialog>

            {/* --------------------------------------snackbar-------------------------------------------- */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert
                onClose={handleCloseSnackbar}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
              >
                The voting link has been successfully copied.
              </Alert>
            </Snackbar>

            {/* ----------------------------confirmation popup----------------------------------------  */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete First of five?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Once you confirm deletion, your First of five will be permanently removed and cannot be recovered. Are you sure you want to proceed?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} varient='outlined' >Cancle</Button>
                <Button onClick={handleDeleteDocument} varient='contained' color='error' autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

          </div>
        )}



      </div>
      <ProAlert showAlert={showPro} hideAlert={handleCloseAlert} />
      <ShowError Error={showError} />
      <DocumentMissing show={showDocumentMissing} />
      <Spinner spin={spin} />
      <SuccessToast
        show={Toast}
        message="This is an Agilebin Pro feature. Please upgrade your plan from Agilebin Free to Agilebin Pro to use this feature."
        subject="Pro Feature"
        style={customStyle}
      />
    </>
  );
};

export default Votings;
