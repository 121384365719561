import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    Timestamp,
    where,
    setDoc,
    orderBy,
    limit,
} from "@firebase/firestore";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/ShowError/ShowError";
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import { getErrorObject, postlog } from "../../functions/Common"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Avatar, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const Support = ({ user }) => {
    const [spin, setSpin] = useState(false);
    const [showError, setShowError] = useState({ show: false, errordata: {} });
    const [editMode, setEditMode] = useState(false);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        id: "",
        message: "",
        name: "",
        useremail: "",
        phoneno: "",
    });
    const [isOpen, setIsOpen] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState();

    useEffect(() => {
        let unsubscribe = null;
        // React advises to declare the async function directly inside useEffect
        async function getSupportData() {
            setSpin(true);
            try {
                const q = await query(
                    collection(firestore, "support"),
                    where("userid", "==", user.userid),
                    orderBy("updateddate", "desc"),
                    limit(5),
                );
                if (unsubscribe) { unsubscribe(); }
                unsubscribe = await onSnapshot(q, (snapshot) => {
                    setData([]);
                    snapshot.forEach((doc) => {
                        const newdata = doc.data();

                        setData((prevMember) => {
                            return [...prevMember, newdata];
                        });

                    });

                    // After fetching data, set default values
                    if (snapshot.docs.length === 0) {
                        // Set default values if no data found
                        setFormData({
                            message: "",
                            name: "",
                            useremail: "",
                            phoneno: "",
                            date: new Date().toISOString().slice(0, 10),
                        });
                    }
                 //   postlog("read support", { component: "support", function: "getSupportData", collection: "support", uid: user.userid });
                    setSpin(false);
                },
                    (err) => {
                        setShowError({ show: true, errordata: getErrorObject(err, user, "Support", "getSupportData") });
                        setSpin(false);
                    });
            } catch (err) {
                setShowError({ show: true, errordata: getErrorObject(err, user, "Support", "getSupportData") });
                setSpin(false);
            }
        }
        getSupportData();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };

    }, []);

    async function saveSupportTicket(data) {
        setSpin(true);
        try {
            if (!data.id) {
                const docRef = doc(collection(firestore, "support"));

                await setDoc(docRef, {
                    id: docRef.id,
                    message: formData.message,
                    useremail: formData.useremail,
                    name: formData.name,
                    phoneno: formData.phoneno,
                    createddate: Timestamp.fromDate(new Date()),
                    updateddate: Timestamp.fromDate(new Date()),
                    createdby: user.userid,
                    updatedby: user.userid,
                    userid: user.userid,
                });
                //postlog("write support", { component: "support", function: "saveSupportTicket", collection: "support" });
            } else {
                // Handle updating an existing ticket
                const docRef = doc(collection(firestore, "support"), data.id);

                await updateDoc(docRef, {
                    message: formData.message,
                    name: formData.name,
                    phoneno: formData.phoneno,
                    updateddate: Timestamp.fromDate(new Date()),
                    updatedby: user.userid,
                });
              //  postlog("write support", { component: "support", function: "saveSupportTicket", collection: "support", uid: data.id });

            }
            setSpin(false);
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Support", "saveSupportTicket") });
            setSpin(false);
        }
    }


    const showForm = (data) => {
        try {
            setEditMode(!!data);
            if (data != null) {
                const dtt = data.date ? new Date(data.date.seconds * 1000) : new Date();

                setFormData({
                    id: data.id,
                    message: data.message,
                    name: data.name,
                    useremail: data.useremail,
                    phoneno: data.phoneno,
                    date: dtt.toISOString().slice(0, 10),
                });
            } else {
                setFormData({
                    message: "",
                    name: `${user.firstname} ${user.lastname}`,
                    useremail: user.useremail,
                    phoneno: "",
                    date: new Date().toISOString().slice(0, 10),
                });
            }
            let model = document.getElementById("formPopup");
            model.style.display = "block";
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Support", "showForm") });
            setSpin(false);
        }
    };



    const hideForm = () => {
        try {
            setFormData({
                message: "",
                name: "",
                useremail: "",
                phoneno: "",
                date: new Date().toISOString().slice(0, 10),
            });

            let model = document.getElementById("formPopup");
            model.style.display = "none";
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Support", "hideForm") });
            setSpin(false);
        }
    };

    const handleChange = (event) => {
        try {
            const { name, value } = event.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Support", "handleChange") });
            setSpin(false);
        }

    };

    const handleSubmit = (event) => {
        try {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                if (editMode) {
                    saveSupportTicket(formData);
                } else {
                    saveSupportTicket({
                        message: formData.message,
                        name: formData.name,
                        useremail: formData.useremail,
                        phoneno: formData.phoneno,
                    });
                }

                setFormData((prevData) => ({
                    ...prevData,
                    message: "",
                }));

                setSnackbarOpen(true);

                hideForm();
                event.preventDefault();
            }
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Support", "handleSubmit") });
            setSpin(false);
        }
    };


    const handleSidebar = () => {
        try {
            setIsOpen(!isOpen);
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Support", "handleSidebar") });
            setSpin(false);
        }
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
      }

    return (
        <>
            <Navbar user={user} toggleSidebar={handleSidebar} />
            <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
            <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
                <div className="row g-0 mb-3">
                    <div className="d-flex gap-2 align-items-center mb-2" >
                        <div>
                            <Typography variant="h6">
                                Agilebin Support
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Need any help? Contact agilebin support team.
                            </Typography>
                        </div>
                    </div>
                </div>
                {data !== undefined && (
                    <div>
                        {/* <div className="container card p-4 table-shadow"> */}
                        {/* <div className="row">
        <div className="col-md-6">
          <b>Agilebin Support</b>
        </div>
        <div className="col-md-6 text-end">
          <Button variant="contained" onClick={() => showForm(null)}>
            Create Ticket
          </Button>
        </div>
      </div>
      <hr /> */}
                        <Box className='row' style={{ padding: '0 40px', marginBottom: '20px' }}>
                            <div className="col-md-6">
                            </div>
                            <div className="col-md-6 text-end">
                                <Button variant="contained" onClick={() => showForm(null)}>
                                    Create Ticket
                                </Button>
                            </div>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Created At</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Email</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((e) => {
                                        const dtt = new Date(e.createddate.seconds * 1000);
                                        const date = dtt.toLocaleDateString();

                                        return (
                                            <TableRow key={e.id} hover onClick={() => showForm(e)}>
                                                <TableCell>{date}</TableCell>
                                                <TableCell>
                                                    {e.createdbyuserprofile && e.createdbyuserprofile != "" ? (
                                                        <Avatar alt="Remy Sharp" sx={{ width: 28, height: 28 }} src={e.createdbyuserprofile} />
                                                    ) : (
                                                        <AccountCircleIcon sx={{ width: 28, height: 28, color: '#5f6368' }} ></AccountCircleIcon>)}
                                                    <a> {e.name}</a>
                                                </TableCell>
                                                <TableCell>{e.useremail}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    
                        <Dialog id="formPopup" open={true} onClose={hideForm} aria-labelledby="form-popup-title" className="modal">
                            <DialogTitle>Contact Agilebin Support Team</DialogTitle>
                            <Divider />
                            <DialogContent>
                                <Typography variant="body1" gutterBottom>
                                    Contact Agilebin support team for any technical issues, feature requests, billing issues, or just to share feedback.
                                </Typography>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        name="message"
                                        label="Message"
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        value={formData.message}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                    />
                                    <TextField
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        value={formData.name}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                    />
                                    <TextField
                                        name="useremail"
                                        label="Email"
                                        variant="outlined"
                                        type="email"
                                        value={formData.useremail}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                    />
                                    <TextField
                                        name="phoneno"
                                        label="Phone"
                                        variant="outlined"
                                        type="tel"
                                        value={formData.phoneno}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Divider />
                                    <DialogActions className="m-2">
                                        <Button onClick={hideForm} color="primary">
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </form>
                            </DialogContent>
                        </Dialog>
                    </div>
                )}
                <ShowError Error={showError} />

                {/* --------------------------------------snackbar-------------------------------------------- */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert
                onClose={handleCloseSnackbar}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
              >
                Record submitted successfully   
              </Alert>
            </Snackbar>
            </div>
            <Spinner spin={spin} />
        </>
    );
};

export default Support;
