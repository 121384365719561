import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../../firebase";
import {
  query,
  collection,
  onSnapshot,
  doc,
  Timestamp,
  where,
  setDoc,
  limit,
} from "@firebase/firestore";
import Spinner from "../../Reusable/Spinner/Spinner";
import ShowError from "../../Reusable/ShowError/ShowError";
import Sidebar from "../../Reusable/Sidebar/Sidebar";
import Navbar from "../../Reusable/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { getErrorObject, getUserDetails, postlog } from "../../../functions/Common"
import Button from '@mui/material/Button';
import CreateNew from '../../Reusable/CreateNew/CreateNew'
import Card from '@mui/material/Card';
import { Typography, Grid, Paper, Box, Divider } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTheme } from '@mui/material/styles';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
const Teams = ({ user }) => {
  const [teams, setTeams] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
  });
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [spin, setSpin] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState();
  const theme = useTheme();
  const [showFormValidate, setShowFormValidate] = useState(false);

  useEffect(() => {
    let unsubscribe = null;
    // React advises to declare the async function directly inside useEffect
    async function getTeams() {
      try {
        setSpin(true);
        const q = await query(
          collection(firestore, "teams"),
          where("users", "array-contains", user.userid),
          limit(100),
        );
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = await onSnapshot(q, (snapshot) => {
          setTeams([]);
          snapshot.forEach((doc) => {
            const newdata = doc.data();
            setTeams((prevMember) => {
              return [...prevMember, newdata];
            });
          });
       //   postlog("read teams", { component: "teams", function: "getTeams", collection: "teams", uid: user.userid });
          setSpin(false);
        },
          (err) => {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "getTeams") });
            setSpin(false);
          });
      } catch (err) {
        setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "getTeams") });
        setSpin(false);
      }
    }
    getTeams();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  async function saveTeam(team) {
    try {
      setSpin(true);
      if (!team.id) {
        let teamDocRef = doc(collection(firestore, "teams"));
        await setDoc(teamDocRef, {
          id: teamDocRef.id,
          name: team.name,
          createddate: Timestamp.fromDate(new Date()),
          updateddate: Timestamp.fromDate(new Date()),
          createdby: user.userid,
          updatedby: user.userid,
          level: "restricted",
          users: [user.userid],
          usersdetails: { [user.userid]: getUserDetails(user) },
        }).then(() => {
         // postlog("write teams", { component: "teams", function: "saveTeam", collection: "teams" });
          postlog("create team", { component: "teams", function: "saveTeam", collection: "teams" });
          navigate(`/teams/${teamDocRef.id}`);
        });
      } else {
        const teamDocRef = doc(collection(firestore, "teams"), team.id);
        setDoc(
          teamDocRef,
          {
            name: team.name,
            updateddate: Timestamp.fromDate(new Date()),
            updatedby: user.userid,
          },
          { merge: true }
        );
       // postlog("write teams", { component: "teams", function: "saveTeam", collection: "teams", docid: team.id });

      }
      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "saveTeam") });
      setSpin(false);
    }
  }

  const showForm = (team) => {
    try {
      if (showFormValidate) {
        setShowFormValidate(false);
      }
      if (team != null) {
        setFormData({
          id: team.id,
          name: team.name,
        });
      }
      handleClickOpenPopup();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "showForm") });
      setSpin(false);
    }
  };

  const hideForm = () => {
    try {
      setFormData({
        id: "",
        name: "",
      });

      handleClosePopup();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "hideForm") });
      setSpin(false);
    }
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "handleChange") });
      setSpin(false);
    }
  };

  const handleSubmit = (event) => {
    try {
      const form = event.currentTarget;
      if (formData.name.trim()) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          // Process the form data here (e.g., submit to the server)
          saveTeam({
            id: formData.id,
            name: formData.name,
            duedate: formData.duedate,
          });

          hideForm();
          event.preventDefault();
        }
      } else {
        setShowFormValidate(true);
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "handleSubmit") });
      setSpin(false);
    }
  };

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "handleSidebar") });
      setSpin(false);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  const handleClickOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  return (
    <>

      <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
        <Navbar user={user} toggleSidebar={handleSidebar} />
        <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
          <div className="row g-0 mb-2">
            <div className="d-flex gap-2 align-items-center mb-2" >
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', fill: 'white', backgroundColor: 'rgba(76, 172, 107, 0.8)', borderRadius: "20%", padding: "10px" }}>
                    <path class="svg-icon" d="M0-240v-63q0-43 44-70t116-27q13 0 25 .5t23 2.5q-14 21-21 44t-7 48v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5q72 0 116 26.5t44 70.5v63H780Zm-455-80h311q-10-20-55.5-35T480-370q-55 0-100.5 15T325-320ZM160-440q-33 0-56.5-23.5T80-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T160-440Zm640 0q-33 0-56.5-23.5T720-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T800-440Zm-320-40q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm0-80q17 0 28.5-11.5T520-600q0-17-11.5-28.5T480-640q-17 0-28.5 11.5T440-600q0 17 11.5 28.5T480-560Zm1 240Zm-1-280Z" />
                  </svg> */}
              <div>
                <Typography variant="h6">
                  Teams
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Click on + to create new team.
                </Typography>
              </div>
            </div>
          </div>
        {spin === false && user.role !== "member" && (<div className="mb-3">
       
        </div>)}


          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

          {user.role !== "member" ?  (<Grid item xs={2} sm={4} md={4}  >
              <Card sx={{ display: 'flex', minHeight: '100px' }} className='card-shadow-hover' onClick={() => {
                showForm(null)
              }}>
                <Card
                  sx={{ width: 120, display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'solid #f2f2f2 1px' }}
                >
                  <AddIcon sx={{ fontSize: '4rem', color: 'rgba(76, 172, 107, 0.5)' }} />
                </Card>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6">
                   New Team
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" component="div">
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>): teams.length <= 0 ? (

              <Card style={{textAlign:'center',color:'#a9a3b7',padding:'3rem 0rem',width:'100vw'}}>
                
              <Typography variant="h5" gutterBottom>
 You've not created or joined any teams yet!
</Typography>

<Typography variant="h7" gutterBottom>
 When you do, they will be show up here.
</Typography>


          </Card>
          
            ): null}

            {teams.map((team, index) => (
              <Grid item xs={2} sm={4} md={4} key={index} >
                <Card sx={{ display: 'flex', minHeight: '100px' }} className='card-shadow-hover' onClick={() => {
                  navigate(`/teams/${team.id}`)
                }}>
                  <Card
                    sx={{ width: 120, display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'solid #f2f2f2 1px' }}
                  >
                    {team.logo != undefined && team.logo != "" ? (<img src={team.logo} alt="team logo" style={{ height: '100px' }} />) : (<GroupWorkOutlinedIcon sx={{ fontSize: '4.5rem', color: 'rgba(76, 172, 107, 0.5)' }} />)}
                  </Card>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" variant="h6">
                        {team.name}
                      </Typography>
                      <Typography variant="subtitle2" color="text.secondary" component="div">
                        {/* Mac Miller */}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
 

        <Dialog open={openPopup} onClose={hideForm} fullWidth maxWidth="sm">
          <DialogTitle>
            <b>Team</b>

          </DialogTitle>
          <Divider />
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <div style={{ marginTop: '10px' }}>
                <TextField
                  label="Name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  required
                  error={showFormValidate && !formData.name.trim()}
                  helperText={showFormValidate && !formData.name.trim() ? 'Team name is required' : ''}
                />
              </div>
            </form>
          </DialogContent>
          <Divider />
          <DialogActions className="m-2">
            <Button onClick={hideForm} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" onClick={handleSubmit} color="primary" size='large' sx={{ textTransform: 'none' }}>
              Create Team
            </Button>
          </DialogActions>

        </Dialog>


        <ShowError Error={showError} />
      </div>
      <Spinner spin={spin} />
    </>
  );
};

export default Teams;
