import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; // Make sure to import getStorage from the correct path
import { getFunctions, httpsCallable } from 'firebase/functions';
import { config } from "exceljs";


const firebaseConfig = {
  apiKey: "AIzaSyBVcijDxnHPG7dYRr3JQCVJMpo-1GBNfRw",
  authDomain: "app.agilebin.com",
  projectId: "agilebin-com",
  storageBucket: "agilebin-com.appspot.com",
  messagingSenderId: "584919682931",
  appId: "1:584919682931:web:1095cf2d069923f9a6ff06",
  measurementId: "G-E01C4EQD0M"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);

