import React from "react";
import { useState, useEffect, useContext,useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { firestore } from "../../firebase";
import { useGlobalState } from "../../GlobalProvider";
import Offer from "../Reusable/Offer/Offer"
import {
  query,
  collection,
  onSnapshot,
  doc,
  where,
  setDoc,
  limit,
  orderBy
} from "@firebase/firestore";
import ShowError from "../Reusable/ShowError/ShowError";
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import Recommendation from "../Reusable/Recommendation/Recommendation";
import { getErrorObject } from "../../functions/Common"
import { Opacity } from "@mui/icons-material";
import Button from '@mui/material/Button';
import CreateNew from '../Reusable/CreateNew/CreateNew'
import Paper from '@mui/material/Paper';
import AssistantIcon from '@mui/icons-material/Assistant';
import { Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Card = ({ svgPath, title, linkTo, color }) => {
  const navigate = useNavigate();

  const handleCardClick = (event) => {
    event.preventDefault();
    navigate(linkTo);
  };

  

  return (
    <div className="maincard mb-3">
      <div onClick={handleCardClick} style={{ backgroundColor: "" }}>
        <span className="custom-span">
          <svg id="svg-maincard" xmlns="http://www.w3.org/2000/svg" fill={color} className="maincard-icon-dashboard" viewBox="0 -960 960 960" style={{ width: '50px', height: '50px', color: color, backgroundColor:"white", borderRadius: "50%", padding: "12px", fill: "white" }}>
            <path d={svgPath} fill={color} />
          </svg>
        </span>
        <p className="mt-2"  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',color:"white" }}>
          {title}
        </p>
      </div>
    </div>
  );
};

const Notification = ({ user, toggleSidebar }) => {
  const { state } = useGlobalState();
  //  let user = user;
  const [spin, setSpin] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [data, setData] = useState([]);
  const [activeNotificationsCount, setactiveNotificationsCount] = useState();

  const navigate = useNavigate();


  useEffect(() => {


    async function getNotificationData() {
      try {
        setSpin(true);
        if (state.notificationsglobaldata != undefined) {
          setactiveNotificationsCount(state.notificationsglobaldata.length);
          setData(state.notificationsglobaldata);
        }
        setSpin(false);
      } catch (err) {
        setShowError({ show: true, errordata: getErrorObject(err, user, "Dashboard", "getNotificationData") });
        setSpin(false);
      }
    }
    getNotificationData();
  }, [state.notificationsglobaldata]);

  const handleClearNotification = async (notification, index) => {
    try {
      const notificationRef = doc(firestore, "notifications", notification.id);
      await setDoc(notificationRef, { status: "cleared" }, { merge: true });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Dashboard", "handleClearNotification") });
      setSpin(false);
    }
  };

  const handleOpenNotification = async (notification, index) => {
    try {
      const notificationRef = doc(firestore, "notifications", notification.id);
      await setDoc(
        notificationRef,
        { status: "cleared" },
        { merge: true }
      ).then(() => {
        if (notification.doctype == "fistoffive") {
          navigate(`/fistoffive/vote/${notification.docid}`)
        } else if (notification.doctype == "board") {
          navigate(`/retrospective/${notification.docid}`)
        } else if (notification.doctype == "actionitems") {
          navigate(`/actionitems/${notification.docid}`)
        }else if (notification.doctype == "leancoffeemeetings") {
          navigate(`/leancoffeemeeting/${notification.docid}`)
        }  else if (notification.doctype == "poker") {
          navigate(`/poker/${notification.docid}`)
        } else if (notification.doctype == "spinwheel") {
          navigate(`/spinwheels/spinwheel/${notification.docid}`)
        } else if (notification.doctype == "teams") {
          navigate(`/teams/${notification.docid}`)
        } else if (notification.doctype == "assessements") {
          navigate(`/assessments/assessmentresponse/${notification.docid}`)
        } else if (notification.doctype == "userinvite") {
          navigate(`/users/userinvites/${notification.docid}`)
        }
        else if (notification.doctype == "fivewhys"){
          navigate(`/fivewhys/${notification.docid}`)
        }
        else if (notification.doctype == "risks"){
          navigate(`/risks`, { state: { notification: true, docid: notification.docid} });
        }
        else if (notification.doctype == "okrs"){
          navigate(`/okrs`, { state: { notification: true, docid: notification.docid} });
        }
        else if (notification.doctype == "dependencies"){
          navigate(`/dependencies`, { state: { notification: true, docid: notification.docid} });
        }
        else if (notification.doctype == "impediments"){
          navigate(`/impediments`, { state: { notification: true, docid: notification.docid} });
        }
        else if (notification.doctype == "feature"){
          navigate(`/features`, { state: { notification: true, docid: notification.docid} });
        } 
        else if (notification.doctype == "programboards"){
          navigate(`/programboard/${notification.docid}`, { state: { notification: true, docid: notification.docid} });
        }

      });

      setData((prevData) => {
        const updatedData = [...prevData];
        if(updatedData >= 1){
          updatedData[index].status = "cleared";
        }
        return updatedData;
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Dashboard", "handleOpenNotification") });
      setSpin(false);
    }
  };

  const handleClearAllNotifications = async () => {

    try {
      setSpin(true);
      data.forEach(element => {
        const notificationRef = doc(firestore, "notifications", element.id);
        setDoc(notificationRef, { status: "cleared" }, { merge: true });
      });
      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Notifications", "handleClearAllNotifications") });
      setSpin(false);
    }
  };

  const handleToggle = () => {
    try {
      toggleSidebar();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Dashboard", "handleToggle") });
      setSpin(false);
    }

  };
  

  return (
    <>
      {activeNotificationsCount > 0 && (
        <div>
          <div style={{ marginBottom: "20px", marginTop: "5px" }}>
            <div className="col-md-12 bg-p">
              <div style={{ height: "150px", overflowY: "auto" }}>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ paddingLeft: "10px" }}>
                      Notifications
                    </b>
                    <Button
                        variant="outlined"
                      onClick={handleClearAllNotifications}
                    >
                      Clear All Notifications
                    </Button>
                  </div>
                  {data
                    .filter((notification) => notification.status !== "cleared")
                    .map((notification, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "white",
                          border: "1px solid #ddd",
                          boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px",
                          margin: "5px",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ flex: "1" }}>
                          {notification.message}
                        </div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <p className="mt-2" style={{ fontSize: "13px" }}>
                            {(() => {
                              const currentDate = new Date();

                              if (notification.createddate) {
                                const createdDate = new Date(
                                  notification.createddate.seconds * 1000 +
                                  notification.createddate.nanoseconds / 1000000
                                );

                                if (
                                  createdDate.getDate() === currentDate.getDate() &&
                                  createdDate.getMonth() === currentDate.getMonth() &&
                                  createdDate.getFullYear() === currentDate.getFullYear()
                                ) {
                                  // Today's date
                                  return new Intl.DateTimeFormat('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  }).format(createdDate);
                                } else if (
                                  createdDate.getDate() === currentDate.getDate() - 1 &&
                                  createdDate.getMonth() === currentDate.getMonth() &&
                                  createdDate.getFullYear() === currentDate.getFullYear()
                                ) {
                                  // Yesterday's date
                                  return new Intl.DateTimeFormat('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                  }).format(createdDate);
                                } else {
                                  // Other dates
                                  return new Intl.DateTimeFormat('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                  }).format(createdDate);
                                }
                              } else {
                                return 'No date available';
                              }
                            })()}
                          </p>
                          &nbsp;
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleOpenNotification(notification, index)
                            }
                          >
                            Open
                          </Button>
                          <Button
                           variant="outlined"
                            onClick={() =>
                              handleClearNotification(notification, index)
                            }
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ShowError Error={showError} />
      {/* <Spinner spin={spin} /> */}
    </>
  );
};

const Dashboard = ({ user }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { state } = useGlobalState();
  const navigate = useNavigate();
  const [openRecommendationDialog, setOpenRecommendationDialog] = useState(false); 
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollLeft -= 200;
  };

  const scrollRight = () => {
    scrollRef.current.scrollLeft += 200;
  };
  
  useEffect(() => {
    function checkAgilebinVersion() {
      if (state != undefined && !openRecommendationDialog && state.agilebinversionglobaldata != null && state.agilebinversionglobaldata != "") {
        const localVersion = window.localStorage.getItem("agilebin-version");
        if (!localVersion) {
          window.localStorage.setItem("agilebin-version", state.agilebinversionglobaldata);
        }
        else {
          if (localVersion != state.agilebinversionglobaldata) {
            window.localStorage.setItem("agilebin-version", state.agilebinversionglobaldata);
            window.location.reload();
          }
        }
      }
    }
    checkAgilebinVersion();
  }, [state.agilebinversionglobaldata])
  const handleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const getLatestVersion = async () => {
    window.location.reload();
  }

  const teamName = state.teamName;

  const handleOpenRecommendation = () => {
    setOpenRecommendationDialog(true);
  };

  return (
    <div className={`sideNav-top listPage-transition ${isOpen ? "side-margin" : ""}`}>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />

      <div className="container bg-p" >
        <div className="container mb-4" style={{ marginTop: "60px" }}>

          <Notification user={user} />
          {state.subscriptionglobaldata.subscription == "False" && <Offer showButton={true}/>}

          <div className="mb-1 mt-4">
          <Paper className="outer-card" style={{opacity:"0.9",background:"linear-gradient(0deg, rgba(29,88,173,1) 15%, rgba(87,167,247,1) 73%)"}}>          
          <h3 className="text-center" style={{fontWeight:"500", marginBottom:"2rem",color:"white"}}>What would you like to do today{state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname!= "none" && state.currentteam.teamname != "" && ` for team ${state.currentteam.teamname}`}?</h3>
          <div className="horizontal-scroll-container">

          <button className="scroll-button left" onClick={scrollLeft} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <ArrowBackIosIcon />
</button>
      <div className="scrollable-cards" ref={scrollRef}>

      <Link to="/fistoffive/votings" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M402-40q-30 0-56-13.5T303-92L48-465l24-23q19-19 45-22t47 12l116 81v-383q0-17 11.5-28.5T320-840q17 0 28.5 11.5T360-800v537L212-367l157 229q5 8 14 13t19 5h278q33 0 56.5-23.5T760-200v-560q0-17 11.5-28.5T800-800q17 0 28.5 11.5T840-760v560q0 66-47 113T680-40H402Zm38-440v-400q0-17 11.5-28.5T480-920q17 0 28.5 11.5T520-880v400h-80Zm160 0v-360q0-17 11.5-28.5T640-880q17 0 28.5 11.5T680-840v360h-80ZM486-300Z"
                title="Fist Of Five"
                description="Start new Fist of Five voting or access your past First of Five results."
                linkTo="fistoffive/votings"
                color="#e73673"

              />
            </Link>

            <Link to="/retrospective/meetings" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M160-240h160v-480H160v480Zm240 0h160v-480H400v480Zm240 0h160v-480H640v480Zm-480 80q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Z"
                title="Retrospective"
                description="Start new Retrospective or access your past Retrospective boards."
                linkTo="retrospective/meetings"
                color="#4cac6b"
              />
            </Link>

            <Link to="/poker/games" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="m608-368 46-166-142-98-46 166 142 98ZM160-207l-33-16q-31-13-42-44.5t3-62.5l72-156v279Zm160 87q-33 0-56.5-24T240-201v-239l107 294q3 7 5 13.5t7 12.5h-39Zm206-5q-31 11-62-3t-42-45L245-662q-11-31 3-61.5t45-41.5l301-110q31-11 61.5 3t41.5 45l178 489q11 31-3 61.5T827-235L526-125Zm-28-75 302-110-179-490-301 110 178 490Zm62-300Z"
                title="Poker Games"
                description="Start new Poker Game for story pointing or access your past Poker Games results."
                linkTo="poker/games"
                color="#fe9f1b"
              />
            </Link>

            <Link to="/assessments" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880h40v331q18 11 29 28.5t11 40.5q0 33-23.5 56.5T480-400q-33 0-56.5-23.5T400-480q0-23 11-41t29-28v-86q-52 14-86 56.5T320-480q0 66 47 113t113 47q66 0 113-47t47-113q0-36-14.5-66.5T586-600l57-57q35 33 56 78.5t21 98.5q0 100-70 170t-170 70q-100 0-170-70t-70-170q0-90 57-156.5T440-717v-81q-119 15-199.5 105T160-480q0 134 93 227t227 93q134 0 227-93t93-227q0-69-27-129t-74-104l57-57q57 55 90.5 129.5T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"
                title="Assessments"
                description="Start new Maturity Assessments or access your past Assessments data. "
                linkTo="assessments"
                color="#3892ee"
              />
            </Link>

            {/* <Link to="/boards" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                icon="fas fa-chalkboard"
                title="Agilebin Boards"
                description="Start new board or access your past Agilebin boards."
                linkTo="boards"
                color="#a83c5c"
              />
            </Link> */}
             <Link to="/actionitems" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M222-200 80-342l56-56 85 85 170-170 56 57-225 226Zm0-320L80-662l56-56 85 85 170-170 56 57-225 226Zm298 240v-80h360v80H520Zm0-320v-80h360v80H520Z"
                title="Action Items"
                description="Start new board or access your past Agilebin boards."
                linkTo="actionitems"
                color="#a83c5c"
              />
            </Link>

            <Link to="/okrs" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-80q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70Zm0-80q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-80q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Z"
                title="OKRs"
                description="Start new OKRs or access your past OKRs."
                linkTo="okrs"
                color="#8c564b"
              />
            </Link>

            <Link to="/dailyscrum " style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"
                title="Daily Scrum"
                description="Start new daily scrum or access your past Daily Scrum."
                linkTo="dailyscrum"
                color="#2ca02c"
              />
            </Link>

            
            <Link to="/impediments" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M480-800 243-663l237 137 237-137-237-137ZM120-321v-318q0-22 10.5-40t29.5-29l280-161q10-5 19.5-8t20.5-3q11 0 21 3t19 8l280 161q19 11 29.5 29t10.5 40v159h-80v-116L479-434 200-596v274l240 139v92L160-252q-19-11-29.5-29T120-321ZM720-80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm-20-80h40v-160h-40v160ZM720 0q-83 0-141.5-58.5T520-200q0-83 58.5-141.5T720-400q83 0 141.5 58.5T920-200q0 83-58.5 141.5T720 0ZM480-491Z"
                title="Impediments"
                description="Start new Impediment or access your past Impediments."
                linkTo="impediments"
                color="#d62728"
              />
            </Link>

                 
            <Link to="/risks" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M480-281q14 0 24.5-10.5T515-316q0-14-10.5-24.5T480-351q-14 0-24.5 10.5T445-316q0 14 10.5 24.5T480-281Zm-30-144h60v-263h-60v263ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm25-60h250l175-175v-250L605-780H355L180-605v250l175 175Zm125-300Z"
                title="Risks"
                description="Start new Risks or access your past Risks."
                linkTo="risks"
                color="#9467bd"
              />
            </Link>

            <Link to="/dependencies" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M600-120v-120H440v-400h-80v120H80v-320h280v120h240v-120h280v320H600v-120h-80v320h80v-120h280v320H600ZM160-760v160-160Zm520 400v160-160Zm0-400v160-160Zm0 160h120v-160H680v160Zm0 400h120v-160H680v160ZM160-600h120v-160H160v160Z"
                title="Dependencies"
                description="Start new dependency or access your past depedency."
                linkTo="dependencies"
                color="#ff7f0e"
              />
            </Link>

            <Link to="/leancoffeemeetings" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M160-120v-80h640v80H160Zm160-160q-66 0-113-47t-47-113v-400h640q33 0 56.5 23.5T880-760v120q0 33-23.5 56.5T800-560h-80v120q0 66-47 113t-113 47H320Zm0-480h320-400 80Zm400 120h80v-120h-80v120ZM560-360q33 0 56.5-23.5T640-440v-320H400v16l72 58q2 2 8 16v170q0 8-6 14t-14 6H300q-8 0-14-6t-6-14v-170q0-2 8-16l72-58v-16H240v320q0 33 23.5 56.5T320-360h240ZM360-760h40-40Z"
                title="Lean Coffee"
                description="Start new meeting or access your past Lean Coffee Meetings. "
                linkTo="leancoffeemeetings"
                color="#5d5d86"
              />
            </Link>

            <Link to="/spinwheels" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="m233-80 54-122q-14-11-27-21.5T235-246q-8 3-15.5 4.5T203-240q-33 0-56.5-23.5T123-320q0-20 8.5-36.5T155-384q-8-23-11-46.5t-3-49.5q0-26 3-49.5t11-46.5q-15-11-23.5-27.5T123-640q0-33 23.5-56.5T203-720q9 0 16.5 1.5T235-714q33-36 75.5-60t90.5-36q5-30 27.5-50t52.5-20q30 0 52.5 20.5T561-810q48 12 90.5 35.5T727-716q8-3 15-4.5t15-1.5q33 0 56.5 23.5T837-642q0 20-8 35.5T807-580q8 24 11 49t3 51q0 26-3 50.5T807-382q14 11 22 26.5t8 35.5q0 33-23.5 56.5T757-240q-8 0-15-1.5t-15-4.5q-12 12-24.5 23.5T675-200l52 120h-74l-38-88q-14 6-27 10.5t-27 7.5q-5 29-27.5 49.5T481-80q-30 0-52.5-20T401-150q-15-3-28.5-7.5T345-168l-38 88h-74Zm76-174 62-140q-14-18-22-40t-8-46q0-57 41.5-98.5T481-620q57 0 98.5 41.5T621-480q0 24-8.5 47T589-392l62 138q9-8 17.5-14.5T685-284q-5-8-6.5-17.5T677-320q0-32 22-55t54-25q6-20 9-39.5t3-40.5q0-21-3-41.5t-9-40.5q-32-2-54-25t-22-55q0-9 2.5-17.5T685-676q-29-29-64-49t-74-31q-11 17-28 26.5t-38 9.5q-21 0-38-9.5T415-756q-41 11-76 31.5T275-674q3 8 5.5 16.5T283-640q0 32-21 54.5T209-560q-6 20-9 39.5t-3 40.5q0 21 3 40.5t9 39.5q32 2 53 25t21 55q0 9-1.5 17.5T275-286q8 9 16.5 16.5T309-254Zm60 34q11 5 22.5 9t23.5 7q11-17 28-26.5t38-9.5q21 0 38 9.5t28 26.5q12-3 22.5-7t21.5-9l-58-130q-12 5-25 7.5t-27 2.5q-15 0-28.5-3t-25.5-9l-58 132Zm112-200q24 0 42-17t18-43q0-24-18-42t-42-18q-26 0-43 18t-17 42q0 26 17 43t43 17Zm0-60Z"
                title="SpinWheels"
                description="Start new Spin Wheel or access your past Spin Wheels."
                linkTo="spinwheels"
                color="#38383e"
              />
            </Link>
              
            <Link to="/fishbones" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M120-360q-33 0-56.5-23.5T40-440v-360q0-33 23.5-56.5T120-880h720q33 0 56.5 23.5T920-800v360q0 33-23.5 56.5T840-360H120Zm0-440v360h720v-360H120Zm3 580-3-60 719-38 3 60-719 38Zm-3 99v-60h720v60H120Zm290-379q74 0 142.5-26T672-606q6 42 44 64t84 22v-200q-46 0-84 22.5T672-632q-53-52-120.5-80T410-740q-79 0-152 27.5T140-620q45 65 118 92.5T410-500ZM120-800v360-360Z"
                title="Fishbones"
                description="Start new Fishbone or access your past fishbones."
                linkTo="fishbones"
                color="#1f77b4"
              />
            </Link>

            
            <Link to="/fivewhys" style={{ display: "s-block", width: "auto", height: "auto" }}>
              <Card
                svgPath="M320-200v-120h240v-100H320v-340h360v120H440v100h120q50 0 85 35t35 85v100q0 50-35 85t-85 35H320Z  "
                title="Fivewhys"
                description="Start new Fivewhy or access your past fivewhys."
                linkTo="fivewhys"
                color="#17becf"
              />
            </Link>

        {/* Add more cards as needed */}
      </div>
   
<button className="scroll-button right" onClick={scrollRight} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <ArrowForwardIosIcon />
</button>
    </div>

          </Paper>
</div>


<div className="mt-2">
  <p style={{fontWeight:"500", color:"#333333",fontSize:"22px"}}>Quick Access</p>
</div>

        {user.role !== "member"  && (<div className="row d-flex flex-row mb-2 ">
          <button
                  style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto", }}
                  className="square-item"
                 onClick={()=>{
                  navigate(`/fistoffive/votings`, { state: { dashboard: true } });
                 }}
                >
                   <CreateNew title='New Fist of Five' color='#e73673'/>
                </button>

                <button
                  style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
                  className="square-item"
                  onClick={()=>{
                    navigate(`/retrospective/meetings`);
                   }}
                >
                  <CreateNew title='New Retrospective' color='#4cac6b'/>
                </button>

                <button
                  style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
                  className="square-item"
                  onClick={()=>{
                    navigate(`/poker/games`, { state: { dashboard: true } });
                   }}
                >
                  <CreateNew title='New Poker Game' color='#fe9f1b'/>
                </button>

                <button
                  style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
                  className="square-item"
                  onClick={()=>{
                    navigate(`/assessments`, { state: { dashboard: true } });
                   }}
                >
                  <CreateNew title='New Assessment' color='#3892ee'/>
                </button>
                
                


          </div>)}

          <div style={{ position: "fixed", bottom: "20px", right: "20px", animation: "float 3s infinite" }}>
            <button
              style={{
                background: "#3892ee", 
                color: "white", // Text color
                border: "none",
                borderRadius: "30px", // Rounded corners
                padding: "15px 30px", // Padding
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Box shadow for depth
                display: "flex",
                alignItems: "center", // Align items vertically
                gap: "10px", // Gap between icon and text
                cursor: "pointer",
                fontWeight: "bold", // Bold text
                fontSize: "18px", // Text size
                transition: "all 0.3s ease-in-out", // Smooth transition for hover effect
              }}
              onClick={handleOpenRecommendation}
              onMouseEnter={(e) => e.target.style.transform = "scale(1.05)"}
              onMouseLeave={(e) => e.target.style.transform = "scale(1)"}
            >
              <AssistantIcon style={{ width: "22px", height: "22px" }} /> {/* Replace "ai_icon.png" with your AI icon image */}
              <span style={{fontSize: '14px'}}>Enjoying Agilebin? Share with friends!</span>
            </button>
          </div>


          <Recommendation open={openRecommendationDialog} handleClose={setOpenRecommendationDialog} user={user} />
          {window.localStorage.getItem("agilebin-version") != undefined && (
            <div style={{ fontSize: "8px" }}>
              Version {window.localStorage.getItem("agilebin-version")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
