import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../../firebase";
import {
  collection,
  onSnapshot,
  where,
  query,
  doc,
  Timestamp,
  setDoc,
  orderBy,
  limit,
  startAfter,
  endBefore,
  getDocs,
  limitToLast,
} from "@firebase/firestore";
import {useGlobalState} from "../../../GlobalProvider"
import Spinner from "../../Reusable/Spinner/Spinner";
import ShowError from "../../Reusable/ShowError/ShowError";
import DocumentMissing from "../../Reusable/DocumentMissing/DocumentMissing";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../../Reusable/Sidebar/Sidebar";
import Navbar from "../../Reusable/Navbar/Navbar";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ProAlert from "../../Reusable/ProAlert/ProAlert"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import CreateNew from "../../Reusable/CreateNew/CreateNew";
import Card from '@mui/material/Card';
import {Typography,TextField,Divider} from '@mui/material';

import { showdate, getErrorObject, getUserDetails, postlog } from "../../../functions/Common"
const Spinwheels = ({ user }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [spin, setSpin] = useState(false);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [data, setData] = useState([]);
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
  });

  const navigate = useNavigate();
  const [openSPopup, setOpenSPopup] = useState(false);
  const [showFormValidate, setShowFormValidate] = useState(false);
  const location = useLocation();
  const { state } = useGlobalState();

  useEffect(() => {
    let unsubscribe = null;
    // React advises to declare the async function directly inside useEffect
    if (location.state && location.state.team) {
      showForm()
      navigate(location.pathname, { state: { team: false } });
    }

    async function getSpinWheels() {
      try {
        setSpin(true);
        let q;
        if(state.currentteam.teamid != "" && state.currentteam.teamid != "none"){
         q = await query(
          collection(firestore, "spinwheels"),
          where("users", "array-contains", user.userid),
          where("teamid","==",state.currentteam.teamid),
          orderBy("createddate", "desc"),
          limit(5),
        );
         }else{
          q = await query(
            collection(firestore, "spinwheels"),
            where("users", "array-contains", user.userid),
            orderBy("createddate", "desc"),
            limit(5),
          );
         }
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = await onSnapshot(q, (snapshot) => {
          setData([]);
          snapshot.forEach((doc) => {
            const spinwheeldata = doc.data();
            setData((prevMember) => {
              return [...prevMember, spinwheeldata];
            });
          });
        //  postlog("read spinwheels", { component: "spinwheels", function: "getSpinWheels", collection: "spinwheels", uid: user.userid });
          setFirstVisible(snapshot.docs[0])
          setLastVisible(snapshot.docs[snapshot.docs.length - 1])
          setSpin(false);
        },
          (err) => {
            setShowError({ show: true, errordata: getErrorObject(err, user, "SpinWheels", "getSpinWheels") });
            setSpin(false);
          });
      } catch (err) {
        setShowError({
          show: true,
          errordata: getErrorObject(err, user, "SpinWheels", "getSpinWheels"),
        });
        setSpin(false);
      }
    }

    getSpinWheels();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [state.currentteam]);

  async function loadNext() {
    setDisablePrevious(false);

    try {
      const nextQuery = query(
        collection(firestore, 'spinwheels'),
        where("users", "array-contains", user.userid),
        orderBy("createddate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );

      const snapshot = await getDocs(nextQuery);
     // postlog("read spinwheels", { component: "spinwheels", function: "loadNext", collection: "spinwheels", uid: user.userid });


      const spinWheels = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        spinWheels.push(newdata);
      });

      if (snapshot.docs.length > 0) {
        setData(spinWheels);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      if (snapshot.docs.length < 5) {
        setDisableNext(true);
      }

      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "spinwheels", "loadNext")
      });
      setSpin(false);
    }
  }

  async function loadPrevious() {
    setDisableNext(false);

    try {
      const previousQuery = query(
        collection(firestore, 'spinwheels'),
        where("users", "array-contains", user.userid),
        orderBy("createddate", "desc"),
        endBefore(firstVisible),
        limitToLast(5),
      );

      const snapshot = await getDocs(previousQuery);
      //postlog("read spinwheels", { component: "spinwheels", function: "loadPrevious", collection: "spinwheels", uid: user.userid });

      const spinWheels = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        spinWheels.push(newdata);
      });

      if (snapshot.docs.length > 0) {
        setData(spinWheels);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setDisablePrevious(!snapshot.docs.length);
      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "spinwheels", "loadPrevious")
      });
      setSpin(false);
    }
  }


  const handleSubmit = async (e) => {
    try {
      if (formData.name.trim()) {
      e.preventDefault()
      const docref = doc(collection(firestore, "spinwheels"));
      await setDoc(docref, {
        id: docref.id,
        spinwheelname: formData.name,
        createddate: Timestamp.fromDate(new Date()),
        updateddate: Timestamp.fromDate(new Date()),
        createdbyuserid: user.userid,
        createdbyusername: user.username,
        createdbyuserprofile: user.profilepicture != undefined ? user.profilepicture : "",
        createdbyuseremail: user.useremail,
        updatedbyuserid: user.userid,
        updatedbyusername: user.username,
        updatedbyuserprofile: user.profilepicture != undefined ? user.profilepicture : "",
        updatedbyuseremail: user.useremail,
        choices: ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
        users: [user.userid],
        usersdetails: { [user.userid]: getUserDetails(user) },
        level: "public",
        teamid : state.currentteam.teamid ? state.currentteam.teamid :  "",
        teamname : state.currentteam.teamname ? state.currentteam.teamname :  ""
      }).then(() => {
      //  postlog("write spinwheels", { component: "spinwheels", function: "handleSubmit", collection: "spinwheels" });
        postlog("create spinwheel", { component: "spinwheels", function: "handleSubmit", collection: "spinwheels" });
        navigate(`/spinwheels/spinwheel/${docref.id}`, { state: { newdoc: true } });
      });
    }else{
      setShowFormValidate(true);
  }
      setSpin(false);

    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "SpinWheels", "handleSubmit") });
    }
  };

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "SpinWheels", "handleSidebar") });
      setSpin(false);
    }
  };

  function showForm() {
    try {
      setShowFormValidate(false);
      handleopenSPopup()
      // document.getElementById("boardNameInput")?.focus()
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "showForm") });
      setSpin(false);
    }
  }

  const hideForm = () => {
    try {
      setFormData({
        name: "",
      });
      handleClosespopup();
      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "hideForm") });
      setSpin(false);
    }
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "handleChange") });
      setSpin(false);
    }
  };


  const handleopenSPopup = () => {
    setOpenSPopup(true);
    // document.getElementById("questionInput")?.focus()
  };

  const handleClosespopup = () => {
    setOpenSPopup(false);
  };

  return (
    <>
       
      <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{width:'75%'}}>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
          <div className="row g-0 mb-2">
                <div className="d-flex gap-2 align-items-center mb-2" >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', fill: 'white', backgroundColor: 'rgba(56, 56, 62, 0.8)'  , borderRadius: "20%", padding: "10px" }}>
                    <path class="svg-icon" d="m233-80 54-122q-14-11-27-21.5T235-246q-8 3-15.5 4.5T203-240q-33 0-56.5-23.5T123-320q0-20 8.5-36.5T155-384q-8-23-11-46.5t-3-49.5q0-26 3-49.5t11-46.5q-15-11-23.5-27.5T123-640q0-33 23.5-56.5T203-720q9 0 16.5 1.5T235-714q33-36 75.5-60t90.5-36q5-30 27.5-50t52.5-20q30 0 52.5 20.5T561-810q48 12 90.5 35.5T727-716q8-3 15-4.5t15-1.5q33 0 56.5 23.5T837-642q0 20-8 35.5T807-580q8 24 11 49t3 51q0 26-3 50.5T807-382q14 11 22 26.5t8 35.5q0 33-23.5 56.5T757-240q-8 0-15-1.5t-15-4.5q-12 12-24.5 23.5T675-200l52 120h-74l-38-88q-14 6-27 10.5t-27 7.5q-5 29-27.5 49.5T481-80q-30 0-52.5-20T401-150q-15-3-28.5-7.5T345-168l-38 88h-74Zm76-174 62-140q-14-18-22-40t-8-46q0-57 41.5-98.5T481-620q57 0 98.5 41.5T621-480q0 24-8.5 47T589-392l62 138q9-8 17.5-14.5T685-284q-5-8-6.5-17.5T677-320q0-32 22-55t54-25q6-20 9-39.5t3-40.5q0-21-3-41.5t-9-40.5q-32-2-54-25t-22-55q0-9 2.5-17.5T685-676q-29-29-64-49t-74-31q-11 17-28 26.5t-38 9.5q-21 0-38-9.5T415-756q-41 11-76 31.5T275-674q3 8 5.5 16.5T283-640q0 32-21 54.5T209-560q-6 20-9 39.5t-3 40.5q0 21 3 40.5t9 39.5q32 2 53 25t21 55q0 9-1.5 17.5T275-286q8 9 16.5 16.5T309-254Zm60 34q11 5 22.5 9t23.5 7q11-17 28-26.5t38-9.5q21 0 38 9.5t28 26.5q12-3 22.5-7t21.5-9l-58-130q-12 5-25 7.5t-27 2.5q-15 0-28.5-3t-25.5-9l-58 132Zm112-200q24 0 42-17t18-43q0-24-18-42t-42-18q-26 0-43 18t-17 42q0 26 17 43t43 17Zm0-60Z" />
                  </svg> */}
                  <div>
                    <Typography variant="h6">
                    SpinWheels {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname!= "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                    Click on + to create new spinwheel.
                    </Typography>
                  </div>
                </div>
          </div>
        {spin === false && user.role !== "member" && (<div className="mb-3">
          <div className="row">
            <button
              onClick={showForm}
              style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
              className="square-item"
            >
               <CreateNew title='Create New' color='#fffff'/>
            </button>
          </div>
        </div>)}


        {spin === false && data !== undefined && user && data.length !== 0 ? (
          <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{width:'10%'}}>Created At</TableCell>
                    <TableCell sx={{width:'15%'}}>Creator</TableCell>
                    <TableCell sx={{ width: '30%' }}>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((board, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          navigate(`/spinwheels/spinwheel/${board.id}`)
                        }}
                        key={board.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{showdate(board.createddate, user.timezone)}</TableCell>
                        <TableCell>
                          <Tooltip title={board.createdbyusername}>
                          <div className="d-flex gap-2 align-items-center" style={{ padding: '10px' }}>
                            {board.createdbyuserprofile && board.createdbyuserprofile != "" ? (
                              <Avatar alt="Remy Sharp" sx={{ width: 28, height: 28 }} src={board.createdbyuserprofile} />
                            ) : (
                              <AccountCircleIcon sx={{ width: 28, height: 28,color:'#5f6368' }} ></AccountCircleIcon>)}
                                   <Typography>
                                                  {board.createdbyusername}
                                                </Typography>
                              </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell> {board.spinwheelname}</TableCell>
                   
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        ) :
          (
            spin === false && data !== undefined && user && data.length === 0 && (
              <Card style={{textAlign:'center',color:'#a9a3b7',padding:'3rem 0rem'}}>
                    <Typography variant="h5" gutterBottom>
       You've not created any spinwheel yet!
      </Typography>
      <Typography variant="h7" gutterBottom>
       When you do, they will be show up here.
      </Typography>
                </Card>
            )
          )}


     
{data && data.length !== 0 && (
                <div className="d-flex justify-content-end mb-2">
                  <ul className="pagination">
                    <li className="page-item">
                      <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                        <KeyboardArrowLeftIcon />
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link" onClick={loadNext} disabled={disableNext} >
                        <KeyboardArrowRightIcon />
                      </button>
                    </li>
                  </ul>
                </div>
              )}


<Dialog open={openSPopup} onClose={hideForm} fullWidth maxWidth="sm">
      <DialogTitle>Spinwheel {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname!= "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}</DialogTitle>
      <Divider />
      <DialogContent>
        <form id="seriesForm" onSubmit={handleSubmit}>
          <div style={{marginTop:'10px'}}>
            <TextField
              id="boardNameInput"
              label="Spinwheel Name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
              error={showFormValidate && !formData.name.trim()}  
              helperText={showFormValidate && !formData.name.trim() ? 'Spinwheel Name is required' : ''}
              autoFocus
            />
          </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions className="m-2">
        <Button onClick={hideForm} color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit} color="primary" size='large' sx={{textTransform:'none'}}>
          Create Spinwheel
        </Button>
      </DialogActions>
    </Dialog>
        {/* <div id="formPopup" className="modal ">
          <div className="modal-content popup-animitation ">
            <form id="seriesForm" onSubmit={handleSubmit}>
              <div className="modal-header">
                <div>
                  <b> Spinwheel</b>
                </div>
                <div className="circleIconContainer"
                  onClick={hideForm}
                >
                  <span className="circleIcon">&times;</span>
                </div>
              </div>
              <div className="modal-body">
                <label>
                  Spinwheel Name
                  <input
                    id="boardNameInput"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </label>
              </div>
              <div className="modal-footer">
                <Button
                  type="submit"
                  variant="contained"
                  size='large'
                  sx={{textTransform:'none'}}
                >
                  Create Spinwheel
                </Button>
              </div>
            </form>
          </div>
        </div> */}


        <ShowError Error={showError} />
        <DocumentMissing show={showDocumentMissing} />
      </div>
      <Spinner spin={spin} />
    </>
  );
};

export default Spinwheels;
