import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { firestore } from "../../../firebase";
import {
  collection,
  doc,
  setDoc,
  onSnapshot,
  deleteField,
  Timestamp
} from "firebase/firestore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import Spinner from "../../Reusable/Spinner/Spinner";
import Timer from "../../Reusable/Timer/Timer";
import ShowError from "../../Reusable/ShowError/ShowError";
import DocumentMissing from "../../Reusable/DocumentMissing/DocumentMissing";
import Feedback from "../../Reusable/Feedback/Feedback";
import SharePopup from "../../Reusable/Share/share";
import { useParams } from "react-router-dom";
import { getErrorObject, postlog } from "../../../functions/Common"
import { useGlobalState } from "../../../GlobalProvider"
import SettingsIcon from '@mui/icons-material/Settings';
import AlarmIcon from '@mui/icons-material/Alarm';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { set } from "firebase/database";
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { lineHeight } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ProAlert from "../../Reusable/ProAlert/ProAlert"
import Popover from '@mui/material/Popover';
import ColorizeIcon from '@mui/icons-material/Colorize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import PersonIcon from '@mui/icons-material/Person';
import { Typography, Tooltip, Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Radio, FormControlLabel, Checkbox, TextareaAutosize, } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import Chip from '@mui/material/Chip';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DialogContentText from '@mui/material/DialogContentText';
const Board = ({ user, actionboardid, containerData, updateContainerData }) => {
  const { docid } = useParams();
  const [sortedboarddata, setSortedBoardData] = useState();
  const [boarddata, setBoardData] = useState();
  const [formData, setFormData] = useState({
    heading: "",
    description: "",
    user: "",
    username: "",
    columnid: "",
    itemid: "",
    assignedto: { userid: "", profilepicture: "", firstname: "", lastname: "", fullname: "", isanonymous: false },
    duedate: "",
    priority: ""
  });

  const [columnFormData, setColumnFormData] = useState({
    id: "",
    name: "",
    layout: "column",
    columns: [],
    backgroundimage: "",
  });
  const [deleteItemidAndColumnid, setDeleteItemidAndColumnid] = useState({ itemid: "", columnid: "" });
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [spin, setSpin] = useState(false);
  const [newAddedItemId, setNewAddedItemId] = useState();
  const [draggedItem, setDraggedItem] = useState();
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [handleRadioChange, setHandleRadioChange] = useState("PDF");
  const [showFeedBackForm, setShowFeedBackForm] = useState(false);
  const [hideIdentity, setHideIdentity] = useState(false);
  const [nonLoggedInUsername, setNonLoggedInUsername] = useState("");
  const location = useLocation();
  const [editnote, setEditnote] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showPro, setShowPro] = useState(false);
  const [actionUserSelected, setActionUserSelected] = useState(false);
  const [noteId, setNoteId] = useState();
  const [showshare, setShowShare] = useState((location.state != null && location.state.newdoc != null && location.state.newdoc != undefined) == true ? true : false);
  const navigate = useNavigate();
  const { state } = useGlobalState();
  const [showMore, setShowMore] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  useEffect(() => {
    let unsubscribe = null;
    async function getBoardData() {
      try {
        setSpin(true);
        let boardData;
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = onSnapshot(doc(firestore, "boards", actionboardid ? actionboardid : docid),
          (doc) => {
            if (doc.data() != undefined) {
              boardData = doc.data();
              if (updateContainerData) {
                if (actionboardid) {
                  updateContainerData(null, actionboardid, null, user.userid == "" ? nonLoggedInUsername : user.username, null)
                } else {
                  updateContainerData(boardData.id, boardData.actionitemsboard, boardData.name, user.userid == "" ? nonLoggedInUsername : user.username, boardData?.usersdetails[user.userid]?.isowner)
                }
              }

              setBoardData(doc.data());
              const sortedcolumns = [];
              const columnsorder = boardData.columnorder.split(",");
              for (var columnorder in columnsorder) {
                for (var column in boardData.columns) {
                  var columnvalue = boardData.columns[column];
                  let itemsorder = [];
                  const sorteditems = [];
                  if (typeof columnvalue.itemorder === "string") {
                    itemsorder = columnvalue["itemorder"].split(",");
                  } else {
                  }

                  for (var itemorder in itemsorder) {
                    for (var item in boardData.items) {
                      var itemvalue = boardData.items[item];
                      if (item === itemsorder[itemorder])
                        sorteditems.push({
                          description: itemvalue["description"],
                          itemid: item,
                          assignedto: itemvalue.assignedto,
                          duedate: itemvalue.duedate,
                          priority: itemvalue.priority,
                          notecolor: itemvalue.notecolor
                        });
                    }
                  }
                  if (column === columnsorder[columnorder]) {
                    sortedcolumns[columnorder] = {
                      name: columnvalue["columnname"],
                      columnid: column,
                      items: sorteditems,
                    };
                  }
                }
                setSortedBoardData(sortedcolumns);
                setSpin(false);
              }
              // removeDublicateNotes(boardData);
            } else {
              setshowDocumentMissing(true);
            }
            if (user.userid != undefined && user.userid != "" && user.userid != null) {
              postlog("open boards", { component: "board", function: "getBoardData", collection: "boards", docid: actionboardid ? actionboardid : docid, });
            }
            // postlog("read boards", { component: "board", function: "getBoardData", collection: "boards", docid: actionboardid ? actionboardid : docid, });

          },
          (err) => {
            setshowDocumentMissing(true);
          });
        setSpin(false);
      } catch (err) {
        setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "getBoardData") });
        setSpin(false);
      }
    }

    getBoardData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);


  const removeDublicateNotes = async (boarddata) => {
    let allitemorder = [];
    for (const key1 in boarddata.columns) {
      allitemorder = [...allitemorder, ...boarddata.columns[key1].itemorder.split(",")]
    }

    let dublicates = [...new Set(allitemorder.filter((value, index, self) => self.indexOf(value) !== index))];
    if (dublicates.length > 0) {
      let updatedItemorder = {};
      dublicates.forEach(element => {
        let isSkiped = false;
        for (const key in boarddata.columns) {
          if (boarddata.columns[key].itemorder.includes(element)) {
            if (isSkiped == false) {
              updatedItemorder[key] = { itemorder: boarddata.columns[key].itemorder }
              isSkiped = true
            } else {
              updatedItemorder[key] = { itemorder: boarddata.columns[key].itemorder.split(",").filter((e) => e != element).join(",") }
            }
          }
        }
      });

      const docRef = doc(firestore, "boards", actionboardid ? actionboardid : docid);
      await setDoc(
        docRef,
        {
          columns: updatedItemorder,
        },
        { merge: true }
      );
      // postlog("write boards", { component: "board", function: "removeDublicateNotes", collection: "boards", docid: actionboardid ? actionboardid : docid, });
    }
  }

  // let duplicates = array1.filter(value => array2.includes(value))
  // const newitemorder = [...new Set(itemorder)];

  const showForm = (columnid, columnname) => {
    try {
      handlePopperClose()
      if (formData.itemid != null && formData.itemid != undefined && formData.itemid != "") {
        setEditnote(true);
      }
      else {
        setEditnote(false);
      }

      setFormData({
        ...formData,
        columnid: columnid,
        columnname: columnname,
      });

      setHideIdentity(formData.assignedto.isanonymous ? true : false)
      let model = document.getElementById("formPopup" + boarddata.id);
      model.style.display = "block";
      let noteTextArea = document.getElementById("noteTextArea");
      noteTextArea?.focus();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "showForm") });
      setSpin(false);
    }
  };

  const hideForm = () => {
    try {
      setFormData({
        heading: "",
        description: "",
        user: "",
        username: "",
        columnid: "",
        itemid: "",
        assignedto: { userid: "", profilepicture: "", firstname: "", lastname: "", fullname: "" },
        duedate: "",
        priority: ""
      });
      let model = document.getElementById("formPopup" + boarddata.id);
      model.style.display = "none";

      let settingPopup = document.getElementById("settingPopup" + boarddata.id);
      settingPopup.style.display = "none";

      let hideIdentityElement = document.getElementById("hideIdentity")
      if (hideIdentityElement) {
        hideIdentityElement.checked = false;
      }
      setHideIdentity(false)
      setShowSettings(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "hideForm") });
      setSpin(false);
    }
  };

  function hideTimerPopup() {
    document.getElementById("timermodal").style.display = "none";
  }
  function showTimerPopup() {
    document.getElementById("timermodal").style.display = "block";
  }

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "handleChange") });
      setSpin(false);
    }
  };

  const handleSubmit = async (event) => {
    hideForm();

    // try {
    event.preventDefault();
    setSpin(true);
    const docRef = doc(firestore, "boards", actionboardid ? actionboardid : docid);

    let assignToObj = {}
    if (actionUserSelected) {
      assignToObj = {
        userid: formData.assignedto.userid,
        profilepicture: formData.assignedto.profilepicture,
        firstname: formData.assignedto.firstname,
        lastname: formData.assignedto.lastname,
        fullname: formData.assignedto.firstname + " " + formData.assignedto.lastname,
        isanonymous: hideIdentity
      }
    } else if (user.userid != "") {
      assignToObj = { userid: user.userid, profilepicture: user.profilepicture, firstname: user.firstname, lastname: user.lastname, fullname: user.username, isanonymous: hideIdentity }
    }
    else {
      let name = "";
      if (nonLoggedInUsername) {
        name = nonLoggedInUsername.split(" ")
      } else {
        if (containerData?.guestusername) {
          name = containerData?.guestusername?.split(" ")
        }
      }
      assignToObj = { userid: formData.assignedto.userid, profilepicture: formData.assignedto.profilepicture, firstname: formData.assignedto.firstname ? formData.assignedto.firstname : (name[0] ? name[0] : ""), lastname: formData.assignedto.lastname ? formData.assignedto.lastname : (name[1] ? name[1] : ""), fullname: formData.assignedto.fullname ? formData.assignedto.fullname : nonLoggedInUsername, isanonymous: hideIdentity }
    }

    if (formData.itemid == null || formData.itemid == "") {
      const newitemkey = doc(collection(firestore, "boards")).id;
      setNewAddedItemId(newitemkey)
      const newitem = {
        itemid: newitemkey,
        heading: formData.heading,
        description: formData.description,
        user: formData.user,
        username: formData.username != undefined ? formData.username : "",
        likes: [],
        votes: [],
        assignedto: assignToObj,
        duedate: formData.duedate,
        priority: formData.priority
      };

      if (containerData != undefined && containerData.actionitemboardid) {
        newitem.retrospectivedetails = { name: containerData.retrospectiveboardname, id: containerData.retrospectiveboardid }
      }

      for (var column in sortedboarddata) {
        if (sortedboarddata[column].columnid === formData.columnid) {
          sortedboarddata[column].items.push(newitem);
        }
      }
      await setDoc(
        docRef,
        {
          columns: {
            [formData.columnid]: {
              itemorder: getItemOrderString(formData.columnid),
            },
          },
          items: {
            [newitemkey]: newitem,
          },
        },
        { merge: true }
      )
        .then(() => {
          const element = document.getElementById(newitemkey);
          if (element) {
            element?.focus();
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest'
            });
          }

        });
      // postlog("write boards", { component: "board", function: "handleSubmit", collection: "boards", docid: actionboardid ? actionboardid : docid, });
    }
    else {
      await setDoc(
        docRef,
        {
          items: {
            [formData.itemid]: {
              description: formData.description,
              assignedto: assignToObj,
              duedate: formData?.duedate,
              priority: formData?.priority
            },
          },
        },
        { merge: true }
      );
      // postlog("write boards", { component: "board", function: "handleSubmit", collection: "boards", docid: actionboardid ? actionboardid : docid, });
    }
    setHideIdentity(false)
    let hideIdentityElement = document.getElementById("hideIdentity")

    if (hideIdentityElement) {
      hideIdentityElement.checked = false;
    }
    setSpin(false);
    // } catch (err) {
    //   setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "handleSubmit") });
    //   setSpin(false);
    // }
    setNewAddedItemId("")
    setActionUserSelected(false);
  };

































// -----------------------------drag drop starts here -------------------------------------------

function drag(itemid, columnid, index) {
  try {
    setDraggedItem({
      itemid: itemid,
      columnid: columnid,
      index: index
    });
  } catch (err) {
    setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "drag") });
    setSpin(false);
  }
}

  const onDragOver = (e) => {
    try {
      if (draggedItem != undefined && draggedItem.itemid != "" && draggedItem.itemid != undefined && draggedItem.itemid != null) {
        e.stopPropagation();
        e.preventDefault();
        e.target.classList.add("drop-here");
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "onDragOver") });
      setSpin(false);
    }
  };

  const onDragLeave = (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      e.target.classList.remove("drop-here");
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "onDragLeave") });
      setSpin(false);
    }
  };

  const onDragOverNote = (e) => {
    try {
      if (draggedItem != undefined && draggedItem.itemid != "" && draggedItem.itemid != undefined && draggedItem.itemid != null) {
        e.stopPropagation();
        e.preventDefault();
        if (e.target && e.target.parentNode && e.target.parentNode.parentNode) {
          // e.target.parentNode.parentNode.style.border = "dashed red 2px";
          e.target.parentNode.parentNode.classList.add("drop-here");
        }
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "onDragOver") });
      setSpin(false);
    }
  };

  const onDragLeaveNote = (e) => {
    try {
      if (e.target && e.target.parentNode && e.target.parentNode.parentNode) {
        e.stopPropagation();
        e.preventDefault();
        e.target.parentNode.parentNode.classList.remove("drop-here");
      }

    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "onDragLeave") });
      setSpin(false);
    }
  };


  async function drop(e) {
    try {
      e.event.stopPropagation();
      e.event.preventDefault();
      if (draggedItem != undefined && draggedItem.itemid != "" && draggedItem.itemid != undefined && draggedItem.itemid != null) {
        const draggedItemId = draggedItem.itemid;
        const draggedfromcolumnid = draggedItem.columnid;
        const draggedtocolumnId = e.columnid;
        const pushatindex = e.sortorder;
        const pullFromIndex = draggedItem.index;
        let moveItem = true;

        if (draggedfromcolumnid === draggedtocolumnId && pushatindex === -1) {
          moveItem = false;
        }

        if (draggedfromcolumnid === draggedtocolumnId && pushatindex === pullFromIndex) {
          moveItem = false;
        }

        if (moveItem) {
          //---------------- remove item from old position--------------

          let itemtomove;
          for (var column in sortedboarddata) {
            if (sortedboarddata[column].columnid === draggedfromcolumnid) {
              for (var item in sortedboarddata[column].items) {
                if (sortedboarddata[column].items[item].itemid === draggedItemId) {
                  itemtomove = sortedboarddata[column].items[item];
                  sortedboarddata[column].items.splice(item, 1);
                }
              }
            }
          }
          //---------------- add item at new position -----------------

          for (var column in sortedboarddata) {
            if (sortedboarddata[column].columnid === draggedtocolumnId) {
              if (pushatindex !== -1)
                sortedboarddata[column].items.splice(pushatindex, 0, itemtomove);
              else sortedboarddata[column].items.push(itemtomove);
            }
          }

          //----------------------------------------------------------

          const docRef = doc(firestore, "boards", actionboardid ? actionboardid : docid);

          setSpin(true);
          if (draggedtocolumnId === draggedfromcolumnid) {
            await setDoc(
              docRef,
              {
                columns: {
                  [draggedtocolumnId]: {
                    itemorder: getItemOrderString(draggedtocolumnId),
                  },
                },


              },
              { merge: true }
            );
            // postlog("write boards", { component: "board", function: "drop", collection: "boards", docid: actionboardid ? actionboardid : docid, });
          } else {
            await setDoc(
              docRef,
              {
                columns: {
                  [draggedfromcolumnid]: {
                    itemorder: getItemOrderString(draggedfromcolumnid),
                  },
                  [draggedtocolumnId]: {
                    itemorder: getItemOrderString(draggedtocolumnId),
                  },
                },
              },
              { merge: true }
            );
            // postlog("write boards", { component: "board", function: "removeDublicateNotes", collection: "boards", docid: actionboardid ? actionboardid : docid, });
          }
        }

      }
      setDraggedItem({
        itemid: "",
        columnid: "",
        index: ""
      });
      e.event.target.classList.remove("drop-here");
      if (e.event.target && e.event.target.parentNode && e.event.target.parentNode.parentNode) {
        e.event.target.parentNode.parentNode.classList.remove("drop-here");;
      }
      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "drop") });
      setSpin(false);
    }
  }

  const noDragDropLeave = (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "noDragDropLeave") });
      setSpin(false);
    }
  };


// ----------------------------------------------------drag drop over ---------------------------------------



  function getItemOrderString(columnid) {
    try {
      let newitemorder = "";

      for (var column in sortedboarddata) {
        if (sortedboarddata[column].columnid === columnid) {
          for (var item in sortedboarddata[column].items) {
            if (newitemorder.length > 0) newitemorder += ",";
            newitemorder =
              newitemorder + sortedboarddata[column].items[item].itemid;
          }
        }
      }

      return newitemorder;
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "getItemOrderString") });
      setSpin(false);
    }
  }


  function showExportPopup() {
    try {
      setOpenExport(true);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "showExportPopup") });
      setSpin(false);
    }
  }

  function hideExportPopup() {
    try {
      setOpenExport(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "hideExportPopup") });
      setSpin(false);
    }
  }
  const [copyMessage, setCopyMessage] = useState("");

  const share = () => {
    try {
      setShowShare(true);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "share") });
      setSpin(false);
    }
  };

  function download() {
    try {
      var notelst = "";
      var exportFormat = document.querySelector(
        "input[name='exportfileformat']:checked"
      ).value;
      if (exportFormat === "PDF") {
        setSpin(true);
        var doc = new jsPDF("landscape");

        let x = 10;
        let y = 10;
        let spaceBetweenColumn;
        let columnWidth;

        if (sortedboarddata.length <= 3) {
          spaceBetweenColumn = 100;
          columnWidth = 81;
        } else if (sortedboarddata.length === 4) {
          spaceBetweenColumn = 75;
          columnWidth = 55;
        } else if (sortedboarddata.length === 5) {
          spaceBetweenColumn = 60;
          columnWidth = 45;
        } else {
          spaceBetweenColumn = 60;
          columnWidth = 35;
        }


        const title = `${boarddata.name}`;
        const exportedDate = new Date().toLocaleDateString();
        const dateText = `Date: ${exportedDate}`;
        const smallFontSize = 12;

        doc.setFontSize(18);
        const titleWidth = doc.getStringUnitWidth(title) * 8;
        const centerTitleX = doc.internal.pageSize.width / 2;
        const titleX = centerTitleX - titleWidth / 2;

        doc.text(title, titleX, y, { maxWidth: columnWidth * sortedboarddata.length });
        y += 8;
        doc.setFontSize(smallFontSize)
        const dateWidth = doc.getStringUnitWidth(dateText) * smallFontSize;
        const dateX = titleX + dateWidth + 20;
        doc.text(dateText, dateX, 17);

        doc.line(10, 20, doc.internal.pageSize.width - 10, 20);
        y += 10;

        sortedboarddata.forEach((column) => {
          doc.setFontSize(16);
          doc.text(column.name, x, y, { maxWidth: columnWidth });
          x += spaceBetweenColumn;
        });

        x = 10;
        y += 10;

        const itemHeight = 18;
        const verticalSpacing = 3;
        const padding = 1;
        sortedboarddata.forEach((column) => {
          column.items.forEach((item) => {
            doc.setFontSize(12);
            doc.setFillColor(196, 230, 255);
            doc.rect(x, y, columnWidth, itemHeight, 'F');
            doc.text(item.description, x + padding, y + padding + 5, { maxWidth: columnWidth - 2 * padding });
            y += itemHeight + verticalSpacing;
          });

          x += spaceBetweenColumn;
          y = 37;
        });
        doc.line(10, doc.internal.pageSize.height - 15, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 15);
        const noteText = "Note - Report generated by Agilebin.com";
        doc.text(noteText, 10, doc.internal.pageSize.height - 7);
        const logoImage = "/images/Agilebin-logo.png";
        const logoWidth = 18;
        const logoHeight = 8;
        const logoX = doc.internal.pageSize.width - 28;
        const logoY = doc.internal.pageSize.height - 12;
        doc.addImage(logoImage, "PNG", logoX, logoY, logoWidth, logoHeight);

        try {
          doc.save(document.querySelector("#boardname").innerHTML + ".pdf");
          setSpin(false);
        } catch (err) {
          setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "download pdf") });
          setSpin(false);
        }
      } else {
        setSpin(true);
        var workbook = new ExcelJS.Workbook();
        var worksheet = workbook.addWorksheet("Retrospective");
        let currentRow = 1;
        let currentCol = 1;

        sortedboarddata.forEach((column) => {
          worksheet.getCell(currentRow, currentCol).value = column.name;
          worksheet.getCell(currentRow, currentCol).font = { bold: true };
          currentRow++;
          currentRow++;
          column.items.forEach((item) => {
            worksheet.getCell(currentRow, currentCol).value = item.description;
            currentRow++;
          });
          currentCol++;

          currentRow = 1;
        });

        workbook.xlsx.writeBuffer().then(function (data) {
          var blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          try {
            saveAs(
              blob,
              document.querySelector("#boardname").innerHTML + ".xlsx"
            );
            setSpin(false);
          } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "download xlsx") });
            setSpin(false);
          }
        });
      }

      hideExportPopup();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "download") });
      setSpin(false);
    }
  }

  function hideLoginPopup() {
    try {
      let model = document.getElementById("loginPopup" + boarddata.id);
      model.style.display = "none";
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "showForm") });
      setSpin(false);
    }
  }

  const addLike = async (itemid) => {
    try {
      let likes = boarddata.items[itemid].likes;
      if (!user.userid || user.userid == "" || user.userid == null) {
        likes.push("NA");
      } else {
        if (!likes.includes(user.userid)) {
          likes.push(user.userid);
        }
        else {
          likes.pop(user.userid);
        }
      }
      const docRef = doc(firestore, "boards", actionboardid ? actionboardid : docid);
      await setDoc(
        docRef,
        {
          items: {
            [itemid]: {
              likes: likes,
            },
          },
        },
        { merge: true }
      );
      // postlog("write boards", { component: "board", function: "addlike", collection: "boards", docid: actionboardid ? actionboardid : docid, });


    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "addLike") });
      setSpin(false);
    }
  };

  const addVote = async (itemid) => {
    try {
      let votes = boarddata.items[itemid].votes;
      if (!user.userid || user.userid == "" || user.userid == null) {
        votes.push("NA");
      } else {
        if (!votes.includes(user.userid)) {
          votes.push(user.userid);
        } else {
          votes.pop(user.userid);
        }
      }
      const docRef = doc(firestore, "boards", actionboardid ? actionboardid : docid);
      await setDoc(
        docRef,
        {
          items: {
            [itemid]: {
              votes: votes,
            },
          },
        },
        { merge: true }
      );
      // postlog("write boards", { component: "board", function: "addvote", collection: "boards", docid: actionboardid ? actionboardid : docid, });


    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "addVote") });
      setSpin(false);
    }
  };

  const backgroundimagestyle = {
    position: "fixed",
    width: "100%",
    zIndex: "-1",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: "0.6",
  };

  function leaveMeeting() {
    try {
      const randomNum = Math.floor(Math.random() * 5) + 1;
      if (randomNum == 2) {
        setShowFeedBackForm(true);
      } else {
        if (boarddata.catogery === "fishboneboard") {
          navigate("/fishbones");
        }
        if ((boarddata.catogery === "actionitemboard" || boarddata.catogery === "kanbanboard") && actionboardid == undefined) {
          navigate("/actionitems");
        }
        else if (boarddata.catogery === "leancoffeeboard") {
          navigate("/leancoffeemeetings");
        }
        else if (boarddata.catogery === "retrospectiveboard" || actionboardid != undefined) {
          navigate("/retrospective/meetings");
        }
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "leaveMeeting") });
      setSpin(false);
    }
  }

  const toggleState = () => {
    setShowShare(false);
    if (location != null && location.state != null && location.state.newdoc) {
      location.state.newdoc = null
    }
  };

  function getnumberoflikes(value) {
    try {
      if (value == undefined) {
        return 0;
      }
      return boarddata.items[value.itemid] !== undefined
        ? boarddata.items[value.itemid].likes.length
        : 0;
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "getnumberoflikes") });
      setSpin(false);
    }
  }
  function getnumberofVotes(value) {
    try {
      if (value == undefined) {
        return 0;
      }
      return boarddata.items[value.itemid] !== undefined
        ? boarddata.items[value.itemid].votes.length
        : 0;
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "getnumberofVotes") });
      setSpin(false);
    }
  }

  const ShowConfirmationPopup = (itemid, columnid) => {
    handlePopperClose()
    let model = document.getElementById("formPopup-3" + boarddata.id);
    model.style.display = "block";
    setDeleteItemidAndColumnid({
      itemid: formData.itemid,
      columnid: formData.columnid,
    })
  }

  const HideConfirmationPopup = () => {
    try {
      let model = document.getElementById("formPopup-3" + boarddata.id);
      model.style.display = "none";
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "HideConfirmationPopup") });
      setSpin(false);
    }
  }

  async function deleteItem() {
    try {
      setSpin(true);
      let itemorder = boarddata.columns[deleteItemidAndColumnid.columnid].itemorder.split(",");
      const index = itemorder.findIndex((item) => {
        return item === deleteItemidAndColumnid.itemid;
      });
      itemorder.splice(index, 1);
      itemorder = itemorder.join();



      let deletedItem = document.getElementById(deleteItemidAndColumnid.itemid)
      deletedItem.classList.add("noteScaleOut")

      setTimeout(async () => {
        const docRef = doc(firestore, "boards", actionboardid ? actionboardid : docid);
        await setDoc(
          docRef,
          {
            items: {
              [deleteItemidAndColumnid.itemid]: deleteField(),
            },
            columns: {
              [deleteItemidAndColumnid.columnid]: {
                itemorder: itemorder,
              },
            },
          },
          { merge: true }
        ).then(
          deletedItem.classList.remove("noteScaleOut")
        );
      }, 900);
      // postlog("write boards", { component: "board", function: "deleteitem", collection: "boards", docid: actionboardid ? actionboardid : docid, });
      setSpin(false);
      hideForm();
      HideConfirmationPopup();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "deleteItem") });
      setSpin(false);
      HideConfirmationPopup()
    }
  }

  function displayTimer(timeUp) {
    if (timeUp) {
      setTimeout(() => {
        document.getElementById("navTimer").style.display = "none";
      }, 5000);
    }
    else {
      document.getElementById("navTimer").style.display = "flex"
    }
  }

  const handleDropdownChange2 = (event) => {
    try {
      if (event.target.value != "selectuser") {
        let userdata = null;
        for (const userId in boarddata.usersdetails) {
          if (boarddata.usersdetails.hasOwnProperty(userId)) {
            const user = boarddata.usersdetails[userId];
            if (user.userid == event.target.value) {
              userdata = user;
              break;
            }
          }
        }
        setActionUserSelected(true)
        const assigneduser = { profilepicture: userdata.profilepicture, userid: userdata.userid, firstname: userdata.firstname, lastname: userdata.lastname, fullname: userdata.firstname + userdata.lastname };
        setFormData({
          ...formData,
          [event.target.name]: assigneduser,
        });

      } else {
        const assigneduser = { profilepicture: "", userid: "", firstname: "", lastname: "", fullname: "" };
        setFormData({
          ...formData,
          [event.target.name]: assigneduser,
        });
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "handleDropdownChange2") });
      setSpin(false);
    }

  };
  const SortNotes = async () => {
    try {
      setSpin(true);
      const sortedItems = {};
      let updatedsorteditems = {};
      const columnsorder = boarddata.columnorder.split(",");
      for (const key in boarddata.columns) {
        if (boarddata.columns[key].itemorder != undefined) {
          let itemorder = boarddata.columns[key].itemorder.split(",");
          let items = boarddata.items;
          let sortedItemorder = itemorder.sort((a, b) => {
            const voteA = items[a]?.votes.length || 0;
            const voteB = items[b]?.votes.length || 0;
            return voteB - voteA;
          });


          let sortedItemorderString = sortedItemorder.join(",");
          updatedsorteditems[key] = { itemorder: sortedItemorderString };
          sortedItems[key] = sortedItemorderString;
        }
      }
      const docRef = doc(firestore, "boards", actionboardid ? actionboardid : docid);
      await setDoc(
        docRef,
        {
          columns:
            updatedsorteditems
          ,
        },
        { merge: true }
      );
      // postlog("write boards", { component: "board", function: "sortNotes", collection: "boards", docid: actionboardid ? actionboardid : docid, });
      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "SortNotes") });
      setSpin(false);
    }
  }

  function changeHideIdentity() {
    setHideIdentity(!hideIdentity)
  }


  function closeNamePopup() {
    document.getElementById("namePopup" + boarddata.id).style.display = "none"
  }

  function handleSubmitName(e) {
    setNonLoggedInUsername(formData.notloggedinusername)
    e.preventDefault()
    setNonLoggedInUsername(formData.nonLoggedInName)
    if (updateContainerData) {
      updateContainerData(null, null, null, formData.nonLoggedInName, null)
    }
    closeNamePopup()
  }

  let totalitemslength = 0;


  const addColumn = () => {
    try {
      if (state.subscriptionglobaldata.subscription == "False") {
        setShowPro(true);
      }
      else {
        let newcolumn = doc(collection(firestore, "boards"));
        newcolumn = { columnname: "", columnid: newcolumn.id, itemorder: "" };
        const newcolumns = [...columnFormData.columns, newcolumn];
        setColumnFormData({ ...columnFormData, columns: newcolumns });
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "addColumn") });
      setSpin(false);
    }
  };

  const handleChange2 = (index, event) => {
    try {
      const { name, value } = event.target;
      const updatedColumns = [...columnFormData.columns];
      updatedColumns[index] = { ...updatedColumns[index], columnname: value };
      setColumnFormData({ ...columnFormData, columns: updatedColumns });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "handleChange2") });
      setSpin(false);
    }
  };

  async function deleteColumn(columnid, boardid) {
    try {
      if (columnFormData.columns.length == 1) {
        alert("At least you shoude have one column.");
      } else {
        const updatedColumns = [...columnFormData.columns];
        const index = updatedColumns.findIndex(
          (obj) => obj.columnid === columnid
        );
        if (index !== -1) {
          const newupdatedColumns = [...updatedColumns];
          newupdatedColumns.splice(index, 1);
          setColumnFormData({ ...columnFormData, columns: newupdatedColumns });
        }
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "deleteColumn") });
      setSpin(false);
    }
  }


  function showSettingPopup() {

    try {
      if (boarddata != null) {
        const columnsorder = boarddata.columnorder.split(",");
        const sortedColumn = [];

        for (var Sortcol in columnsorder) {
          for (var col in boarddata.columns) {
            if (columnsorder[Sortcol] === col) {
              sortedColumn.push(boarddata.columns[col]);
            }
          }
        }

        setColumnFormData({
          id: boarddata.id,
          name: boarddata.name,
          columns: sortedColumn,
          boardid: boarddata.id,
        });
      }

      let model = document.getElementById("settingPopup" + boarddata.id);
      model.style.display = "block";
      document.getElementById("boardNameInput")?.focus()
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "showSettingPopup") });
      setSpin(false);
    }

  }


  function settingHandleChange(event) {
    try {
      const { name, value } = event.target;
      setColumnFormData({
        ...columnFormData,
        [name]: value,
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "settingHandleChange") });
      setSpin(false);
    }
  }


  const settingHandleSubmit = (event) => {
    try {
      setSpin(true);
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        // Process the form data here (e.g., submit to the server)
        event.preventDefault();
        event.stopPropagation();
        saveBoard({
          id: columnFormData.id,
          name: columnFormData.name,
          columns: columnFormData.columns,
          backgroundimage: columnFormData.backgroundimage,
          layout: columnFormData.layout,
          mode: columnFormData.mode,
        });

        hideForm();
        event.preventDefault();
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "settingHandleSubmit") });
    }
  };

  async function saveBoard(board) {
    try {
      //////////////////Build column map/////////////////////////
      let columns = {};
      let columnOrder = "";
      for (const key in board.columns) {
        columnOrder += board.columns[key].columnid;
        columnOrder += ",";
        columns[board.columns[key].columnid] = {
          columnname: board.columns[key].columnname,
          columnid: board.columns[key].columnid,
          itemorder: board.columns[key].itemorder,
        };
      }

      const docRefboard = doc(firestore, "boards", board.id);
      await setDoc(
        docRefboard,
        {
          columns: deleteField(),
        },
        { merge: true }
      );
      // postlog("write boards", { component: "board", function: "saveBoard", collection: "boards", docid: actionboardid ? actionboardid : docid });
      postlog("create boards", { component: "board", function: "saveBoard", collection: "boards", docid: actionboardid ? actionboardid : docid });


      await setDoc(
        docRefboard,
        {
          name: board.name,
          updateddate: Timestamp.fromDate(new Date()),
          updatedby: user.userid,
          columns: columns,
          columnorder: columnOrder,
        },
        { merge: true }
      );
      // postlog("write boards", { component: "boards", function: "saveBoard", collection: "boards", docid: actionboardid ? actionboardid : docid });

      hideForm();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "saveBoard") });
      setSpin(false);
    }
  }

  const handleCloseAlert = () => {
    setShowPro(false);
  };


  /////////////////// popover start ///////////////////////
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayPopoverId, setDisplayPopoverId] = useState()
  const handleClick = (event, itemId, value, columnvalue) => {
    setDisplayPopoverId(itemId)
    setNoteId(itemId)
    setAnchorEl(event.currentTarget);
    setFormData({
      heading: value.description,
      description: value.description,
      user: user.userid,
      username: user.username,
      columnid: columnvalue.columnid,
      itemid: value.itemid,
      assignedto: { userid: value.assignedto.userid, profilepicture: value.assignedto.profilepicture, firstname: value.assignedto.firstname, lastname: value.assignedto.lastname, fullname: value.assignedto.fullname, isanonymous: value.assignedto.isanonymous ? true : false },
      duedate: value?.duedate ? value.duedate : "",
      priority: value?.priority ? value.priority : ""
    });
  };
  const handlePopperClose = () => {
    setAnchorEl(null);
    hideForm()
  };
  const open = Boolean(anchorEl);
  /////////////////// popover end ///////////////////////



  async function changeNoteColor(noteColor) {
    setFormData({
      ...formData,
      description: '',
    });
    setAnchorEl(null);
    const docRef = doc(firestore, "boards", actionboardid ? actionboardid : docid);
    await setDoc(
      docRef,
      {
        items: {
          [noteId]: {
            notecolor: noteColor
          },
        },
      },
      { merge: true }
    );
    // postlog("write boards", { component: "board", function: "changeNoteColor", collection: "boards", docid: actionboardid ? actionboardid : docid, });
  }


  // Determine the value of doctype based on the current route
  let doctype;
  if (location.pathname.includes('leancoffeemeeting')) {
    doctype = 'leancoffeemeetings';
  } else if (location.pathname.includes('/actionitems')) {
    doctype = 'actionitems';
  } else {
    doctype = 'board'; // Default or fallback value
  }


  /////////////////////////////////////////////////// HTML //////////////////////////////////////////////////

  return (
    <>
      <div>
        {boarddata !== undefined && boarddata.name !== undefined && (
          <div
            style={{
              width: "100vw",
              height: "100vh - 50px",
            }}
          >
            <div>
              <nav
                className="navbar navbar-expand-lg card-shadow fixed-top bg-light"
                style={{ width: "100vw", }}
              >
                <div className="container-fluid">
                  <a className="navbar-brand fw-bold">
                    <span id="boardname">{boarddata.name}</span>
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>



                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>

                    <ul className="navbar-nav mb-2 mb-lg-0 navbar-right">

                      <li id="navTimer" style={{ display: "none", alignItems: "center" }}>
                        <Timer docid={docid} textTimer={"textTimer"} displayTimer={displayTimer} />
                      </li>

                      {boarddata?.usersdetails?.[user.userid]?.isowner && (
                        <>
                          {boarddata.catogery != "actionitemboard" && (
                            <Tooltip
                              title="Timer"
                            >
                              <IconButton
                                color="primary"
                                onClick={showTimerPopup}
                              >
                                <AlarmIcon />
                              </IconButton>
                            </Tooltip>
                          )}


                          <li className="nav-item">
                            <Tooltip
                              title="Export"
                            >
                              <IconButton
                                color="primary"
                                onClick={showExportPopup}
                              >
                                <SaveAltIcon />
                              </IconButton>
                            </Tooltip>

                          </li>
                        </>
                      )}



                      <li className="nav-item">
                        <Tooltip
                          title="Sort"
                        >
                          <IconButton
                            color="primary"
                            onClick={SortNotes}
                          >
                            <SwapVertIcon></SwapVertIcon>
                          </IconButton>
                        </Tooltip>

                      </li>


                      {boarddata?.usersdetails[user.userid]?.isowner && (
                        <li>
                          <Tooltip
                            title="Setting"
                          >
                            <IconButton
                              color="primary"
                              onClick={showSettingPopup}
                            >
                              <SettingsIcon></SettingsIcon>
                            </IconButton>
                          </Tooltip>

                        </li>
                      )}

{boarddata?.usersdetails[user.userid]?.isowner && (      <li className="nav-item">
                        {actionboardid == undefined && (
                          <Button
                            variant="outlined"
                            sx={{ margin: "0 2px" }}
                            id="btncopylink"
                            onClick={share}
                            startIcon={<PersonAddAltIcon />}
                          >
                            Invite
                          </Button>
                        )}
                      </li> )}

                      <li className="nav-item">
                        <Button
                          variant="outlined"
                          sx={{ margin: "0 2px" }}
                          id="btncopylink"
                          onClick={leaveMeeting}
                          startIcon={<CloseIcon />}
                        >
                          Close
                        </Button>
                      </li>


                    </ul>
                  </div>
                </div>
              </nav>

              <div style={{
                overflow: "hidden",
                marginTop: "58px",
              }}>
                {boarddata.backgroundimage ? (
                  <img
                    src={boarddata.backgroundimage}
                    alt="Image"
                    className="image bg-img bg-img-retro"
                    style={backgroundimagestyle}
                  />
                ) : null}

                {/* --------------------------- Board Started -------------------------------- */}

                <div
                  className="row d-flex justify-content-center g-0"
                  style={{ margin: "0 5px" }}
                >
                  {/* --------------------------- Column Mapping Started -------------------------------- */}
                  {sortedboarddata.map((columnvalue, index) => {
                    let itemslength = Object.keys(columnvalue.items).length;
                    totalitemslength += itemslength;
                    const dragarea =
                      itemslength < 3 ? 90 - itemslength * 42 : 5;
                    return (
                      <div
                        className={`
                          ${boarddata.layout === "grid"
                            ? "col-md-6 scrollable-div" : boarddata.layout == "grid6" ? "col-md-4 scrollable-div"
                              : "col scrollable-div"
                          }`}
                        style={{
                          border: "solid rgba(0, 0, 0, 0.418) 1px",
                          // borderTop: "none",
                          height: boarddata.layout === "grid" || boarddata.layout == "grid6" ? `calc((100vh - 58px) / 2) ` : `calc(100vh - 58px)`
                        }}
                        key={index}
                        onDragOver={noDragDropLeave}
                        onDrop={noDragDropLeave}
                        onDragLeave={noDragDropLeave}
                      >

                        {/* --------------------------- Column Header Started -------------------------------- */}

                        <div
                          draggable={false}
                          className="row g-0 column-header"
                          style={{
                            zIndex: "10",
                            display: "flex",
                            gap: "5px",
                            justifyContent: "center",
                            backgroundColor: `${boarddata.mode == "light" ? "rgba(255, 255, 255, 0.4)" : "rgb(51, 51, 51)"}`,
                            padding: "5px 0",
                            height: "40px",
                            boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px",
                          }}
                          onDragOver={noDragDropLeave}
                          onDrop={noDragDropLeave}
                          onDragLeave={noDragDropLeave}
                          onClick={() =>
                            showForm(columnvalue.columnid, columnvalue.name)
                          }
                        >
                          <Tooltip title='Add a note'>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: "pointer" }}  onClick={() =>
                            showForm(columnvalue.columnid, columnvalue.name)
                          }>
                            <span className={`fs-5  ${boarddata.mode == "light" ? "text-dark" : "text-white"}`} draggable={false}>
                              {columnvalue.name}
                            </span>
                            <AddCircleIcon
                              className="fs-3 text-primary mx-2"
                              role="button"
                              draggable={false}
                              onClick={() =>
                                showForm(columnvalue.columnid, columnvalue.name)
                              }
                            ></AddCircleIcon>
                          <div style={{padding:"0px 4px",fontSize:"18px",alignSelf:"end"}} > {itemslength != 0 && itemslength}</div> 
                          </div>
                          </Tooltip>
                        </div>

                        {/* --------------------------- Item Mapping Started -------------------------------- */}

                        <div className="scrollable-div"
                          style={{
                            height: boarddata.layout === "grid" || boarddata.layout === "grid6" ? "38vh" : "84vh",
                            overflow: "auto",
                            marginLeft: "10px",
                          }}
                          onDrop={(event) =>
                            drop({
                              itemid: columnvalue.itemid,
                              sortorder: -1,
                              columnid: columnvalue.columnid,
                              event: event,
                            })
                          }
                          onDragOver={onDragOver}
                          onDragLeave={onDragLeave}
                        >
                          {columnvalue.items.map((value, index) => {
                            return (value && (
                              <div
                                id={value.itemid}
                                style={{
                                  display: "inline-block",
                                  padding: "5px",
                                }}
                                key={index}
                                onDrop={(event) =>
                                  drop({
                                    sortorder: index,
                                    columnid: columnvalue.columnid,
                                    event: event,
                                  })
                                }
                                onDragOver={onDragOver}
                                onDragLeave={onDragLeave}
                              >
                                <div
                                  draggable
                                  onDrag={() =>
                                    drag(value.itemid, columnvalue.columnid, index)
                                  }
                                  onDragOver={onDragOverNote}
                                  onDragLeave={onDragLeaveNote}
                                  className={`cat1  m-0 ${newAddedItemId == value.itemid ? 'noteScaleIn' : ''}`}
                                  id="dragItem"
                                  style={{
                                    width: "230px",
                                    backgroundColor: value.notecolor ? value.notecolor : "#c4e6ff",
                                    transition: "0.5s",
                                    fontSize: "16px",
                                    height: "180px",
                                    border: '2px solid transparent'
                                  }}
                                >
                                  <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <div 
                                           onDragOver={noDragDropLeave}
                                           onDrop={noDragDropLeave}
                                           onDragLeave={noDragDropLeave}
                                    >
                                      {(value.assignedto.isanonymous || value.assignedto.firstname == "" || value.assignedto.firstname == undefined) ? (<small style={{ marginBottom: "1px" }}>   <AccountCircleIcon sx={{ width: 20, height: 20, color: "#575656" }} ></AccountCircleIcon><span className="open-sans-600" style={{ fontSize: "13px" }}>&nbsp;Anonymous</span></small>
                                      ) : (<small style={{ marginBottom: "1px" }}>
                                        {(value.assignedto.firstname != "" && value.assignedto.firstname != undefined) && (
                                          <small className="d-flex">    {value.assignedto.profilepicture && value.assignedto.profilepicture != "" ? (
                                            <Avatar alt="Remy Sharp" sx={{ width: 20, height: 20 }} src={value.assignedto.profilepicture} />
                                          ) : (
                                            <AccountCircleIcon sx={{ width: 20, height: 20, color: "#575656" }} ></AccountCircleIcon>
                                          )}   <span className="open-sans-600 " style={{ fontSize: "13px" }}>&nbsp;{value.assignedto.firstname}</span></small>
                                        )}
                                      </small>)}
                                    </div>
                                    <div>
                                    <Tooltip
                                        title="Edit Note"
                                        onDragOver={noDragDropLeave}
                                        onDrop={noDragDropLeave}
                                        onDragLeave={noDragDropLeave}
                                    >
                                      <IconButton
                                        role="button"
                                        size="small"
                                        variant="contained"
                                        onClick={(event) => handleClick(event, value.itemid, value, columnvalue)}>
                                        <MoreVertIcon fontSize="small"></MoreVertIcon>
                                      </IconButton>
                                          </Tooltip>
                                    </div>
                                  </div>

                                  <div
                                    className="text-dark scrollable-div open-sans-400"
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      overflow: "auto",
                                      textOverflow: "ellipsis",
                                      display: "inline-block",
                                      height: boarddata.catogery == "retrospectiveboard" || boarddata.catogery == "leancoffeeboard" ? "110px" : "120px",
                                      minWidth: "215px",
                                      fontSize: '15px',
                                      fontStyle: "Open Sans",
                                    }}
                                  >
                                    {value.description}{" "}
                                  </div>

                                  <div className="d-flex"
                                    style={{ position: "absolute", bottom: "0", width: "100%", height: "34px" }}
                                    onDragOver={noDragDropLeave}
                                    onDrop={noDragDropLeave}
                                    onDragLeave={noDragDropLeave}
                                  >

                                    <IconButton aria-label="Vote" role="button" size="small" color="primary"
                                      title="Vote"
                                      onClick={() => {
                                        addVote(value.itemid);
                                      }}>
                                      <ThumbUpAltIcon fontSize="inherit" />
                                    </IconButton>

                                    <div style={{
                                      position: 'relative',
                                      left: '-10px',
                                      bottom: '-5px',
                                    }}>&nbsp; {getnumberofVotes(value)}</div>

                                    {value.itemid == displayPopoverId && (
                                      <Popover
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handlePopperClose}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        }}
                                        disabled={((value.assignedto.userid == "" || value.assignedto.userid == user.userid || boarddata?.usersdetails[user.userid]?.isowner) && (value.assignedto.firstname == undefined || value.assignedto.firstname == "" || value.assignedto.firstname == nonLoggedInUsername || value.assignedto.firstname == user.firstname ||
                                          boarddata?.usersdetails[user.userid]?.isowner)) ? false : true}
                                      >
                                        <div style={{ width: "200px", }}>
                                          <>
                                            {/* <div className="popperBtn" onClick={() =>
                                              showForm(
                                                columnvalue.columnid,
                                              )}
                                              disabled={((value.assignedto.userid == "" || value.assignedto.userid == user.userid || boarddata?.usersdetails[user.userid]?.isowner) && (value.assignedto.firstname == undefined || value.assignedto.firstname == "" || value.assignedto.firstname == nonLoggedInUsername || value.assignedto.firstname == user.firstname ||
                                                boarddata?.usersdetails[user.userid]?.isowner)) ? false : true}
                                            >
                                              <EditIcon fontSize="inherit" /> Edit
                                            </div> */}



                                            <div className={`popperBtn`}
                                              onClick={() => showForm(columnvalue.columnid, columnvalue.name)} style={{ color: "#5f6368" }}>

                                              <EditIcon fontSize="inherit" sx={{ marginRight: '8px' }} /> Edit
                                            </div>

                                            <div className={`popperBtn`}
                                              onClick={() => ShowConfirmationPopup(value.itemid,
                                                columnvalue.columnid)
                                              } style={{ color: "#5f6368" }}>

                                              <DeleteIcon fontSize="inherit" sx={{ marginRight: '8px' }} /> Delete
                                            </div>
                                          </>

                              

                                          <div 
                                            style={{ padding: "10px 10px 0 10px", }}>
                                            <span>Change Note Color</span>
                                          </div>

                                          <div
         
                                            style={{ padding: "10px", display: "flex", justifyContent: "space-between" }}>

                                            <div className="noteColorSelector" style={{ cursor: "pointer", width: "30px", height: "30px", borderRadius: "5px", backgroundColor: "#9ed6ff" }} onClick={() => changeNoteColor("#c4e6ff")} >

                                            </div>

                                            <div className="noteColorSelector" style={{ cursor: "pointer", width: "30px", height: "30px", borderRadius: "5px", backgroundColor: "#98faa7" }} onClick={() => changeNoteColor("#bdffc7")}>

                                            </div>

                                            <div className="noteColorSelector" style={{ cursor: "pointer", width: "30px", height: "30px", borderRadius: "5px", backgroundColor: "#fcfa95" }} onClick={() => changeNoteColor("#fcfab3")}>

                                            </div>

                                            <div className="noteColorSelector" style={{ cursor: "pointer", width: "30px", height: "30px", borderRadius: "5px", backgroundColor: "#fca9a9" }} onClick={() => changeNoteColor("#ffd1d1")}>

                                            </div>

                                          </div>

                                     

                                        </div>
                                      </Popover>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ));
                          })}

                        </div>
                      </div>
                    );
                  })}

                  {totalitemslength != undefined && totalitemslength == 0 && (
                  <div
                  className={`messeg-box text-center ${boarddata.mode === "light" ? "text-dark" : "text-white"}`}
                  style={{
                    position: "fixed",
                    top: "14.5vh",
                    left: "15%", // Center horizontally
                    transform: "translateX(-50%)", // Center horizontally
                    backgroundColor: boarddata.mode === "light" ? "#fff" : "#333",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    maxWidth: "350px", // Set a maximum width for the message
                    width: "100%", // Take up the full width within the maximum width
                  }}
                >
                  <div className="arrow-up"></div>
                  <span className="fs-4">How to add notes?</span><br />
                  <span className="fs-5">Click the column header to add notes.</span>
                </div>
                  )}
                </div>
              </div>

             
              <Dialog open={openExport} onClose={hideExportPopup}>
                <DialogTitle>
                  <b>Export</b>
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Typography>Select the export file format and click on Download button</Typography>
                  <br />
                  <FormControlLabel
                    control={
                      <Radio  
                        id="pdffileformat"
                        name="exportfileformat"
                        value="PDF"
                        checked={handleRadioChange === "PDF"}
                        onChange={(e) => {
                          setHandleRadioChange(e.target.value);
                        }}
                      />
                    }
                    label="PDF"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Radio
                        id="excelfileformat"
                        name="exportfileformat"
                        value="EXCEL"
                        checked={handleRadioChange === "EXCEL"}
                        onChange={(e) => {
                          setHandleRadioChange(e.target.value);
                        }}
                      />
                    }
                    label="Excel"
                  />
                  <br />
                </DialogContent>
                <Divider />
                <DialogActions>
                  <div className="float-end">
                    <Button onClick={hideExportPopup} color="primary">
                      Cancel
                    </Button>
                    <Button id="btndownload" onClick={download} variant="contained" color="primary">
                      Download
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>

              <div
                id={"loginPopup" + boarddata.id}
                className="modal"
                style={{ zIndex: "20000" }}
              >
                <div className="modal-content">
                  <div className="modal-header ">
                    <div>Login</div>
                    <div className="circleIconContainer" onClick={hideLoginPopup}>
                      <span className="circleIcon">&times;</span>
                    </div>
                  </div>
                  <div className="modal-body">
                    <p className="text-center">
                      In order to like an item you must be a authorised/logged
                      in user.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <div>
                      <Link
                        to="/"
                        className="btn btn-primary mt-2 mx-2 text-end"
                      >
                        Login
                      </Link>

                      <button
                        className="btn btn-primary mt-2 text-end"
                        onClick={hideLoginPopup}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <Dialog open={true}
                id={`formPopup${boarddata.id}`}
                onClose={hideForm}
                className="modal"
                aria-labelledby="form-popup"
                aria-describedby="form-popup-description"
                fullWidth={true} maxWidth='sm'
              >
                {formData.columnname != undefined && formData.columnname != null && formData.columname != '' && (
                  <DialogTitle>
                    {formData.columnname}
                  </DialogTitle>
                )}
                <Divider />
                <DialogContent>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <TextareaAutosize
                        className="mb-1"
                        id="noteTextArea"
                        minRows={4}
                        onChange={handleChange}
                        value={formData.description}
                        name="description"
                        placeholder="Type your note here"
                        style={{
                          width: '100%',
                          padding: '8px',
                          border: '1px solid #bdbdbd',
                          borderRadius: '4px',
                          outline: 'none', // Remove outline when focused
                          transition: 'border-color 0.2s, box-shadow 0.2s', // Transition for smoother effect
                        }}
                        required
                        onFocus={(e) => {
                          e.target.style.borderColor = '#b4deff';
                          e.target.style.boxShadow = '0 0 0 3px #b4deff'; // Blue box shadow on focus
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = '#bdbdbd'; // Reset border color on blur
                          e.target.style.boxShadow = 'none'; // Remove box shadow on blur
                        }}
                      />

                      {boarddata.catogery === "actionitemboard" && (
                        <div>
                          <span className="showmorebutton" role="button" onClick={() => { setShowMore(!showMore) }}>
                            {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            Show more options
                          </span>
                        </div>
                      )}

                      <div className={`row mt-3 showsettings ${showMore ? 'show' : ''}`}>
                        {boarddata.catogery === "actionitemboard" && (
                          <>
                            <div className="mb-2 col-md-4">
                              <InputLabel htmlFor="duedate">Due Date</InputLabel>
                              <FormControl fullWidth>
                                <TextField
                                  type="date"
                                  id="duedate"
                                  name="duedate"
                                  value={formData.duedate}
                                  onChange={handleChange}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                            </div>

                            <div className="mb-2 col-md-4">
                              <InputLabel htmlFor="priority">Priority</InputLabel>
                              <FormControl fullWidth>
                                <Select
                                  name="priority"
                                  id="priority"
                                  value={formData.priority}
                                  onChange={handleChange}
                                  variant="outlined"
                                >
                                  <MenuItem value="">Select Priority</MenuItem>
                                  <MenuItem value="low">Low</MenuItem>
                                  <MenuItem value="medium">Medium</MenuItem>
                                  <MenuItem value="high">High</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </>
                        )}

                        {(boarddata.catogery !== "retrospectiveboard") && (boarddata.catogery !== "leancoffeeboard") && (
                          <div className="col-md-4">
                            <InputLabel htmlFor="assignedto">Assignee</InputLabel>
                            <FormControl fullWidth>
                              <Select
                                id="assignedto"
                                name="assignedto"
                                onChange={handleDropdownChange2}
                                value={formData.assignedto.userid}
                                variant="outlined"
                                startIcon={<PersonIcon />}
                              >
                                <MenuItem value="selectuser">Select Users</MenuItem>
                                {boarddata && Object.keys(boarddata.usersdetails).map((key) => (
                                  (boarddata.usersdetails[key].isactive || boarddata.usersdetails[key].isactive === undefined) && (
                                    <MenuItem key={boarddata.usersdetails[key].userid} value={boarddata.usersdetails[key].userid}>
                                      {boarddata.usersdetails[key].firstname + " " + boarddata.usersdetails[key].lastname}
                                    </MenuItem>
                                  )
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </div>

                    </div>


                    {boarddata.catogery !== "actionitemboard" && boarddata.catogery !== "kanbanboard" ? (
                      <>
                        <div>
                          <FormControlLabel
                            control={<Checkbox id="hideIdentity" onChange={changeHideIdentity} checked={hideIdentity} />}
                            label="Hide my name, I want to be anonymous for this note."
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <Divider />
                        <DialogActions>
                          <div>
                            <Button onClick={hideForm} color="primary">
                              Cancel
                            </Button>
                            <Button size="large" variant="contained" type="submit" style={{ marginLeft: '10px' }}>
                              {editnote ? "Save" : "Add Note"}
                            </Button>
                          </div>
                        </DialogActions>
                      </>
                    ) : (
                      <DialogActions>
                        <Button onClick={hideForm} color="primary">
                          Cancel
                        </Button>
                        <Button size="large" variant="contained" type="submit" style={{ marginLeft: '10px' }}>
                          {editnote ? "Save" : "Add Note"}
                        </Button>
                      </DialogActions>
                    )}
                  </form>
                </DialogContent>
              </Dialog>
            </div>


            <Dialog
              className="modal"
              Dialog open={true}
              id={"formPopup-3" + boarddata.id}
              onClose={HideConfirmationPopup}
              aria-labelledby="form-popup"
              aria-describedby="form-popup-description"
              fullWidth={true} maxWidth='sm'
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Note?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete note?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={HideConfirmationPopup} varient='outlined' >Cancle</Button>
                <Button  variant="contained" color='error' onClick={deleteItem}  autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            {/* Name popup  */}
            {(user.userid === "" && boarddata.catogery != "actionitemboard" && nonLoggedInUsername == "" && (containerData != undefined ? (containerData.guestusername == null || containerData.guestusername == "" || containerData.guestusername == undefined) : true)) && (
              <div id={"namePopup" + boarddata.id} className="modal" style={{ display: "block", zIndex: "101" }} >

                <form onSubmit={handleSubmitName}>

                  <div className="modal-content">
                    <div className="modal-header">
                      <div>
                        <b>Enter Your Name to join</b>
                      </div>
                    </div>

                    <div className="modal-body">
                      Enter your Name
                      <input type="text" name="nonLoggedInName" className="form-control w-50" onChange={handleChange} required />
                      If you want to be anonymous, no problem! You can create a note anonymously.
                    </div>

                    <div className="modal-footer">
                      <div>
                        <button className="btn btn-primary" type="submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}

          </div>
        )}



        {/* timer Popup */}

        <Dialog open={true} id='timermodal' className="modal" onClose={hideTimerPopup} aria-labelledby="timer-dialog-title">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
             Timer
              <Tooltip title='Minimize'>
                <IconButton onClick={hideTimerPopup} className="circleIconContainer">
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ maxWidth: '65vw', maxHeight: '80vh' }}>
            <div className="modal-body">
              <div
                className="text-center"
                style={{
                  // backgroundColor: "#ebf5ff",
                  padding: "20px",
                  // maxWidth: "300px",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                <Timer docid={docid} displayTimer={displayTimer} hideIcon={boarddata && boarddata?.usersdetails[user.userid]?.isowner ? true : false} />
              </div>
            </div>
            </DialogContent>
            <Divider />
          </Dialog>

        {/* settingPopup */}
        {
          boarddata && (
            <Dialog open={true} id={`settingPopup${boarddata.id}`} onClose={hideForm} className="modal"
              aria-labelledby="form-popup"
              aria-describedby="form-popup-description"
              fullWidth={true} maxWidth='sm'>
              <DialogTitle>
                Retrospective Meeting
              </DialogTitle>
              <Divider />
              <DialogContent>
                <form onSubmit={settingHandleSubmit}>
                  <div>
                    <TextField
                      id="boardNameInput"
                      type="text"
                      name="name"
                      label="Board Name"
                      value={columnFormData.name}
                      onChange={settingHandleChange}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                    />
                  </div>
                  <Button className="mt-2 showmorebutton" role="button" onClick={() => setShowSettings(!showSettings)}>
                    {showSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />} Show Settings
                  </Button>
                  <div className={`scrollable-div showsettings mt-1 ${showSettings ? 'show' : ''}`} style={{ padding: '0 10px' }}>
                    <div className="mb-1">
                      {state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False" && (
                        <div className="mt-3">
                          Customize columns
                          {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                        </div>
                      )}
                    </div>
                    <div className="text-end mb-1">
                      <Button
                        type="button"
                        title="Add Column"
                        onClick={addColumn}
                        variant="outlined"
                        size="small"
                      >
                        Add Column &nbsp;
                        <ControlPointIcon />
                      </Button>
                    </div>
                    {columnFormData.columns && columnFormData.columns.map((element, index) => (
                      <div key={index} className="mb-2" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <TextField
                          type="text"
                          placeholder="Column name"
                          name={element.columnid}
                          value={element.columnname}
                          onChange={(event) => handleChange2(index, event)}
                          variant="outlined"
                          fullWidth
                          required={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "True"}
                          disabled={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False"}
                        />
                        <button
                          title="You can only delete a column if it's empty."
                          disabled={element.itemorder !== "" || columnFormData.columns.length < 2 || state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False"}
                          style={{ border: "none", background: "none" }}
                          type="button"
                        >
                          <i
                            className="fa fa-trash fs-6"
                            role="button"
                            onClick={() => deleteColumn(element.columnid, columnFormData.boardid)}
                          ></i>
                        </button>
                      </div>
                    ))}
                  </div>
                  <Divider />
                  <DialogActions>
                  <Button onClick={hideForm}>Cancel</Button>
                <Button type="submit" variant="contained" color="primary" size='large'>
                  {columnFormData.id && columnFormData.id !== null && columnFormData.id !== undefined ? "Save Changes" : "Start Retrospective"}
                </Button>
              </DialogActions>
                </form>
              </DialogContent>
              <ProAlert showAlert={showPro} hideAlert={handleCloseAlert} />
            </Dialog>
          )
        }



        
        <Spinner spin={spin} />
        <ShowError Error={showError} />
        <DocumentMissing show={showDocumentMissing} />
        <Feedback show={showFeedBackForm} user={user} docid={actionboardid ? actionboardid : docid} componentName={actionboardid ? "retrospectiveboard" : boarddata?.catogery} />
        <SharePopup
          show={showshare}
          hideForm={toggleState}
          userid={user.userid}
          docid={actionboardid ? actionboardid : docid}
          docData={boarddata}
          doctype={doctype}
          collectionname="boards"
          subscription={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription}
          message="Copy and share the link with participants for public access to the board. Or, privately share the board with specific users."
        />
      </div>
    </>
  );
};
export default Board;
