import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../../firebase";
import { Link, useNavigate } from "react-router-dom";
import {
  query,
  collection,
  onSnapshot,
  setDoc,
  doc,
  Timestamp,
  where,
  deleteField,
  orderBy,
  limit,
  getDocs,
  startAfter,
  endBefore,
  limitToLast,
} from "@firebase/firestore";
import Spinner from "../../Reusable/Spinner/Spinner";
import ShowError from "../../Reusable/ShowError/ShowError";
import SuccessToast from "../../Reusable/Toasts/SuccessToast";
import Sidebar from "../../Reusable/Sidebar/Sidebar";
import Navbar from "../../Reusable/Navbar/Navbar";
import { getErrorObject, getUserDetails, postlog, showdate } from "../../../functions/Common"
import TemplateCard from "../../Reusable/TemplateCard/TemplateCard";
import { useGlobalState } from "../../../GlobalProvider";
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MaterialCard from "../../Reusable/MaterialCard/MaterialCard"
import Box from '@mui/material/Box';
import ProAlert from "../../Reusable/ProAlert/ProAlert"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import {Typography,TextField,Divider,Chip} from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';


const Boards = ({ user }) => {
  const [spin, setSpin] = useState(false);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [Toast, setShowToast] = useState(false);
  const [boards, setBoards] = useState([]);
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    layout: "column",
    columns: [],
    backgroundimage: "",
  });
  const [showSettings, setShowSettings] = useState(false);
  const { state } = useGlobalState();

  const [boardTemplates, setBoardTemplates] = useState();
  const [customStyle, setCustomStyle] = useState({
    backgroundColor: "#61a1fe",
    border: "2px solid #61a1fe",
  });
  const [showPro,setShowPro]= useState(false);
  const [openSPopup, setOpenSPopup] = useState(false);
  const [showFormValidate, setShowFormValidate] = useState(false);


  useEffect(() => {
    let unsubscribe = null;

    async function getBoards() {
      try {
        setSpin(true);
        let q;
        if(state.currentteam.teamid != "" && state.currentteam.teamid != "none"){
         q = await query(
          collection(firestore, "boards"),
          where("users", "array-contains", user.userid),
          where("catogery", "==", "fishboneboard"),
          where("teamid","==",state.currentteam.teamid),
          orderBy("updateddate", "desc"),
          limit(5)
        );
         }else{
          q = await query(
            collection(firestore, "boards"),
            where("users", "array-contains", user.userid),
            where("catogery", "==", "fishboneboard"),
            orderBy("updateddate", "desc"),
            limit(5)
          );
         }
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = await onSnapshot(q, (snapshot) => {
          setBoards([]);

          snapshot.forEach((doc) => {
            const newdata = doc.data();
            setBoards((prevMember) => {
              return [...prevMember, newdata];
            });
            setSpin(false);
          });
          // postlog("read boards", { component: "fishbone", function: "getBoards", collection: "boards", uid: user.userid });
          if (user.userid !== "" && user.userid !== null && user.userid !== undefined) {
            postlog("open boards", { component: "fishbone", function: "getBoards", collection: "boards", uid: user.userid });
          }
          setFirstVisible(snapshot.docs[0])
          setLastVisible(snapshot.docs[snapshot.docs.length - 1])
          setSpin(false);
        },
          (err) => {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "getBoards") });
            setSpin(false);
          });
      } catch (err) {
        setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "getBoards") });
        setSpin(false);
      }
    }

    async function getBoardTemplates() {
      try {
        let docexist = false;
        setSpin(true);
        const q = await query(collection(firestore, "boardtemplates"), where("isactive", "==", true), where("catogery", "==", "fishboneboard"), limit(100));
        const snapshot = await getDocs(q);
        // postlog("read boardtemplates", { component: "fishbone", function: "getBoardTemplates", collection: "boardtemplates" });
        setBoardTemplates([]);
        let boardTemplatess = [];
        snapshot.forEach((doc) => {
          docexist = true;
          const newdata = doc.data();
          boardTemplatess.push(newdata);
          setBoardTemplates((prevMember) => {
            return [...prevMember, newdata];
          });
          setSpin(false);
        });

        const sortedArray = boardTemplatess.sort((a, b) => a.displayorder - b.displayorder);
        setBoardTemplates(sortedArray);

        if (!docexist) {
          setshowDocumentMissing(true);
        }
      } catch (err) {
        setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "getBoardTemplates") });
        setSpin(false);
      }
    }

    getBoards();
    getBoardTemplates();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

  }, [state.currentteam]);


  async function loadNext() {
    setDisablePrevious(false)
    try {
      const nextQuery = query(
        collection(firestore, 'boards'),
        where("users", "array-contains", user.userid),
        where("catogery", "==", "fishboneboard"),
        orderBy("updateddate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );

      const snapshot = await getDocs(nextQuery);
      // postlog("read boards", { component: "fishbone", function: "loadNext", collection: "boards", uid: user.userid });

      const boards = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        boards.push(newdata);
      });

      if (snapshot.docs.length > 0) {
        setBoards(boards);
        setFirstVisible(snapshot.docs[0])
        setLastVisible(snapshot.docs[snapshot.docs.length - 1])
      }

      if (snapshot.docs.length < 5) {
        setDisableNext(true);
      }

      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "boards", "loadNext")
      });
      setSpin(false);
    }
  }


  async function loadPrevious() {
    setDisableNext(false);

    try {
      const previousQuery = query(
        collection(firestore, 'boards'),
        where("users", "array-contains", user.userid),
        where("catogery", "==", "fishboneboard"),
        orderBy("updateddate", "desc"),
        endBefore(firstVisible),
        limitToLast(5),
      );

      const snapshot = await getDocs(previousQuery);
      // postlog("read boards", { component: "fishbone", function: "loadPrevious", collection: "boards", uid: user.userid });

      const boards = [];
      snapshot.forEach((doc) => {
        const newData = doc.data();
        boards.push(newData);
      });

      if (snapshot.docs.length > 0) {
        setBoards(boards);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setDisablePrevious(!snapshot.docs.length);
      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "boards", "loadPrevious")
      });
      setSpin(false);
    }
  }

  async function saveBoard(board) {
    try {
      //////////////////Build column map/////////////////////////
      let columns = {};
      let columnOrder = "";
      for (const key in board.columns) {
        columnOrder += board.columns[key].columnid;
        columnOrder += ",";
        columns[board.columns[key].columnid] = {
          columnname: board.columns[key].columnname,
          columnid: board.columns[key].columnid,
          itemorder: board.columns[key].itemorder,
        };
      }
      //////////////////////////////////////////////////////////
      if (!board.id) {
        const docref = doc(collection(firestore, "boards"));

        await setDoc(docref, {
          id: docref.id,
          name: board.name,
          createddate: Timestamp.fromDate(new Date()),
          updateddate: Timestamp.fromDate(new Date()),
          createdbyuserid: user.userid,
          createdbyusername: user.username,
          createdbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          createdbyuseremail: user.useremail,
          updatedbyuserid: user.userid,
          updatedbyusername: user.username,
          updatedbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          updatedbyuseremail: user.useremail,
          columns: columns,
          columnorder: columnOrder,
          items: {},
          level: "public",
          backgroundimage: board.backgroundimage,
          layout: board.layout,
          meetingurl: docref.id,
          users: [user.userid],
          usersdetails: { [user.userid]: getUserDetails(user) },
          mode: board.mode,
          catogery: board.catogery,
          teamid : state.currentteam.teamid ? state.currentteam.teamid :  "",
          teamname : state.currentteam.teamname ? state.currentteam.teamname :  ""
        }).then(() => {
          // postlog("write boards", { component: "fishbone", function: "saveBoard", collection: "boards" });
          postlog("create board", { component: "fishbone", function: "saveBoard", collection: "boards" });
          navigate(`/fishbone/${docref.id}`, { state: { newdoc: true } });
        });
      } else {
        const docRefboard = doc(firestore, "boards", board.id);
        await setDoc(
          docRefboard,
          {
            columns: deleteField(),
          },
          { merge: true }
        );
        // postlog("write boards", { component: "fishbone", function: "saveBoard", collection: "boards", docid: board.id });


        await setDoc(
          docRefboard,
          {
            name: board.name,
            updateddate: Timestamp.fromDate(new Date()),
            updatedby: user.userid,
            columns: columns,
            columnorder: columnOrder,
          },
          { merge: true }
        );
        // postlog("write boards", { component: "fishbone", function: "saveBoard", collection: "boards", docid: board.id });

      }

      hideForm();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "saveBoard") });
      setSpin(false);
    }
  }

  async function deleteColumn(columnid, boardid) {
    try {
      if (formData.columns.length == 1) {
        alert("At least you shoude have one column.");
      } else {
        const updatedColumns = [...formData.columns];
        const index = updatedColumns.findIndex(
          (obj) => obj.columnid === columnid
        );
        if (index !== -1) {
          const newupdatedColumns = [...updatedColumns];
          newupdatedColumns.splice(index, 1);
          setFormData({ ...formData, columns: newupdatedColumns });
        }
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "deleteColumn") });
      setSpin(false);
    }
  }
  const handleCloseAlert = () => {
    setShowPro(false);
  };
  const showForm = (board) => {
    try {
      if (board != null) {
        const columnsorder = board.columnorder.split(",");
        const sortedColumn = [];

        for (var Sortcol in columnsorder) {
          for (var col in board.columns) {
            if (columnsorder[Sortcol] === col) {
              sortedColumn.push(board.columns[col]);
            }
          }
        }

        setFormData({
          id: board.id,
          name: board.name,
          columns: sortedColumn,
          boardid: board.id,
        });
      }
      handleopenSPopup();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "showForm") });
      setSpin(false);
    }
  };

  const hideForm = () => {
    try {
      setFormData({
        id: "",
        name: "",
        columns: [],
        backgroundimage: "",
        layout: "",
        mode: "",
        catogery: "",
      });
      setShowSettings(false);
      handleClosespopup();
      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "hideForm") });
      setSpin(false);
    }
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "handleChange") });
      setSpin(false);
    }
  };

  const handleChange2 = (index, event) => {
    try {
      const { name, value } = event.target;
      const updatedColumns = [...formData.columns];
      updatedColumns[index] = { ...updatedColumns[index], columnname: value };
      setFormData({ ...formData, columns: updatedColumns });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "handleChange2") });
      setSpin(false);
    }
  };
  const addColumn = () => {
    try {
      if (state.subscriptionglobaldata.subscription == "False") {
       setShowPro(true)
      }
      else {
        let newcolumn = doc(collection(firestore, "boards"));
        newcolumn = { columnname: "", columnid: newcolumn.id, itemorder: "" };
        const newcolumns = [...formData.columns, newcolumn];
        setFormData({ ...formData, columns: newcolumns });
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "addColumn") });
      setSpin(false);
    }
  };

  const handleSubmit = (event) => {
    try {
      if (formData.name.trim()) {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        // Process the form data here (e.g., submit to the server)
        event.preventDefault();
        event.stopPropagation();
        saveBoard({
          id: formData.id,
          name: formData.name,
          columns: formData.columns,
          backgroundimage: formData.backgroundimage,
          layout: formData.layout,
          meetingurl: "https://www.agilebin.com/fishbone/",
          mode: formData.mode,
          catogery: formData.catogery,
        });

        hideForm();
        event.preventDefault();
      }
    }else{
      setShowFormValidate(true);
  }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "handleSubmit") });
      setSpin(false);
    }
  };

  function createBoard(templateid,type) {
    try {
      if(showFormValidate){
        setShowFormValidate(false);
      }
      if(type != "pro" ){
        const selectedTemplate = boardTemplates.find(
          (template) => template.id === templateid
        );
        let columns = [];
        columns = selectedTemplate["columns"].split(",");
        handleopenSPopup();
        // document.getElementById("boardNameInput")?.focus()
        let columns2 = [];
  
        columns.map((colname) => {
          let newcolumn = doc(collection(firestore, "boards"));
          newcolumn = {
            columnname: colname,
            columnid: newcolumn.id,
            itemorder: "",
          };
          columns2.push(newcolumn);
        });
  
        setFormData({
          name: formData.name,
          columns: columns2,
          backgroundimage: selectedTemplate.backgroundimage,
          layout: selectedTemplate.layout,
          mode: selectedTemplate.mode,
    catogery: selectedTemplate.catogery,
        });
      }else{
if(state.subscriptionglobaldata.subscription == "False"){
  setShowPro(true);

}else{
  const selectedTemplate = boardTemplates.find(
    (template) => template.id === templateid
  );
  let columns = [];
  columns = selectedTemplate["columns"].split(",");
  handleopenSPopup();
  // document.getElementById("boardNameInput")?.focus()
  let columns2 = [];

  columns.map((colname) => {
    let newcolumn = doc(collection(firestore, "boards"));
    newcolumn = {
      columnname: colname,
      columnid: newcolumn.id,
      itemorder: "",
    };
    columns2.push(newcolumn);
  });

  setFormData({
    name: formData.name,
    columns: columns2,
    backgroundimage: selectedTemplate.backgroundimage,
    layout: selectedTemplate.layout,
    mode: selectedTemplate.mode,
    catogery: selectedTemplate.catogery,

  });
}
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "createBoard") });
      setSpin(false);
    }
  }

  if (formData.columns.length == 0) {
    let newcolumn = doc(collection(firestore, "boards"));
    newcolumn = { columnname: "", columnid: newcolumn.id, itemorder: "" };
    const newcolumns = [...formData.columns, newcolumn];
    setFormData({ ...formData, columns: newcolumns });
  }

  const [isOpen, setIsOpen] = useState(true);

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "handleSidebar") });
      setSpin(false);
    }
  };

  const handleopenSPopup = () => {
    setOpenSPopup(true);
    // document.getElementById("questionInput")?.focus()
  };

  const handleClosespopup = () => {
    setOpenSPopup(false);
  };

  // --------------------------------------html starts here----------------------------------
  return (
    <>
      <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{width:'75%'}}>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
      <div className="row g-0">
            <div className="d-flex gap-2 align-items-center mb-2" >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', fill: 'white', backgroundColor: 'rgba(93, 93, 134, 0.8)', borderRadius: "20%", padding: "10px" }}>
                    <path class="svg-icon" d="M160-120v-80h640v80H160Zm160-160q-66 0-113-47t-47-113v-400h640q33 0 56.5 23.5T880-760v120q0 33-23.5 56.5T800-560h-80v120q0 66-47 113t-113 47H320Zm0-480h320-400 80Zm400 120h80v-120h-80v120ZM560-360q33 0 56.5-23.5T640-440v-320H400v16l72 58q2 2 8 16v170q0 8-6 14t-14 6H300q-8 0-14-6t-6-14v-170q0-2 8-16l72-58v-16H240v320q0 33 23.5 56.5T320-360h240ZM360-760h40-40Z" />
                  </svg> */}
                  <div>
                    <Typography variant="h6">
                    Fishbone {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname!= "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                    Select a template to start a new Fishbone. {state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "False" && <span style={{ fontSize: "12px" }}><Link to="/subscriptions" style={{ fontSize: "12px" }}>Upgrade to PRO</Link > to use premium template.</span>}
                    </Typography>
                  </div>
                </div>
              {/* <hr style={{ border: "1px solid gray" }} /> */}
            </div>
        {user.role !== "member" && boardTemplates && boards && (
          <div
            className="bg-p"
            style={{ overflow: "hidden", height: "auto" }}
          >
            <div className="row">
              {boardTemplates.map((e, index) => (
                 <Tooltip title={`Select ${e.templatename}`}>
                <button
                  key={index}
                  onClick={() => createBoard(e.id,e.type)}
                  style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
                  className="square-item"
                  disabled={e.type == "pro" && state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "False"}
                >
                  {/* <TemplateCard user={user} templatedata={e} /> */}
                  <MaterialCard user={user} templatedata={e} />
                </button>
                </Tooltip>
              ))}
            </div>
          </div>
        )}

{spin === false && boardTemplates && boards.length !== 0 ? (
      <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={{width:'15%'}}>Created At</TableCell>
                <TableCell sx={{ width: '20%' }}>Creator</TableCell>
                <TableCell sx={{ width: '30%' }}>Name</TableCell>
                <TableCell >Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {boards.map((board, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={() => {
                      navigate(`/fishbone/${board.id}`)
                    }}
                    key={board.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{showdate(board.createddate, user.timezone)}</TableCell>
                    <TableCell>
                      <Tooltip title={board.createdbyusername}>
                      <div className="d-flex gap-2 align-items-center" style={{ padding: '10px' }}>
                        {board.createdbyuserprofile && board.createdbyuserprofile != "" ? (
                          <Avatar alt="Remy Sharp" sx={{ width: 28, height: 28 }} src={board.createdbyuserprofile} />
                        ) : (
                          <AccountCircleIcon sx={{ width: 28, height: 28,color:'#5f6368' }} ></AccountCircleIcon>)}
                               <Typography>
                                                  {board.createdbyusername}
                                                </Typography>
                          </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell><ViewWeekOutlinedIcon color='primary' /> {board.name}</TableCell>
                    <TableCell>
                      <Tooltip title='Edit'>
                      <IconButton onClick={(event) => {
                        event.stopPropagation();
                        showForm(board);
                      }}>
                        <EditOutlinedIcon />
                      </IconButton>
                      </Tooltip>
               
                      {/* <IconButton title="Delete" color="error" onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteConfirmation(board.id);
                      }}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
        ) : (
          spin === false && boardTemplates && boards.length === 0 && (
            <Card style={{textAlign:'center',color:'#a9a3b7',padding:'3rem 0rem'}}>
            <Typography variant="h5" gutterBottom>
You've not created or joined any meetings yet!
</Typography>
<Typography variant="h7" gutterBottom>
When you do, they will be show up here.
</Typography>
        </Card>
          )
        )}

{boards && boards.length !== 0 && (
                <div className="d-flex justify-content-end">
                  <ul className="pagination">
                    <li className="page-item">
                      <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                        <KeyboardArrowLeftIcon />
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link" onClick={loadNext} disabled={disableNext} >
                        <KeyboardArrowRightIcon />
                      </button>
                    </li>
                  </ul>
                </div>
              )}

        {/*------------------------------------------ Popup Starts here--------------------------------- */}

        <Dialog
      open={openSPopup} 
      onClose={hideForm}
      aria-labelledby="form-popup"
      aria-describedby="form-popup-description"
      fullWidth={true} maxWidth='sm'
    >
          <DialogTitle>Fishbone {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname!= "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}</DialogTitle>
          <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            id="boardNameInput"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            label="Meeting Name"
            variant="outlined"
            required
            fullWidth
            margin="normal"
            error={showFormValidate && !formData.name.trim()}  
            helperText={showFormValidate && !formData.name.trim() ? 'Board Name is required' : ''}
            autoFocus
          />

          <div className="mb-2"></div>
          <Button className="mt-2 showmorebutton" role="button" onClick={() => setShowSettings(!showSettings)}>
            {showSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />} Show Settings
          </Button>
            <div className={`scrollable-div showsettings mt-1 ${showSettings ? 'show': ''}`} style={{padding:'0 10px'}}>
            <div className="mb-1">
                {state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False" && (
                  <div className="mt-3">
                    Customize columns 
                {state.subscriptionglobaldata.subscription == "False"  && (<Chip  label="Upgrade" size="small"  sx={{color:'#043680',transform:'scale(0.8)',padding:'0 4px',cursor:'pointer'}} onClick={()=>setShowPro(true)}/>)}
                  </div>
                )} 
              </div>

              <div className="text-end mb-1">
                <Button
                  type="button"
                  title="Add Column"
                  onClick={addColumn}
                  variant="outlined"
                  sx={{textTransform:'none'}}
                >
                  Add Column &nbsp;
                  <ControlPointIcon />
                </Button>
              </div>
              {formData.columns !== undefined &&
                formData.columns.map((element, index) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    key={index}
                    className="mb-2"
                  >
                    <TextField
                      key={index}
                      type="text"
                      placeholder="Column name"
                      name={element.columnid}
                      value={element.columnname}
                      onChange={(event) => handleChange2(index, event)}
                      required={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "True"}
                      disabled={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False"}
                      variant="outlined"
                      sx={{width:'100%'}}
                    />
                    <IconButton
                      title="You can only delete a column if it's empty."
                      disabled={
                        element.itemorder !== "" ||
                        formData.columns.length < 2 ||
                        state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False"
                      }
                      style={{ border: "none", background: "none" }}
                      type="button"
                    >
                      <i
                        className="fa fa-trash fs-6"
                        role="button"
                        onClick={() => deleteColumn(element.columnid, formData.boardid)}
                      ></i>
                    </IconButton>
                  </div>
                ))}
            </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions className="m-2">
        <Button onClick={hideForm}>Cancel</Button>
        <Button type="submit" variant="contained" size="large" onClick={handleSubmit} sx={{ textTransform: 'none' }}>
          {formData.id !== "" && formData.id != null && formData.id !== undefined ? "Save" : "Start Fishbone"}
        </Button>
      </DialogActions>
    </Dialog>



        <ProAlert showAlert={showPro} hideAlert={handleCloseAlert}/>
      </div>
      <ShowError Error={showError} />
      <Spinner spin={spin} />
      <SuccessToast
        show={Toast}
        message="This is an Agilebin Pro feature. Please upgrade your plan to Agilebin Pro to use this feature."
        subject="Pro Feature"
        style={customStyle}
      />
    </>
  );
};

export default Boards;
