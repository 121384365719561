import {
  collection,
  doc,
  setDoc,
  Timestamp,
  query,
  onSnapshot,
  where,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  getDocs
} from "firebase/firestore";
import { firestore } from "../../../firebase";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../Reusable/Spinner/Spinner";
import ShowError from "../../Reusable/ShowError/ShowError";
import Sidebar from "../../Reusable/Sidebar/Sidebar";
import Navbar from "../../Reusable/Navbar/Navbar";
import SuccessToast from "../../Reusable/Toasts/SuccessToast";
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ProAlert from "../../Reusable/ProAlert/ProAlert"
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import CreateNew from "../../Reusable/CreateNew/CreateNew";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Radio, RadioGroup, FormControl, FormLabel, Divider } from '@mui/material';
import {
  showdate,
  getErrorObject,
  getUserDetails,
  postlog
} from "../../../functions/Common";
import { useGlobalState } from "../../../GlobalProvider"
import Chip from '@mui/material/Chip';

function PokerGames({ user }) {
  const [allGames, setAllGames] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    "0, 1, 2, 3, 5, 8, 13, 20, 40, 100"
  );
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const { state } = useGlobalState();
  const [Toast, setShowToast] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [customStyle, setCustomStyle] = useState({
    backgroundColor: "#61a1fe",
    border: "2px solid #61a1fe",
  });
  const location = useLocation();
  const [showPro, setShowPro] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const [openSPopup, setOpenSPopup] = useState(false);

  useEffect(() => {

    if (location.state && location.state.dashboard) {
      showForm(null)
      navigate(location.pathname, { state: { dashboard: false } });
    }

    let unsubscribepokergames = null;
    try {

      async function getAllGames() {
        try {
          setSpin(true);
          let q;
          if (state.currentteam.teamid != "" && state.currentteam.teamid != "none") {
            q = query(
              collection(firestore, "pokergames"),
              where("users", "array-contains", user.userid),
              where("teamid", "==", state.currentteam.teamid),
              orderBy("createddate", "desc"),
              limit(5)
            );
          }
          else {
            q = query(
              collection(firestore, "pokergames"),
              where("users", "array-contains", user.userid),
              orderBy("createddate", "desc"),
              limit(5)
            );
          }
          if (unsubscribepokergames) { unsubscribepokergames(); }
          unsubscribepokergames = onSnapshot(q, (snapshot) => {
            setAllGames([])
            snapshot.forEach((doc) => {
              const newdata = doc.data();
              setAllGames((prevMember) => {
                return [...prevMember, newdata];
              });
            });
            // postlog("read pokergames", { component: "pokergames", function: "getAllGames", collection: "pokergames", uid: user.userid });

            setFirstVisible(snapshot.docs[0])
            setLastVisible(snapshot.docs[snapshot.docs.length - 1])
            setSpin(false);
          }, (err) => {
            setShowError({
              show: true,
              errordata: getErrorObject(err, user, "PokerGames", "getAllGames")
            });
            setSpin(false);
          });
        } catch (err) {
          setShowError({
            show: true,
            errordata: getErrorObject(err, user, "PokerGames", "getAllGames"),
          });
          setSpin(false);
        }
      }


      getAllGames();
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "PokerGames", "useEffect"),
      });
      setSpin(false);
    }

    return () => {
      if (unsubscribepokergames) {
        unsubscribepokergames();
      }
    };
  }, [state.currentteam]);

  async function loadNext() {
    setDisablePrevious(false);

    try {
      const nextQuery = query(
        collection(firestore, 'pokergames'),
        where("users", "array-contains", user.userid),
        orderBy("createddate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );

      const snapshot = await getDocs(nextQuery);
      // postlog("read pokergames", { component: "pokergames", function: "loadNext", collection: "pokergames", uid: user.userid });

      const allPokerGames = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        allPokerGames.push(newdata);
      });

      if (snapshot.docs.length > 0) {
        setAllGames(allPokerGames);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      if (snapshot.docs.length < 5) {
        setDisableNext(true);
      }

      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "PokerGames", "loadNext")
      });
      setSpin(false);
    }
  }


  async function loadPrevious() {
    setDisableNext(false);

    try {
      const previousQuery = query(
        collection(firestore, 'pokergames'),
        where("users", "array-contains", user.userid),
        orderBy("createddate", "desc"),
        endBefore(firstVisible),
        limitToLast(5),
      );

      const snapshot = await getDocs(previousQuery);
      //postlog("read pokergames", { component: "pokergames", function: "loadPrevious", collection: "pokergames", uid: user.userid });

      const pokerGames = [];
      snapshot.forEach((doc) => {
        const newData = doc.data();
        pokerGames.push(newData);
      });

      if (snapshot.docs.length > 0) {
        setAllGames(pokerGames);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setDisablePrevious(!snapshot.docs.length);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "PokerGames", "loadPrevious")
      });
      setSpin(false);
    }
  }



  const handleRadioChange = (event) => {
    try {

      if (state.subscriptionglobaldata.subscription == "False") {
        setShowPro(true);
      }
      else {
        setSelectedOption(event.target.value);
      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "PokerGames", "handleRadioChange"),
      });
      setSpin(false);
    }
  };

  function showForm() {
    try {
      // let model = document.getElementById("formPopup");
      // if (model) {
      //   model.style.display = "block";
      // }
      handleopenSPopup();
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "PokerGames", "showForm"),
      });
      setSpin(false);
    }
  }

  function hideForm() {
    try {
      handleClosespopup();
      setSelectedOption("0, 1, 2, 3, 5, 8, 13, 20, 40, 100");
      setShowSettings(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "PokerGames", "hideForm"),
      });
      setSpin(false);
    }
  }

  async function handleSubmit(event) {
    try {
      setSpin(true);
      event.preventDefault();
      if (state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "True") {
        let userData = {};
        if (user == undefined || user == "") {
          userData = {
            userid: user.userid,
            useremail: user.useremail,
            username: user.username,
            isowner: true,
          };
        } else {
          userData = user;
          userData.isowner = true;
        }

        const pokergamesdocRef = doc(collection(firestore, "pokergames"));
        await setDoc(pokergamesdocRef, {
          createdbyuserid: user.userid,
          createdbyusername: user.username,
          createdbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          createdbyuseremail: user.useremail,
          updatedbyuserid: user.userid,
          updatedbyusername: user.username,
          updatedbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          updatedbyuseremail: user.useremail,
          id: pokergamesdocRef.id,
          createddate: Timestamp.fromDate(new Date()),
          updateddate: Timestamp.fromDate(new Date()),
          series: selectedOption,
          state: "idle",
          leadername: user.username,
          low: "",
          majority: "",
          high: "",
          users: [user.userid],
          usersdetails: { [user.userid]: getUserDetails(user) },
          level: "public",
          teamid: state.currentteam.teamid ? state.currentteam.teamid : "",
          teamname: state.currentteam.teamname ? state.currentteam.teamname : "",
          theme: {
            themename: "default",
            backgroundimage: "default",
            frontcardimage: "default",
            backcardimage: "default",
            votedcardimage: "default",
          },
        }).then(() => {
          // postlog("read pokergames", { component: "pokergames", function: "handleSubmit", collection: "pokergames" });
          postlog("create pokergame", { component: "pokergames", function: "handleSubmit", collection: "pokergames" });
          navigate(`/poker/${pokergamesdocRef.id}`, { state: { newdoc: true } });

        });
      } else {
        if (selectedOption) {
          const selectedValue = selectedOption.value;
          const pokergamesdocRef = doc(collection(firestore, "pokergames"));
          setDoc(pokergamesdocRef, {
            createdbyuserid: user.userid,
            createdbyusername: user.username,
            createdbyuserprofile:
              user.profilepicture != undefined ? user.profilepicture : "",
            createdbyuseremail: user.useremail,
            updatedbyuserid: user.userid,
            updatedbyusername: user.username,
            updatedbyuserprofile:
              user.profilepicture != undefined ? user.profilepicture : "",
            updatedbyuseremail: user.useremail,
            id: pokergamesdocRef.id,
            createddate: Timestamp.fromDate(new Date()),
            updateddate: Timestamp.fromDate(new Date()),
            series: selectedOption,
            state: "idle",
            leadername: user.username,
            low: "",
            majority: "",
            high: "",
            meetingended: false,
            level: "public",
            users: [user.userid],
            usersdetails: { [user.userid]: getUserDetails(user) },
            teamid: state.currentteam.teamid ? state.currentteam.teamid : "",
            teamname: state.currentteam.teamname ? state.currentteam.teamname : "",
            theme: {
              themename: "default",
              backgroundimage: "default",
              frontcardimage: "default",
              backcardimage: "default",
              votedcardimage: "default",
              mode: "dark"
            },
          }).then(() => {
            //    postlog("read pokergames", { component: "pokergames", function: "handleSubmit", collection: "pokergames" });
            postlog("create pokergame", { component: "pokergames", function: "handleSubmit", collection: "pokergames" });
            navigate(`/poker/${pokergamesdocRef.id}`, { state: { newdoc: true } });
          });
        }
        hideForm();
      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "PokerGames", "handleSubmit"),
      });
      setSpin(false);
    }
  }
  const [isOpen, setIsOpen] = useState(true);

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "PokerGames", "handleSidebar"),
      });
      setSpin(false);
    }
  };

  const handleCloseAlert = () => {
    setShowPro(false);
  };

  const handleopenSPopup = () => {
    setOpenSPopup(true);
    // document.getElementById("questionInput")?.focus()
  };

  const handleClosespopup = () => {
    setOpenSPopup(false);
  };



  return (
    <>

      {allGames && user && (
        <div className={`sideNav-top listPage-transition container ${isOpen ? "side-margin" : ""}`} style={{ width: '75%' }}>
          <Navbar user={user} toggleSidebar={handleSidebar} />
          <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
          {spin === false && user.role !== "member" && (<div className="mb-3">
            <div className="row g-0">
              <div className="d-flex gap-2 align-items-center mb-2" >
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', fill: 'white', backgroundColor: 'rgba(254, 159, 27, 0.8)', borderRadius: "20%", padding: "10px" }}>
                    <path class="svg-icon" d="m608-368 46-166-142-98-46 166 142 98ZM160-207l-33-16q-31-13-42-44.5t3-62.5l72-156v279Zm160 87q-33 0-56.5-24T240-201v-239l107 294q3 7 5 13.5t7 12.5h-39Zm206-5q-31 11-62-3t-42-45L245-662q-11-31 3-61.5t45-41.5l301-110q31-11 61.5 3t41.5 45l178 489q11 31-3 61.5T827-235L526-125Zm-28-75 302-110-179-490-301 110 178 490Zm62-300Z" />
                  </svg> */}
                <div>
                  <Typography variant="h6">
                    Poker Games {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname != "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    Click on + to start new poker game.
                  </Typography>
                </div>
              </div>

              {/* <hr style={{ border: "1px solid gray" }} /> */}
            </div>
            <div className="row " style={{ width: "98%" }}>
              <button
                onClick={showForm}
                style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
                className="square-item"
              >
                <CreateNew title='New Poker Game' color='#fe9f1b' />
              </button>
            </div>
          </div>)}


          {spin === false && allGames !== undefined && allGames.length !== 0 ? (
            <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '15%' }}>Created At</TableCell>
                        <TableCell >Creator</TableCell>
                        <TableCell sx={{ width: '35%' }}>Series</TableCell>
                        <TableCell>Game Status</TableCell>
                        <TableCell >Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allGames.map((board, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => {
                              if (board.meetingended) {
                                navigate(`/poker/report/${board.id}`)
                              } else {
                                navigate(`/poker/${board.id}`)
                              }
                            }}
                            key={board.id}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell>{showdate(board.createddate, user.timezone)}</TableCell>
                            <TableCell>
                              <Tooltip title={board.createdbyusername}>
                                <div className="d-flex gap-2 align-items-center" style={{ padding: '10px' }}>
                                  {board.createdbyuserprofile && board.createdbyuserprofile != "" ? (
                                    <Avatar alt="Remy Sharp" sx={{ width: 28, height: 28 }} src={board.createdbyuserprofile} />
                                  ) : (
                                    <AccountCircleIcon sx={{ width: 28, height: 28, color: '#5f6368' }} ></AccountCircleIcon>)}
                                  <Typography>
                                    {board.createdbyusername}
                                  </Typography>
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell><StyleOutlinedIcon color='primary' /> {board.series}</TableCell>
                            <TableCell>
                              {!board.meetingended ? (
                                <>
                                  <Button variant="outlined" size="small" onClick={(event) => {
                                    event.stopPropagation();
                                    navigate(`/poker/${board.id}`)
                                  }}>Join</Button>
                                </>
                              ) : (
                                <span className="text-secondary">Meeting Ended</span>
                              )}
                            </TableCell>
                            <TableCell>
                              {copyMessage && (
                                <p className="text-success" style={{ fontSize: "small" }}>
                                  {copyMessage}
                                </p>
                              )}
                              <Tooltip title="Report" >
                                <IconButton onClick={(event) => {
                                  event.stopPropagation();
                                  navigate(`/poker/report/${board.id}`)
                                }}>
                                  <SummarizeOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          ) : (
            spin === false && allGames !== undefined && allGames.length === 0 && (
              <Card style={{ textAlign: 'center', color: '#a9a3b7', padding: '3rem 0rem' }}>
                <Typography variant="h5" gutterBottom>
                  You've not created or joined any poker games yet!
                </Typography>
                <Typography variant="h7" gutterBottom>
                  When you do, they will be show up here.
                </Typography>
              </Card>
            )
          )}
          {allGames && allGames.length !== 0 && (
            <div className="d-flex justify-content-end mb-2">
              <ul className="pagination">
                <li className="page-item">
                  <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                    <KeyboardArrowLeftIcon />
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={loadNext} disabled={disableNext} >
                    <KeyboardArrowRightIcon />
                  </button>
                </li>
              </ul>
            </div>
          )}




          <Dialog open={openSPopup} onClose={hideForm} maxWidth="md" fullWidth>
            <DialogTitle>Poker Game {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname != "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}</DialogTitle>
            <Divider />
            <DialogContent>
              <FormControl component="fieldset" sx={{ width: '100%' }}>
                <FormLabel component="legend">Poker Series</FormLabel>
                <RadioGroup aria-label="poker-series" name="poker-series" value={selectedOption} onChange={handleRadioChange}>
                  <FormControlLabel
                    value="0, 1, 2, 3, 5, 8, 13, 20, 40, 100"
                    control={<Radio />}
                    label="Simple modified fibonacci series: 0, 1, 2, 3, 5, 8, 13, 20, 40, 100"
                  />
                  <div>
                    <Button className="showmorebutton" onClick={() => setShowSettings(!showSettings)} sx={{ textTransform: 'none' }}>
                      {showSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}    {showSettings ? 'Hide Settings' : 'Show Settings'}
                    </Button>
                  </div>
                  <div className={`scrollable-div showsettings ${showSettings ? 'show' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box>
                      <FormControlLabel
                        value="0, ½, 1, 2, 3, 5, 8, 13, 20, 40, 100"
                        control={<Radio />}
                        label="Modified fibonacci series: 0, ½, 1, 2, 3, 5, 8, 13, 20, 40, 100"
                      />
                      {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="0, 1, 2, 3, 5, 8, 13, 21"
                        control={<Radio />}
                        label="Short fibonacci series: 0, 1, 2, 3, 5, 8, 13, 21"
                      />
                      {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                    </Box>

                    <Box>
                      <FormControlLabel
                        value="1, 2, 3, 5, 8, 13"
                        control={<Radio />}
                        label="Shorter fibonacci series:  1, 2, 3, 5, 8, 13"
                      />
                      {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="XS, S, M, L, XL, XXL, XXXL"
                        control={<Radio />}
                        label="T-Shirt sizes: XS, S, M, L, XL, XXL, XXXL"
                      />
                      {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="Small, Medium, Large"
                        control={<Radio />}
                        label="Three buckets: Small, Medium, Large"
                      />
                      {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="0, 1, 2, 4, 8, 16, 32, 64"
                        control={<Radio />}
                        label="Power to two: 0, 1, 2, 4, 8, 16, 32, 64"
                      />
                      {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                    </Box>
                    <Box>

                      <FormControlLabel
                        value="0, 1, 2, 3, 5, 10, 20, 40"
                        control={<Radio />}
                        label="Series of five: 0, 1, 2, 3, 5, 10, 20, 40"
                      />
                      {state.subscriptionglobaldata.subscription == "False" && (<Chip label="Upgrade" size="small" sx={{ color: '#043680', transform: 'scale(0.8)', padding: '0 4px', cursor: 'pointer' }} onClick={() => setShowPro(true)} />)}
                    </Box>
                  </div>
                </RadioGroup>
              </FormControl>
              {/* Additional settings */}
            </DialogContent>
            <Divider />
            <DialogActions className="m-2">
              <Button onClick={hideForm} color="primary">Cancel</Button>
              <Button onClick={handleSubmit} variant="contained" color="primary" size='large' sx={{ textTransform: 'none' }}>Start Poker Game</Button>
            </DialogActions>
          </Dialog>
          <ProAlert showAlert={showPro} hideAlert={handleCloseAlert} />
          <ShowError Error={showError} />
        </div>
      )
      }
      <Spinner spin={spin} />
      <SuccessToast
        show={Toast}
        message="This is an Agilebin Pro feature. Please upgrade your plan to Agilebin Pro to use this feature."
        subject="Pro Feature"
        style={customStyle}
      />
    </>
  );
}

export default PokerGames;
