import React from 'react'
import { Avatar, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Tabs, Tab, Box, Button, Typography, Grid, Container } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
const ManageMembers = ({ team, user, ShowSharePopup }) => {
    return (
        <Container>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 2 }}
                >
                    <Typography variant="h6">
                        Members
                    </Typography>
                    {team.usersdetails[user.userid].isowner && (<Button
                        onClick={() => ShowSharePopup()}
                        // style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
                        color='primary'
                        sx={{ display: 'inline', width: 'auto', borderRadius: '10px', textTransform: 'capitalize' }}
                        variant="contained"
                        size='large'
                    >
                        {/* <CreateNew title='Create New Team' color='#4cac6b'/> */}Manage Members
                    </Button>)}
                </Grid>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell >Name</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Owners</TableCell>
                                    {/* <TableCell >Actions</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(team.usersdetails).map((member, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            key={member.id}
                                            sx={{ cursor: 'pointer',height: 50 }}
                                        >
                                            <TableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar
                                                    alt={`${user.firstname} ${user.lastname}`}
                                                    sx={{ width: 28, height: 28, mr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    src={team.usersdetails[member].profilepicture || ''}

                                                >
                                                    {team.usersdetails[member].profilepicture ? null : <AccountCircleIcon />}
                                                </Avatar>
                                                {team.usersdetails[member].firstname + " " + team.usersdetails[member].lastname}
                                                </Box>
                                            </TableCell>

                                            <TableCell> {team.usersdetails[member].role}</TableCell>
                                            <TableCell> {team.usersdetails[member].isowner ? 'Yes' : "No"}</TableCell>
                                            {/* <TableCell>
                                                <Tooltip title='Remove Member'>
                                                    <IconButton onClick={(event) => {
                                                        event.stopPropagation();
                                                        //   handleDeleteConfirmation(board.id);
                                                    }}>
                                                        <PersonRemoveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </Container>
    )
}

export default ManageMembers