import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import {
  query,
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
  where,
  setDoc,
  orderBy,
  or,
  limit
} from "@firebase/firestore";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/ShowError/ShowError";
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom"
import { getErrorObject, postlog } from "../../functions/Common"
import Button from '@mui/material/Button';
const Notifications = ({ user }) => {
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    email: "",
    message: "",
    status: "active",

  });
  const [isOpen, setIsOpen] = useState(true);
  const { docid } = useParams();


  useEffect(() => {
    let unsubscribe = null;

    async function getNotificationData() {
      setSpin(true);
      try {
        const q = await query(
          collection(firestore, "notifications"),
          or(
            where("userid", "==", user.userid),
            where("email", "==", user.useremail),
          ), orderBy("createddate", "desc"),
          limit(5)
        );
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = await onSnapshot(q, (snapshot) => {
          setData([]);
          snapshot.forEach((doc) => {
            const newdata = doc.data();
            setData((prevMember) => {
              return [...prevMember, newdata];
            });
          });
          // postlog("read notifications", { component: "notifications", function: "getNotificationData", collection: "notifications", uid: user.userid, email: user.useremail });
          setSpin(false);
        });
      } catch (err) {
        setShowError({ show: true, errordata: getErrorObject(err, user, "Notifications", "getNotificationData") });
        setSpin(false);
      }
    }

    getNotificationData();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const handleClearNotification = async (notification, index) => {
    try {
      const notificationRef = doc(firestore, "notifications", notification.id);
      setSpin(true)
      await setDoc(notificationRef, { status: "cleared" }, { merge: true });
     // postlog("write notifications", { component: "notifications", function: "handleClearNotification", collection: "notifications", docid: notification.id });


      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index].status = "cleared";
        return updatedData;
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Notifications", "handleClearNotification") });
      setSpin(false);
    }
  };

  const handleOpenNotification = async (notification, index) => {
    try {
      const notificationRef = doc(firestore, "notifications", notification.id);
      setSpin(true);
      await setDoc(
        notificationRef,
        { status: "cleared" },
        { merge: true }
      ).then(() => {
        //postlog("write notifications", { component: "notifications", function: "handleOpenNotification", collection: "notifications", docid: notification.id });
        if (notification.doctype == "fistoffive") {
          navigate(`/fistoffive/vote/${notification.docid}`)
        } else if (notification.doctype == "board") {
          navigate(`/retrospective/${notification.docid}`)
        } else if (notification.doctype == "poker") {
          navigate(`/poker/${notification.docid}`)
        } else if (notification.doctype == "spinwheel") {
          navigate(`/spinwheels/spinwheel/${notification.docid}`)
        } else if (notification.doctype == "teams") {
          navigate(`/teams/${notification.docid}`)
        } else if (notification.doctype == "assessements") {
          navigate(`/assessments/assessmentresponse/${notification.docid}`)
        } else if (notification.doctype == "userinvite") {
          navigate(`/users/userinvites/${notification.docid}`)
        }
        else if (notification.doctype == "fivewhys"){
          navigate(`/fivewhys/${notification.docid}`)
        }
        else if (notification.doctype == "risks"){
          navigate(`/risks`)
        }
        else if (notification.doctype == "okrs"){
          navigate(`/okrs`)
        }
        else if (notification.doctype == "dependencies"){
          navigate(`/dependencies`)
        }
        else if (notification.doctype == "impediments"){
          navigate(`/impediments`)
        }
        else if (notification.doctype == "programboards"){
          navigate(`/programboard/${notification.docid}`)
        }
      });
      setSpin(false);
      setSpin(true);
      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index].status = "cleared";
        return updatedData;
      });
      setSpin(false);

    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Notifications", "handleOpenNotification") });
      setSpin(false);
    }
  };

  const recentNotifications = data.filter(
    (notification) => notification.status === "active"
  );
  const pastNotifications = data.filter(
    (notification) => notification.status === "cleared"
  );


  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Notifications", "handleSidebar") });
      setSpin(false);
    }
  }

  const handleClearAllNotifications = async () => {
    try {
      setSpin(true);
      data.forEach(element => {
        const notificationRef = doc(firestore, "notifications", element.id);
        setDoc(notificationRef, { status: "cleared" }, { merge: true });
      //  postlog("write notifications", { component: "notifications", function: "handleClearNotification", collection: "notifications", docid: element.id });
      });
      //setData([]);
      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Notifications", "handleClearAllNotifications") });
      setSpin(false);
    }
  };


  return (
    <>
      <div>
        <Navbar user={user} toggleSidebar={handleSidebar} />
        <Sidebar isopen={isOpen} user={user} />
        {data !== undefined && (
          <div className="side-margin bg-p">
            <div>
              <div style={{ marginBottom: "20px", marginTop: "65px" }}>
                <div className="col-md-12 bg-p">
                  <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                    <div style={{ width: "98%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <b style={{ paddingLeft: "10px" }}>
                          Recent Notifications
                        </b>
                        <Button
                          variant="contained"
                          onClick={handleClearAllNotifications}
                        >
                          Clear All Notifications
                        </Button>
                      </div>
                      {recentNotifications.slice() // Create a copy to avoid modifying the original array
                        .sort((a, b) => {
                          const dateA = new Date(
                            a.createddate.seconds * 1000 + a.createddate.nanoseconds / 1000000
                          );
                          const dateB = new Date(
                            b.createddate.seconds * 1000 + b.createddate.nanoseconds / 1000000
                          );

                          return dateB - dateA; // Sort in descending order
                        }).map((notification, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #ddd",
                              boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px",
                              margin: "5px",
                              padding: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ flex: "1" }}>
                              {notification.message}
                            </div>
                            <div style={{ display: "flex", gap: "8px" }}>
                              <p className="mt-2" style={{ fontSize: "13px" }}>
                                {(() => {
                                  const currentDate = new Date();

                                  if (notification.createddate) {
                                    const createdDate = new Date(
                                      notification.createddate.seconds * 1000 +
                                      notification.createddate.nanoseconds / 1000000
                                    );

                                    if (
                                      createdDate.getDate() === currentDate.getDate() &&
                                      createdDate.getMonth() === currentDate.getMonth() &&
                                      createdDate.getFullYear() === currentDate.getFullYear()
                                    ) {
                                      // Today's date
                                      return new Intl.DateTimeFormat('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                      }).format(createdDate);
                                    } else if (
                                      createdDate.getDate() === currentDate.getDate() - 1 &&
                                      createdDate.getMonth() === currentDate.getMonth() &&
                                      createdDate.getFullYear() === currentDate.getFullYear()
                                    ) {
                                      // Yesterday's date
                                      return new Intl.DateTimeFormat('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                      }).format(createdDate);
                                    } else {
                                      // Other dates
                                      return new Intl.DateTimeFormat('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                      }).format(createdDate);
                                    }
                                  } else {
                                    return 'No date available';
                                  }
                                })()}
                              </p>
                              &nbsp;
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleOpenNotification(notification, index)
                                }
                              >
                                Open
                              </Button>
                              <Button
                               variant="contained"
                               color="error"
                                onClick={() =>
                                  handleClearNotification(notification, index)
                                }
                              >
                                Clear
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div style={{ marginBottom: "20px", marginTop: "5px" }}>
                <div className="col-md-12 bg-p">
                  <div style={{ maxheight: "350px", overflowY: "auto" }}>
                    <div style={{ width: "100%" }}>
                      <b style={{ paddingLeft: "10px" }}>
                        Past Notifications
                      </b>
                      {pastNotifications
                        .slice() // Create a copy to avoid modifying the original array
                        .sort((a, b) => {
                          const dateA = new Date(
                            a.createddate.seconds * 1000 + a.createddate.nanoseconds / 1000000
                          );
                          const dateB = new Date(
                            b.createddate.seconds * 1000 + b.createddate.nanoseconds / 1000000
                          );

                          return dateB - dateA; // Sort in descending order
                        }).map((notification, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #ddd",
                              boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px",
                              margin: "5px",
                              padding: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ flex: "1" }}>
                              {notification.message}
                            </div>
                            <div style={{ display: "flex", gap: "8px" }}>
                              <p className="mt-2" style={{ fontSize: "13px" }}>
                                {(() => {
                                  const currentDate = new Date();

                                  if (notification.createddate) {
                                    const createdDate = new Date(
                                      notification.createddate.seconds * 1000 +
                                      notification.createddate.nanoseconds / 1000000
                                    );

                                    if (
                                      createdDate.getDate() === currentDate.getDate() &&
                                      createdDate.getMonth() === currentDate.getMonth() &&
                                      createdDate.getFullYear() === currentDate.getFullYear()
                                    ) {
                                      // Today's date
                                      return new Intl.DateTimeFormat('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                      }).format(createdDate);
                                    } else if (
                                      createdDate.getDate() === currentDate.getDate() - 1 &&
                                      createdDate.getMonth() === currentDate.getMonth() &&
                                      createdDate.getFullYear() === currentDate.getFullYear()
                                    ) {
                                      // Yesterday's date
                                      return new Intl.DateTimeFormat('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                      }).format(createdDate);
                                    } else {
                                      // Other dates
                                      return new Intl.DateTimeFormat('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                      }).format(createdDate);
                                    }
                                  } else {
                                    return 'No date available';
                                  }
                                })()}
                              </p>
                              &nbsp;
                              <Button
                               variant="contained"
                                onClick={() =>
                                  handleOpenNotification(notification, index)
                                }
                              >
                                Open
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        )}
        <ShowError Error={showError} />
        <Spinner spin={spin} />
      </div>
    </>
  );
};

export default Notifications;