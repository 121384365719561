import React from 'react'
import { Link } from 'react-router-dom'
const DocumentMissing = ({ show }) => {
  if (show) {
    return (
      <div className="center-container" style={{ zIndex: 10000, position: "fixed", top: "0", height: "100vh", width: "100vw", background: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div className="card">
          <div className="card-body">
            <div className="missing-document">
              <div className="icon">
                <i className="fa fa-regular fa-file-excel" style={{ fontSize: '3rem', color: "#4a4c4d" }}></i>
              </div>
              <div className="message">
                <h2>Page Not Accessible</h2>
                <p>Sorry, the page you are looking for is either not available or you do not have access to this page.</p>
                <div>
                  <Link to="/" className="btn btn-primary mt-3">
                    Go to Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DocumentMissing