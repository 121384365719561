/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { Link } from "react-router-dom"
import {
  query,
  collection,
  onSnapshot,
  doc,
  Timestamp,
  deleteDoc,
  where,
  setDoc,
  getDocs,
  deleteField,
  limit,
} from "@firebase/firestore";
import { useNavigate } from 'react-router-dom';
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/ShowError/ShowError";
import SuccessToast from "../Reusable/Toasts/SuccessToast";
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import { Notify, getErrorObject, postlog } from "../../functions/Common";
import { useGlobalState } from "../../GlobalProvider";
import { deepOrange, deepPurple } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import ProAlert from "../Reusable/ProAlert/ProAlert"
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextareaAutosize, Divider, TextField, MenuItem, Card } from '@mui/material';
import { Avatar, Button, Paper,Alert,Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
function Users({ user }) {
  const [quantity, setQuantity] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Member");
  const [userInvites, setUserinvites] = useState([]);
  const [pendingInvites, setPendingInvites] = useState(0);
  const [userDetails, setUserDetails] = useState("");
  const [Toast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSubject, setToastSubject] = useState("");
  const [customStyle, setCustomStyle] = useState({
    backgroundColor: "#61a1fe",
    border: "2px solid #61a1fe",
  });
  const [showPro, setShowPro] = useState(false);

  const [formData, setFormData] = useState({
    useremail: "",
    role: "",
  });
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [isOpen, setIsOpen] = useState(true);
  const [selectedInputUser, setSelectedInputUser] = useState("Select Users");
  const [selectedUser, setSelectedUser] = useState({});
  const [deletedUser, setDeletedUser] = useState({});
  const [deactivatedUser, setDeactivatedUser] = useState(0);
  const [pokerGames, setPokerGames] = useState([]);
  const [boards, setboards] = useState([]);
  const [votings, setVotings] = useState([]);
  const [spinwheel, setSpinwheel] = useState([]);
  const [assessment, setAssessment] = useState([]);
  const [teams, setTeams] = useState([]);
  const { state } = useGlobalState();
  const [snackbarOpen, setSnackbarOpen] = useState();

  useEffect(() => {
    let unsubscribeusers = null;
    let unsubscribesubscription = null;
    let unsubscribeuserinvites = null;
    let unsubscribesubscription3 = null;

    if (user.tenentid != undefined && user.tenentid != "") {

      const getUsers = async (tenentid) => {
        setSpin(true);
        try {
          if (tenentid != "" && tenentid != undefined && tenentid != null) {
            const q = await query(
              collection(firestore, "users"),
              where("tenentid", "==", tenentid),
              limit(100),
            );
            let usersArr = [];
            let deactivatedUser = 0;
            if (unsubscribeusers) { unsubscribeusers(); }
            unsubscribeusers = await onSnapshot(q, (snapshot) => {
              deactivatedUser = 0;
              usersArr = [];
              snapshot.forEach((doc) => {
                const newdata = doc.data();
                if (newdata.isactive == false) {
                  deactivatedUser += 1;
                }
                usersArr.push(newdata);
              });
        //      postlog("read users", { component: "users", function: "getUsers", collection: "users", tenentid: tenentid });
              setAllUsers(usersArr);
              setDeactivatedUser(deactivatedUser);
              setSpin(false);
            },
              (err) => {
                setShowError({ show: true, errordata: getErrorObject(err, user, "Users", "getUsers") });
                setSpin(false);
              });
          }
        } catch (err) {
          setShowError({
            show: true,
            errordata: getErrorObject(err, user, "Users", "getUsers"),
          });
          setSpin(false);
        }
      };

      const getSubscription = async (tenentid) => {
        setSpin(true);
        try {

          if (tenentid != "" && tenentid != undefined && tenentid != null) {
         // --------------------------------------check if subscription exist in stripe for this tenentid 
         const q = await query(
          collection(firestore, "customers/" + tenentid + "/subscriptions"),
          where("status", "==", "active"),
          limit(1),
        );
        unsubscribesubscription = await onSnapshot(q, (snapshot) => {
          snapshot.forEach((docc) => {
            setQuantity(docc.data().quantity);
          });
    //      postlog("read customers", { component: "globalprovider", function: "getSubscription", collection: "customers", tenentid: tenentid });
        });
        //-----------------------------------------------------------------------
        // --------------------------------------check if subscription exist in users in backend for this tenentid
        unsubscribesubscription3 = onSnapshot(doc(firestore, "users", tenentid),
        (doc) => {
          if (doc.data() != undefined) {
            if (doc.data().subscription != undefined && doc.data().subscription != "False" && doc.data().subscription.isactive) {
              if(doc.data().subscription.type != "FreeTrial"){
                setQuantity(doc.data().subscription.quantity);
              }
              else{
              if(doc.data().subscription.freetrialenddate.toDate() > new Date()){
                setQuantity(doc.data().subscription.quantity);
              }
            }
          }
        }

        //  postlog("read users", { component: "globalprovider", function: "getSubscription", collection: "customers", tenentid: tenentid });
        });

        //------------------------------------------------------------------------------------
      }









        

          setSpin(false);
        } catch (err) {
          setShowError({
            show: true,
            errordata: getErrorObject(err, user, "Users", "getSubscription"),
          });
          setSpin(false);
        }
      };

      async function getUserInvites(id) {
        setSpin(true);
        try {
          if (id != "" && id != undefined && id != null) {
            const q = await query(
              collection(firestore, "userinvites"),
              where("tenentid", "==", id),
              limit(100),
            );
            if (unsubscribeuserinvites) { unsubscribeuserinvites(); }
            unsubscribeuserinvites = await onSnapshot(q, (snapshot) => {
              setUserinvites([]);
              setPendingInvites(0);
              snapshot.forEach((doc) => {
                const newdata = doc.data();
                if (newdata.status == "pending") {
                  setPendingInvites((pendingInvites) => pendingInvites + 1);
                }
                setUserinvites((prevMember) => {
                  return [...prevMember, newdata];
                });
                setSpin(false);
              });
       //       postlog("read userinvites", { component: "users", function: "getUserInvites", collection: "userinvites", tenentid: id });
            });
          }
        } catch (err) {
          setShowError({
            show: true,
            errordata: getErrorObject(err, user, "Users", "getUserInvites"),
          });
          setSpin(false);
        }
      }

      getUsers(user.tenentid);
      getSubscription(user.tenentid);
      getUserInvites(user.tenentid);
    }

    return () => {
      if (unsubscribeusers) {
        unsubscribeusers();
      }
      if (unsubscribesubscription) {
        unsubscribesubscription();
      }
      if (unsubscribesubscription3) {
        unsubscribesubscription3();
      }
      if (unsubscribeuserinvites) {
        unsubscribeuserinvites();
      }
    };
  }, []);

  async function handleSubmit(event) {
    try {
      setSpin(true);
      event.preventDefault();
      let validUser = true;
      const q = query(
        collection(firestore, "users"),
        where("useremail", "==", formData.useremail),
        limit(1),
      );

      const querySnapshot = await getDocs(q);
     // postlog("read users", { component: "users", function: "handleSubmit", collection: "users", email: formData.useremail });

      querySnapshot.forEach((doc) => {
        const existingUser = doc.data();
        if (
          existingUser.tenentid != "" &&
          existingUser.tenentid != undefined &&
          existingUser.tenentid != user.tenentid
        ) {
          validUser = false;
        }
      });

      if (validUser) {
        const form = event.target;
        saveUser({
          useremail: form.useremail.value,
          role: selectedValue,
        });
        hideForm();
      } else {
        setCustomStyle({
          backgroundColor: "#dc3545",
          border: "2px solid #61a1fe",
        });
        setSnackbarOpen(true);
        setSpin(false);
      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "handleSubmit"),
      });
      setSpin(false);
    }
  }

  const hideForm = () => {
    try {
      setFormData({
        useremail: "",
        role: "",
      });

      let model = document.getElementById("formPopup");
      model.style.display = "none";
      let transferOwnerPopup = document.getElementById("transferOwner");
      transferOwnerPopup.style.display = "none";
      setSelectedValue("Member")
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "hideForm"),
      });
      setSpin(false);
    }
  };

  const handleDropdownChange = (event) => {
    try {
      let email = formData.useremail;
      setFormData({
        useremail: email,
        role: event.target.value,
      });
      setSelectedValue(event.target.value);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "handleDropdownChange"),
      });
      setSpin(false);
    }
  };

  const showForm = (userdata) => {
    try {
      if (userdata.useremail !== undefined) {
        setFormData({
          useremail: userdata.useremail,
          role: userdata.role,
        });
        document.getElementById("email-input-id").disabled = true;
        setUserDetails(userdata);
      } else {
        document.getElementById("email-input-id").disabled = false;
      }
      if (
        allUsers.length + pendingInvites - deactivatedUser >= quantity &&
        userdata.useremail === undefined
      ) {
        setShowPro(true);
        // setCustomStyle({
        //   backgroundColor: "#61a1fe",
        //   border: "2px solid #61a1fe",
        // });
        // setToastMessage(
        //   "Upgrade now to add more users with our subscription plan!"
        // );
        // setToastSubject("Premium Plan");
        // setShowToast(true);
        // setTimeout(() => {
        //   setShowToast(false);
        // }, 3000);
      } else {
        let model = document.getElementById("formPopup");
        model.style.display = "block";
      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "showForm"),
      });
      setSpin(false);
    }
  };

  async function saveUser(userdata) {
    try {
      if (userDetails) {
        const docref = doc(collection(firestore, "users"), userDetails.userid);
        await setDoc(
          docref,
          {
            role: selectedValue.toLowerCase(),
            updateddate: Timestamp.fromDate(new Date()),
          },
          { merge: true }
        );
        //postlog("write users", { component: "users", function: "saveUser", collection: "users", uid: userDetails.userid });
      } else {
        const docref = doc(collection(firestore, "userinvites"));
        await setDoc(
          docref,
          {
            id: docref.id,
            useremail: userdata.useremail,
            role: selectedValue.toLowerCase(),
            tenentid: user.tenentid,
            status: "pending",
            createddate: Timestamp.fromDate(new Date()),
            updateddate: Timestamp.fromDate(new Date()),
            senderusername: user.username,
            senderemail: user.useremail,
            senderuserid: user.userid
          },
          { merge: true }
        );
        //postlog("write userinvites", { component: "users", function: "saveUser", collection: "userinvites" });

        Notify(
          "Invitation to join",
          `You have been invited by ${user.username} to join their company.`,
          userdata.useremail,
          selectedValue,
          true,
          true,
          "userinvite",
          docref.id,
          user.username
        );
      }

      setUserDetails("");
      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "saveUser"),
      });
      setSpin(false);
    }
  }

  async function activateMember(e) {
    if (allUsers.length + pendingInvites - deactivatedUser < quantity) {
      const docref = doc(collection(firestore, "users"), e.userid);
      await setDoc(
        docref,
        {
          isactive: true,
        },
        { merge: true }
      );
   //   postlog("write users", { component: "users", function: "activateMember", collection: "users", uid: e.userid });

      setCustomStyle({
        backgroundColor: "#61a1fe",
        border: "2px solid #61a1fe",
      });
      setToastMessage(
        "User Activated"
      );
      setToastSubject("User Activated");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    } else {
      setCustomStyle({
        backgroundColor: "#61a1fe",
        border: "2px solid #61a1fe",
      });
      setToastMessage(
        "Upgrade now to add more users with our subscription plan!"
      );
      setToastSubject("Premium Plan");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }

  async function deleteMember(e) {
    try {
      let isowner = false;
      setSpin(true);
      const pokerQuery = query(
        collection(firestore, "pokergames"),
        where(`usersdetails.${e.userid}.isowner`, "==", true)
      );
      const pokerSnapshot = await getDocs(pokerQuery);
      //postlog("read pokergames", { component: "users", function: "deleteMember", collection: "pokergames", uid: e.userid });
      const allPokerGames = pokerSnapshot.docs.map((doc) => doc.data());
      setPokerGames(allPokerGames);
      if (allPokerGames.length > 0) {
        isowner = true;
      }

      const boardQuery = query(
        collection(firestore, "boards"),
        where(`usersdetails.${e.userid}.isowner`, "==", true)
      );
      const boardSnapshot = await getDocs(boardQuery);
    //  postlog("read boards", { component: "users", function: "deleteMember", collection: "boards", uid: e.userid });
      const boards = boardSnapshot.docs.map((doc) => doc.data());
      setboards(boards);
      if (boards.length > 0) {
        isowner = true;
      }

      const votingsQuery = query(
        collection(firestore, "votings"),
        where(`usersdetails.${e.userid}.isowner`, "==", true)
      );
      const votingSnapshot = await getDocs(votingsQuery);
     // postlog("read votings", { component: "users", function: "deleteMember", collection: "votings", uid: e.userid });
      const allVotings = votingSnapshot.docs.map((doc) => doc.data());
      setVotings(allVotings);
      if (allVotings.length > 0) {
        isowner = true;
      }

      const spinwheelsQuery = query(
        collection(firestore, "spinwheels"),
        where(`usersdetails.${e.userid}.isowner`, "==", true)
      );
      const spinwheelSnapshot = await getDocs(spinwheelsQuery);
      //postlog("read spinwheels", { component: "users", function: "deleteMember", collection: "spinwheels", uid: e.userid });
      const allSpinwheel = spinwheelSnapshot.docs.map((doc) => doc.data());
      setSpinwheel(allSpinwheel);
      if (allSpinwheel.length > 0) {
        isowner = true;
      }

      const assessmentsQuery = query(
        collection(firestore, "assessements"),
        where(`usersdetails.${e.userid}.isowner`, "==", true)
      );
      const assessmentSnapshot = await getDocs(assessmentsQuery);
    //  postlog("read assessements", { component: "users", function: "deleteMember", collection: "assessements", uid: e.userid });
      const allAssessments = assessmentSnapshot.docs.map((doc) => doc.data());
      setAssessment(allAssessments);
      if (allAssessments.length > 0) {
        isowner = true;
      }

      const teamsQuery = query(
        collection(firestore, "teams"),
        where("users", "array-contains", e.userid)
      );
      const teamsSnapshot = await getDocs(teamsQuery);
      //postlog("read teams", { component: "users", function: "deleteMember", collection: "teams", uid: e.userid });
      const allteams = teamsSnapshot.docs.map((doc) => doc.data());
      setTeams(allteams);

      allteams.forEach(element => {
        if (element.usersdetails[e.userid].isowner == true) {
          isowner = true;
        }
      });

      if (isowner) {
        document.getElementById("transferOwner").style.display = "block";
        setDeletedUser(e);
        setSpin(false);
      } else {
        allteams.forEach((element) => {
          let newUserArr = element.users.filter((item) => {
            if (item == e.userid) {
              return false;
            } else {
              return true;
            }
          });

          const docref = doc(collection(firestore, "teams"), element.id);
          setDoc(
            docref,
            {
              usersdetails: {
                [e.userid]: deleteField(),
              },
              users: newUserArr,
            },
            { merge: true }
          );
       //   postlog("write teams", { component: "users", function: "deleteMember", collection: "teams", docid: element.id });
        });

        const docref = doc(collection(firestore, "users"), e.userid);
        await setDoc(
          docref,
          {
            isactive: false,
          },
          { merge: true }
        );
        //postlog("write users", { component: "users", function: "deleteMember", collection: "users", uid: e.userid });
        setCustomStyle({
          backgroundColor: "#61a1fe",
          border: "2px solid #61a1fe",
        });
        setToastMessage(
          "User Deactivated"
        );
        setToastSubject("User Deactivated");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "deleteMember"),
      });
      setSpin(false);
    }
  }

  async function deleteInvites(e) {
    try {
      await deleteDoc(doc(firestore, "userinvites", e.id));
     // postlog("write userinvites", { component: "users", function: "deleteInvites", collection: "userinvites", uid: e.userid });
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "deleteInvites"),
      });
      setSpin(false);
    }
  }

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "handleChange"),
      });
      setSpin(false);
    }
  };

  function userDropdown(event) {
    try {
      let userId = event.target.value;
      allUsers.forEach((element) => {
        if (element.userid == userId) {
          setSelectedInputUser(allUsers.userId);
          setSelectedUser(element);
        }
      });
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "userDropdown"),
      });
      setSpin(false);
    }
  }

  function handleDelete(event) {
    event.preventDefault();

    try {
      pokerGames.forEach((element) => {
        let newUserArr = element.users.filter((item) => {
          if (item == deletedUser.userid || item == selectedUser.userid) {
            return false;
          } else {
            return true;
          }
        });
        newUserArr.push(selectedUser.userid);
        const docref = doc(collection(firestore, "pokergames"), element.id);
        setDoc(
          docref,
          {
            usersdetails: {
              [deletedUser.userid]: deleteField(),
              [selectedUser.userid]: { ...selectedUser, isowner: true },
            },
            users: newUserArr,
          },
          { merge: true }
        );
       // postlog("write pokergames", { component: "users", function: "handleDelete", collection: "pokergames", docid: element.id });
      });

      boards.forEach((element) => {
        let newUserArr = element.users.filter((item) => {
          if (item == deletedUser.userid || item == selectedUser.userid) {
            return false;
          } else {
            return true;
          }
        });
        newUserArr.push(selectedUser.userid);
        const docref = doc(collection(firestore, "boards"), element.id);
        setDoc(
          docref,
          {
            usersdetails: {
              [deletedUser.userid]: deleteField(),
              [selectedUser.userid]: { ...selectedUser, isowner: true },
            },
            users: newUserArr,
          },
          { merge: true }
        );
    //    postlog("write boards", { component: "users", function: "handleDelete", collection: "boards", docid: element.id });

      });

      votings.forEach((element) => {
        let newUserArr = element.users.filter((item) => {
          if (item == deletedUser.userid || item == selectedUser.userid) {
            return false;
          } else {
            return true;
          }
        });
        newUserArr.push(selectedUser.userid);
        const docref = doc(collection(firestore, "votings"), element.votingid);
        setDoc(
          docref,
          {
            usersdetails: {
              [deletedUser.userid]: deleteField(),
              [selectedUser.userid]: { ...selectedUser, isowner: true },
            },
            users: newUserArr,
          },
          { merge: true }
        );
      //  postlog("write votings", { component: "users", function: "handleDelete", collection: "votings", docid: element.id });

      });

      spinwheel.forEach((element) => {
        let newUserArr = element.users.filter((item) => {
          if (item == deletedUser.userid || item == selectedUser.userid) {
            return false;
          } else {
            return true;
          }
        });
        newUserArr.push(selectedUser.userid);
        const docref = doc(collection(firestore, "spinwheels"), element.id);
        setDoc(
          docref,
          {
            usersdetails: {
              [deletedUser.userid]: deleteField(),
              [selectedUser.userid]: { ...selectedUser, isowner: true },
            },
            users: newUserArr,
          },
          { merge: true }
        );
      //  postlog("write spinwheels", { component: "users", function: "handleDelete", collection: "spinwheels", docid: element.id });

      });

      assessment.forEach((element) => {
        let newUserArr = element.users.filter((item) => {
          if (item == deletedUser.userid || item == selectedUser.userid) {
            return false;
          } else {
            return true;
          }
        });
        newUserArr.push(selectedUser.userid);
        const docref = doc(collection(firestore, "assessements"), element.id);
        setDoc(
          docref,
          {
            usersdetails: {
              [deletedUser.userid]: deleteField(),
              [selectedUser.userid]: { ...selectedUser, isowner: true },
            },
            users: newUserArr,
          },
          { merge: true }
        );
        //postlog("write assessements", { component: "users", function: "handleDelete", collection: "assessements", docid: element.id });

      });

      teams.forEach((element) => {
        let newUserArr = element.users.filter((item) => {
          if (item == deletedUser.userid || item == selectedUser.userid) {
            return false;
          } else {
            return true;
          }
        });
        newUserArr.push(selectedUser.userid);

        const docref = doc(collection(firestore, "teams"), element.id);
        setDoc(
          docref,
          {
            usersdetails: {
              [deletedUser.userid]: deleteField(),
              [selectedUser.userid]: { ...selectedUser, isowner: true },
            },
            users: newUserArr,
          },
          { merge: true }
        );
        //postlog("write teams", { component: "users", function: "handleDelete", collection: "teams", docid: element.id });

      });

      const docref = doc(collection(firestore, "users"), deletedUser.userid);
      setDoc(
        docref,
        {
          isactive: false,
        },
        { merge: true }
      );
     // postlog("write users", { component: "users", function: "handleDelete", collection: "users", uid: deletedUser.userid });
      hideForm();
      setCustomStyle({
        backgroundColor: "#61a1fe",
        border: "2px solid #61a1fe",
      });
      setToastMessage(
        "User Deactivated"
      );
      setToastSubject("User Deactivated");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "handleDelete"),
      });
      setSpin(false);
    }
  }

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Users", "handleSidebar"),
      });
      setSpin(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  }

  const handleCloseAlert = () => {
    setShowPro(false);
  };

  const getRoleIcon = (role) => {
    switch (role) {
      case 'member':
        return <PersonIcon />;
      case 'superadmin':
        return <ManageAccountsIcon />;
      case 'administrator':
        return <AdminPanelSettingsIcon />;
      case 'facilitator':
        return <PersonAddAltSharpIcon />;
      default:
        return null;
    }
  };


  return (
    <>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
      <div style={{maxHeight: '100vh', overflow: 'auto'}}>
        {allUsers != undefined &&
          pendingInvites != undefined &&
          quantity != undefined &&
          (userInvites != "" || userInvites != null) && (
            <div>
              <div id="transferOwner" className="modal">
                <div className="modal-content">
                  <div className="modal-header">
                    <div>
                      <b>Users</b>
                    </div>
                    <div className="circleIconContainer" onClick={hideForm}>
                      <span className="circleIcon">&times;</span>
                    </div>
                  </div>
                  <div className="modal-body">
                    <p>
                      This Users is the owner of some documents. Please transfer
                      the ownership to different user to remove the user
                    </p>
                    <form onSubmit={handleDelete}>
                      <div className="row mb-2  mb-2">
                        <div className="col-6">
                          <select
                            className="form-select"
                            onChange={userDropdown}
                            value={selectedInputUser}
                          >
                            <option value="selectuser">Select Users</option>
                            {allUsers.map((user) =>
                              !allUsers.hasOwnProperty(user.userid) &&
                                user.userid != deletedUser.userid ? (
                                <option key={user.userid} value={user.userid}>
                                  {user.firstname + user.lastname}
                                </option>
                              ) : null
                            )}
                          </select>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        type="submit"
                        className="mt-2 text-end"
                      >
                        Submit
                      </Button>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <div>
                      <b>Agilebin Teams</b>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
                  <div className="row g-0 mb-2">
                    <div className="d-flex gap-2 align-items-center mb-2" >
                      <div>
                        <Typography variant="h6">
                          Users
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          Manage users in your account here.{state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "False" && <span style={{ fontSize: "12px" }}><Link to="/subscriptions" style={{ fontSize: "12px" }}>Upgrade to PRO</Link > to add more users.</span>}
                        </Typography>
                      </div>
                    </div>
                  </div>
              
              <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Typography style={{ flex: 1 }} variant="h6">
                    Available Seats: {quantity - (allUsers.length + pendingInvites - deactivatedUser)}/{quantity}
                  </Typography>
                  <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" size='large' onClick={showForm} sx={{textTransform:"capitalize"}}>
                      Add Users
                    </Button>
                  </div>
                </div>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  {allUsers.length > 0 ? (
                    <TableContainer className="scrollable-div" style={{ maxHeight: '310px' }}>
                      <Table stickyHeader aria-label="sticky table" size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: '20%' }}>Name</TableCell>
                            <TableCell sx={{ width: '20%' }}>Email</TableCell>
                            <TableCell sx={{ width: '20%' }}>Role</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allUsers.map((e, index) => (
                            <TableRow hover key={index}>
                              <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Avatar alt="User Avatar" src={e.profilepicture || ''} sx={{ bgcolor: e.profilepicture ? 'transparent' : deepOrange[500], width: 24, height: 24 }}>
                                    {e.firstname[0]}
                                  </Avatar>
                                  <span style={{ marginLeft: 8 }}>{e.firstname + ' ' + e.lastname}</span>
                                </div>
                              </TableCell>
                              <TableCell>{e.useremail}</TableCell>
                              <TableCell>
                                <Chip
                                  icon={getRoleIcon(e.role)}
                                  label={e.role}
                                  variant="outlined"
                                  color="primary"
                                  style={{ color: '#b39ddb', borderColor: '#b39ddb' }}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell>
                                <Tooltip title='Edit'>
                                  <IconButton
                                    size="small"
                                    onClick={() => showForm(e)}
                                    disabled={user.userid === e.userid}
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                                {/* <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  className="mx-2"
                                  onClick={() => e.isactive ? deleteMember(e) : activateMember(e)}
                                  disabled={user.userid === e.userid}
                                >
                                  {e.isactive ? 'Deactivate' : 'Activate'}
                                </Button> */}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Card style={{ textAlign: 'center', color: '#a9a3b7', padding: '3rem 0rem' }}>
                      <Typography variant="h5" gutterBottom>
                        No Available Seats!
                      </Typography>
                      <div style={{ padding: '0 8rem' }}>
                        <Typography variant="h7" gutterBottom>
                          Upgrade to Agilebin Pro or Enterprise subscription and purchase user seats for multiple users according to your needs. Once you have available user seats, you can proceed to add users to your account.
                        </Typography>
                      </div>
                      <div className="mt-2"><Button variant="contained" onClick={()=>{navigate("/subscriptions")}} autoFocus>Upgrade</Button></div>
                    </Card>
                  )}
                </Paper>
              </Box>
                <Dialog open={true} onClose={hideForm} aria-labelledby="form-popup-title" id="formPopup" className="modal" fullWidth={true} maxWidth='sm'>
                  <DialogTitle>
                    Users
                  </DialogTitle>
                  <Divider className="mb-1" />
                  <DialogContent>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <div className="row mb-3">
                          <TextField
                            label="Email"
                            type="email"
                            name="useremail"
                            value={formData.useremail}
                            onChange={handleChange}
                            required
                            id="email-input-id"
                            margin="normal"
                          />
                        </div>

                        <div className="row">
                          <TextField
                            select
                            label="Select Role"
                            id="sel1"
                            onChange={handleDropdownChange}
                            value={formData.role}
                          >
                            <MenuItem value="member">Member</MenuItem>
                            <MenuItem value="facilitator">Facilitator</MenuItem>
                            <MenuItem value="administrator">Administrator</MenuItem>
                          </TextField>
                        </div>
                      </div>
                      <Divider className="mt-5" />
                      <DialogActions>
                        <Button color="primary" onClick={hideForm} sx={{textTransform:"capitalize"}}>
                          Cancel
                        </Button>
                        <Button variant="contained" type="submit" size="large" sx={{textTransform:"capitalize"}} >
                          Add User
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
                
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* pending invitations*/}
                {userInvites.some((invite) => invite.status === 'pending') && (         
                <Box sx={{ width: '48%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <b className="text-warning" style={{ flex: 1 }}>
                      Pending Invitations
                    </b>
                  </div>
                  <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                    <Table aria-labelledby="tableTitle" size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: '20%' }}>Email</TableCell>
                            <TableCell sx={{ width: '20%' }}>Roles</TableCell>
                            <TableCell sx={{ width: '20%' }}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userInvites.map((e) => {
                            if (e.status === 'pending') {
                              return (
                                <TableRow hover key={e.id}>
                                  <TableCell>
                                    {e.profilepicture && e.profilepicture !== '' ? (
                                      <Avatar alt="Avatar" sx={{ width: 28, height: 28 }} src={e.profilepicture} />
                                    ) : (
                                      <AccountCircleIcon sx={{ width: 28, height: 28, color: '#5f6368' }} />
                                    )}
                                    {e.useremail}
                                  </TableCell>
                                  <TableCell>
                                    <Chip
                                      icon={getRoleIcon(e.role)}
                                      label={e.role}
                                      variant="outlined"
                                      color="primary"
                                      style={{ color: '#b39ddb', borderColor: '#b39ddb' }}
                                      size="small"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title='Delete'>
                                      <IconButton
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={() => deleteInvites(e)}
                                      >
                                        <DeleteOutlineOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
                 )}

                {/* declined invitations*/}
                {userInvites.some((invite) => invite.status === 'declined') && (
                <Box sx={{ width: '48%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <b className="text-danger" style={{ flex: 1 }}>
                      Declined Invitations
                    </b>
                  </div>
                  <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                      <Table aria-labelledby="tableTitle" size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: '20%' }}>Email</TableCell>
                            <TableCell sx={{ width: '20%' }}>Roles</TableCell>
                            <TableCell sx={{ width: '20%' }}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userInvites.map((e) => {
                            if (e.status === 'declined') {
                              return (
                                <TableRow hover key={e.id}>
                                  <TableCell>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {e.profilepicture && e.profilepicture !== '' ? (
                                        <Avatar alt="Avatar" sx={{ width: 28, height: 28 }} src={e.profilepicture} />
                                      ) : (
                                        <AccountCircleIcon sx={{ width: 28, height: 28, color: '#5f6368' }} />
                                      )}
                                      {e.useremail}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <Chip
                                      icon={getRoleIcon(e.role)}
                                      label={e.role}
                                      variant="outlined"
                                      color="primary"
                                      style={{ color: '#b39ddb', borderColor: '#b39ddb' }}
                                      size="small"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title='Delete'>
                                      <IconButton
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={() => deleteInvites(e)}
                                      >
                                        <DeleteOutlineOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              )}
              </div>
              </div>
              <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert
                onClose={handleCloseSnackbar}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
              >
                Invalid user or the user is already linked to another company.
              </Alert>
            </Snackbar>
            </div>
          )}
      </div>

      <ProAlert showAlert={showPro} hideAlert={handleCloseAlert} />
      <Spinner spin={spin} />
      <ShowError Error={showError} />
      <SuccessToast
        show={Toast}
        message={toastMessage}
        subject={toastSubject}
        style={customStyle}
      />
    </>
  );
}

export default Users;
