import { useState, useEffect } from "react";
import { firestore } from "../../../firebase";
import {
  onSnapshot,
  doc,
} from "@firebase/firestore";
import Spinner from "../../Reusable/Spinner/Spinner";
import ShowError from "../../Reusable/ShowError/ShowError";
import DocumentMissing from "../../Reusable/DocumentMissing/DocumentMissing";
import { useParams, useNavigate } from "react-router-dom";
import SharePopup from "../../Reusable/Share/share";
import { getErrorObject, postlog } from "../../../functions/Common";
import { useGlobalState } from "../../../GlobalProvider"
import { Button, Link, Breadcrumbs } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Paper, TableContainer,Grid, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Tabs, Tab, Box, Typography, CardActions, CardMedia, CardContent, Card, IconButton } from '@mui/material';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import { deepOrange, deepPurple } from '@mui/material/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TeamMembers from "../TeamComponents/TeamMembers";
import BasicTeamInfo from "../TeamComponents/BasicTeamInfo"
import ManageMembers from "../TeamComponents/ManageMembers"
import Retrospectives from "../TeamComponents/Retrospectives"
import Fistoffives from "../TeamComponents/Fistoffives";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import Navbar from "../../Reusable/Navbar/Navbar";
import Sidebar from "../../Reusable/Sidebar/Sidebar";
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddIcon from '@mui/icons-material/Add';
import PokerGames from "../TeamComponents/PokerGames";
import ActionItems from "../TeamComponents/ActionItems";
import Assessment from "../TeamComponents/Assessments";
import LeanCoffee from "../TeamComponents/LeanCoffee";
import SpinWheels from "../TeamComponents/SpinWheels";
function Team({ user }) {
  const navigate = useNavigate();
  const { docid } = useParams();
  const [team, setTeam] = useState();
  const [showshare, setShowShare] = useState(false);
  const [spin, setSpin] = useState(false);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const { state } = useGlobalState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    let unsubscribe = null;
    async function getTeam() {
      try {
        setSpin(true);
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = onSnapshot(doc(firestore, "teams", docid ? docid : state.currentteam.teamid),
          (doc) => {
            setTeam(doc.data());
           // postlog("read teams", { component: "team", function: "getTeam", collection: "teams", docid: docid });
            postlog("open team", { component: "team", function: "getTeam", collection: "teams", docid: docid });
          },
          (error) => {
            setshowDocumentMissing(true);
          });

        setSpin(false);
      } catch (err) {
        setSpin(false);
        setshowDocumentMissing(true);
      }
    }

    getTeam();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user,state.currentteam]);

  function leaveMeeting() {
    try {
      navigate(`/teams`);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Team", "leaveMeeting"),
      });
      setSpin(false);
    }
  }

  const HideSharePopup = () => {
    try {
      setShowShare(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Team", "toggleState"),
      });
      setSpin(false);
    }
  };

  const ShowSharePopup = () => {
    try {
      setShowShare(true);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Team", "share"),
      });
      setSpin(false);
    }
  };


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "handleSidebar") });
      setSpin(false);
    }
  };

  return (
    <>
      <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
        <Navbar user={user} toggleSidebar={handleSidebar} />
        <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />


        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" type='button' color="inherit" onClick={()=>{navigate('/teams')}}>
            Teams
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
          >
            {team && team.name}
          </Link>
        </Breadcrumbs>


        <Tabs value={selectedTab} onChange={handleTabChange} centered sx={{ marginBottom: '1rem' }}>
          <Tab label="Team charter" icon={<SpaceDashboardIcon />} iconPosition="start" sx={{ textTransform: 'capitalize' }} />
          <Tab label="Members" icon={<GroupsIcon />} iconPosition="start" sx={{ textTransform: 'capitalize' }} />
        </Tabs>
        {selectedTab === 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {/* <TeamMembers team={team} user={user}/> */}
            {team && (<BasicTeamInfo team={team} user={user} />)}
          </Box>
        )}
        {selectedTab === 1 && (
          <Box>
            <ManageMembers team={team} user={user} ShowSharePopup={ShowSharePopup} />
          </Box>
        )}
      </div> 

      <Spinner spin={spin} />
      <DocumentMissing show={showDocumentMissing} />
      <ShowError Error={showError} />
      <SharePopup
        show={showshare}
        hideForm={HideSharePopup}
        userid={user.userid}
        docid={docid}
        docData={team}
        doctype="teams"
        collectionname="teams"
        subscription={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription}
        message="Select users and add them to a team, enabling you to share access to other documents with the entire team at once."
      />
    </>
  );
}

export default Team;
