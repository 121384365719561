import React from 'react'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { CardActionArea,CardMedia, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
const CreateNew = ({title,color}) => {
  return (
    <div>
      <Tooltip title={`${title}`}>
              <Card  sx={{ maxWidth: 200 , minWidth: 200, minHeight:170,display:'flex',justifyContent:'center',alignItems:'center' }} className='mb-3' >
            <div>
            <CardMedia sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
             <AddIcon style={{ fontSize: "45px", color: color }}/>
             </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant="h7" component="div">
                       {title} 
                    </Typography>
                </CardContent>
            </div>
        </Card>
        </Tooltip>
    </div>
  )
}

export default CreateNew