import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../../firebase";
import {
  query,
  collection,
  onSnapshot,
  doc,
  Timestamp,
  where,
  getDocs,
  setDoc,
  limit,
  startAfter,
  endBefore,
  orderBy,
  limitToLast,
} from "@firebase/firestore";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../Reusable/Spinner/Spinner";
import ShowError from "../../Reusable/ShowError/ShowError";
import DocumentMissing from "../../Reusable/DocumentMissing/DocumentMissing";
import SuccessToast from "../../Reusable/Toasts/SuccessToast";
import Sidebar from "../../Reusable/Sidebar/Sidebar";
import Navbar from "../../Reusable/Navbar/Navbar";
import { getErrorObject, getUserDetails, postlog,showdate } from "../../../functions/Common";
import TemplateCard from "../../Reusable/TemplateCard/TemplateCard";
import { useGlobalState } from "../../../GlobalProvider";
import Button from '@mui/material/Button';
import MaterialCard from "../../Reusable/MaterialCard/MaterialCard"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProAlert from "../../Reusable/ProAlert/ProAlert"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import {Card,TextField,Divider} from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
const Assessement = ({ user }) => {
  const location = useLocation();
  const [Toast, setShowToast] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const handleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [assessement, setassessement] = useState([]);
  const [openSPopup, setOpenSPopup] = useState(false);
  const [showFormValidate, setShowFormValidate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    duedate: "",
    templateid: "",
  });
  const [spin, setSpin] = useState(false);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [assessementTemplates, setAssessementTemplates] = useState();
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const { state } = useGlobalState();
  const [copyMessage, setCopyMessage] = useState("");

  useEffect(() => {
    let unsubscribe = null;
    async function getassessement() {
      try {
        setSpin(true);
        let q;
        if(state.currentteam.teamid != "" && state.currentteam.teamid != "none"){
         q = await query(
          collection(firestore, "assessements"),
          where("users", "array-contains", user.userid),
          where("teamid","==",state.currentteam.teamid),
          orderBy("updateddate", "desc"),
          limit(5)
        );
         }else{
          q = await query(
            collection(firestore, "assessements"),
            where("users", "array-contains", user.userid),
            orderBy("updateddate", "desc"),
            limit(5)
          );
         }
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = await onSnapshot(q, (snapshot) => {
          setassessement([]);
          snapshot.forEach((doc) => {
            const newdata = doc.data();
            setassessement((prevMember) => {
              return [...prevMember, newdata];
            });
          });
          // postlog("read assessements", { component: "assessments", function: "getassessement", collection: "assessments", uid: user.userid });
          setFirstVisible(snapshot.docs[0])
          setLastVisible(snapshot.docs[snapshot.docs.length - 1])
          setSpin(false);
        },
          (err) => {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Assessments", "getassessement") });
            setSpin(false);
          });
      } catch (err) {
        setShowError({
          show: true,
          errordata: getErrorObject(err, user, "Assessments", "getassessement"),
        });
        setSpin(false);
      }
    }

    getassessement();
    getAssessementTemplates();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [state.currentteam]);

  async function loadNext() {
    setDisablePrevious(false);

    try {
      const nextQuery = query(
        collection(firestore, 'assessements'),
        where("users", "array-contains", user.userid),
        orderBy("updateddate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );

      const snapshot = await getDocs(nextQuery);
      // postlog("read assessements", { component: "assessments", function: "loadNext", collection: "assessments", uid: user.userid });


      const assessments = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        assessments.push(newdata);
      });

      if (snapshot.docs.length > 0) {
        setassessement(assessments);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      if (snapshot.docs.length < 5) {
        setDisableNext(true);
      }

      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Assessments", "loadNext")
      });
      setSpin(false);
    }
  }

  async function loadPrevious() {
    setDisableNext(false);

    try {
      const previousQuery = query(
        collection(firestore, 'assessements'),
        where("users", "array-contains", user.userid),
        orderBy("updateddate", "desc"),
        endBefore(firstVisible),
        limitToLast(5),
      );

      const snapshot = await getDocs(previousQuery);
      // postlog("read assessements", { component: "assessments", function: "loadPrevious", collection: "assessments", uid: user.userid });


      const assessments = [];
      snapshot.forEach((doc) => {
        const newData = doc.data();
        assessments.push(newData);
      });

      if (snapshot.docs.length > 0) {
        setassessement(assessments);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setDisablePrevious(!snapshot.docs.length);
      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Assessments", "loadPrevious")
      });
      setSpin(false);
    }
  }





  async function getAssessementTemplates() {
    try {
      setSpin(true);
      const q = await query(collection(firestore, "assessmenttemplates"), where("isactive", "==", true), limit(100));
      const snapshot = await getDocs(q);
      // postlog("read assessmenttemplates", { component: "assessments", function: "getAssessementTemplates", collection: "assessmenttemplates", });
      setAssessementTemplates([]);
      let assessmentTemplatess = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        assessmentTemplatess.push(newdata);
        setSpin(false);
      });
      const sortedArray = assessmentTemplatess.sort((a, b) => a.displayorder - b.displayorder);
      setAssessementTemplates(sortedArray)
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(
          err,
          user,
          "Assessments",
          "getAssessementTemplates"
        ),
      });
      setSpin(false);
    }
  }

  function getQuestionDataByTemplateId(templateid) {
    const templatedata = assessementTemplates.find((template) => {
      return templateid == template.id;
    })
    return templatedata;
  }

  async function saveAssessement(assessement) {
    try {
      setSpin(true);
      const questions = getQuestionDataByTemplateId(assessement.templateid);
      if (!assessement.id) {
        const assessementdocref = doc(collection(firestore, "assessements"));
        setDoc(assessementdocref, {
          id: assessementdocref.id,
          createddate: Timestamp.fromDate(new Date()),
          updateddate: Timestamp.fromDate(new Date()),
          updatedbyuserid: user.userid,
          updatedbyusername: user.username,
          updatedbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          updatedbyuseremail: user.useremail,
          name: assessement.name,
          duedate: Timestamp.fromDate(new Date(assessement.duedate)),
          assesmentlink: assessementdocref.id,
          createdbyuserid: user.userid,
          createdbyusername: user.username,
          createdbyuserprofile: user.profilepicture,
          createdbyuseremail: user.useremail,
          questiondata: questions.questiondata,
          questionorder: questions.questionorder,
          teamid : state.currentteam.teamid ? state.currentteam.teamid :  "",
          teamname : state.currentteam.teamname ? state.currentteam.teamname :  "",
          usersdetails: {
            [user.userid]: getUserDetails(user),
          },
          level: "public",
          users: [user.userid],
          mode: "dark",
          teamid : state.currentteam.teamid ? state.currentteam.teamid :  "",
          teamname : state.currentteam.teamname ? state.currentteam.teamname :  ""
        }).then(() => {
         // postlog("write assessements", { component: "assessments", function: "saveAssessement", collection: "assessements", });
          postlog("create assessement", { component: "assessments", function: "saveAssessement", collection: "assessements", });
          navigate(`/assessments/${assessementdocref.id}`, { state: { newdoc: true } });
        });
      } else {
        const docRef = doc(firestore, "assessements", assessement.id);
        setDoc(
          docRef,
          {
            name: assessement.name,
            duedate: Timestamp.fromDate(new Date(assessement.duedate)),
            updateddate: Timestamp.fromDate(new Date()),
            updatedby: user.userid,
            updatedbyuserid: user.userid,
            updatedbyusername: user.username,
            updatedbyuserprofile: user.profilepicture,
            updatedbyuseremail: user.useremail,
          },
          { merge: true }
        );
      //  postlog("write assessements", { component: "assessments", function: "saveAssessement", collection: "assessements", });
      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Assessments", "saveAssessement"),
      });
      setSpin(false);
    }
  }

  const hideForm = () => {
    try {
      setFormData({
        id: "",
        name: "",
        duedate: new Date().toISOString().slice(0, 10),
        templateid: "",
      });
      handleClosespopup();
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Assessments", "hideForm"),
      });
      setSpin(false);
    }
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
      event.preventDefault();
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Assessments", "handleChange"),
      });
      setSpin(false);
    }
  };

  const handleSubmit = (event) => {
    try {
      const form = event.currentTarget;
      if (formData.name.trim()) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        hideForm();
        event.preventDefault();
      }

      saveAssessement({
        id: formData.id,
        name: formData.name,
        duedate: formData.duedate,
        templateid: formData.templateid,
      });
    }else{
      setShowFormValidate(true);
    }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Assessments", "handleSubmit"),
      });
      setSpin(false);
    }
  };

  const customStyle = {
    backgroundColor: "#61a1fe",
    border: "2px solid #61a1fe",
  };

  async function createAssessment(templateid) {
    try {
      setSpin(true);
      const selectedTemplate = assessementTemplates.find(
        (template) => template.id === templateid
      );
      const assessementdocref = doc(collection(firestore, "assessements"));
      setDoc(assessementdocref, {
        id: assessementdocref.id,
        name: selectedTemplate.name,
        duedate: Timestamp.fromDate(new Date()),
        createdbyuserid: user.userid,
        createdbyusername: user.username,
        createdbyuserprofile: user.profilepicture,
        createdbyuseremail: user.useremail,
        questiondata: selectedTemplate.questiondata,
        questionorder: selectedTemplate.questionorder,
        usersdetails: {
          [user.userid]: getUserDetails(user),
        },
        level: "public",
        users: [user.userid],
      });
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Assessments", "createAssessment"),
      });
      setSpin(false);
    }
  }

  const showForm = (assessement, docid) => {
    try {
      if(showFormValidate){
        setShowFormValidate(false);
      }
      const today = new Date();
      const dueDate = new Date(today);
      dueDate.setDate(today.getDate() + 1);
      if (assessement != null) {
        const dtt = new Date(assessement.duedate.seconds * 1000);
        setFormData({
          id: assessement.id,
          name: assessement.name,
          duedate: dtt.toISOString().slice(0, 10),
        });
      } else if (docid != null) {
        setFormData({
          ...formData,
          templateid: docid,
          duedate: dueDate.toISOString().split('T')[0],
        })
      }
      handleopenSPopup();
      // document.getElementById("assessmentNameInput")?.focus();
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Assessments", "showForm"),
      });
      setSpin(false);
    }
  };

  useEffect(() => {
    if(location.state && location.state.dashboard && assessementTemplates){
      let defaultTemplate =  assessementTemplates.find((template)=>{
         return template.templatename == "Agile Maturity Assessment"
       });
       showForm(null,defaultTemplate.id)
       navigate(location.pathname, { state: { dashboard: false } });
     }
  }, [assessementTemplates]);


  const handleopenSPopup = () => {
    setOpenSPopup(true);
    // document.getElementById("questionInput")?.focus()
  };

  const handleClosespopup = () => {
    setOpenSPopup(false);
  };

  return (
    <>

      <div className={`sideNav-top listPage-transition container ${isOpen ? "side-margin" : ""}`} style={{width:'75%'}}>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
        <div className="bg-p">
          <div className="row" style={{marginRight:"0px"}}>
            <div className="d-flex gap-2 align-items-center mb-2" >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', fill: 'white', backgroundColor: 'rgba(56, 146, 238, 0.8)', borderRadius: "20%", padding: "10px" }}>
                    <path class="svg-icon" d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880h40v331q18 11 29 28.5t11 40.5q0 33-23.5 56.5T480-400q-33 0-56.5-23.5T400-480q0-23 11-41t29-28v-86q-52 14-86 56.5T320-480q0 66 47 113t113 47q66 0 113-47t47-113q0-36-14.5-66.5T586-600l57-57q35 33 56 78.5t21 98.5q0 100-70 170t-170 70q-100 0-170-70t-70-170q0-90 57-156.5T440-717v-81q-119 15-199.5 105T160-480q0 134 93 227t227 93q134 0 227-93t93-227q0-69-27-129t-74-104l57-57q57 55 90.5 129.5T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                  </svg> */}
                  <div>
                    <Typography variant="h6">
                    Agile Assessments {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname!= "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                    Select a template to create new agile assessment.
                    </Typography>
                  </div>
                </div>
            {/* <hr style={{ border: "1px solid gray" }} /> */}
          </div>
          <div className="row" >
            {user.role !== "member" && assessementTemplates &&
              assessementTemplates.map((e, index) => (
                <Tooltip title={`Select ${e.templatename}`}>
                <button
                  style={{ background: "none", border: "none", display: "s-block", width: "auto", height: "auto" }}
                  className="square-item"
                  disabled={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "False" && e.type === "pro"}
                  onClick={() => showForm(null, e.id)}
                  key={index}
                >
                  {/* <TemplateCard user={user} templatedata={e} /> */}
                  <MaterialCard user={user} templatedata={e} />
                </button>
                </Tooltip>
              ))}
          </div>
        </div>



        {spin === false  && assessement !== undefined && assessement.length !== 0 ? (
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{width:'15%'}}>Created At</TableCell>
                          <TableCell sx={{ width: '20%' }}>Creator</TableCell>
                          <TableCell sx={{ width: '30%' }}>Name</TableCell>
                          <TableCell sx={{ width: '10%' }}>Due Date</TableCell>
                          <TableCell >Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {assessement.map((board, index) => {
                          const dtt = new Date(board.duedate.seconds * 1000);
                          const issubmitted =
                          board.respondentmembers &&
                          board.respondentmembers[user.useremail] &&
                          board.respondentmembers[user.useremail].status == "submitted";
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={() => {
                                navigate(`/assessments/${board.id}`)
                              }}
                              key={board.id}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell>{showdate(board.createddate, user.timezone)}</TableCell>
                              <Tooltip title={board.createdbyusername}>
                                <div className="d-flex gap-2 align-items-center" style={{ padding: '10px' }}>
                                  {board.createdbyuserprofile && board.createdbyuserprofile != "" ? (
                                    <Avatar alt="Remy Sharp" sx={{ width: 28, height: 28 }} src={board.createdbyuserprofile} />
                                  ) : (
                                    <AccountCircleIcon sx={{ width: 28, height: 28,color:'#5f6368' }} ></AccountCircleIcon>)}
                                         <Typography>
                                                  {board.createdbyusername}
                                                </Typography>
                                    </div>
                                </Tooltip>
                              <TableCell><AssessmentOutlinedIcon color='primary' /> {board.name}</TableCell>
                              <TableCell>
                              {dtt.toLocaleDateString()}
                              </TableCell>
                              <TableCell>
                              {issubmitted ? (
                        <span className="text-success p-2">Submitted</span>
                      ) : (
                      <Tooltip title='Respond'>
 <IconButton  variant="outlined" size="small" 
                          onClick={(event)=>{
                            event.stopPropagation();
                            navigate(`/assessments/assessmentresponse/${board.id}`)
                          }}
                        >
                          <AppRegistrationIcon />
                        </IconButton>
                      </Tooltip>
                      )}
                      <>
                        {board.usersdetails[user.userid].isowner && (
                          <>
                             &nbsp; &nbsp;
                             <Tooltip title='Edit'>
                             <IconButton  variant="outlined" size="small"  className="point" onClick={(event) => {
                              event.stopPropagation();
                              showForm(board, null)
                              }}>
                            <EditOutlinedIcon />
                            </IconButton>
                             </Tooltip>
                      
                          </>
                        )}
                      </>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
              
              ) : (
                spin === false && assessement !== undefined && assessement.length === 0 && (
                  <Card style={{textAlign:'center',color:'#a9a3b7',padding:'3rem 0rem'}}>
                  <Typography variant="h5" gutterBottom>
     You've not created any assessments yet!
    </Typography>
    <Typography variant="h7" gutterBottom>
     When you do, they will be show up here.
    </Typography>
              </Card>
                )
              )}

{assessement && assessement.length !== 0 && (
                <div className="d-flex justify-content-end mb-2">
                  <ul className="pagination">
                    <li className="page-item">
                      <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                        <KeyboardArrowLeftIcon />
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link" onClick={loadNext} disabled={disableNext} >
                        <KeyboardArrowRightIcon />
                      </button>
                    </li>
                  </ul>
                </div>
              )}


        

<Dialog open={openSPopup} onClose={hideForm} fullWidth maxWidth="sm">
      <DialogTitle >Assessment {state && state.currentteam && state.currentteam.teamname && state.currentteam.teamname!= "none" && state.currentteam.teamname != "" && `- ${state.currentteam.teamname}`}</DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div style={{marginTop:'10px'}}>
            <TextField
              id="assessmentNameInput"
              label="Assessment Name"
              type="text"
              name="name"
              value={formData.name || ""}
              onChange={handleChange}
              fullWidth
              required
              error={showFormValidate && !formData.name.trim()}  
              helperText={showFormValidate && !formData.name.trim() ? 'Assessment Name is required' : ''}
              autoFocus
            />
          </div>
          <div className="mt-3">
            <TextField
              type="date"
              name="duedate"
              label="Due Date"
              value={formData.duedate}
              onChange={handleChange}
              fullWidth
              required
            />
          </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions className="m-2">
        <Button onClick={hideForm} color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit} color="primary" size='large' sx={{textTransform:'none'}}>
          Save
        </Button>
      </DialogActions>
    </Dialog>

        <div id="formPopup" className="modal">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <div>
                  <b>Assessment</b>
                </div>
                <span className="close" onClick={hideForm}>
                  &times;
                </span>
              </div>
              <div className="modal-body">
                <div>
                  <label>
                    <span>Assessment Name</span>
                    <input
                      id="assessmentNameInput"
                      type="text"
                      name="name"
                      value={formData.name || ""}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </label>
                  <div />
                  <div className="mt-3">
                    <label>
                      <span>Due Date</span>
                      <input
                        type="date"
                        name="duedate"
                        value={formData.duedate}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </label>
                    <br />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  type="submit"
                  variant="contained"
                  size='large'
                  sx={{textTransform:'none'}}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div >

      <DocumentMissing show={showDocumentMissing} />
      <ShowError Error={showError} />
      <Spinner spin={spin} />
      <SuccessToast
        show={Toast}
        message="You do not have any members who have responded."
        subject="No Respondent"
        style={customStyle}
      />
    </>
  );
};

export default Assessement;
