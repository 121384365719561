import * as React from 'react';
import { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import {
    query,
    collection,
    onSnapshot,
    doc,
    Timestamp,
    where,
    setDoc,
    limit,
} from "@firebase/firestore";
import { styled, alpha } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, Typography,Button,IconButton } from '@mui/material';
import { getErrorObject, postlog,getUserDetails } from "../../functions/Common";
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";

import Menu from "./Menu"
const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color:
        theme.palette.mode === 'light'
            ? theme.palette.grey[800]
            : theme.palette.grey[200],
    [`& .${treeItemClasses.content}`]: {
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.2, 0),
        [`& .${treeItemClasses.label}`]: {
            fontSize: '0.8rem',
            fontWeight: 500,
        },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        borderRadius: '50%',
        backgroundColor:
            theme.palette.mode === 'light'
                ? alpha(theme.palette.primary.main, 0.25)
                : theme.palette.primary.dark,
        color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
        padding: theme.spacing(0, 1.2),
    },
}));

export default function OrgHierarchy({ user }) {
    const [isOpen, setIsOpen] = useState(true);
    const [spin, setSpin] = useState(false);
    const [showError, setShowError] = useState({ show: false, errordata: {} });
    const [orgHierarchyData, setOrgHierarchyData] = useState();

    let unsubscribe = null;
    useEffect(() => {
        try {
            getOrgHierarchy();
        } catch (err) {
            setShowError({
                show: true,
                errordata: getErrorObject(err, user, "AssessmentResponse", "UseEffect"),
            });
            setSpin(false);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    async function getOrgHierarchy() {
        const q = query(
            collection(firestore, "organizations"),
            where("users", "array-contains", user.userid),
            limit(1)
        );
        let orghierarchyData;
        let unsubscribe = await onSnapshot(q, (snapshot) => {
            snapshot.forEach((doc) => {
                 orghierarchyData = doc.data();
            });

            
            if (orghierarchyData != undefined) {
                setOrgHierarchyData(orghierarchyData);
            } else {
                createNewOrgHierarchy();
            }
            // postlog("read teams", { component: "orghierarchy", function: "getOrgHierarchy", collection: "organization", uid: user.userid });
            setSpin(false);
        },
            (err) => {
                setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "getTeams") });
                setSpin(false);
            });
          
    }


    async function createNewOrgHierarchy() {
        try {
            const docref = doc(collection(firestore, "organizations"));
            const department1key = doc(collection(firestore, "organizations")).id;
            const department2key = doc(collection(firestore, "organizations")).id;
            const newHierarchy = {
                id: docref.id,
                createddate: Timestamp.fromDate(new Date()),
                updateddate: Timestamp.fromDate(new Date()),
                createdby: user.userid,
                updatedby: user.userid,
                userid: user.userid,
                username: user.username,
                useremail: user.useremail,
                status: "active",
                users: [user.userid],
                usersdetails: { [user.userid]: getUserDetails(user) },
                hierarchy: {
                    [docref.id]: {
                        name: "Organization Name",
                        id: docref.id,
                        level: "Organization",
                        levels: {
                           [department1key]: { name: "Department 1",
                            id: department1key,
                            level: "Organization"},
                            [department2key]: {
                                name: "Department 2",
                                id: department2key,
                                level: "Organization"
                            }
                        }
                    }
                }
            }
            setDoc(docref, newHierarchy)
            postlog("createNewOrgHierarchy", { component: "createNewOrgHierarchy", function: "createNewOrgHierarchy", collection: "createNewOrgHierarchy" });

        } catch (err) {
            setShowError({
                show: true,
                errordata: getErrorObject(
                    err,
                    user,
                    "AssessmentResponse",
                    "createNewResponse"
                ),
            });
            setSpin(false);
        }
    }


    const handleSidebar = () => {
        try {
            setIsOpen(!isOpen);
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Teams", "handleSidebar") });
            setSpin(false);
        }
    };


    return (
        <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
            <Navbar user={user} toggleSidebar={handleSidebar} />
            <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
            <div className="row g-0 mb-2">
                <div className="d-flex gap-2 align-items-center mb-2" >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', fill: 'white', backgroundColor: 'rgba(76, 172, 107, 0.8)', borderRadius: "20%", padding: "10px" }}>
                    <path class="svg-icon" d="M0-240v-63q0-43 44-70t116-27q13 0 25 .5t23 2.5q-14 21-21 44t-7 48v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5q72 0 116 26.5t44 70.5v63H780Zm-455-80h311q-10-20-55.5-35T480-370q-55 0-100.5 15T325-320ZM160-440q-33 0-56.5-23.5T80-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T160-440Zm640 0q-33 0-56.5-23.5T720-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T800-440Zm-320-40q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm0-80q17 0 28.5-11.5T520-600q0-17-11.5-28.5T480-640q-17 0-28.5 11.5T440-600q0 17 11.5 28.5T480-560Zm1 240Zm-1-280Z" />
                  </svg> */}
                    <div>
                        <Typography variant="h6">
                            Org Hierarchy
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                        </Typography>
                    </div>
                </div>
            </div>

            <SimpleTreeView
                aria-label="customized"
                defaultExpandedItems={['1']}
                sx={{ overflowX: 'hidden', minHeight: 270, flexGrow: 1, maxWidth: 300 }}
            >

            <StyledTreeItem itemId="temp1"  label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {orgHierarchyData && orgHierarchyData.hierarchy && orgHierarchyData.hierarchy[orgHierarchyData.id].name &&  <span>{orgHierarchyData.hierarchy[orgHierarchyData.id].name}</span>}  
                <Menu levelData={orgHierarchyData.hierarchy[orgHierarchyData.id]}/>
            </div>}>
  {orgHierarchyData && orgHierarchyData.hierarchy[orgHierarchyData.id].levels && Object.keys(orgHierarchyData.hierarchy[orgHierarchyData.id].levels).map((key) => (
      <StyledTreeItem itemId={orgHierarchyData.hierarchy[orgHierarchyData.id].levels[key].id}   label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {orgHierarchyData && orgHierarchyData.hierarchy && orgHierarchyData.hierarchy[orgHierarchyData.id].levels[key].name &&  <span>{orgHierarchyData.hierarchy[orgHierarchyData.id].levels[key].name}</span>}  
          <Menu levelData={orgHierarchyData.hierarchy[orgHierarchyData.id].levels[key]}/>
      </div>
      }>

         </StyledTreeItem>   
                                ))}
              </StyledTreeItem>                 

                {/* <StyledTreeItem itemId="1" label="Main">
                    <StyledTreeItem itemId="2" label="Hello" >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    1
                                </Grid>
                                <Grid item xs={4}>
                                    2
                                </Grid>
                                <Grid item xs={4}>
                                    2
                                </Grid>
                                <Grid item xs={4}>
                                    4
                                </Grid>
                            </Grid>
                        </Box>
                    </StyledTreeItem>
                    <StyledTreeItem itemId="3" label="Subtree with children">
                        <StyledTreeItem itemId="6" label="Hello" />
                        <StyledTreeItem itemId="7" label="Sub-subtree with children">
                            <StyledTreeItem itemId="9" label="Child 1" />
                            <StyledTreeItem itemId="10" label="Child 2" />
                            <StyledTreeItem itemId="11" label="Child 3" />
                        </StyledTreeItem>
                        <StyledTreeItem itemId="8" label="Hello" />
                    </StyledTreeItem>
                    <StyledTreeItem itemId="4" label="World" />
                    <StyledTreeItem itemId="5" label="Something something" />
                </StyledTreeItem> */}

            </SimpleTreeView>
        </div>
    );
}