import React, { useState, useEffect } from "react";

function SuccessToast({ show, message, subject, style }) {
  const [showToast, setShowToast] = useState(show);

  useEffect(() => {
    let timer;
    if (show) {
      handleShowToast();
    }
    timer = setTimeout(() => {
      setShowToast(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  const handleShowToast = () => {
    setShowToast(true);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };




  return (
    <div>
      {showToast && (
        <div style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          transform: "translate(-50%, -50%)",
          zIndex: 800,
          backgroundColor: "rgb(0 0 0 / 52%)",
        }} onClick={(e) => e.stopPropagation()}
        >
          <div
            className="toast fade show"
            style={{
              maxWidth: "400px",
              maxHeight: "250px",
              backgroundColor: "mediumseagreen",
              color: "#fff",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 801,
              ...style,
            }}
          >
            <div className="toast-header" style={{ fontSize: "18px" }}>
              <strong className="me-auto">
                {subject || "Success"}
              </strong>
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseToast}
              ></button>
            </div>
            <div className="toast-body" style={{ fontSize: "16px" }}>
              {message || "Record submitted successfully!"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SuccessToast;
