import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDocs,
  getDoc,
  Timestamp,
  where,
  setDoc,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  runTransaction,
} from "@firebase/firestore";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider"
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/Share/share";
import { showdate, getErrorObject, getUserDetails, postlog } from "../../functions/Common";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container, Paper, Table, TableBody,Card, TableCell, TableContainer, TableHead, TableRow, Box, Avatar, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Autocomplete from '@mui/material/Autocomplete';
import SharePopup from "../Reusable/Share/share";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const Impediments = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useGlobalState();
  const [showshare, setShowShare] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [spin, setSpin] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbaropen, setSnackbaropen] = useState(false);
  const [showFormValidate, setShowFormValidate] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    status: "",
    reportinguser: {
      userid: user.userid || "",
      useremail: user.useremail || "",
      username: user.username || "",
      profilepicture: user.profilepicture || "", 
    },
    foruser: {
      userid: user.userid || "",
      useremail: user.useremail || "",
      username: user.username || "",
      profilepicture: user.profilepicture || "", 
    },
    impactedartifact: "",
    priority: "",
    team: "",
  });

  useEffect(() => {
    let unsubscribe = null;


    const dailyScrum = async () => {
      if (location.state && location.state.dailyscrum) {
        showForm(null)
        // navigate(location.pathname, { state: { dailyscrum: false } });
      }
      if (location.state && location.state.immediateid) {
        const docRef = doc(firestore, "impediments", location.state.immediateid);
        const docSnap = await getDoc(docRef);
        let immediatedata = docSnap.data();
        handleRowClick(immediatedata);
        // navigate(location.pathname, { state: { dailyscrum: false } });
      }
    }

    dailyScrum();

    async function getImpediments() {
      setSpin(false);
      try {
        const q = await query(
          collection(firestore, "impediments"),
          where("users", "array-contains", user.userid),
          orderBy("createddate", "desc"),
          limit(5)
        );

        unsubscribe = onSnapshot(q, (snapshot) => {
          const impedimentsData = [];
          snapshot.forEach((doc) => {
            const newdata = doc.data();

            impedimentsData.push(newdata);
          });
          setData(impedimentsData);
          setSpin(false);
          setFirstVisible(snapshot.docs[0])
          setLastVisible(snapshot.docs[snapshot.docs.length - 1])
        });

      } catch (error) {
        setShowError({ show: true, errordata: getErrorObject(error, user, "Impediments", "getImpediments") });
        setSpin(false);
      }
    }

    getImpediments();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  async function loadNext() {
    setDisablePrevious(false);

    try {
      const nextQuery = query(
        collection(firestore, "impediments"),
        where("users", "array-contains", user.userid),
        orderBy("createddate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );

      const snapshot = await getDocs(nextQuery);
      // postlog("read impediments", { component: "impediments", function: "loadNext", collection: "impediments", uid: user.userid });


      const impedimentsData = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        impedimentsData.push(newdata);
      });

      if (snapshot.docs.length > 0) {
        setData(impedimentsData);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      if (snapshot.docs.length < 5) {
        setDisableNext(true);
      }

      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "impediments", "loadNext")
      });
      setSpin(false);
    }
  }

  async function loadPrevious() {
    setDisableNext(false);

    try {
      const previousQuery = query(
        collection(firestore, 'impediments'),
        where("users", "array-contains", user.userid),
        orderBy("updateddate", "desc"),
        endBefore(firstVisible),
        limitToLast(5),
      );

      const snapshot = await getDocs(previousQuery);
      // postlog("read impediments", { component: "votings", function: "loadPrevious", collection: "impediments", uid: user.userid });

      const impedimentsData = [];
      snapshot.forEach((doc) => {
        const newData = doc.data();
        impedimentsData.push(newData);
      });

      if (snapshot.docs.length > 0) {
        setData(impedimentsData);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setDisablePrevious(!snapshot.docs.length);
      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Impediments", "loadPrevious")
      });
      setSpin(false);
    }
  }

  async function saveImpediments(data) {
    setSpin(true);
    try {
      let reportinguserDetails = {};
      if (formData.reportinguser.username) {
        reportinguserDetails = {
          userid: formData.reportinguser.userid,
          useremail: formData.reportinguser.useremail,
          username: formData.reportinguser.username,
          profilepicture: formData.reportinguser.profilepicture,
        };
      } else {
        reportinguserDetails = {
          userid: formData.reportinguser.userid,
          useremail: formData.reportinguser.useremail,
          username: formData.reportinguser.firstname + " " + formData.reportinguser.lastname,
          profilepicture: formData.reportinguser.profilepicture,
        };
      }

      let foruserDetails = {};
      if (formData.foruser.username) {
        foruserDetails = {
          userid: formData.foruser.userid,
          useremail: formData.foruser.useremail,
          username: formData.foruser.username,
          profilepicture: formData.foruser.profilepicture,
        };
      } else {
        foruserDetails = {
          userid: formData.foruser.userid,
          useremail: formData.foruser.useremail,
          username: formData.foruser.firstname + " " + formData.foruser.lastname,
          profilepicture: formData.foruser.profilepicture,
        };
      }

      if (selectedRowData === null) {

        try {
          const sfDocRef = doc(collection(firestore, "impediments"), "index");
          let documentCounter = 1;

          await runTransaction(firestore, async (transaction) => {
            const sfDoc = await transaction.get(sfDocRef);
            if (!sfDoc.exists()) {
              transaction.set(sfDocRef, { counter: 1 });
            } else {
              const newcounter = sfDoc.data().counter + 1;
              documentCounter = newcounter;
              transaction.update(sfDocRef, { counter: newcounter });
            }

            let dailyscrumid = location?.state?.dailyscrumid
            const docRef = doc(collection(firestore, "impediments"));
            await setDoc(docRef, {
              id: docRef.id,
              title: formData.title,
              description: formData.description,
              status: formData.status,
              reportinguser: reportinguserDetails,
              foruser: foruserDetails,
              impactedartifact: formData.impactedartifact,
              priority: formData.priority,
              team: formData.team,
              level: "restricted",
              users: formData.team.users,
              usersdetails: formData.team.usersdetails,
              createddate: Timestamp.fromDate(new Date()),
              updateddate: Timestamp.fromDate(new Date()),
              createdbyuserid: user.userid,
              createdbyusername: user.username,
              createdbyuserprofile:
                user.profilepicture != undefined ? user.profilepicture : "",
              createdbyuseremail: user.useremail,
              updatedbyuserid: user.userid,
              updatedbyusername: user.username,
              updatedbyuserprofile:
                user.profilepicture != undefined ? user.profilepicture : "",
              updatedbyuseremail: user.useremail,
              number: "I" + documentCounter,
              dailyscrumid: dailyscrumid != null ? dailyscrumid : "",
            });


            if(dailyscrumid != null){
              const docRef3 = doc(firestore, "dailyscrums", dailyscrumid);
              await setDoc(
                docRef3,
                {
                  impediments : {
                    [docRef.id] : docRef.id,
                  },
                  haveimpediments : true,
                },
                { merge: true }
              );
            }
         


            setSelectedRowData(
              {
                id: docRef.id,
                title: formData.title,
                description: formData.description,
                status: formData.status,
                reportinguser: reportinguserDetails,
                foruser: foruserDetails,
                impactedartifact: formData.impactedartifact,
                priority: formData.priority,
                team: formData.team,
                level: "restricted",
                users: formData.team.users,
                usersdetails: formData.team.usersdetails,
                createddate: Timestamp.fromDate(new Date()),
                updateddate: Timestamp.fromDate(new Date()),
                createdbyuserid: user.userid,
                createdbyusername: user.username,
                createdbyuserprofile:
                  user.profilepicture != undefined ? user.profilepicture : "",
                createdbyuseremail: user.useremail,
                updatedbyuserid: user.userid,
                updatedbyusername: user.username,
                updatedbyuserprofile:
                  user.profilepicture != undefined ? user.profilepicture : "",
                updatedbyuseremail: user.useremail,
                number: "I" + documentCounter,
              }
            );
           // postlog("write impediments", { component: "Impediments", function: "saveImpediments", collection: "impediments" });
           postlog("create impediments", { component: "impediments", function: "saveImpediments", collection: "impediments" });

          });

        } catch (e) {
          setShowError({ show: true, errordata: getErrorObject(e, user, "Impediments", "saveImpediments") });
        }
      } else {
        const docRef = doc(collection(firestore, "impediments"), data.id);

        await setDoc(docRef, {
          title: formData.title,
          description: formData.description,
          status: formData.status,
          reportinguser: reportinguserDetails,
          foruser: foruserDetails,
          impactedartifact: formData.impactedartifact,
          priority: formData.priority,
          team: formData.team,
          updateddate: Timestamp.fromDate(new Date()),
          updatedby: user.userid,
        },
          { merge: true }
        );


       // postlog("write impediments", { component: "Impediments", function: "saveImpediments", collection: "impediments", uid: data.id });

      }
      setSpin(false);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Impediments", "saveImpediments") });
      setSpin(false);
    }
  }



  const showForm = (data) => {
    try {
      if (data != null) {
        const dtt = new Date(data.date.seconds * 1000);

        setFormData({
          id: data.id,
          title: data.title,
          description: data.description,
          status: data.status,
          reportinguser: data.reportinguser,
          foruser: data.foruser,
          impactedartifact: data.impactedartifact,
          priority: data.priority,
          team: data.team,
          date: dtt.toISOString().slice(0, 10),
        });
      } else {
        setIsEditMode(true);
        setFormData({
          id: "",
          title: "",
          description: "",
          status: '' || null,
          reportinguser: {
            userid: user.userid || "",
            useremail: user.useremail || "",
            username: user.username || "",
            profilepicture: user.profilepicture || "", // handle case where profile picture might be undefined
          },
          foruser: {
            userid: user.userid || "",
            useremail: user.useremail || "",
            username: user.username || "",
            profilepicture: user.profilepicture || "", // handle case where profile picture might be undefined
          },
          impactedartifact: "",
          priority: "",
          team: "",
          date: new Date().toISOString().slice(0, 10),
        });

        setSelectedRowData(null);
      }
      handleDrawerOpen();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Impediments", "showForm") });
      setSpin(false);
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.title.trim() || !formData.description.trim() || formData.status == null || formData.reportinguser == null || formData.foruser == null || !formData.team || !formData.priority) {
      setShowFormValidate(true);
    } else {
      if (isEditMode && selectedRowData !== null) {
        // Update existing document
        saveImpediments(selectedRowData);
      } else {
        // Create new document
        saveImpediments(formData);
      }
      setIsEditMode(false);
      setSnackbaropen(true);
      // handleDrawerClose();
    }
  };





  // Function to handle form input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Impediments", "handleSidebar") });
      setSpin(false);
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    if (selectedRowData == null) {
      setDrawerOpen(false);
    }
    setIsEditMode(false);
    setShowFormValidate(false);
  };

  const HideSharePopup = () => {
    try {
      setShowShare(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Impediments", "HideSharePopup"),
      });
      setSpin(false);
    }
  };

  const ShowSharePopup = () => {
    try {
      setShowShare(true);
      setShowError({
        show: true,
        errordata: getErrorObject("err", user, "Impediments", "HideSharePopup"),
      });
    } catch (err) {

      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Impediments", "ShowSharePopup"),
      });
      setSpin(false);
    }
  };

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setFormData(rowData);
    handleDrawerOpen();
  };

  const toggleEditMode = () => {
    setIsEditMode((prevMode) => !prevMode);
  };

  return (
    <>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
      <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
        <div className="row g-0 mb-3">
          <div className="d-flex gap-2 align-items-center mb-2" >
            <div>
              <Typography variant="h6">
                Impediments
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Click on 'Add Impediments' to add a new impediments
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <Box className='row' style={{ padding: '0 40px', marginBottom: '20px' }}>
            <div className="col-md-6">
            </div>
            <div className="col-md-6 text-end">
              <Button variant="contained" size="large" onClick={() => showForm(null)} sx={{ textTransform: 'capitalize' }}>
                Add Impediments
              </Button>
            </div>
          </Box>
       {data.length != 0 ? (  <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table" size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Reporting User</TableCell>
                  <TableCell>For User</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((impediment) => (
                  <TableRow key={impediment.id} onClick={() => handleRowClick(impediment)} hover style={{height:"50px"}}>
                    <TableCell><span className="highlighted-number">{impediment.number}</span></TableCell>
                    <TableCell>{showdate(impediment.createddate, user.timezone)}</TableCell>
                    <TableCell>{impediment.title}</TableCell>

                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {impediment.reportinguser && impediment.reportinguser.profilepicture ? (
                          <Avatar src={impediment.reportinguser.profilepicture} sx={{ marginRight: '8px', width: '1.7rem', height: '1.7rem' }} />
                        ) : (
                          <AccountCircleIcon sx={{ width: '2.2rem', height: '2.2rem', marginRight: '8px', color: '#5f6368' }} />
                        )}
                        <span>{impediment.reportinguser.username}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {impediment.foruser && impediment.foruser.profilepicture ? (
                          <Avatar src={impediment.foruser.profilepicture} sx={{ marginRight: '8px', width: '1.7rem', height: '1.7rem' }} />
                        ) : (
                          <AccountCircleIcon sx={{ width: '2.2rem', height: '2.2rem', marginRight: '8px', color: '#5f6368' }} />
                        )}
                        <span>{impediment.foruser.username}</span>
                      </div>
                    </TableCell>
                    <TableCell>{impediment.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
) : (
  <Card style={{textAlign:'center',color:'#a9a3b7',padding:'3rem 0rem'}}>
  <Typography variant="h5" gutterBottom>
You've not created any Impediment yet!
</Typography>
<Typography variant="h7" gutterBottom>
When you do, they will be show up here.
</Typography>
</Card>
)} 
          {data && data.length !== 0 && (
            <div className="d-flex justify-content-end mb-3 mt-3">
              <ul className="pagination">
                <li className="page-item">
                  <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                    <KeyboardArrowLeftIcon />
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={loadNext} disabled={disableNext} >
                    <KeyboardArrowRightIcon />
                  </button>
                </li>
              </ul>
            </div>
          )}

          {/* Drawer Component */}
          <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false)
              handleDrawerClose();
            }}
            onOpen={() => { }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div style={{ width: "50vw", padding: '20px' }}>

              <Box sx={{ position: 'fixed', top: '0px', backgroundColor: 'white', width: '50vw', padding: '10px 8px', paddingRight: '35px', zIndex: 999 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6" gutterBottom>
                    {isEditMode && ('Add Impediments')}
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    {isEditMode && (
                      <Button
                        onClick={handleDrawerClose}
                        color='primary'
                        sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                        variant="outlined"
                        size='medium'
                      >
                        Cancel
                      </Button>
                    )}

                    {isEditMode && (

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{
                          display: 'inline',
                          width: 'auto',
                          textTransform: 'capitalize',
                          marginRight: '8px',
                          flex: '0 0 auto'
                        }}>
                        Save Impediments
                      </Button>
                    )}
                    {!isEditMode && <Button
                      onClick={toggleEditMode}
                      color='primary'
                      sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                      variant="outlined"
                      size='medium'
                      startIcon={<EditIcon />}

                    >
                      Edit
                    </Button>}


                    {!isEditMode && selectedRowData?.usersdetails?.[user.userid]?.isowner && (
                      <Button
                        onClick={ShowSharePopup}
                        color='primary'
                        sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                        variant="outlined"
                        size='medium'
                        style={{ display: selectedRowData && selectedRowData.usersdetails && selectedRowData.usersdetails[user.userid] && selectedRowData.usersdetails[user.userid].isowner ? 'flex' : 'none' }}
                        startIcon={<PersonAddAltIcon />}
                      >
                        Share
                      </Button>
                    )}

                    {!isEditMode && (
                      <Button
                        onClick={() => {
                          setDrawerOpen(false)
                          handleDrawerClose();
                        }}
                        color='primary'
                        sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '25px' }}
                        variant="outlined"
                        size='medium'
                        startIcon={<CloseIcon />}
                      >
                        Close
                      </Button>
                    )}

                  </div>
                </div>
              </Box>
              {isEditMode ? (
                <form>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                    <div style={{ flex: '70%' }}>
                      <TextField
                        name="title"
                        label="Title"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formData.title}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        error={showFormValidate && !formData.title.trim()}
                        helperText={showFormValidate && !formData.title.trim() ? 'Title is required' : ''}
                      />
                    </div>
                    <div style={{ marginLeft: '20px', flex: '30%' }}>
                      <Autocomplete
                        options={['Open', 'Close']}
                        value={formData.status || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            status: newValue,
                          }));
                        }}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            margin="normal"
                            required
                            error={showFormValidate && formData.status == null}
                            helperText={showFormValidate && formData.status == null ? 'Status is required' : ''}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    error={showFormValidate && !formData.description.trim()}
                    helperText={showFormValidate && !formData.description.trim() ? 'Description is required' : ''}
                  />

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={
                          Array.isArray(state.usersglobaldata) ?
                            state.usersglobaldata.map((oneuser) => ({
                              user: oneuser,
                              label: `${oneuser?.firstname || ''} ${oneuser?.lastname || ''}`.trim(),
                              profilePicture: oneuser.profilepicture,
                            })) : [{
                              user: user,
                              label: `${user?.firstname || ''} ${user?.lastname || ''}`.trim(),
                              profilePicture: user?.profilepicture,
                            }]
                        }
                        value={
                          formData.reportinguser?.username ||
                          `${formData.reportinguser?.firstname || ''} ${formData.reportinguser?.lastname || ''}`.trim()
                        }
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            setFormData((prevData) => ({
                              ...prevData,
                              reportinguser: newValue.user,
                            }));
                          }
                        }}
                        renderOption={(props, option) => (
                          <div {...props} style={{ display: 'flex', alignItems: 'center' }}>
                            {option.profilePicture ? (
                              <Avatar src={option.profilePicture} sx={{ marginRight: '8px', width: '2rem', height: '2rem' }} />
                            ) : (
                              <AccountCircleIcon sx={{ width: '2rem', height: '2rem', marginRight: '8px', color: '#5f6368' }} />
                            )}
                            <Typography>{option.label}</Typography>
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reporting User"
                            variant="outlined"
                            margin="normal"
                            required
                            error={showFormValidate && !formData.reportinguser}
                            helperText={showFormValidate && !formData.reportinguser ? 'Reporting User is required' : ''}
                          />
                        )}
                      />

                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={
                          Array.isArray(state.usersglobaldata) ?
                            state.usersglobaldata.map((oneuser) => ({
                              user: oneuser,
                              label: `${oneuser?.firstname || ''} ${oneuser?.lastname || ''}`.trim(),
                              profilePicture: oneuser.profilepicture,
                            })) : [{
                              user: user,
                              label: `${user?.firstname || ''} ${user?.lastname || ''}`.trim(),
                              profilePicture: user?.profilepicture,
                            }]
                        }
                        value={
                          formData.foruser?.username ||
                          `${formData.foruser?.firstname || ''} ${formData.foruser?.lastname || ''}`.trim()
                        }
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            setFormData((prevData) => ({
                              ...prevData,
                              foruser: newValue.user,
                            }));
                          }
                        }}
                        renderOption={(props, option) => (
                          <div {...props} style={{ display: 'flex', alignItems: 'center' }}>
                            {option.profilePicture ? (
                              <Avatar src={option.profilePicture} sx={{ marginRight: '8px', width: '2rem', height: '2rem' }} />
                            ) : (
                              <AccountCircleIcon sx={{ width: '2rem', height: '2rem', marginRight: '8px', color: '#5f6368' }} />
                            )}
                            <Typography>{option.label}</Typography>
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="For User"
                            variant="outlined"
                            margin="normal"
                            required
                            error={showFormValidate && !formData.foruser}
                            helperText={showFormValidate && !formData.foruser ? 'For User is required' : ''}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Autocomplete
                    options={
                      state && state.teamsglobaldata &&  state.teamsglobaldata.length > 0
                        ? state.teamsglobaldata.map((team) => ({ id: team.id, name: team.name, users : team.users , usersdetails : team.usersdetails }))
                        : [{ id: null, name: "No Teams" }]
                    }
                    value={formData.team || null}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.name !== "No Teams") {
                        setFormData((prevData) => ({
                          ...prevData,
                          team: newValue,
                        }));
                      } else {
                        setFormData((prevData) => ({
                          ...prevData,
                          team: null,
                        }));
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Team"
                        margin="normal"
                        required
                        error={showFormValidate && !formData.team}
                        helperText={showFormValidate && !formData.team ? 'Team is required' : ''}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} style={{ pointerEvents: option.id === null ? 'none' : 'auto', opacity: option.id === null ? 0.5 : 1 }}>
                        {option.name}
                      </li>
                    )}
                  />


                  <Autocomplete
                    options={['High', 'Medium', 'Low']}
                    value={formData.priority || null}
                    onChange={(event, newValue) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        priority: newValue,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Priority"
                        variant="outlined"
                        margin="normal"
                        error={showFormValidate && !formData.priority}
                        helperText={showFormValidate && !formData.priority ? 'Priority is required' : ''}
                      />
                    )}
                  />

                  <TextField
                    name="impactedartifact"
                    label="Impacted Artifact"
                    variant="outlined"
                    multiline
                    rows={1}
                    value={formData.impactedartifact}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />

                </form>
              ) : (
                <Container maxWidth="md" sx={{ mt: 6, mb: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Title: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{formData.title}</Box></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Status: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{formData.status}</Box></Typography>
                    </Grid>
                  </Grid>

                  {formData.description && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Description:</Typography>
                      <Typography variant="body2">{formData.description}</Typography>
                    </Box>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>Reporting User:</Typography>
                        <Typography variant="body2">{formData.reportinguser.username || formData.reportinguser.firstname + " " + formData.reportinguser.lastname}</Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>For User:</Typography>
                        <Typography variant="body2">{formData.foruser.username || formData.foruser.firstname + " " + formData.foruser.lastname}</Typography>
                      </Item>
                    </Grid>
                  </Grid>

                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Team:</Typography>
                    <Typography variant="body2">{formData.team && formData.team.name}</Typography>
                  </Box>

                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Priority:</Typography>
                    <Typography variant="body2">{formData.priority}</Typography>
                  </Box>

                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Impacted Artifact:</Typography>
                    <Typography variant="body2">{formData.impactedartifact ? formData.impactedartifact : "Not Available"}</Typography>
                  </Box>
                </Container>
              )}


            </div>

          </SwipeableDrawer>
        </div>
      </div>
      <Snackbar open={snackbaropen} autoHideDuration={6000} onClose={() => {
        setSnackbaropen(false);
      }}>
        <Alert
          onClose={() => {
            setSnackbaropen(false);
          }}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Record updated successfully!
        </Alert>
      </Snackbar>
      <ShowError Error={showError} />
      <Spinner spin={spin} />
      <SharePopup
        show={showshare}
        hideForm={HideSharePopup}
        userid={user.userid}
        docid={selectedRowData ? selectedRowData.id : null}
        docData={selectedRowData}
        doctype="impediments"
        collectionname="impediments"
        subscription={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription}
        message="Select users and add them to a team, enabling you to share access to other documents with the entire team at once."
      />
    </>
  );
}

export default Impediments;