import React from 'react'
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { firestore } from "../../../firebase";
import { postlog } from "../../../functions/Common";
import AlarmIcon from '@mui/icons-material/Alarm';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { Divider, Tooltip, Typography } from "@mui/material";


import {
  query,
  collection,
  onSnapshot,
  doc,
  setDoc,
  Timestamp,
  where,
  limit,
} from "@firebase/firestore";

const Timer = forwardRef((props, ref) => {
  const [currentTimer, setCurrentTimer] = useState()

  const [timer, setTimer] = useState({
    id: "",
    seconds: 0,
    minutes: 2,
    isRunning: false,
    isTimeUp: false,
  })

  useEffect(() => {
    let unsubscribe = null;

    async function getTimer() {
      let timerData;
      const q = query(collection(firestore, "timers"), where("id", "==", props.docid), limit(1),);
      if (unsubscribe) { unsubscribe(); }
      unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type == "modified") {
            timerData = change.doc.data();
            if (timerData.minutes == 0) {
              setTimer({
                isTimeUp: true
              })
              props.displayTimer(true);
            }

            if (timerData.minutes > 0) {
              setTimer({
                id: timerData.id,
                seconds: 0,
                minutes: timerData.savedtime ? timerData.savedtime : 2,
                isRunning: true,
                isTimeUp: false
              })
            }
            else {
              setTimer({
                id: timerData.id,
                minutes: timerData.savedtime ? timerData.savedtime : 2,
                seconds: 0,
                isRunning: false,
                isTimeUp: false
              })
            }
          }
          else if (change.type === "added") {
            setTimer({
              id: change.doc.data().id,
              seconds: 0,
              minutes: timerData?.savedtime ? timerData?.savedtime : 2,
              isRunning: false,
              isTimeUp: false
            })
          }

        });
      //  postlog("read timers", { component: "timer", function: "getTimer", collection: "timers", docid: props.docid });
      });
    }

    getTimer();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

  }, []);

  // countDown function 
  useEffect(() => {
    let timerInterval;
    if (timer.isRunning) {
      timerInterval = setInterval(() => {
        if (timer.seconds === 0) {
          if (timer.minutes > 0) {
            setTimer(prevTimer => ({
              ...prevTimer,
              minutes: prevTimer.minutes - 1,
              seconds: 59,
              isTimeUp: false
            }));
          } else {
            setTimer(prevTimer => ({
              ...prevTimer,
              minutes: timer.minutes,
              isRunning: false,
              isTimeUp: true
            }));
            props.displayTimer(true);
          }
        } else {
          setTimer(prevTimer => ({
            ...prevTimer,
            seconds: prevTimer.seconds - 1
          }));
        }
        props.displayTimer();
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);

  }, [timer]);

  const handleStart = async () => {
    const docRef = doc(collection(firestore, 'timers'), props.docid);
    if (timer.id == "") {
      await setDoc(docRef, {
        id: props.docid,
        minutes: 0,
        createddate: Timestamp.fromDate(new Date()),
        updateddate: Timestamp.fromDate(new Date()),
        savedtime: currentTimer ? currentTimer : 2
      }).then(async () => {
      //  postlog("write timers", { component: "timer", function: "handleStart", collection: "timers", docid: props.docid });
        await setDoc(docRef, {
          id: props.docid,
          minutes: timer.minutes,
          createddate: Timestamp.fromDate(new Date()),
          updateddate: Timestamp.fromDate(new Date()),
          savedtime: currentTimer ? currentTimer : 2
        });
      //  postlog("write timers", { component: "timer", function: "handleStart", collection: "timers", docid: props.docid });
      });
    } else {
      await setDoc(docRef, {
        id: props.docid,
        minutes: timer.minutes,
        createddate: Timestamp.fromDate(new Date()),
        updateddate: Timestamp.fromDate(new Date()),
        savedtime: currentTimer ? currentTimer : 2
      });
      //postlog("write timers", { component: "timer", function: "handleStart", collection: "timers", docid: props.docid });
    }
  };

  useImperativeHandle(ref, () => ({
    handleStart: handleStart,
    handleReset: handleReset
  }));




  const handleReset = async () => {
    const docRef = doc(collection(firestore, 'timers'), props.docid);
    await setDoc(docRef, {
      id: props.docid,
      minutes: 0,
      createddate: Timestamp.fromDate(new Date()),
      updateddate: Timestamp.fromDate(new Date()),
      savedtime: currentTimer ? currentTimer : 2
    })
    //postlog("write timers", { component: "timer", function: "handleReset", collection: "timers", docid: props.docid });
  }
  
  const handleMinutesChange = event => {
    const inputValue = parseInt(event.target.value);
    setTimer(prevTimer => ({
      ...prevTimer,
      minutes: inputValue,
    }));
    setCurrentTimer(inputValue)
  };

  return (
    <>
      {props.textTimer ? (
        <>
          {timer.isTimeUp ? (
            <p style={{ fontWeight: "500", fontSize: "20px",color:'red' }} > Time Up!</p>
          ) : (
            <>
              <p className={` ${timer.minutes == 0 && timer.seconds <= 10 ? 'blinking-text' : ''}`} style={{ fontWeight: "500", fontSize: "20px", color: '#5f6368' }}>
                {timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}:{timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
                {props.hideIcon == false && (<AlarmIcon className='mb-1' />)}
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <div className='d-flex justify-content-center align-items-center'>
            <span style={{ fontSize: '50px', marginRight: '5px' }}>
              <AlarmIcon style={{ fontSize: '50px', }} />
            </span>
            {timer.isTimeUp ? (
              <span style={{ fontWeight: 'bold', fontSize: '100px', margin: "0 25px",color: 'red' }}>
                Time Up!
              </span>
            ) : (
              <input
                type="number"
                value={currentTimer ? currentTimer : timer.minutes}
                min="1"
                max="300"
                className='form-control text-center'
                style={{ width: '150px', display: timer.isRunning ? "none" : "inline", fontSize: "28px" }}
                onChange={handleMinutesChange}
              />
            )}

            <span className={timer.minutes == 0 && timer.seconds <= 10 ? 'blinking-text' : ''} style={{ fontSize: '100px', display: timer.isRunning ? 'inline' : 'none', margin: "0 25px" }}>
              {timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}:{timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
            </span>

            <a style={{ display: timer.isRunning || timer.isTimeUp ? 'none' : "inline" }}>
            <Tooltip title = 'Start'>
              <PlayCircleFilledOutlinedIcon role="button" style={{ fontSize: '50px', color: '#0D6EFD', marginLeft: '5px', marginBottom: "10px" }} title="Start Timer" onClick={handleStart} />
            </Tooltip>
            </a>
            <a style={{ display: timer.isRunning || timer.isTimeUp ? 'inline' : "none" }}>
            <Tooltip title = 'Stop and Reset'>
              <ReplayOutlinedIcon role="button" color='primary' style={{ fontSize: '50px', marginBottom: "10px" }} onClick={handleReset} />
              </Tooltip>
            </a>
          </div>
        </>
      )}
    </>
  )
})

export default Timer