import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  postlog } from "../../../functions/Common";
import Button from '@mui/material/Button';
const Offer = ({showButton}) => {
  const [daysLeft, setDaysLeft] = useState(0);
  const navigate = useNavigate();
  const [copyMessage, setCopyMessage] = useState("");

  useEffect(() => {
    // Calculate the remaining days until February 8th
    const currentDate = new Date();
    const targetDate = new Date('2024-02-08'); // Change the year as needed
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
   
    setDaysLeft(remainingDays);
  }, []);

  
  const handleGetItNow = () => {
    navigate('/subscriptions');
    postlog("offer click", { component: "offer", function: "handleGetItNow" });
  };

  const copyPromocode = (value) => {
    const dummyInput = document.createElement("input");
    document.body.appendChild(dummyInput);
    dummyInput.value = value;
    dummyInput.select();
    document.execCommand("copy");
    document.body.removeChild(dummyInput);
    if (copyMessage === "") {
      setCopyMessage("Promo code copied to clipboard!");
    }
    setTimeout(() => {
      setCopyMessage("");
    }, 2000);
    postlog("copy promo code", { component: "offer", function: "copyPromocode" });

  };
  return (
    daysLeft > 0 &&  <div className={`alert text-dark d-flex justify-content-between align-items-center table-shadow`} role="alert" style={{backgroundColor:"#ffff85", width:"75vw"}}>
        {/* <strong className='text-white p-3 d-flex justify-content-center' style={{backgroundColor:"#ff3f3f" , borderRadius:"15px" }}>Only {daysLeft} day left</strong> */}
        <div>
        <strong>Hurry! Only <span style={{color:"red"}}>{daysLeft} day{daysLeft !== 1 ? 's' : ''}</span> left to get 20% off!</strong>

        <div><small>Upgrade to Pro and get 20% discount for initial 12 months. This exclusive offer is valid until 08/02/2024. Don't miss out!</small></div> 
        </div>
      {daysLeft > 0 && showButton ? (
        <Button variant="contained" onClick={handleGetItNow}>
          Get It Now
        </Button>
      ) :
      <div>
        <div className='fs-6 mb-1'><small>Copy Promo Code</small></div>
        <Button  variant="contained"  onClick={() => copyPromocode("PROSAVE20")}>
       PROSAVE20
    </Button>
    {copyMessage && (
                        <p
                          className="text-success"
                          style={{
                            fontSize: "small",
                            // float: "right",
                            position: "absolute",
                            // right: "155px",
                            // top: "25px",
                          }}
                        >
                          {copyMessage}
                        </p>
                      )}
    </div>}
      
    </div>
  );
};

export default Offer;
