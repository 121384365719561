import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDocs,
  Timestamp,
  where,
  setDoc,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  runTransaction,
} from "@firebase/firestore";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider"
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/Share/share";
import { showdate, getErrorObject, getUserDetails, postlog } from "../../functions/Common";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Avatar, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Autocomplete from '@mui/material/Autocomplete';
import SharePopup from "../Reusable/Share/share";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SelectSprint from "../Reusable/SelectSprint/SelectSprint";
const Risks = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useGlobalState();
  const [showshare, setShowShare] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [spin, setSpin] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbaropen, setSnackbaropen] = useState(false);
  const [showFormValidate, setShowFormValidate] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    status: "",
    occurrence: "",
    impact: "",
    criticalpath: "",
    program: "",
    programincrement: "",
    owner: "",
    consequence: "",
    contingency: "",
    mitigation: "",
    resolutionstatus: "",
  });
  const [programBoardData, setProgramBoardData] = useState();
  const [selectedRowId, setSelectedRowId] = useState();

  useEffect(() => {
    let unsubscribe = null;
    async function fetchRisks() {
      setSpin(true);
      try {
        const q = await query(
          collection(firestore, "risks"),
          where("users", "array-contains", user.userid),
          orderBy("createddate", "desc"),
          limit(5)
        );

        unsubscribe = onSnapshot(q, (snapshot) => {
          const risksData = [];
          snapshot.forEach((doc) => {
            const newdata = doc.data();
            risksData.push(newdata);
            if (selectedRowId && selectedRowId == newdata.id) {
              handleRowClick(newdata)
            }
            if (location.state && location.state.notification && location.state.docid == newdata.id) {
              handleRowClick(newdata)
              navigate(location.pathname, { state: { notification: false, docid: false } });
            }
          });


          setData(risksData);
          setSpin(false);
          setFirstVisible(snapshot.docs[0])
          setLastVisible(snapshot.docs[snapshot.docs.length - 1])
        });
      } catch (error) {
        setShowError({ show: true, errordata: getErrorObject(error, user, "Risks", "fetchRisks") });
        setSpin(false);
      }
    }

    fetchRisks();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

  }, [selectedRowId]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  async function loadNext() {
    setDisablePrevious(false);

    try {
      const nextQuery = query(
        collection(firestore, "risks"),
        where("users", "array-contains", user.userid),
        orderBy("createddate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );

      const snapshot = await getDocs(nextQuery);
      //    postlog("read risks", { component: "risks", function: "loadNext", collection: "risks", uid: user.userid });


      const impedimentsData = [];
      snapshot.forEach((doc) => {
        const newdata = doc.data();
        impedimentsData.push(newdata);
      });

      if (snapshot.docs.length > 0) {
        setData(impedimentsData);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      if (snapshot.docs.length < 5) {
        setDisableNext(true);
      }

      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "impediments", "loadNext")
      });
      setSpin(false);
    }
  }

  async function loadPrevious() {
    setDisableNext(false);

    try {
      const previousQuery = query(
        collection(firestore, 'risks'),
        where("users", "array-contains", user.userid),
        orderBy("updateddate", "desc"),
        endBefore(firstVisible),
        limitToLast(5),
      );

      const snapshot = await getDocs(previousQuery);
      //  postlog("read risks", { component: "votings", function: "loadPrevious", collection: "risks", uid: user.userid });

      const impedimentsData = [];
      snapshot.forEach((doc) => {
        const newData = doc.data();
        impedimentsData.push(newData);
      });

      if (snapshot.docs.length > 0) {
        setData(impedimentsData);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setDisablePrevious(!snapshot.docs.length);
      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Impediments", "loadPrevious")
      });
      setSpin(false);
    }
  }



  async function saveRisks(data) {
    setSpin(true);
    try {
      let ownerDetails = {};
      if (formData.owner.username) {
        ownerDetails = {
          userid: formData.owner.userid,
          useremail: formData.owner.useremail,
          username: formData.owner.username,
          profilepicture: formData.owner.profilepicture,
        };
      } else {
        ownerDetails = {
          userid: formData.owner.userid,
          useremail: formData.owner.useremail,
          username: formData.owner.firstname + " " + formData.owner.lastname,
          profilepicture: formData.owner.profilepicture,
        };
      }

      if (selectedRowData === null) {

        try {
          const sfDocRef = doc(collection(firestore, "risks"), "index");
          let documentCounter = 1;

          await runTransaction(firestore, async (transaction) => {
            const sfDoc = await transaction.get(sfDocRef);
            if (!sfDoc.exists()) {
              transaction.set(sfDocRef, { counter: 1 });
            } else {
              const newcounter = sfDoc.data().counter + 1;
              documentCounter = newcounter;
              transaction.update(sfDocRef, { counter: newcounter });
            }
            const docRef = doc(collection(firestore, "risks"));
            await setDoc(docRef, {
              id: docRef.id,
              title: formData.title,
              description: formData.description,
              status: formData.status,
              occurrence: formData.occurrence,
              impact: formData.impact,
              criticalpath: formData.criticalpath,
              program: formData.program,
              programincrement: formData.programincrement,
              owner: ownerDetails,
              relationship: formData.relationship,
              resolutionmethod: formData.resolutionmethod,
              feature: formData.feature,
              targetresolutiondate: formData.targetresolutiondate,
              consequence: formData.consequence,
              contingency: formData.contingency,
              mitigation: formData.mitigation,
              resolutionstatus: formData.resolutionstatus,
              level: "restricted",
              users: [user.userid],
              usersdetails: { [user.userid]: getUserDetails(user) },
              createddate: Timestamp.fromDate(new Date()),
              updateddate: Timestamp.fromDate(new Date()),
              createdbyuserid: user.userid,
              createdbyusername: user.username,
              createdbyuserprofile:
                user.profilepicture != undefined ? user.profilepicture : "",
              createdbyuseremail: user.useremail,
              updatedbyuserid: user.userid,
              updatedbyusername: user.username,
              updatedbyuserprofile:
                user.profilepicture != undefined ? user.profilepicture : "",
              updatedbyuseremail: user.useremail,
              number: "R" + documentCounter,
              programboard: formData.programboard ? formData.programboard : "",
              programincrement: formData.programincrement ? formData.programincrement : "",
              programsprint: formData.programsprint ? formData.programsprint : "",
            });
            setSelectedRowId(docRef.id)
            //postlog("write risks", { component: "risks", function: "saveRisks", collection: "risks" });
            postlog("create risks", { component: "risks", function: "saveRisks", collection: "risks" });
          });

        } catch (e) {
          setShowError({ show: true, errordata: getErrorObject(e, user, "Risks", "saveRisks") });
        }
      } else {
        // Handle updating an existing ticket
        const docRef = doc(collection(firestore, "risks"), selectedRowData.id);
        await setDoc(docRef, {
          title: formData.title,
          description: formData.description,
          status: formData.status,
          occurrence: formData.occurrence,
          impact: formData.impact,
          criticalpath: formData.criticalpath,
          program: formData.program,
          programincrement: formData.programincrement,
          owner: ownerDetails,
          relationship: formData.relationship,
          resolutionmethod: formData.resolutionmethod,
          feature: formData.feature,
          targetresolutiondate: formData.targetresolutiondate,
          consequence: formData.consequence,
          contingency: formData.contingency,
          mitigation: formData.mitigation,
          resolutionstatus: formData.resolutionstatus,
          updateddate: Timestamp.fromDate(new Date()),
          updatedby: user.userid,
          programboard: formData.programboard ? formData.programboard : "",
          programincrement: formData.programincrement ? formData.programincrement : "",
          programsprint: formData.programsprint ? formData.programsprint : "",
        },
          { merge: true }
        );
        //postlog("write risks", { component: "risks", function: "saveRisks", collection: "risks", uid: data.id });

      }
      setSpin(false);
    } catch (err) {

      setShowError({ show: true, errordata: getErrorObject(err, user, "Risks", "saveRisks") });
      setSpin(false);
    }
  }

  const showForm = (data) => {
    try {

      setIsEditMode(true);
      setFormData({
        id: "",
        title: "",
        description: "",
        status: '' || null,
        occurrence: '' || null,
        impact: '' || null,
        criticalpath: '' || null,
        program: '' || null,
        programincrement: '' || null,
        owner: '',
        relationship: '' || null,
        resolutionmethod: '' || null,
        feature: '' || null,
        targetresolutiondate: "",
        consequence: "",
        contingency: "",
        mitigation: "",
        resolutionstatus: "",
        date: new Date().toISOString().slice(0, 10),
        programsprint: "",
        programincrement: "",
        programboard: "",
      });

      setSelectedRowData(null);
      setSelectedRowId(null);
      setProgramBoardData({
        programsprint: "",
        programincrement: "",
        programboard: "",
      })
      handleDrawerOpen();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "showForm") });
      setSpin(false);
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.title.trim() || !formData.description.trim() || formData.status == null || formData.occurrence == null || formData.impact == null || formData.criticalpath == null || formData.owner == "" || formData.resolutionmethod == null || formData.targetresolutiondate == "") {
      setShowFormValidate(true);
    } else {
      saveRisks(formData);
      setSnackbaropen(true);
      setIsEditMode(false);
      // handleDrawerClose();
    }

  };




  // Function to handle form input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Risks", "handleSidebar") });
      setSpin(false);
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    if (selectedRowData == null) {
      setDrawerOpen(false);
    }
    setIsEditMode(false);
    setShowFormValidate(false);
  };

  const HideSharePopup = () => {
    try {
      setShowShare(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Risks", "HideSharePopup"),
      });
      setSpin(false);
    }
  };

  const ShowSharePopup = () => {
    try {
      setShowShare(true);
      setShowError({
        show: true,
        errordata: getErrorObject("err", user, "Risks", "HideSharePopup"),
      });
    } catch (err) {

      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Risks", "ShowSharePopup"),
      });
      setSpin(false);
    }
  };

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setSelectedRowId(rowData.id);
    setProgramBoardData({
      programsprint: rowData.programsprint,
      programincrement: rowData.programincrement,
      programboard: rowData.programboard,
    })
    handleDrawerOpen();
  };


  const toggleEditMode = () => {
    if (!isEditMode) {
      setFormData({
        id: selectedRowData.id,
        title: selectedRowData.title,
        description: selectedRowData.description,
        status: selectedRowData.status,
        occurrence: selectedRowData.occurrence,
        impact: selectedRowData.impact,
        criticalpath: selectedRowData.criticalpath,
        program: selectedRowData.program,
        owner: selectedRowData.owner,


        startdate: selectedRowData.startdate,
        enddate: selectedRowData.enddate,
        health: selectedRowData.health,
        blocked: selectedRowData.blocked,
        category: selectedRowData.category,
        type: selectedRowData.type,
        notes: selectedRowData.notes,
        planndedvalue: selectedRowData.planndedvalue,
        deleveredvalue: selectedRowData.deleveredvalue,
        // date: dtt.toISOString().slice(0, 10),
        keyresults: selectedRowData.keyresults,
        programsprint: selectedRowData.programsprint,
        programincrement: selectedRowData.programincrement,
        programboard: selectedRowData.programboard,
        relationship: selectedRowData.relationship,
        resolutionmethod: selectedRowData.resolutionmethod,
        feature: selectedRowData.feature,
        targetresolutiondate: selectedRowData.targetresolutiondate,
        consequence: selectedRowData.consequence,
        contingency: selectedRowData.contingency,
        mitigation: selectedRowData.mitigation,
        resolutionstatus: selectedRowData.resolutionstatus,
      });
    }

    setIsEditMode((prevMode) => !prevMode);
  };


  const handleChildStateChange = (PBData) => {
    setFormData({
      ...formData,
      programboard: PBData.programboard ? PBData.programboard : "",
      programincrement: PBData.programincrement ? PBData.programincrement : "",
      programsprint: PBData.programsprint ? PBData.programsprint : "",
    })
  };

  return (
    <>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
      <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
        <div className="row g-0 mb-3">
          <div className="d-flex gap-2 align-items-center mb-2" >
            <div>
              <Typography variant="h6">
                Risks
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Click on 'Add Risk' to add a new risk
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <Box className='row' style={{ padding: '0 40px', marginBottom: '20px' }}>
            <div className="col-md-6">
            </div>
            <div className="col-md-6 text-end">
              <Button variant="contained" size="large" onClick={() => showForm(null)} sx={{ textTransform: 'capitalize' }}>
                Add Risk
              </Button>
            </div>
          </Box>
          {data.length != 0 ? (<TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table" size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Occurence</TableCell>
                  <TableCell>Impact</TableCell>
                  <TableCell>Critical</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((risk) => (
                  <TableRow key={risk.id} onClick={() => handleRowClick(risk)} hover>
                    <TableCell><span className="highlighted-number">{risk.number}</span></TableCell>
                    <TableCell>{showdate(risk.createddate, user.timezone)}</TableCell>
                    <TableCell>{risk.title}</TableCell>
                    <TableCell>{risk.occurrence}</TableCell>
                    <TableCell>{risk.impact}</TableCell>
                    <TableCell>{risk.criticalpath}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {risk.owner && risk.owner.profilepicture ? (
                          <Avatar src={risk.owner.profilepicture} sx={{ marginRight: '8px', width: '2rem', height: '2rem' }} />
                        ) : (
                          <AccountCircleIcon sx={{ width: '2.2rem', height: '2.2rem', marginRight: '8px', color: '#5f6368' }} />
                        )}
                      </div>
                    </TableCell>



                    <TableCell>{risk.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>) : (
            <Card style={{ textAlign: 'center', color: '#a9a3b7', padding: '3rem 0rem' }}>
              <Typography variant="h5" gutterBottom>
                You've not created any risk yet!
              </Typography>
              <Typography variant="h7" gutterBottom>
                When you do, they will be show up here.
              </Typography>
            </Card>
          )}

          {data && data.length !== 0 && (
            <div className="d-flex justify-content-end mb-3 mt-3">
              <ul className="pagination">
                <li className="page-item">
                  <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                    <KeyboardArrowLeftIcon />
                  </button>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={loadNext} disabled={disableNext} >
                    <KeyboardArrowRightIcon />
                  </button>
                </li>
              </ul>
            </div>
          )}

          {/* Drawer Component */}
          <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false)
              handleDrawerClose();
            }}
            onOpen={() => { }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div style={{ width: "50vw", padding: '20px' }}>

              <Box sx={{ position: 'fixed', top: '0px', backgroundColor: 'white', width: '50vw', padding: '10px 8px', paddingRight: '35px', zIndex: 999 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6" gutterBottom>{isEditMode && ('Add Risk')}</Typography>
                  <div style={{ display: 'flex' }}>
                    {isEditMode && (
                      <Button
                        onClick={handleDrawerClose}
                        color='primary'
                        sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                        variant="outlined"
                        size='medium'
                      >
                        Cancel
                      </Button>
                    )}

                    {isEditMode && (

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{
                          display: 'inline',
                          width: 'auto',
                          textTransform: 'capitalize',
                          marginRight: '8px',
                          flex: '0 0 auto'
                        }}>
                        Save Risk
                      </Button>
                    )}
                    {!isEditMode && <Button
                      onClick={toggleEditMode}
                      color='primary'
                      sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                      variant="outlined"
                      size='medium'
                      startIcon={<EditIcon />}

                    >
                      Edit
                    </Button>}


                    {!isEditMode && selectedRowData?.usersdetails?.[user.userid]?.isowner && (
                      <Button
                        onClick={ShowSharePopup}
                        color='primary'
                        sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                        variant="outlined"
                        size='medium'
                        style={{ display: selectedRowData && selectedRowData.usersdetails && selectedRowData.usersdetails[user.userid] && selectedRowData.usersdetails[user.userid].isowner ? 'flex' : 'none' }}
                        startIcon={<PersonAddAltIcon />}
                      >
                        Share
                      </Button>
                    )}

                    {!isEditMode && (
                      <Button
                        onClick={() => {
                          setDrawerOpen(false)
                          handleDrawerClose();
                        }}
                        color='primary'
                        sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '25px' }}
                        variant="outlined"
                        size='medium'
                        startIcon={<CloseIcon />}
                      >
                        Close
                      </Button>
                    )}

                  </div>
                </div>
              </Box>
              {isEditMode ? (
                <form onSubmit={handleSubmit}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                    <div style={{ flex: '70%' }}>
                      <TextField
                        name="title"
                        label="Title"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formData.title}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        error={showFormValidate && !formData.title.trim()}
                        helperText={showFormValidate && !formData.title.trim() ? 'Title is required' : ''}
                      />
                    </div>
                    <div style={{ marginLeft: '20px', flex: '30%' }}>
                      <Autocomplete
                        options={['Open', 'Close']}
                        value={formData.status || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            status: newValue,
                          }));
                        }}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            margin="normal"
                            required
                            error={showFormValidate && formData.status == null}
                            helperText={showFormValidate && formData.status == null ? 'Status is required' : ''}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    error={showFormValidate && !formData.description.trim()}
                    helperText={showFormValidate && !formData.description.trim() ? 'Description is required' : ''}
                  />


                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '33%' }}> {/* Each Autocomplete field takes 33% of the width */}
                      <Autocomplete
                        options={['No Set', 'Critical', 'High', 'Medium', 'Low']}
                        value={formData.occurrence || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            occurrence: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Occurrence"
                            margin="normal"
                            error={showFormValidate && formData.occurrence == null}
                            helperText={showFormValidate && formData.occurrence == null ? 'Occurrence is required' : ''}
                            required
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: '33%', marginLeft: '20px' }}> {/* Add spacing between fields */}
                      <Autocomplete
                        options={['No Set', 'Critical', 'High', 'Medium', 'Low']}
                        value={formData.impact || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            impact: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Impact"
                            variant="outlined"
                            margin="normal"
                            required
                            error={showFormValidate && formData.impact == null}
                            helperText={showFormValidate && formData.impact == null ? 'Impact is required' : ''}
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: '33%', marginLeft: '20px' }}> {/* Add spacing between fields */}
                      <Autocomplete
                        options={['Yes', 'No']}
                        value={formData.criticalpath || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            criticalpath: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Critical Path"
                            variant="outlined"
                            margin="normal"
                            required
                            error={showFormValidate && formData.criticalpath == null}
                            helperText={showFormValidate && formData.criticalpath == null ? 'Criticalpath is required' : ''}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* <Autocomplete
                  options={['No Set', 'Critical', 'High', 'Medium', 'Low']}
                  value={formData.program || null}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      program: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Program"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                /> */}

                  {/* <Autocomplete
                  options={['No Set', 'Critical', 'High', 'Medium', 'Low']}
                  value={formData.programincrement || null}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      programincrement: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Program Increment"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                /> */}

                  <Autocomplete
                    options={
                      (Array.isArray(state.usersglobaldata) && state.usersglobaldata.length > 0) ?
                        state.usersglobaldata.map((oneuser) => ({
                          user: oneuser,
                          label: `${oneuser?.firstname || ''} ${oneuser?.lastname || ''}`.trim(),
                          profilePicture: oneuser?.profilepicture,
                        })) :
                        [{
                          user: user,
                          label: `${user?.firstname || ''} ${user?.lastname || ''}`.trim(),
                          profilePicture: user?.profilepicture,
                        }]
                    }
                    value={
                      formData.owner?.username ||
                      `${formData.owner?.firstname || ''} ${formData.owner?.lastname || ''}`.trim()
                    }
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setFormData((prevData) => ({
                          ...prevData,
                          owner: newValue.user,
                        }));
                      }
                    }}
                    renderOption={(props, option) => (
                      <div {...props} style={{ display: 'flex', alignItems: 'center' }}>
                        {option.profilePicture ? (
                          <Avatar src={option.profilePicture} sx={{ marginRight: '8px', width: '2rem', height: '2rem' }} />
                        ) : (
                          <AccountCircleIcon sx={{ width: '2rem', height: '2rem', marginRight: '8px', color: '#5f6368' }} />
                        )}
                        <Typography>{option.label}</Typography>
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Owner"
                        variant="outlined"
                        margin="normal"
                        error={showFormValidate && !formData.owner}
                        helperText={showFormValidate && !formData.owner ? 'Owner is required' : ''}
                      />
                    )}
                  />

                  {/* <Autocomplete
                  options={['No Set', 'Critical', 'High', 'Medium', 'Low']}
                  value={formData.relationship || null}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      relationship: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Relationship"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                /> */}
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={['Resolved', 'Owned', 'Accepted', 'Mitigated']}
                        value={formData.resolutionmethod || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            resolutionmethod: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Resolution Method"
                            variant="outlined"
                            margin="normal"
                            required
                            error={showFormValidate && formData.resolutionmethod == null}
                            helperText={showFormValidate && formData.resolutionmethod == null ? 'Resolution method is required' : ''}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="targetresolutiondate"
                        label="Target Resolution Date"
                        variant="outlined"
                        type="date" // Set type to "date"
                        value={formData.targetresolutiondate}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        error={showFormValidate && formData.targetresolutiondate == ""}
                        helperText={showFormValidate && formData.targetresolutiondate == "" ? 'Target resolution date is required' : ''}
                      />

                    </Grid>
                  </Grid>


                  {/* <Autocomplete
                  options={['No Set', 'Critical', 'High', 'Medium', 'Low']}
                  value={formData.feature || null}
                  onChange={(event, newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      feature: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Feature"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                /> */}

                  <SelectSprint programboarddata={programBoardData} isViewMode={false} SyncWithParentState={handleChildStateChange} />

                  <TextField
                    name="consequence"
                    label="Consequence"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.consequence}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    name="contingency"
                    label="Contingency"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.contingency}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    name="mitigation"
                    label="Mitigation"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.mitigation}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    name="resolutionstatus"
                    label="Resolution Status"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.resolutionstatus}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </form>
              ) : (
                <Container maxWidth="md" sx={{ mt: 6, mb: 4 }}>
             {selectedRowData && (
              <React.Fragment>
                 <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Title: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{selectedRowData.title}</Box></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Status: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{selectedRowData.status}</Box></Typography>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Owner: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{selectedRowData.owner.username || selectedRowData.owner.firstname + " " + selectedRowData.owner.lastname}</Box></Typography>
                  </Box>
                
                  {selectedRowData && selectedRowData.description && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Description:</Typography>
                      <Typography variant="body2">{selectedRowData.description}</Typography>
                    </Box>
                  )}

                  <Box sx={{ flexGrow: 1, mb: 2 }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                      <Grid item xs={2} sm={4} md={4}>
                        <Item>
                          <Typography variant="body1" sx={{ color: 'text.secondary' }}>Occurrence</Typography>
                          <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.occurrence}</Typography>
                        </Item>
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Item>
                          <Typography variant="body1" sx={{ color: 'text.secondary' }}>Impact</Typography>
                          <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.impact}</Typography>
                        </Item>
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        <Item>
                          <Typography variant="body1" sx={{ color: 'text.secondary' }}>Critical Path</Typography>
                          <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.criticalpath}</Typography>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Resolution Method:</Typography>
                      <Typography variant="body2">{selectedRowData.resolutionmethod}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Target Resolution Date:</Typography>
                      <Typography variant="body2">{selectedRowData.targetresolutiondate}</Typography>
                    </Grid>
                  </Grid>


                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Consequence:</Typography>
                    <Typography variant="body2">{selectedRowData.consequence ? selectedRowData.consequence : "Not Available"}</Typography>
                  </Box>

                  <SelectSprint programboarddata={programBoardData} isViewMode={true} SyncWithParentState={handleChildStateChange} />


                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Contingency:</Typography>
                    <Typography variant="body2">{selectedRowData.contingency ? selectedRowData.contingency : "Not Available"}</Typography>
                  </Box>



                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Mitigation:</Typography>
                    <Typography variant="body2">{selectedRowData.mitigation ? selectedRowData.mitigation : "Not Available"}</Typography>
                  </Box>


                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Resolution Status:</Typography>
                    <Typography variant="body2">{selectedRowData.resolutionstatus ? selectedRowData.resolutionstatus : "Not Available"}</Typography>
                  </Box>
                  </React.Fragment>
)} 
                </Container>
              )}


            </div>

          </SwipeableDrawer>
        </div>
      </div>
      <Snackbar open={snackbaropen} autoHideDuration={6000} onClose={() => {
        setSnackbaropen(false);
      }}>
        <Alert
          onClose={() => {
            setSnackbaropen(false);
          }}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Record updated successfully!
        </Alert>
      </Snackbar>
      <ShowError Error={showError} />
      <Spinner spin={spin} />
      <SharePopup
        show={showshare}
        hideForm={HideSharePopup}
        userid={user.userid}
        docid={selectedRowData ? selectedRowData.id : null}
        docData={selectedRowData}
        doctype="risks"
        collectionname="risks"
        subscription={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription}
        message="Select users and add them to a team, enabling you to share access to other documents with the entire team at once."
      />
    </>
  );
}

export default Risks;