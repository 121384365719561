import React, { useEffect, useState, useRef } from "react";
import { firestore } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  onSnapshot,
  doc,
  Timestamp,
  setDoc,
} from "@firebase/firestore";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/ShowError/ShowError";
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import SuccessToast from "../Reusable/Toasts/SuccessToast";
import { getErrorObject, postlog } from "../../functions/Common";
import { Link,useLocation } from "react-router-dom";
import { Button, Container, Typography, Select, MenuItem, FormControl, InputLabel,TextField,  Avatar,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';



const ProfileManager = ({ user }) => {
  const storage = getStorage();
  const [spin, setSpin] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [data, setData] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [timezones, settimezones] = useState([]);
  const [Toast, setShowToast] = useState(false);
  const [Profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    profilepicture: "",
    timezone: "",
    id: "",
  });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  useEffect(() => {
    let unsubscribe = null;


    async function getUserData() {
      try {
        setSpin(true);
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = onSnapshot(doc(firestore, "users", user.userid),
          (doc) => {
            let userData; userData = doc.data();
            if (userData != undefined) {
              setData(userData);
              setProfile({
                firstname: userData.firstname,
                lastname: userData.lastname,
                profilepicture: userData.profilepicture,
                timezone: userData.timezone,
                userid: userData.userid,
              });
            }
            // postlog("read users", { component: "profile", function: "getUserData", collection: "users", uid: user.userid });
            setSpin(false);
          })
      } catch (err) {
        setShowError({
          show: true,
          errordata: getErrorObject(err, user, "Profile", "getUserData"),
        });
        setSpin(false);
      }
    }
    settimezons();
    getUserData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

  }, []);

  function settimezons() {
    try {
      const moment = require("moment-timezone");
      const alltimezones = moment.tz.names();

      const formattedtimezones = alltimezones.map((timezone) => {
        const offset = moment.tz(timezone).format("Z");
        return {
          offset: offset,
          timezone: timezone,
        };
      });

      formattedtimezones.sort((a, b) => {
        const offsetA = parseInt(a.offset.replace(":", ""));
        const offsetB = parseInt(b.offset.replace(":", ""));
        return offsetA - offsetB;
      });

      const sortedtimezones = formattedtimezones.map((entry) => {
        return `(${entry.offset}) ${entry.timezone}`;
      });

      const localtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const offset = getTimezoneOffset(localtimezone);
      const localtimezoneWithOffset = `(${offset}) ${localtimezone}`;

      function getTimezoneOffset(timezone) {
        const now = new Date();
        const utcDate = new Date(
          now.toLocaleString("en-US", { timeZone: "Etc/UTC" })
        );
        const localDate = new Date(
          now.toLocaleString("en-US", { timeZone: timezone })
        );

        const offsetMinutes = (localDate - utcDate) / (60 * 1000);
        const offsetHours = Math.floor(offsetMinutes / 60);
        const offsetMinutesRemaining = Math.abs(offsetMinutes) % 60;
        const offsetSign = offsetMinutes >= 0 ? "+" : "-";

        return `${offsetSign}${padZero(offsetHours)}:${padZero(
          offsetMinutesRemaining
        )}`;
      }

      function padZero(value) {
        return String(value).padStart(2, "0");
      }

      settimezones(sortedtimezones);

      setProfile((prevNames) => ({
        ...prevNames,
        timezone: localtimezoneWithOffset,
      }));
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "settimezons"),
      });
      setSpin(false);
    }
  }

  const handleFileInputChange = (e) => {
    try {
      const file = e.target.files[0];
      setSelectedFile(file);

      // Display the preview of the selected image
      if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
        const reader = new FileReader();

        reader.onload = function (e) {
          if (file.type === 'image/png') {
            // Convert PNG to JPEG
            const img = new Image();
            img.src = e.target.result;

            img.onload = function () {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              canvas.width = img.width;
              canvas.height = img.height;

              ctx.drawImage(img, 0, 0);

              // Convert the canvas to data URL with JPEG format
              const jpegDataURL = canvas.toDataURL('image/jpeg');

              setProfile((prevNames) => ({
                ...prevNames,
                profilepicture: jpegDataURL,
              }));
            };
            
          } else {
            // If it's not a PNG, set the profile picture directly
            setProfile((prevNames) => ({
              ...prevNames,
              profilepicture: e.target.result,
            }));
          }
        };

        reader.readAsDataURL(file);
      } else {
        setProfile((prevNames) => ({
          ...prevNames,
          profilepicture: null,
        }));
        e.target.value = null;
      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(
          err,
          user,
          "Profile",
          "handleFileInputChange"
        ),
      });
      setSpin(false);
    }
  };


  const resizeAndCompressImage = (file, maxWidth = 200, quality = 0.8) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          const canvas = document.createElement("canvas");
          const scale = maxWidth / image.width;
          canvas.width = maxWidth;
          canvas.height = image.height * scale;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            quality
          );
        };
      };
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      if (selectedFile) {
        setSpin(true);

        // Resize and compress the image
        const compressedBlob = await resizeAndCompressImage(selectedFile);

        const mountainsRef = ref(storage, `${user.userid}_p`);
        const uploadTask = uploadBytes(mountainsRef, compressedBlob);

        uploadTask.then((snapshot) => {
          // Upload is complete. Now get the download URL.
          getDownloadURL(mountainsRef).then((downloadURL) => {
            saveProfile(downloadURL);
            setSpin(false);
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, 1000);
          });
        });
      } else {
        saveProfile(Profile.profilepicture);
        setSpin(false);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1000);
      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "handleSave"),
      });
      setSpin(false);
    }
  };

  async function saveProfile(url) {
    try {
      const docRef = doc(firestore, "users", data.userid);
      await setDoc(
        docRef,
        {
          userid: docRef.id,
          profilepicture: url
            ? url
            : Profile.profilepicture != undefined
              ? Profile.profilepicture
              : "",
          firstname: Profile.firstname,
          lastname: Profile.lastname,
          useremail: user.useremail,
          timezone: Profile.timezone != undefined ? Profile.timezone : "",
          updateddate: Timestamp.fromDate(new Date()),
        },
        { merge: true }
      );
      // postlog("read users", { component: "profile", function: "saveProfile", collection: "users", uid: data.userid });
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "saveProfile"),
      });
      setSpin(false);
    }
  }

  const handleFirstname = (event) => {
    try {
      setProfile((prevNames) => ({
        ...prevNames,
        firstname: event.target.value,
      }));
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "handleFirstname"),
      });
      setSpin(false);
    }
  };

  const handleLastname = (event) => {
    try {
      setProfile((prevNames) => ({
        ...prevNames,
        lastname: event.target.value,
      }));
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "handleLastname"),
      });
      setSpin(false);
    }
  };

  const showForm = (data) => {
    try {
      let model = document.getElementById("formPopup");
      model.style.display = "block";
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "showForm"),
      });
      setSpin(false);
    }
  };

  const hideForm = () => {
    try {
      let model = document.getElementById("formPopup");
      model.style.display = "none";
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "hideForm"),
      });
      setSpin(false);
    }
  };

  const handleRemoveButtonClick = () => {
    try {
      setProfile((prevNames) => ({
        ...prevNames,
        profilepicture: "",
      })); // Update the client-side state to remove the image
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(
          err,
          user,
          "Profile",
          "handleRemoveButtonClick"
        ),
      });
      setSpin(false);
    }
  };

  const handleChange = (event) => {
    try {
      setProfile((prevNames) => ({
        ...prevNames,
        timezone: event.target.value,
      }));
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "handleChange"),
      });
      setSpin(false);
    }
  };

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Profile", "handleSidebar"),
      });
      setSpin(false);
    }
  };


  // const handleEditClick = () => {
  //   setIsEditDialogOpen(true);
  // };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleSaveChanges = () => {
    // Implement your logic to save changes here
    // Update user information with editedFirstName, editedLastName, and editedTimeZone
    // Close the dialog
    setIsEditDialogOpen(false);
  };

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  // Encode a string to Base64
function encodeToBase64(text) {
  return btoa(text);
}

// Decode a Base64-encoded string
function decodeFromBase64(base64String) {
  return atob(base64String);
}

  return (
    <>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} />

      <div className={`sideNav-top listPage-transition ${isOpen ? "side-margin" : ""}`}>
        <div className="container ">
        <div className="profile-card">
        <div className="row g-0 mb-4">
                <div className="d-flex gap-2 align-items-center" >
                  <i
                    className={`fas fa-user fs-6`}
                    style={{
                      color: "#fff",
                      backgroundColor: '#767676',
                      borderRadius: "50%",
                      padding: "12px"
                    }}
                  />
                  <div>
                    <div className="fs-5"> Manage Your Profile </div>
                    <p style={{ fontSize: "14px" }}>Change your account settings.</p>
                  </div>
                </div>
              </div>
    <div className="profile-view">
    <Container className="m-0 p-0">
      <Card>
        <CardContent>
          <Avatar
            sx={{
              width: 100,
              height: 100,
              backgroundColor: "primary.main",
              marginRight: 2,
            }}
          >
            {Profile.profilepicture ? (
              <img
                src={Profile.profilepicture}
                alt="Profile"
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            ) : (
              <AccountCircleIcon sx={{ width: "100%", height: "100%" }} />
            )}
          </Avatar>

          <Typography variant="h4" gutterBottom>
            {`${user.firstname} ${user.lastname}`}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {`Time Zone:  ${user.timezone == '' ? 'Default' : user.timezone}`}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {`Email: ${user.useremail}`}
          </Typography>
          {/* <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton> */}
          <Button size='small' variant="contained" startIcon={<EditIcon />} sx={{textTransform:"capitalize"}}  onClick={handleEditClick}>
  Edit Profile
</Button>

<div className="mt-3">
              <Link to={`/emailpreference/${encodeToBase64(user.userid)}`}>
                Manage email preference
              </Link>
            </div>
            <div className="mt-2">
              <Link to="/resetpassword">
                Reset Your Password
              </Link>
            </div>
        </CardContent>

      </Card>

      {/* Edit Dialog */}
      <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <div className="profileEdit">
          <div className="mb-3" style={{ position: "relative" }}>
              {/* <label className="form-label">
                <b>Profile Picture</b>
              </label> */}

              <div
                className="d-flex align-items-center justify-content-center"
                style={{ width: "110px" }}
              >
                {Profile.profilepicture && (
                  <div className="module-border-wrap2"></div>
                )}
                <div
                  className="rounded-circle profile-image mb-0"
                  style={{
                    width: "110px",
                    height: "110px",
                    position: "relative",
                    overflow: "hidden",
                    cursor: "pointer",
                    // display: 'inline-block',
                  }}
                >
                  {Profile.profilepicture ? (
                    <img
                      src={Profile.profilepicture}
                      alt="Profile"
                      className="img-fluid"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center w-100 h-100"
                      style={{
                        background: "#f0f0f0",
                        color: "#333",
                        fontSize: "14px",
                      }}
                    >
                      Choose File
                    </div>
                  )}
                  <input
                    type="file"
                    className="d-none"
                    id="profilepicturefile"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleFileInputChange}
                  />
                </div>
              </div>
              <div className="btn-2 mt-2 d-flex gap-2">
              <Button variant="contained" size="small"  onClick={() => fileInputRef.current.click()}>Upload</Button>
               <Button size="small" variant="outlined" color="error"   onClick={handleRemoveButtonClick}>Remove</Button>
              </div>
            </div>
          </div>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            id="firstName"
             name="fname"
             value={Profile.firstname}
              onChange={handleFirstname}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="normal"
            id="lastName"
             name="sname" 
            value={Profile.lastname}
             onChange={handleLastname}
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="timezone-label">Time Zone</InputLabel>
            <Select
                  labelId="timezone-label"
                  id="timezone"
                  value={Profile.timezone}
                  onChange={handleChange}
                  label="Time Zone"
                >
                  {timezones && timezones.map((timezone, index) => (
                    <MenuItem key={index} value={timezone}>
                      {timezone}
                    </MenuItem>
                  ))}
                </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button  onClick={handleSave} type='button' color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    </div>
          </div>
          <div id="formPopup" className="modal" style={{zIndex:"10000"}}>
            <div className="modal-content" style={{ width: "400px" }}>
              <div className="modal-header">
                <div>
                  <b>Profile picture</b>
                </div>
                <div className="circleIconContainer" onClick={hideForm}>
                  <span className="circleIcon">&times;</span>
                </div>
              </div>
              <div className="modal-body" style={{ padding: "40px 2px" }}>
                <div
                  className="mb-3"
                  style={{ position: "relative", textAlign: "center" }}
                >
                  {/* <label className="form-label">Profile Picture</label> */}
                  <div
                    className="rounded-circle profile-image mb-0"
                    style={{
                      width: "150px",
                      height: "150px",
                      position: "relative",
                      overflow: "hidden",
                      cursor: "pointer",
                      margin: "0 auto",
                      textAlign: "center",
                    }}
                  >
                    {Profile.profilepicture ? (
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          className="circular-overlay top-overlay text-light"
                          onClick={() => fileInputRef.current.click()}
                        >
                          Add
                        </div>
                        <div className="line-between"></div>
                        <div
                          className="circular-overlay bottom-overlay text-light"
                          onClick={handleRemoveButtonClick}
                        >
                          Remove
                        </div>
                        <img
                          src={Profile.profilepicture}
                          alt="Profile"
                          className="img-fluid"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center w-100 h-100"
                        style={{
                          background: "#f0f0f0",
                          color: "#333",
                          fontSize: "14px",
                        }}
                        onClick={() => fileInputRef.current.click()}
                      >
                        Choose File
                      </div>
                    )}
                    <input
                      type="file"
                      className="d-none"
                      id="profilepicture"
                      ref={fileInputRef}
                      accept="image/*"
                      onChange={handleFileInputChange}
                    />
                    {Profile.profilepicture == null && <p className="text-danger fs-7">Please upload JEPG or JPG Image only.</p>}
                  </div>
                </div>

                {/* {imageURL && (
                      <button className="btn btn-primary mt-3" style={{float:"left"}} onClick={() => fileInputRef.current.click()}>
                        Change
                      </button>
                    )}
                  {imageURL ? ( */}
                <button
                  className="btn btn-primary mt-3"
                  style={{ float: "right" }}
                  onClick={hideForm}
                >
                  Done
                </button>
                {/* ) : (
                      <button className="btn btn-primary mt-3" onClick={() => fileInputRef.current.click()}>
                        Add Picture
                      </button>
                    )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner spin={spin} />
      <ShowError Error={showError} />
      <SuccessToast show={Toast} />
    </>
  );
};

export default ProfileManager;
