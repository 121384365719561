import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    getDocs,
    Timestamp,
    where,
    setDoc,
    orderBy,
    startAfter,
    endBefore,
    limitToLast,
    limit,
    runTransaction
} from "@firebase/firestore";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider"
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/Share/share";
import { showdate, getErrorObject, getUserDetails, postlog } from "../../functions/Common";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container, Paper, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Avatar, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Autocomplete from '@mui/material/Autocomplete';
import SharePopup from "../Reusable/Share/share";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SelectSprint from "../Reusable/SelectSprint/SelectSprint";
const Dependencies = ({ user }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = useGlobalState();
    const [showshare, setShowShare] = useState(false);
    const [showError, setShowError] = useState({ show: false, errordata: {} });
    const [spin, setSpin] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [data, setData] = useState([]);
    const [showDocumentMissing, setshowDocumentMissing] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [snackbaropen, setSnackbaropen] = useState(false);
    const [disablePrevious, setDisablePrevious] = useState(true);
    const [disableNext, setDisableNext] = useState(false);
    const [lastVisible, setLastVisible] = useState();
    const [firstVisible, setFirstVisible] = useState();
    const [formData, setFormData] = useState({
        id: "",
        title: "",
        description: "",
        requestingteam: "",
        dependsonteam: "",
        needbydate: "",
        status: "",
        programsprint: "",
        programincrement: "",
        programboard: "",
    });
    const [showFormValidate, setShowFormValidate] = useState(false);
    const [programBoardData, setProgramBoardData] = useState();
    const [selectedRowId, setSelectedRowId] = useState();

    useEffect(() => {
        let unsubscribe = null;

        async function fetchDependencies() {
            setSpin(true);
            try {
                const q = await query(
                    collection(firestore, "dependencies"),
                    where("users", "array-contains", user.userid),
                    orderBy("createddate", "desc"),
                    limit(5)
                );

                unsubscribe = onSnapshot(q, (snapshot) => {
                    const dependenciesData = [];
                    snapshot.forEach((doc) => {
                        const newdata = doc.data();
                        dependenciesData.push(newdata);
                        if (selectedRowId && selectedRowId == newdata.id) {
                            handleRowClick(newdata)
                        }
                        if (location.state && location.state.notification && location.state.docid == newdata.id) {
                            handleRowClick(newdata)
                            navigate(location.pathname, { state: { notification: false, docid: false } });
                        }
                    });

                    setData(dependenciesData);
                    setSpin(false);
                    setFirstVisible(snapshot.docs[0])
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1])
                });
                setSpin(false);
            } catch (error) {
                setShowError({ show: true, errordata: getErrorObject(error, user, "Dependencies", "fetchDependencies") });
                setSpin(false);
            }
        }

        fetchDependencies();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };

    }, [selectedRowId]);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    async function loadNext() {
        setDisablePrevious(false);

        try {
            const nextQuery = query(
                collection(firestore, "dependencies"),
                where("users", "array-contains", user.userid),
                orderBy("createddate", "desc"),
                startAfter(lastVisible),
                limit(5)
            );

            const snapshot = await getDocs(nextQuery);
            //   postlog("read dependencies", { component: "dependencies", function: "loadNext", collection: "dependencies", uid: user.userid });


            const impedimentsData = [];
            snapshot.forEach((doc) => {
                const newdata = doc.data();
                impedimentsData.push(newdata);
            });

            if (snapshot.docs.length > 0) {
                setData(impedimentsData);
                setFirstVisible(snapshot.docs[0]);
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }

            if (snapshot.docs.length < 5) {
                setDisableNext(true);
            }

            setSpin(false);
        } catch (err) {
            setShowError({
                show: true,
                errordata: getErrorObject(err, user, "dependencies", "loadNext")
            });
            setSpin(false);
        }
    }

    async function loadPrevious() {
        setDisableNext(false);

        try {
            const previousQuery = query(
                collection(firestore, 'dependencies'),
                where("users", "array-contains", user.userid),
                orderBy("updateddate", "desc"),
                endBefore(firstVisible),
                limitToLast(5),
            );

            const snapshot = await getDocs(previousQuery);
            //   postlog("read dependencies", { component: "dependencies", function: "loadPrevious", collection: "dependencies", uid: user.userid });

            const impedimentsData = [];
            snapshot.forEach((doc) => {
                const newData = doc.data();
                impedimentsData.push(newData);
            });

            if (snapshot.docs.length > 0) {
                setData(impedimentsData);
                setFirstVisible(snapshot.docs[0]);
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }

            setDisablePrevious(!snapshot.docs.length);
            setSpin(false);
        } catch (err) {

            setShowError({
                show: true,
                errordata: getErrorObject(err, user, "dependencies", "loadPrevious")
            });
            setSpin(false);
        }
    }


    async function saveDependencies(data) {
        setSpin(true);
        try {
            if (selectedRowData == null) {
                try {
                    const sfDocRef = doc(collection(firestore, "dependencies"), "index");
                    let documentCounter = 1;

                    await runTransaction(firestore, async (transaction) => {
                        const sfDoc = await transaction.get(sfDocRef);
                        if (!sfDoc.exists()) {
                            transaction.set(sfDocRef, { counter: 1 });
                        } else {
                            const newcounter = sfDoc.data().counter + 1;
                            documentCounter = newcounter;
                            transaction.update(sfDocRef, { counter: newcounter });
                        }

                        let users = [...formData.requestingteam.users, ...formData.dependsonteam.users]
                        let uniqueusers = [...new Set(users)];

                        let usersdetails = { ...formData.requestingteam.usersdetails, ...formData.dependsonteam.usersdetails }

                        //  --- remove duplicates from object code here 
                        let updatedUserdetails = {};
                        uniqueusers.forEach(id => {
                            if (usersdetails[id]) {
                                updatedUserdetails[id] = usersdetails[id];
                            }
                        });
                        updatedUserdetails[user.userid].isowner = true;




                        // ------------------------------------------------

                        const docRef = doc(collection(firestore, "dependencies"));
                        await setDoc(docRef, {
                            id: docRef.id,
                            title: formData.title,
                            description: formData.description,
                            requestingteam: formData.requestingteam,
                            dependsonteam: formData.dependsonteam,
                            needbydate: formData.needbydate,
                            status: formData.status,
                            level: "restricted",
                            users: uniqueusers,
                            usersdetails: updatedUserdetails,
                            createddate: Timestamp.fromDate(new Date()),
                            updateddate: Timestamp.fromDate(new Date()),
                            createdbyuserid: user.userid,
                            createdbyusername: user.username,
                            createdbyuserprofile:
                                user.profilepicture != undefined ? user.profilepicture : "",
                            createdbyuseremail: user.useremail,
                            updatedbyuserid: user.userid,
                            updatedbyusername: user.username,
                            updatedbyuserprofile:
                                user.profilepicture != undefined ? user.profilepicture : "",
                            updatedbyuseremail: user.useremail,
                            number: "D" + documentCounter,
                            programboard: formData.programboard ? formData.programboard : "",
                            programincrement: formData.programincrement ? formData.programincrement : "",
                            programsprint: formData.programsprint ? formData.programsprint : "",
                        });
                        setSelectedRowId(docRef.id)
                        //     postlog("write dependencies", { component: "dependencies", function: "saveDependencies", collection: "dependencies" });
                        postlog("create dependencies", { component: "dependencies", function: "saveDependencies", collection: "dependencies" });
                    });
                } catch (e) {
                    setShowError({ show: true, errordata: getErrorObject(e, user, "Risks", "saveRisks") });
                }
            } else {
                // Handle updating an existing dependencies
                const docRef = doc(collection(firestore, "dependencies"), selectedRowData.id);
                await setDoc(docRef, {
                    title: formData.title,
                    description: formData.description,
                    requestingteam: formData.requestingteam,
                    dependsonteam: formData.dependsonteam,
                    needbydate: formData.needbydate,
                    status: formData.status,
                    level: "restricted",
                    users: [user.userid],
                    usersdetails: { [user.userid]: getUserDetails(user) },
                    updateddate: Timestamp.fromDate(new Date()),
                    updatedbyuserid: user.userid,
                    updatedbyusername: user.username,
                    updatedbyuserprofile:
                        user.profilepicture != undefined ? user.profilepicture : "",
                    updatedbyuseremail: user.useremail,
                    programboard: formData.programboard ? formData.programboard : "",
                    programincrement: formData.programincrement ? formData.programincrement : "",
                    programsprint: formData.programsprint ? formData.programsprint : "",
                },
                    { merge: true }
                );
                //  postlog("write dependencies", { component: "dependencies", function: "saveDependencies", collection: "dependencies", uid: data.id });
            }
            setSpin(false);
        } catch (err) {

            setShowError({ show: true, errordata: getErrorObject(err, user, "Dependencies", "saveDependencies") });
            setSpin(false);
        }
    }

    const showForm = (data) => {
        try {

            setIsEditMode(true);
            setFormData({
                id: "",
                title: "",
                description: "",
                requestingteam: "" || null,
                dependsonteam: "" || null,
                needbydate: "",
                status: '' || null,
                date: new Date().toISOString().slice(0, 10),
                programsprint: "",
                programincrement: "",
                programboard: "",
            });

            setSelectedRowData(null);
            setSelectedRowId(null);
            setProgramBoardData({
                programsprint: "",
                programincrement: "",
                programboard: "",
            })
            handleDrawerOpen();
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Dependencies", "showForm") });
            setSpin(false);
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!formData.title.trim() || !formData.description.trim() || formData.status == null || formData.needbydate == "" || !formData.dependsonteam || !formData.requestingteam) {
            setShowFormValidate(true);
        } else {
            saveDependencies(formData);
            setSnackbaropen(true);
            setIsEditMode(false);
        }

    };


    // Function to handle form input change
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSidebar = () => {
        try {
            setIsOpen(!isOpen);
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Dependencies", "handleSidebar") });
            setSpin(false);
        }
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        if (selectedRowData == null) {
            setDrawerOpen(false);
        }
        setIsEditMode(false);
        setShowFormValidate(false);
    };

    const HideSharePopup = () => {
        try {
            setShowShare(false);
        } catch (err) {
            setShowError({
                show: true,
                errordata: getErrorObject(err, user, "Dependencies", "HideSharePopup"),
            });
            setSpin(false);
        }
    };

    const ShowSharePopup = () => {
        try {
            setShowShare(true);
            setShowError({
                show: true,
                errordata: getErrorObject("err", user, "Dependencies", "HideSharePopup"),
            });
        } catch (err) {

            setShowError({
                show: true,
                errordata: getErrorObject(err, user, "Dependencies", "ShowSharePopup"),
            });
            setSpin(false);
        }
    };

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
        setSelectedRowId(rowData.id);
        setProgramBoardData({
            programsprint: rowData.programsprint,
            programincrement: rowData.programincrement,
            programboard: rowData.programboard,
        })
        handleDrawerOpen();
    };


    const handleSelectScrumData = (data) => {
        setProgramBoardData(data);
    }

    const toggleEditMode = () => {
        if (!isEditMode) {
            setFormData({
                id: selectedRowData.id,
                title: selectedRowData.title,
                description: selectedRowData.description,
                requestingteam: selectedRowData.requestingteam,
                dependsonteam: selectedRowData.dependsonteam,
                needbydate: selectedRowData.needbydate,
                status: selectedRowData.status,
                programsprint: selectedRowData.programsprint,
                programincrement: selectedRowData.programincrement,
                programboard: selectedRowData.programboard,
            })
        }
        setIsEditMode((prevMode) => !prevMode);
    };

    const handleChildStateChange = (PBData) => {
        setFormData({
            ...formData,
            programboard: PBData.programboard ? PBData.programboard : "",
            programincrement: PBData.programincrement ? PBData.programincrement : "",
            programsprint: PBData.programsprint ? PBData.programsprint : "",
        })
    };




    return (
        <>
            <Navbar user={user} toggleSidebar={handleSidebar} />
            <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
            <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
                <div className="row g-0 mb-3">
                    <div className="d-flex gap-2 align-items-center mb-2" >
                        <div>
                            <Typography variant="h6">
                                Dependencies
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Click on 'Create Dependency' to add a new dependency
                            </Typography>
                        </div>
                    </div>
                </div>

                <div>
                    <Box className='row' style={{ padding: '0 40px', marginBottom: '20px' }}>
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6 text-end">
                            <Button variant="contained" size="large" sx={{ textTransform: 'capitalize' }} onClick={() => showForm(null)}>
                                Create Dependency
                            </Button>
                        </div>
                    </Box>
                    {data.length != 0 ? (<TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table" size='medium'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Requesting Team</TableCell>
                                    <TableCell>Depends on Team</TableCell>
                                    <TableCell>Need by Date</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(data) && data.map((dependency) => (
                                    <TableRow key={dependency.id} onClick={() => handleRowClick(dependency)} hover>
                                        <TableCell><span className="highlighted-number">{dependency.number}</span></TableCell>
                                        <TableCell>{showdate(dependency.createddate, user.timezone)}</TableCell>
                                        <TableCell>{dependency.title}</TableCell>
                                        <TableCell>{dependency.requestingteam.name}</TableCell>
                                        <TableCell>{dependency.dependsonteam.name}</TableCell>
                                        <TableCell>{dependency.needbydate}</TableCell>
                                        <TableCell>{dependency.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>) : (
                        <Card style={{ textAlign: 'center', color: '#a9a3b7', padding: '3rem 0rem' }}>
                            <Typography variant="h5" gutterBottom>
                                You've not created any depedency yet!
                            </Typography>
                            <Typography variant="h7" gutterBottom>
                                When you do, they will be show up here.
                            </Typography>
                        </Card>
                    )}

                    {data && data.length !== 0 && (
                        <div className="d-flex justify-content-end mb-3 mt-3">
                            <ul className="pagination">
                                <li className="page-item">
                                    <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                                        <KeyboardArrowLeftIcon />
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button className="page-link" onClick={loadNext} disabled={disableNext} >
                                        <KeyboardArrowRightIcon />
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}

                    {/* Drawer Component */}
                    <SwipeableDrawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={() => {
                            setDrawerOpen(false)
                            handleDrawerClose();
                        }}
                        onOpen={() => { }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <div style={{ width: "50vw", padding: '20px' }}>

                            <Box sx={{ position: 'fixed', top: '0px', backgroundColor: 'white', width: '50vw', padding: '10px 8px', paddingRight: '35px', zIndex: 999 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="h6" gutterBottom >
                                        {isEditMode && ('Create Dependency')}
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                        {isEditMode && (
                                            <Button
                                                onClick={handleDrawerClose}
                                                color='primary'
                                                sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                                                variant="outlined"
                                                size='medium'
                                            >
                                                Cancel
                                            </Button>
                                        )}
                                        {isEditMode && (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                sx={{
                                                    display: 'inline',
                                                    width: 'auto',
                                                    textTransform: 'capitalize',
                                                    marginRight: '14px',
                                                    flex: '0 0 auto'
                                                }}>
                                                Save Dependency
                                            </Button>
                                        )}
                                        {!isEditMode && (<Button
                                            onClick={toggleEditMode}
                                            color='primary'
                                            sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                                            variant="outlined"
                                            size='medium'
                                            startIcon={<EditIcon />}
                                        >
                                            Edit
                                        </Button>)}
                                        {!isEditMode && selectedRowData?.usersdetails?.[user.userid]?.isowner && (
                                            <Button
                                                onClick={ShowSharePopup}
                                                color='primary'
                                                sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                                                variant="outlined"
                                                size='medium'
                                                style={{ display: selectedRowData && selectedRowData.usersdetails && selectedRowData.usersdetails[user.userid] && selectedRowData.usersdetails[user.userid].isowner ? 'flex' : 'none' }}
                                                startIcon={<PersonAddAltIcon />}
                                            >
                                                Share
                                            </Button>
                                        )}
                                        {!isEditMode && (<Button
                                            onClick={() => {
                                                setDrawerOpen(false)
                                                handleDrawerClose();
                                            }}
                                            color='primary'
                                            sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '25px' }}
                                            variant="outlined"
                                            size='medium'
                                            startIcon={<CloseIcon />}
                                        >
                                            Close
                                        </Button>)}
                                    </div>
                                </div>
                            </Box>
                            {isEditMode ? (
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        name="title"
                                        label="Title"
                                        variant="outlined"
                                        multiline
                                        rows={1}
                                        value={formData.title}
                                        onChange={handleChange}
                                        required
                                        fullWidth
                                        margin="normal"
                                        error={showFormValidate && !formData.title.trim()}
                                        helperText={showFormValidate && !formData.title.trim() ? 'Title is required' : ''}
                                        sx={{ marginTop: '45px' }}
                                    />

                                    <TextField
                                        name="description"
                                        label="Description"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        value={formData.description}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                        error={showFormValidate && !formData.description.trim()}
                                        helperText={showFormValidate && !formData.description.trim() ? 'Description is required' : ''}
                                    />

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ flex: '33%' }}>
                                            {/* <Autocomplete
                                                options={
                                                    (state.teamsglobaldata && state.teamsglobaldata.length > 0)
                                                        ? state.teamsglobaldata.map((team) => team.name)
                                                        : [{ label: "No Teams", value: null }]
                                                }
                                                value={formData.requestingteam || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.value !== null) {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            requestingteam: newValue,
                                                        }));
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Requesting Team"
                                                        margin="normal"
                                                        required
                                                        error={showFormValidate && formData.requestingteam == null}
                                                        helperText={showFormValidate && formData.requestingteam == null ? 'Requesting Team is required' : ''}
                                                    />
                                                )}
                                                getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                                                renderOption={(props, option) => (
                                                    <li {...props} style={{ pointerEvents: option.value === null ? 'none' : 'auto', opacity: option.value === null ? 0.5 : 1 }}>
                                                        {typeof option === 'string' ? option : option.label}
                                                    </li>
                                                )}
                                            /> */}

                                            <Autocomplete
                                                options={
                                                    state && state.teamsglobaldata && state.teamsglobaldata.length > 0
                                                        ? state.teamsglobaldata.map((team) => ({ id: team.id, name: team.name, users: team.users, usersdetails: team.usersdetails }))
                                                        : [{ id: null, name: "No Teams" }]
                                                }
                                                value={formData.requestingteam || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.name !== "No Teams") {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            requestingteam: newValue,
                                                        }));
                                                    } else {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            requestingteam: null,
                                                        }));
                                                    }
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Requesting Team"
                                                        margin="normal"
                                                        required
                                                        error={showFormValidate && !formData.requestingteam}
                                                        helperText={showFormValidate && !formData.requestingteam ? 'Requesting Team is required' : ''}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props} style={{ pointerEvents: option.id === null ? 'none' : 'auto', opacity: option.id === null ? 0.5 : 1 }}>
                                                        {option.name}
                                                    </li>
                                                )}
                                            />

                                        </div>
                                        <div style={{ flex: '33%', marginLeft: '20px' }}>
                                            {/* <Autocomplete
                                                options={
                                                    (state.teamsglobaldata && state.teamsglobaldata.length > 0)
                                                        ? state.teamsglobaldata.map((team) => team.name)
                                                        : [{ label: "No Teams", value: null }]
                                                }
                                                value={formData.dependsonteam || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.value !== null) {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            dependsonteam: newValue,
                                                        }));
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Depends on Team"
                                                        margin="normal"
                                                        required
                                                        error={showFormValidate && formData.dependsonteam == null}
                                                        helperText={showFormValidate && formData.dependsonteam == null ? 'Requesting Team is required' : ''}
                                                    />
                                                )}
                                                getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                                                renderOption={(props, option) => (
                                                    <li {...props} style={{ pointerEvents: option.value === null ? 'none' : 'auto', opacity: option.value === null ? 0.5 : 1 }}>
                                                        {typeof option === 'string' ? option : option.label}
                                                    </li>
                                                )}
                                            /> */}

                                            <Autocomplete
                                                options={
                                                    state && state.teamsglobaldata && state.teamsglobaldata.length > 0
                                                        ? state.teamsglobaldata.map((team) => ({ id: team.id, name: team.name, users: team.users, usersdetails: team.usersdetails }))
                                                        : [{ id: null, name: "No Teams" }]
                                                }
                                                value={formData.dependsonteam || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.name !== "No Teams") {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            dependsonteam: newValue,
                                                        }));
                                                    } else {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            dependsonteam: null,
                                                        }));
                                                    }
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Depends on Team"
                                                        margin="normal"
                                                        required
                                                        error={showFormValidate && !formData.dependsonteam}
                                                        helperText={showFormValidate && !formData.dependsonteam ? 'Depends on Team is required' : ''}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props} style={{ pointerEvents: option.id === null ? 'none' : 'auto', opacity: option.id === null ? 0.5 : 1 }}>
                                                        {option.name}
                                                    </li>
                                                )}
                                            />

                                        </div>
                                    </div>


                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <TextField
                                                name="needbydate"
                                                label="Need by Date"
                                                variant="outlined"
                                                type="date"
                                                value={formData.needbydate}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                InputLabelProps={{ shrink: true }}
                                                error={showFormValidate && formData.needbydate == ""}
                                                helperText={showFormValidate && formData.needbydate == "" ? 'Need by Date is required' : ''}
                                            />

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                options={['Open', 'Close']}
                                                value={formData.status || null}
                                                onChange={(event, newValue) => {
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        status: newValue,
                                                    }));
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Status"
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        error={showFormValidate && formData.status == null}
                                                        helperText={showFormValidate && formData.status == null ? 'Status is required' : ''}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <SelectSprint programboarddata={programBoardData} isViewMode={false} SyncWithParentState={handleChildStateChange} />

                                </form>
                            ) : (
                                <Container maxWidth="md" sx={{ mt: 6, mb: 4 }}>

                                    {selectedRowData && (<React.Fragment>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{ color: 'text.secondary' }}>Title: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{selectedRowData.title}</Box></Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" sx={{ color: 'text.secondary' }}>Status: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{selectedRowData.status}</Box></Typography>
                                            </Grid>
                                        </Grid>

                                        <Box sx={{ flexGrow: 1, mb: 2, mt: 2 }}>
                                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                                                <Grid item xs={2} sm={4} md={4}>
                                                    <Item>
                                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>Requesting Team</Typography>
                                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.requestingteam && selectedRowData.requestingteam.name}</Typography>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={2} sm={4} md={4}>
                                                    <Item>
                                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>Depends on Team</Typography>
                                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.dependsonteam && selectedRowData.dependsonteam.name}</Typography>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={2} sm={4} md={4}>
                                                    <Item>
                                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>Need by Date</Typography>
                                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.needbydate}</Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {selectedRowData.description && (
                                            <Box sx={{ mt: 2, mb: 2 }}>
                                                <Typography variant="body1" sx={{ color: 'text.secondary' }}>Description:</Typography>
                                                <Typography variant="body2">{selectedRowData.description}</Typography>
                                            </Box>
                                        )}

                                        <SelectSprint programboarddata={programBoardData} isViewMode={true} SyncWithParentState={handleChildStateChange} />
                                    </React.Fragment>)}

                                </Container>
                            )}


                        </div>

                    </SwipeableDrawer>
                </div>
            </div>
            <Snackbar open={snackbaropen} autoHideDuration={6000} onClose={() => {
                setSnackbaropen(false);
            }}>
                <Alert
                    onClose={() => {
                        setSnackbaropen(false);
                    }}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Record updated successfully!
                </Alert>
            </Snackbar>
            <ShowError Error={showError} />
            <Spinner spin={spin} />
            <SharePopup
                show={showshare}
                hideForm={HideSharePopup}
                userid={user.userid}
                docid={selectedRowData ? selectedRowData.id : null}
                docData={selectedRowData}
                doctype="dependencies"
                collectionname="dependencies"
                subscription={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription}
                message="Select users and add them to a team, enabling you to share access to other documents with the entire team at once."
            />
        </>
    );
}

export default Dependencies;