import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

export default function ProAlert({showAlert,hideAlert}) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    hideAlert();
    setOpen(false);
  };

  React.useEffect(() => {
    if (showAlert) {
      handleClickOpen();
    }
  }, [showAlert]);

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Upgrade your plan"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Unlock advanced features with Agilebin Pro plan! Gain access to pro templates, tailor your board and meeting settings to your needs, and securely share with users and teams. Subscribe today to elevate your Agilebin experience!
          </DialogContentText>
        </DialogContent>
        <DialogActions className='m-2'>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={()=>{
            navigate("/subscriptions")
          }} autoFocus variant='contained'>
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}