import { useEffect, useState } from "react";
import { firestore } from "../../../firebase";
import { collection, doc, setDoc, Timestamp } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { postlog } from "../../../functions/Common";
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextareaAutosize, Divider } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { styled } from '@mui/material/styles'; 
const Feedback = ({ show, componentName, user, docid }) => {
  const navigate = useNavigate();
  const [commentsAndSuggestion, setCommentsAndSuggestion] = useState("");
  const [selectedRating, setSelectedRating] = useState(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  function hideFeedbackPopup() {
    document.getElementById("feedbackPopup").style.display = "none";
    switch (componentName) {
      case "retrospectiveboard":
        navigate("/retrospective/meetings");
        break;
      case "kanbanboard":
        navigate("/actionitems");
        break;
      case "actionitemboard":
        navigate("/actionitems");
        break;
      case "leancoffeeboard":
        navigate("/leancoffeemeetings");
        break;
        case "fishbone":
        navigate("/fishbone");
        break;
      case "poker":
        navigate("/poker/games");
        break;
      case "fistoffive":
        navigate("/fistoffive/votings");
        break;
      case "spinwheel":
        navigate("/spinwheels");
        break;
      case "assessment":
        navigate("/assessments");
        break;
      default:
        navigate("/");
    };
  }

  const handleIconHover = (rating) => {
    setHoveredIcon(rating);
  };

  const handleIconLeave = () => {
    setHoveredIcon(null);
  };

  const handleRatingClick = (rating) => {
    setSelectedRating(rating);
  }

  const AnimatedIcon = styled('div')({
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  });

  async function handleSubmit(event) {
    event.preventDefault();

    if (!selectedRating) {
      document.getElementById("validationMsg").style.display = "block";
    } else {
      const feedbacksDocRef = doc(collection(firestore, "feedbacks"));

      const feedbackdata = {
        comments: commentsAndSuggestion,
        rating: selectedRating,
        componentName: componentName,
        docid: docid,
        createddate: Timestamp.fromDate(new Date()),
        createdbyuserid: user.userid || "",
        createdbyusername: user.username || "",
        createdbyuseremail: user.useremail || "",
      };

      if (user && user.userid && user.emailpreference) {
        feedbackdata.user = user;
      }

      await setDoc(feedbacksDocRef, feedbackdata).then(() => {
       postlog("write feedbacks", { component: "feedback", function: "handleSubmit", collection: "feedbacks" });

        switch (componentName) {
          case "retrospectiveboard":
            navigate("/retrospective/meetings");
            break;
          case "kanbanboard":
            navigate("/boards");
            break;
          case "actionitemboard":
            navigate("/actionitems");
            break;
          case "leancoffeeboard":
            navigate("/leancoffeemeetings");
            break;
            case "fishbone":
            navigate("/fishbone");
            break;
          case "poker":
            navigate("/poker/games");
            break;
          case "fistoffive":
            navigate("/fistoffive/votings");
            break;
          case "spinwheel":
            navigate("/spinwheels");
            break;
          case "assessment":
            navigate("/assessments");
            break;
          default:
            navigate("/");
        };
      });
    }
  }


  function handleChange(event) {
    setCommentsAndSuggestion(event.target.value);
  }

  if (show) {
    return (
      <>
        <Dialog open={true} onClose={hideFeedbackPopup} aria-labelledby="feedback-popup-title" id="feedbackPopup">
          <DialogTitle id="feedback-popup-title">
            <Typography variant="h6">Agilebin Feedback</Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography align="center">
              {componentName === 'poker' ? (
                <>How was your experience with the Agilebin Poker Tool?</>
              ) : componentName === 'board' ? (
                <>How was your experience with the Agilebin Retrospective Board?</>
              ) : componentName === 'fistoffive' ? (
                <>How was your experience with the Agilebin Fist Of Five Voting?</>
              ) : componentName === 'spinwheel' ? (
                <>How was your experience with the Agilebin Spinwheel?</>
              ) : componentName === 'assessments' ? (
                <>How was your experience with the Agilebin Assessment?</>
              ) : componentName === 'retrospectiveboard' ? (
                <>How was your experience with the Agilebin Retrospective?</>
              ) : componentName === 'actionitemboard' ? (
                <>How was your experience with the Agilebin Action Items?</>
              ) : componentName === 'leancoffeeboard' ? (
                <>How was your experience with the Agilebin Lean Coffee?</>
              ) : componentName === 'fishbone' ? (
                <>How was your experience with the Agilebin Fishbone Coffee?</>
              ) : null}
            </Typography>

            
            
            <Typography
                className="text-danger"
                id="validationMsg"
                style={{ display: 'none', marginTop: '10px', textAlign: 'center' }}
              >
                Please select one before submitting.
              </Typography>

              <div className="mt-4 mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
              <AnimatedIcon>
            <SentimentVeryDissatisfiedIcon
              style={{
                color: (selectedRating === "Very Bad" || hoveredIcon === "Very Bad") ? "red" : "gray",
                fontSize: 45,
                transition: 'transform 0.2s',
              }}
              onMouseOver={() => handleIconHover("Very Bad")}
              onMouseLeave={() => handleIconLeave()}
              onClick={() => handleRatingClick("Very Bad")}
            />
            </AnimatedIcon>
            <AnimatedIcon>
            <SentimentDissatisfiedIcon
              style={{
                color: (selectedRating === "Bad" || hoveredIcon === "Bad") ? "red" : "gray",
                fontSize: 45,
                transition: 'transform 0.2s',
              }}
              onMouseOver={() => handleIconHover("Bad")}
              onMouseLeave={() => handleIconLeave()}
              onClick={() => handleRatingClick("Bad")}
            />
            </AnimatedIcon>
            <AnimatedIcon>
            <SentimentSatisfiedIcon
              style={{
                color: (selectedRating === "Good" || hoveredIcon === "Good") ? "orange" : "gray",
                fontSize: 45,
                transition: 'transform 0.2s',
              }}
              onMouseOver={() => handleIconHover("Good")}
              onMouseLeave={() => handleIconLeave()}
              onClick={() => handleRatingClick("Good")}
            />
            </AnimatedIcon>
            <AnimatedIcon>
            <SentimentSatisfiedAltIcon
              style={{
                color: (selectedRating === "Very Good" || hoveredIcon === "Very Good") ? "green" : "gray",
                fontSize: 45,
                transition: 'transform 0.2s',
              }}
              onMouseOver={() => handleIconHover("Very Good")}
              onMouseLeave={() => handleIconLeave()}
              onClick={() => handleRatingClick("Very Good")}
            />
            </AnimatedIcon>
            <AnimatedIcon>
            <SentimentVerySatisfiedIcon
              style={{
                color: (selectedRating === "Excellent" || hoveredIcon === "Excellent") ? "green" : "gray",
                fontSize: 45,
                transition: 'transform 0.2s',
              }}
              onMouseOver={() => handleIconHover("Excellent")}
              onMouseLeave={() => handleIconLeave()}
              onClick={() => handleRatingClick("Excellent")}
            />
            </AnimatedIcon>
          </div>

            {/* <Typography
              className="mb-3"
              align="center"
              id="hoveringMsg"
              style={{ visibility: 'visible' }}
            >
              {hoveredIcon}
            </Typography> */}

            <Typography>Any Comments/Suggestions?</Typography>
            <TextareaAutosize
              id="exampleFormControlTextarea1"
              minRows={2}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '8px',
                border: '1px solid #bdbdbd',
                borderRadius: '4px',
                outline: 'none', // Remove outline when focused
                transition: 'border-color 0.2s, box-shadow 0.2s', // Transition for smoother effect
              }}

              onFocus={(e) => {
                e.target.style.borderColor = '#b4deff';
                e.target.style.boxShadow = '0 0 0 3px #b4deff'; // Blue box shadow on focus
              }}
              onBlur={(e) => {
                e.target.style.borderColor = '#bdbdbd'; // Reset border color on blur
                e.target.style.boxShadow = 'none'; // Remove box shadow on blur
              }}
            />
          </DialogContent>
          <Divider />
          <DialogActions className="mb-2">
            <Button onClick={hideFeedbackPopup} color="primary">
              Later
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    <div></div>;
  }
}

export default Feedback;
