import React from "react";
import { useState, useEffect, useRef } from "react";
import { firestore } from "../../firebase";
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDocs,
  Timestamp,
  where,
  setDoc,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  runTransaction
} from "@firebase/firestore";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider"
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/Share/share";
import { showdate, getErrorObject, getUserDetails, postlog } from "../../functions/Common";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container, Slider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Avatar, Grid, IconButton, Card, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Autocomplete from '@mui/material/Autocomplete';
import SharePopup from "../Reusable/Share/share";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SelectSprint from "../Reusable/SelectSprint/SelectSprint";

const Okrs = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useGlobalState();
  const [showshare, setShowShare] = useState(false);
  const [value, setValue] = useState(0);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [spin, setSpin] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isKREditMode, setKRIsEditMode] = useState(false);
  const [openKr, setopenKr] = useState(false);
  const [snackbaropen, setSnackbaropen] = useState(false);
  const [showFormValidate, setShowFormValidate] = useState(false);
  const [showFormValidatekr, setShowFormValidatekr] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    tire: "",
    startdate: "",
    enddate: "",
    status: "",
    owner: "",
    category: "",
    type: "",
    planndedvalue: "",
    deleveredvalue: "",
    krcompletionpersentage: "",
    keyresults: "",
    programsprint:  "",
    programincrement:  "",
    programboard :  "",
  });
  const [formDataKeyResults, setFormDataKeyResults] = useState({
    krtitle: "",
    krdescription: "",
    krstatus: "",
    krplanndedvalue: "",
    krdeleveredvalue: "",
  });
  const [programBoardData,setProgramBoardData] = useState();
  const [selectedRowId, setSelectedRowId] = useState();


  useEffect(() => {
    let unsubscribe = null;
    async function fetchRisks() {
      setSpin(true);
      try {
        const q = await query(
          collection(firestore, "okrs"),
          where("users", "array-contains", user.userid),
          orderBy("createddate", "desc"),
          limit(5)
        );

        unsubscribe = onSnapshot(q, (snapshot) => {

          const risksData = [];
          snapshot.forEach((doc) => {
            const newdata = doc.data();
            risksData.push(newdata);
              if (selectedRowId && selectedRowId == newdata.id) {
                handleRowClick(newdata)
              }
            if (location.state && location.state.notification && location.state.docid == newdata.id) {
              handleRowClick(newdata)
              navigate(location.pathname, { state: { notification: false, docid: false } });
            }
          });
       
          setData(risksData);
          setSpin(false);
          setFirstVisible(snapshot.docs[0])
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]) 
        });
        setSpin(false);

      } catch (error) {
        setShowError({ show: true, errordata: getErrorObject(error, user, "Risks", "fetchRisks") });
        setSpin(false);
      }
    }

    fetchRisks();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

  }, [selectedRowId]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  async function loadNext() {
    setDisablePrevious(false);

    try {
      const nextQuery = query(
        collection(firestore, "okrs"),
          where("users", "array-contains", user.userid),
          orderBy("createddate", "desc"),
          startAfter(lastVisible),
          limit(5)
      );

      const snapshot = await getDocs(nextQuery);
      // postlog("read okrs", { component: "okrs", function: "loadNext", collection: "okrs", uid: user.userid });


      const impedimentsData = [];
          snapshot.forEach((doc) => {
            const newdata = doc.data();
            impedimentsData.push(newdata);
          });

      if (snapshot.docs.length > 0) {
        setData(impedimentsData);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      if (snapshot.docs.length < 5) {
        setDisableNext(true);
      }

      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "impediments", "loadNext")
      });
      setSpin(false);
    }
  }

  async function loadPrevious() {
    setDisableNext(false);

    try {
      const previousQuery = query(
        collection(firestore, 'okrs'),
        where("users", "array-contains", user.userid),
        orderBy("updateddate", "desc"),
        endBefore(firstVisible),
        limitToLast(5),
      );
 
      const snapshot = await getDocs(previousQuery);
      // postlog("read okrs", { component: "okrs", function: "loadPrevious", collection: "okrs", uid: user.userid });

      const impedimentsData = [];
      snapshot.forEach((doc) => {
        const newData = doc.data();
        impedimentsData.push(newData);
      });

      if (snapshot.docs.length > 0) {
        setData(impedimentsData);
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setDisablePrevious(!snapshot.docs.length);
      setSpin(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Impediments", "loadPrevious")
      });
      setSpin(false);
    }
  }


  async function saveRisks(data) {
    setSpin(true);
    try {
      let ownerDetails = {};
      if (formData.owner.username) {
        ownerDetails = {
          userid: formData.owner.userid,
          useremail: formData.owner.useremail,
          username: formData.owner.username,
          profilepicture: formData.owner.profilepicture,
        };
      } else {
        ownerDetails = {
          userid: formData.owner.userid,
          useremail: formData.owner.useremail,
          username: formData.owner.firstname + " " + formData.owner.lastname,
          profilepicture: formData.owner.profilepicture,
        };
      }

      if (selectedRowData==null) {
        try { 

          const sfDocRef = doc(collection(firestore, "okrs"), "index");
          let documentCounter = 1;

          await runTransaction(firestore, async (transaction) => {
            const sfDoc = await transaction.get(sfDocRef);
            if (!sfDoc.exists()) {
              transaction.set(sfDocRef, { counter: 1 });
            } else {  
              const newcounter = sfDoc.data().counter + 1;
              documentCounter = newcounter;
              transaction.update(sfDocRef, { counter: newcounter });
            }

        const docRef = doc(collection(firestore, "okrs"));
        await setDoc(docRef, {
          id: docRef.id,
          title: formData.title,
          description: formData.description,
          tire: formData.tire,
          startdate: formData.startdate,
          enddate: formData.enddate,
          status: formData.status,
          owner: ownerDetails,
          health: formData.health,
          blocked: formData.blocked,
          category: formData.category,
          type: formData.type,
          planndedvalue: formData.planndedvalue,
          deleveredvalue: formData.deleveredvalue,
          keyresults: "",
          notes: formData.notes,
          level: "restricted",
          users: [user.userid],
          usersdetails: { [user.userid]: getUserDetails(user) },
          createddate: Timestamp.fromDate(new Date()),
          updateddate: Timestamp.fromDate(new Date()),
          createdbyuserid: user.userid,
          createdbyusername: user.username,
          createdbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          createdbyuseremail: user.useremail,
          updatedbyuserid: user.userid,
          updatedbyusername: user.username,
          updatedbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          updatedbyuseremail: user.useremail,
          number :"O" + documentCounter,
          programboard: formData.programboard ? formData.programboard : "" ,
          programincrement: formData.programincrement ? formData.programincrement : "",
          programsprint: formData.programsprint ? formData.programsprint : "",
        });
        setSelectedRowId(docRef.id)
        postlog("create okrs", { component: "okrs", function: "saveRisks", collection: "okrs" });
      //  postlog("write risks", { component: "risks", function: "saveRisks", collection: "risks" });
      });
    } catch (e) {
        setShowError({ show: true, errordata: getErrorObject(e, user, "Risks", "saveRisks") });
      }
      } else {
        // Handle updating an existing ticket
  
        const docRef = doc(collection(firestore, "okrs"), selectedRowData.id);
        await setDoc(docRef, {
          title: formData.title,
          description: formData.description,
          tire: formData.tire,
          startdate: formData.startdate,
          enddate: formData.enddate,
          status: formData.status,
          owner: ownerDetails,
          health: formData.health,
          blocked: formData.blocked,
          category: formData.category,
          type: formData.type,
          planndedvalue: formData.planndedvalue,
          deleveredvalue: formData.deleveredvalue,
          notes: formData.notes,
          updateddate: Timestamp.fromDate(new Date()),
          updatedby: user.userid,
          programboard: formData.programboard ? formData.programboard : "" ,
          programincrement: formData.programincrement ? formData.programincrement : "",
          programsprint: formData.programsprint ? formData.programsprint : "",
        },
          { merge: true }
        );

      //  postlog("write risks", { component: "risks", function: "saveRisks", collection: "okrs", uid: data.id });

      }
      setSpin(false);
    } catch (err) {

      setShowError({ show: true, errordata: getErrorObject(err, user, "Risks", "saveRisks") });
      setSpin(false);
    }
  }

  const showForm = (data) => {
    try {
        setIsEditMode(true);
        setFormData({
          id: "",
          title: "",
          description: "",
          tire: '' || null,
          startdate: '' || null,
          enddate: '' || null,
          status: '' || null,
          owner: '',
          health: '' || null,
          blocked: "",
          category: "",
          notes: "",
          type: "",
          planndedvalue: "",
          deleveredvalue: "",
          keyresults: "",
          date: new Date().toISOString().slice(0, 10),
          programsprint:  "",
          programincrement:  "",
          programboard :  "",
        });
        setFormDataKeyResults({
          krtitle: "",
          krdescription: "",
          krstatus: "",
          krplanndedvalue: "",
          krdeleveredvalue: "",
          krcompletionpersentage: "",
        })
        setSelectedRowData(null);
        setSelectedRowId(null);
        setProgramBoardData({
          programsprint:  "",
          programincrement:  "",
          programboard :  "",
        })
      handleDrawerOpen();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Votings", "showForm") });
      setSpin(false);
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.title.trim() || !formData.description.trim() || formData.tire == null || formData.status == null || formData.owner == "" || formData.blocked == '' || formData.enddate == null || formData.startdate == null || formData.health == null || formData.category == '' || formData.type == '') {
      setShowFormValidate(true);
    } else {
      saveRisks(formData);
      setSnackbaropen(true);
      setIsEditMode(false);
    }

    // handleDrawerClose();
  };


  // Function to handle form input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setFormDataKeyResults((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      // setKRIsEditMode(false);
    }else{
      setFormDataKeyResults({
        krtitle: "",
        krdescription: "",
        krstatus: "",
        krplanndedvalue: "",
        krdeleveredvalue: "",
        krcompletionpersentage: "",
      })
    }
  };

  const handleSidebar = () => {
    try {
      setIsOpen(!isOpen);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Risks", "handleSidebar") });
      setSpin(false);
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    if(selectedRowData == null){
      setDrawerOpen(false);
    }
    setIsEditMode(false);
    setShowFormValidate(false);
    setValue(0)
  };

  const HideSharePopup = () => {
    try {
      setShowShare(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Risks", "HideSharePopup"),
      });
      setSpin(false);
    }
  };

  const ShowSharePopup = () => {
    try {
      setShowShare(true);
      setShowError({
        show: true,
        errordata: getErrorObject("err", user, "Risks", "HideSharePopup"),
      });
    } catch (err) {

      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Risks", "ShowSharePopup"),
      });
      setSpin(false);
    }
  };

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setSelectedRowId(rowData.id);
    setProgramBoardData({
      programsprint: rowData.programsprint,
      programincrement: rowData.programincrement,
      programboard : rowData.programboard,
    })
    handleDrawerOpen();
  };

  const toggleEditMode = () => {
    if(!isEditMode){
      setFormData({
        id: selectedRowData.id,
        title: selectedRowData.title,
        description: selectedRowData.description,
        tire: selectedRowData.tire,
        startdate: selectedRowData.startdate,
        enddate: selectedRowData.enddate,
        status: selectedRowData.status,
        owner: selectedRowData.owner,
        health: selectedRowData.health,
        blocked: selectedRowData.blocked,
        category: selectedRowData.category,
        type: selectedRowData.type,
        notes: selectedRowData.notes,
        planndedvalue: selectedRowData.planndedvalue,
        deleveredvalue: selectedRowData.deleveredvalue,
        // date: dtt.toISOString().slice(0, 10),
        keyresults: selectedRowData.keyresults,
        programsprint: selectedRowData.programsprint,
        programincrement: selectedRowData.programincrement,
        programboard : selectedRowData.programboard,
      });
      setFormDataKeyResults({
        krtitle: "",
        krdescription: "",
        krstatus: "",
        krplanndedvalue: "",
        krdeleveredvalue: "",
        krcompletionpersentage: "",
      })
    }
    setIsEditMode((prevMode) => !prevMode);
  };


  const handleChildStateChange = (PBData) => {
    setFormData({
      ...formData,
      programboard: PBData.programboard ? PBData.programboard : "",
      programincrement: PBData.programincrement ? PBData.programincrement : "",
      programsprint: PBData.programsprint ? PBData.programsprint : "",
    })
  };
  

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangee = (event, newValue) => {
    setValue(newValue);
  };

  const SaveKeyResult = async () => {
    if (!formDataKeyResults.krtitle.trim() || !formDataKeyResults.krdescription.trim() || !formDataKeyResults.krstatus.trim() || !formDataKeyResults.krcompletionpersentage.trim()) {
      setShowFormValidatekr(true);
    } else {
    let docRefokrs = doc(firestore, "okrs", selectedRowData.id);
    let newkrkey;
    if (isKREditMode != null && isKREditMode != "" && isKREditMode != undefined) {
      newkrkey = isKREditMode;
    } else {
      newkrkey = doc(collection(firestore, "okrs")).id;
    }
    await setDoc(
      docRefokrs,
      {
        keyresults: {
          [newkrkey]: {
            krtitle: formDataKeyResults.krtitle,
            krdescription: formDataKeyResults.krdescription,
            krstatus: formDataKeyResults.krstatus,
            krplanndedvalue: formDataKeyResults.krplanndedvalue,
            krdeleveredvalue: formDataKeyResults.krdeleveredvalue,
            krcompletionpersentage: formDataKeyResults.krcompletionpersentage,
          }
        },
      },
      { merge: true }
    );
  
    setKRIsEditMode();
    handleCloseKr();
  }
  }


  const EditKeyResult = (data,id) => {
    setKRIsEditMode(id)
    setopenKr(true);
    setFormDataKeyResults({
      krtitle: data.krtitle,
      krdescription: data.krdescription,
      krstatus: data.krstatus,
      krplanndedvalue: data.krplanndedvalue,
      krdeleveredvalue: data.krdeleveredvalue,
      krcompletionpersentage: data.krcompletionpersentage,
    })

  }

  const handleCloseKr = ()=>{

    setopenKr(false);
    setKRIsEditMode();
    setShowFormValidatekr(false);

  }






  return (
    <>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
      <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
        <div className="row g-0 mb-3">
          <div className="d-flex gap-2 align-items-center mb-2" >
            <div>
              <Typography variant="h6">
                Okrs
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Click on 'Add Objective' to add a new Okrs
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <Box className='row' style={{ padding: '0 40px', marginBottom: '20px' }}>
            <div className="col-md-6">
            </div>
            <div className="col-md-6 text-end">
              <Button variant="contained" size="large" sx={{textTransform:'capitalize'}} onClick={() => showForm(null)}>
                Add Objective
              </Button>
            </div>
          </Box>
          {data.length !== 0 ? (
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table" size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Tire</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((risk) => (
                  <TableRow key={risk.id} onClick={() => handleRowClick(risk)} hover>
                      <TableCell><span className="highlighted-number">{risk.number}</span></TableCell>
                    <TableCell>{showdate(risk.createddate, user.timezone)}</TableCell>
                    <TableCell>{risk.title}</TableCell>
                    <TableCell>{risk.startdate}</TableCell>
                    <TableCell>{risk.enddate}</TableCell>
                    <TableCell>{risk.status}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {risk.owner && risk.owner.profilepicture ? (
                          <Avatar src={risk.owner.profilepicture} sx={{ marginRight: '8px', width: '2rem', height: '2rem' }} />
                        ) : (
                          <AccountCircleIcon sx={{ width: '2.2rem', height: '2.2rem', marginRight: '8px', color: '#5f6368' }} />
                        )}
                      </div>
                    </TableCell>



                    <TableCell>{risk.tire}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>) : (
              spin === false && data !== undefined && data.length === 0 && (
                <Card style={{ textAlign: 'center', color: '#a9a3b7', padding: '3rem 0rem' }}>
                  <Typography variant="h5" gutterBottom>
                    You've not created any okrs yet!
                  </Typography>
                  <Typography variant="h7" gutterBottom>
                    When you do, they will be show up here.
                  </Typography>
                </Card>
              )
            )}

          {data && data.length !== 0 && (
              <div className="d-flex justify-content-end mb-3 mt-3">
                <ul className="pagination">
                  <li className="page-item">
                    <button className="page-link" onClick={loadPrevious} disabled={disablePrevious} style={{ marginRight: '15px' }}>
                      <KeyboardArrowLeftIcon />
                    </button>
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={loadNext} disabled={disableNext} >
                      <KeyboardArrowRightIcon />
                    </button>
                  </li>
                </ul>
              </div>
            )}

          {/* Drawer Component */}
          <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={() => { setDrawerOpen(false)
                        handleDrawerClose();
                      }}
            onOpen={() => { }}
          >
            <div style={{ width: "50vw", padding: '20px' }}>

            <Box sx={{ position: 'fixed', top: '0px', backgroundColor: 'white', width: '50vw', padding: '10px 8px', paddingRight: '35px', zIndex: 999 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h6" gutterBottom>
                  {isEditMode && ('Add Okrs')}
                  </Typography>
                  <div>

                    {isEditMode && (
                      <Button
                        onClick={handleDrawerClose}
                        color='primary'
                        sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                        variant="outlined"
                        size='medium'
                      >
                        Cancel
                      </Button>
                    )}
                    {isEditMode && (<Button
                      type="submit"
                      variant="contained" color="primary"
                      onClick={handleSubmit}
                      sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}>

                      Save Objective
                    </Button>
                    )}
                    {!isEditMode && value != 1 && (<Button
                      onClick={toggleEditMode}
                      color='primary'
                      sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                      variant="outlined"
                      size='medium'
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>)}
                    {!isEditMode && value != 1 && selectedRowData?.usersdetails?.[user.userid]?.isowner && (
                      <Button
                        onClick={ShowSharePopup}
                        color='primary'
                        sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                        variant="outlined"
                        size='medium'
                        startIcon={<PersonAddAltIcon />}
                        style={{ display: selectedRowData && selectedRowData.usersdetails && selectedRowData.usersdetails[user.userid] && selectedRowData.usersdetails[user.userid].isowner ? 'inline' : 'none' }}
                      >
                        Share
                      </Button>
                    )}
                    {!isEditMode && (<Button
                      onClick={() => { setDrawerOpen(false)
                        handleDrawerClose();
                      }}
                      color='primary'
                      sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '10px' }}
                      variant="outlined"
                      size='medium'
                      startIcon={<CloseIcon />}
                    >
                      Close
                    </Button>)}
                  </div>
                </div>
              </Box>
              {isEditMode ? (
                <form onSubmit={handleSubmit}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '45px' }}>
                    <div style={{ flex: '70%' }}>
                      <TextField
                        name="title"
                        label="Title"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formData.title}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        error={showFormValidate && !formData.title.trim()}
                        helperText={showFormValidate && !formData.title.trim() ? 'Title is required' : ''}
                      />
                    </div>
                    <div style={{ marginLeft: '20px', flex: '30%' }}>
                      <Autocomplete
                        options={['Enterprise', 'Portfolio', 'Department', 'Value Stream', 'Train', 'Team']}
                        value={formData.tire || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            tire: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tire"
                            variant="outlined"
                            margin="normal"
                            error={showFormValidate && formData.tire == null}
                            helperText={showFormValidate && formData.tire == null ? 'Tire is required' : ''}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    error={showFormValidate && !formData.description.trim()}
                    helperText={showFormValidate && !formData.description.trim() ? 'Description is required' : ''}
                  />

                  <Autocomplete
                    options={
                      (Array.isArray(state.usersglobaldata) && state.usersglobaldata.length > 0) ?
                        state.usersglobaldata.map((oneuser) => ({
                          user: oneuser,
                          label: `${oneuser?.firstname || ''} ${oneuser?.lastname || ''}`.trim(),
                          profilePicture: oneuser?.profilepicture,
                        })) :
                        [{
                          user: user, 
                          label: `${user?.firstname || ''} ${user?.lastname || ''}`.trim(),
                          profilePicture: user?.profilepicture,
                        }]
                    }
                    
                    value={
                      formData.owner?.username ||
                      `${formData.owner?.firstname || ''} ${formData.owner?.lastname || ''}`.trim()
                    }
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setFormData((prevData) => ({
                          ...prevData,
                          owner: newValue.user,
                        }));
                      }
                    }}
                    renderOption={(props, option) => (
                      <div {...props} style={{ display: 'flex', alignItems: 'center' }}>
                        {option.profilePicture ? (
                          <Avatar src={option.profilePicture} sx={{ marginRight: '8px', width: '2rem', height: '2rem' }} />
                        ) : (
                          <AccountCircleIcon sx={{ width: '2rem', height: '2rem', marginRight: '8px', color: '#5f6368' }} />
                        )}
                        <Typography>{option.label}</Typography>
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Owner"
                        variant="outlined"
                        margin="normal"
                        error={showFormValidate && !formData.owner}
                        helperText={showFormValidate && !formData.owner ? 'Owner is required' : ''}
                      />
                    )}
                  />

                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={4} md={4} >
                      <TextField
                        name="startdate"
                        label="Start Date"
                        variant="outlined"
                        type="date" // Set type to "date"
                        value={formData.startdate} // Ensure formData.targetresolutiondate is in YYYY-MM-DD format
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        InputLabelProps={{ shrink: true }} // This ensures that the label floats when a date is selected
                        error={showFormValidate && formData.startdate == null}
                        helperText={showFormValidate && formData.startdate == null ? 'Start date is required' : ''}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} >
                      <TextField
                        name="enddate"
                        label="End Date"
                        variant="outlined"
                        type="date" // Set type to "date"
                        value={formData.enddate} // Ensure formData.targetresolutiondate is in YYYY-MM-DD format
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        InputLabelProps={{ shrink: true }} // This ensures that the label floats when a date is selected
                        error={showFormValidate && formData.enddate == null}
                        helperText={showFormValidate && formData.enddate == null ? 'End date is required' : ''}
                      />
                    </Grid>

                    <Grid item xs={2} sm={4} md={4} >
                      <Autocomplete
                        options={['On Track', 'In Progress', 'Pending', 'Paused', 'Completed', 'Cancelled', 'Missed']}
                        value={formData.status || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            status: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            margin="normal"
                            error={showFormValidate && formData.status == null}
                            helperText={showFormValidate && formData.status == null ? 'Status is required' : ''}
                          />
                        )}
                      />
                    </Grid>

                  </Grid>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={['Red', 'Orange', 'Green', 'Yellow', 'Gray', 'Blue']}
                        value={formData.health || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            health: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Health"
                            variant="outlined"
                            margin="normal"
                            error={showFormValidate && formData.health == null}
                            helperText={showFormValidate && formData.health == null ? 'Health is required' : ''}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>

                      <Autocomplete
                        name="blocked"
                        options={['Yes', 'No',]}
                        value={formData.blocked || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            blocked: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Blocked"
                            variant="outlined"
                            margin="normal"
                            error={showFormValidate && formData.blocked == ''}
                            helperText={showFormValidate && formData.blocked == '' ? 'Blocked is required' : ''}
                          />
                        )}
                      />

                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        name="category"
                        options={['Critical Path', 'Stretch Goal',]}
                        value={formData.category || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            category: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            variant="outlined"
                            margin="normal"
                            error={showFormValidate && formData.category == ''}
                            helperText={showFormValidate && formData.category == '' ? 'Category is required' : ''}
                          />
                        )}
                      />

                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        name="type"
                        options={['Feature Finisher', 'Incremental Delivery', 'Non-Code', 'Roadmap Milestone']}
                        value={formData.type || null}
                        onChange={(event, newValue) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            type: newValue,
                          }));
                        }}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type"
                            variant="outlined"
                            margin="normal"
                            error={showFormValidate && formData.type == ''}
                            helperText={showFormValidate && formData.type == '' ? 'Type is required' : ''}

                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <SelectSprint programboarddata={programBoardData} isViewMode={false} SyncWithParentState={handleChildStateChange}/>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography gutterBottom>Plannded Value: {formData.planndedvalue}</Typography>
                      <Slider
                        name="planndedvalue"
                        aria-label="Plannded Value"
                        defaultValue={3}
                        value={formData.planndedvalue}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        shiftStep={3}
                        step={1}
                        marks
                        min={1}
                        max={10}
                      />


                    </Grid>
                    <Grid item xs={6}>
                      <Typography gutterBottom>Delevered Value: {formData.deleveredvalue}</Typography>
                      <Slider
                        name="deleveredvalue"
                        aria-label="Delevered Value"
                        defaultValue={3}
                        value={formData.deleveredvalue}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        shiftStep={3}
                        step={1}
                        marks
                        min={1}
                        max={10}
                      />

                    </Grid>

                  </Grid>

                  <TextField
                    name="notes"
                    label="Notes"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.notes}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </form>
              ) : (
                <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>

                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChangee} aria-label="basic tabs example">
                      <Tab label="Objective" {...a11yProps(0)} />
                      <Tab label="Key Results" {...a11yProps(1)} />
                    </Tabs>
                  </Box>

                  {selectedRowData && (<CustomTabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>Title: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{selectedRowData.title}</Box></Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>Tire: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{selectedRowData.tire}</Box></Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Owner: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{selectedRowData.owner.username || selectedRowData.owner.firstname + " " + selectedRowData.owner.lastname}</Box></Typography>
                    </Box>


                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Description:</Typography>
                      <Typography variant="body2">{selectedRowData.description ? selectedRowData.description : "Not Available"}</Typography>
                    </Box>


                    <Box sx={{ flexGrow: 1, mb: 2 }}>
                      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                        <Grid item xs={2} sm={4} md={4}>
                          <Item>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Start date</Typography>
                            <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.startdate}</Typography>
                          </Item>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Item>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>End Date</Typography>
                            <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.enddate}</Typography>
                          </Item>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Item>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Status</Typography>
                            <Typography variant="body2" sx={{ color: 'text.primary' }}>{selectedRowData.status}</Typography>
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>

                    <Divider sx={{ mt: 2, mb: 2 }} />


                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>Health:</Typography>
                        <Typography variant="body2">{selectedRowData.health}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>Blocked:</Typography>
                        <Typography variant="body2">{selectedRowData.blocked}</Typography>
                      </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {selectedRowData.category && (
                          <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Category:</Typography>
                            <Typography variant="body2">{selectedRowData.category}</Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {selectedRowData.type && (
                          <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Type:</Typography>
                            <Typography variant="body2">{selectedRowData.type}</Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {selectedRowData.planndedvalue && (
                          <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Plannded Value:</Typography>
                            <Typography variant="body2">{selectedRowData.planndedvalue}</Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {selectedRowData.deleveredvalue && (
                          <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Delevered Value:</Typography>
                            <Typography variant="body2">{selectedRowData.deleveredvalue}</Typography>
                          </Box>
                        )}
                      </Grid>

                    </Grid>
                    <SelectSprint programboarddata={programBoardData} isViewMode={true} SyncWithParentState={handleChildStateChange}/>



                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Notes:</Typography>
                      <Typography variant="body2">{selectedRowData.notes ? selectedRowData.notes : "Not Available"}</Typography>
                    </Box>

                  </CustomTabPanel>)}
                  <CustomTabPanel value={value} index={1}>


                    <div style={{ display: 'flex', width: '100%', justifyContent: 'spaceBetween', alignItems: 'center', marginBottom: '10px' }}>
                      <Typography style={{ flex: 1 }} variant="h6">
                        Key Results
                      </Typography>
                      <div style={{ textAlign: 'right' }}>
                        <Button onClick={() => { 
                          setopenKr(true)
                          setFormDataKeyResults({
                            krtitle: "",
                            krdescription: "",
                            krstatus: "",
                            krplanndedvalue: "",
                            krdeleveredvalue: "",
                          })
                          }} variant='outlined' sx={{ textTransform: "capitalize" }} endIcon={<AddCircleOutlineIcon />}>
                          Add Key Results
                        </Button>
                      </div>
                    </div>
                  
                    <Box>
                      {selectedRowData && selectedRowData.keyresults && Object.keys(selectedRowData.keyresults).map((key) => {
                        return <Box sx={{ mb: 2, mt: 2 }}>
                          {/* <Typography>{selectedRowData.keyresults[key].krtitle}</Typography> */}
                          <Accordion expanded={expanded === key} onChange={handleChangeAccordion(key)}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {selectedRowData.keyresults[key].krtitle.length > 20 ? (selectedRowData.keyresults[key].krtitle.substring(0, 20) + "...").toLowerCase() : selectedRowData.keyresults[key].krtitle}
                              </Typography>
                              <Typography sx={{ color: 'text.secondary' }}>{selectedRowData.keyresults[key].krstatus}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                             <div>
                                <Box style={{ display: 'flex', justifyContent: 'space-between' }} >
                                  <Box sx={{ mb: 2 }}>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Title:</Typography>
                                    <Typography variant="body2">{
                                      selectedRowData.keyresults[key].krtitle

                                    }</Typography>
                                  </Box>
                                  <Box>
                                    <IconButton onClick={(event) => {
                                      event.stopPropagation();
                                      EditKeyResult(selectedRowData.keyresults[key],key)

                                    }}>
                                      <EditOutlinedIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>Description:</Typography>
                                  <Typography variant="body2">{selectedRowData.keyresults[key].krdescription ? selectedRowData.keyresults[key].krdescription : "Not Available"}</Typography>
                                </Box>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                  <Grid item xs={6}>
                                    <Box sx={{ mb: 2 }}>
                                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Status:</Typography>
                                      <Typography variant="body2">{selectedRowData.keyresults[key].krstatus ? selectedRowData.keyresults[key].krstatus : "Not Available"}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box sx={{ mb: 2 }}>
                                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Completion Persentage:</Typography>
                                      <Typography variant="body2">{selectedRowData.keyresults[key].krcompletionpersentage ? selectedRowData.keyresults[key].krcompletionpersentage : "Not Available"}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box sx={{ mb: 2 }}>
                                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Delevered Value:</Typography>
                                      <Typography variant="body2">{selectedRowData.keyresults[key].krdeleveredvalue ? selectedRowData.keyresults[key].krdeleveredvalue : "Not Available"}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box sx={{ mb: 2 }}>
                                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>Plannded Value:</Typography>
                                      <Typography variant="body2">{selectedRowData.keyresults[key].krplanndedvalue ? selectedRowData.keyresults[key].krplanndedvalue : "Not Available"}</Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </div>
                            </AccordionDetails>
                          </Accordion>


                        </Box>
                      })}
                    </Box>
                  </CustomTabPanel>

                </Container>
              )}
            </div>

          </SwipeableDrawer>
        </div>
      </div>
      <Dialog
        open={openKr}
        onClose={handleCloseKr}
        size="md"
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            handleCloseKr();
          },
        }}
      >
        <DialogTitle>Add new Key results</DialogTitle>
        <DialogContent>
        <form className="Addkeyresults">
                  
                  <TextField
                    name="krtitle"
                    label="Title"
                    variant="outlined"
                    type='text'
                    multiline
                    rows={1}
                    value={formDataKeyResults.krtitle}
                    onChange={handleChange2}
                    required
                    fullWidth
                    margin="normal"
                    key="krtitle"
                    autoFocus="autoFocus"
                    error={showFormValidatekr && !formDataKeyResults.krtitle.trim()}
                    helperText={showFormValidatekr && !formDataKeyResults.krtitle.trim() ? 'Title is required' : ''}
                  />


                  <TextField
                    name="krdescription"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formDataKeyResults.krdescription}
                    onChange={handleChange2}
                    fullWidth
                    required
                    margin="normal"
                    error={showFormValidatekr && !formDataKeyResults.krdescription.trim()}
                    helperText={showFormValidatekr && !formDataKeyResults.krdescription.trim() ? 'Description is required' : ''}
                  />


                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={['Open', 'Close']}
                        value={formDataKeyResults.krstatus || null}
                        onChange={(event, newValue) => {
                          setFormDataKeyResults((prevData) => ({
                            ...prevData,
                            krstatus: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            margin="normal"
                            error={showFormValidatekr && !formDataKeyResults.krstatus.trim()}
                            helperText={showFormValidatekr && !formDataKeyResults.krstatus.trim() ? 'Status is required' : ''}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="krcompletionpersentage"
                        label="Completion Persentage"
                        type="number"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formDataKeyResults.krcompletionpersentage}
                        onChange={handleChange2}
                        required
                        fullWidth
                        margin="normal"
                        error={showFormValidatekr && !formDataKeyResults.krcompletionpersentage.trim()}
                        helperText={showFormValidatekr && !formDataKeyResults.krcompletionpersentage.trim() ? 'Completion Persentage is required' : ''}
                      />
                    </Grid>
                  </Grid>




                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <TextField
                        name="krplanndedvalue"
                        label="Plannded Value"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formDataKeyResults.krplanndedvalue}
                        onChange={handleChange2}
                        required
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="krdeleveredvalue"
                        label="Delevered Value"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formDataKeyResults.krdeleveredvalue}
                        onChange={handleChange2}
                        required
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseKr}>Cancel</Button>
          <Button type="submit" variant="contained" sx={{textTransform:'capitalize'}} onClick={(event)=>{
            event.preventDefault();
            event.stopPropagation();
            SaveKeyResult()
            }}>Save Key Result</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbaropen} autoHideDuration={6000} onClose={() => {
        setSnackbaropen(false);
      }}>
        <Alert
          onClose={() => {
            setSnackbaropen(false);
          }}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Record updated successfully!
        </Alert>
      </Snackbar>
      <ShowError Error={showError} />
      <Spinner spin={spin} />
      <SharePopup
        show={showshare}
        hideForm={HideSharePopup}
        userid={user.userid}
        docid={selectedRowData ? selectedRowData.id : null}
        docData={selectedRowData}
        doctype="okrs"
        collectionname="okrs"
        subscription={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription}
        message="Select users and add them to a team, enabling you to share access to other documents with the entire team at once."
      />
      
    </>
  );
}

export default Okrs;