import React from 'react'
import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import ShowError from "../ShowError/ShowError";
import { useGlobalState } from "../../../GlobalProvider"
import { getErrorObject, postlog } from '../../../functions/Common';
import ProfileMenu from "../../ProfileMenu/ProfileMenu"
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {  createTheme, ThemeProvider, Tooltip } from '@mui/material';
const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Navbar = ({ user, toggleSidebar }) => {
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [activeNotificationsCount, setactiveNotificationsCount] = useState();
  const { state } = useGlobalState();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    async function getNotificationData() {
      try {
        if (state.notificationsglobaldata != undefined) {
          setactiveNotificationsCount(state.notificationsglobaldata.length);
        }
      } catch (err) {
        setShowError({ show: true, errordata: getErrorObject(err, user, "Navbar", "getNotificationData") });
      }
    }
    getNotificationData();
  }, [state.notificationsglobaldata]);

  const handleToggle = () => {
    toggleSidebar();
  }

  const lightTheme = createTheme({
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff', // Set a lighter background color
          },
        },
      },
    },
  });

  return (
    <>
      <AppBar position="fixed" open={open} sx={{ backgroundColor: '#f8f9fe', color: 'black', height:'58px' }} elevation={0}>
        <Toolbar sx={{ justifyContent: 'space-between' }}> 
          <div>
            <Tooltip title='Show Sidebar'>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggle}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            </Tooltip>
            {/* <Typography variant="h6" noWrap component="div">
            Agilebin
          </Typography> */}
            <img src={process.env.PUBLIC_URL + "/images/Agilebin-logo.png"} alt="Agilebin-logo" style={{ width: "100px" }} className="mx-2" />
            
          </div>
         
          <div>
            <Tooltip title='Notifications'>
            <Link
              to="/notifications"
              className=" m-1 position-relative"
              title="Notifications"
            >

              <IconButton variant="text" size="small" >
                <Badge badgeContent={activeNotificationsCount} color="error">
                  <NotificationsIcon sx={{ color: '#5f6368', fontSize: '1.3rem' }} />
                </Badge>
              </IconButton>
            </Link>
            </Tooltip>
            <ProfileMenu user={user} />
          </div>
        </Toolbar>
      </AppBar>
      {/* <nav
        className="navbar navbar-expand-lg fixed-top table-shadow p-0"
        style={{ backgroundColor: "white", minHeight:"58px"  }}
      >
        <div className="container-fluid">
          <button className="navbar-toggler-resuable navbar-toggler mx-2" type="button" onClick={handleToggle}>
            <span className="navbar-toggler-icon"></span>
          </button>
      
          <img src={process.env.PUBLIC_URL + "/images/Agilebin-logo.png"} alt="Agilebin-logo" style={{ width: "120px" }} className="mx-2" />
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            <ul className="navbar-nav mb-2 mb-lg-0 navbar-right">
              <li className="nav-item">
                <Link
                  to="/notifications"
                  className=" m-1 position-relative"
                  title="Notifications"
                >

                   <IconButton variant="text"  size="small" >
<Badge badgeContent={activeNotificationsCount} color="error">
                               <NotificationsIcon  sx={{ color: '#5f6368' }}/>
                  </Badge>
                  </IconButton>
                </Link>
                <ProfileMenu user={user}/>
              </li>
            </ul>
          </div>
          <div></div>
        </div>
      </nav> */}
      <ShowError Error={showError} />
      {/* <Spinner spin={spin} /> */}
    </>
  );
};

export default Navbar;