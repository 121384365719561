import React from 'react';
import { useEffect,useState } from 'react';
import { firestore } from "../../../firebase";
import { Notify, postlog, getErrorObject } from "../../../functions/Common";
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDocs,
  Timestamp,
  where,
  setDoc,
  orderBy,
  limit,
} from "@firebase/firestore";
import { Button, Dialog, DialogContent, DialogActions, TextField, Tabs, Tab, IconButton, Box  } from '@mui/material';
import { Facebook, Twitter, LinkedIn, WhatsApp, Message } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Recommendations = ({ user, open, handleClose }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [spin, setSpin] = useState(false);
  const [snackbaropen, setSnackbaropen] = useState(false);
  const [showFormValidate, setShowFormValidate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    fromuserid: "",
    fromuserfirstname: "",
    fromuserlastname: "",
    sharetype:"",
    message: "I've been using Agilebin and really like it. I recommend you give it a try too!",
    toemails: "",
    socialmedia: "",
  });

  useEffect(() => {
    // Reset form data when the dialog is closed
    if (!open) {
      setFormData({
        fromuserid: "",
        fromuserfirstname: "",
        fromuserlastname: "",
        sharetype: "",
        message: "I've been using Agilebin and really like it. I recommend you give it a try too!",
        toemails: "",
      });
    }
  }, [open]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCloseDialog = () => {
    handleClose(false);
    setShowFormValidate(false);
  };

  async function saveRecommendations(data) {
    setSpin(true);
    try {

      let toEmailsArray = [];
      if (formData.toemails) {
        toEmailsArray = formData.toemails.split(',').map(email => email.trim());
      }

      let shareType;
      if (selectedTab === 0) {
        shareType = "email";
      } else if (selectedTab === 1) {
        shareType = "social";
      }

      if (!data.id) {
        const docRef = doc(collection(firestore, "recommendations"));
        await setDoc(docRef, {
          id: docRef.id,
          fromuserid: user.userid,
          fromuserfirstname: user.firstname,
          fromuserlastname: user.lastname,
          sharetype: shareType,
          toemails: toEmailsArray,
          message:  formData.message,
          createddate: Timestamp.fromDate(new Date()),
          updateddate: Timestamp.fromDate(new Date()),
          createdbyuserid: user.userid,
          createdbyusername: user.username,
          createdbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          createdbyuseremail: user.useremail,
          updatedbyuserid: user.userid,
          updatedbyusername: user.username,
          updatedbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          updatedbyuseremail: user.useremail,

        });
     //   postlog("write risks", { component: "recommendations", function: "saveRecommendations", collection: "recommendations" });
      } else {
      
        const docRef = doc(collection(firestore, "recommendations"), data.id);

        await setDoc(docRef, {
          fromuserid: user.userid,
          fromuserfirstname: user.firstname,
          fromuserlastname: user.lastname,
          sharetype: shareType,  
          toemails: toEmailsArray,
          message: formData.message,
          updateddate: Timestamp.fromDate(new Date()),
          updatedbyuserid: user.userid,
          updatedbyusername: user.username,
          updatedbyuserprofile:
            user.profilepicture != undefined ? user.profilepicture : "",
          updatedbyuseremail: user.useremail,
        },
          { merge: true }
        );


        //postlog("write recommendations", { component: "recommendations", function: "saveRecommendations", collection: "recommendations", uid: data.id });

      }
      setSpin(false);
    } catch (err) {

      setShowError({ show: true, errordata: getErrorObject(err, user, "Recommendations", "saveRecommendations") });
      setSpin(false);
    }
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.toemails) {
      setShowFormValidate(true);
  } else {
      saveRecommendations(formData);

      const toEmailsArray = formData.toemails.split(',').map(email => email.trim());
      Notify(
        "Recommendation for Agilebin",
        formData.message,
        toEmailsArray,
        'administrator',
        true,
        true,
        "recommendations",
        "id",
        user.firstname+" "+user.lastname,
      );
      setSnackbaropen(true);
      handleCloseDialog();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleShareLink = (socialMedia) => {
    let shareLink = "https://www.agilebin.com/"; // Your website link
  
    switch (socialMedia) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareLink)}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareLink)}`, '_blank');
        break;
      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(formData.message + ' ' + shareLink)}`, '_blank');
        break;
      default:
        break;
    }
  };
  

  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog} fullWidth={true} maxWidth='sm'>
      <DialogContent style={{ minHeight: "340px" }}>
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="Share via Email" />
          <Tab label="Share on Socials" />
        </Tabs>
        {selectedTab === 0 && (
          <div style={{marginTop: "20px"}}>
            <TextField
              autoFocus
              name="toemails"
              margin="dense"
              id="email"
              label="To Emails, Comma separated emails"
              type="email"toemails
              fullWidth
              multiline
              rows={1}
              variant="outlined"
              value={formData.toemails}
              onChange={handleChange}
              required
              error={showFormValidate && !formData.toemails}
              helperText={showFormValidate && !formData.toemails ? 'Please enter valid email addresses' : ''}
            />
            <TextField
              name="message"
              margin="dense"
              id="Message"
              label="Message"
              type="Message"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={formData.message}
              onChange={handleChange}
            />
             <DialogActions sx={{marginTop: '15px'}}>
             <Button onClick={handleCloseDialog} sx={{ textTransform: 'none' }} >Cancel</Button>
        <Button onClick={handleSubmit} color="primary" size='medium' variant='contained' sx={{textTransform:"none"}}>
          Send
        </Button>
      </DialogActions>
          </div>
        )}
        {selectedTab === 1 && (
          <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: '80px'}}>
          <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", margin: "5px" }} >
              <IconButton aria-label="Share on Facebook" color="primary" onClick={() => handleShareLink('facebook')}  >
                <Facebook  style={{ fontSize: 30 }}/>
              </IconButton>
            </div>
            <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", margin: "5px" }}>
              <IconButton aria-label="Share on Twitter" style={{ color: "#1DA1F2" }} onClick={() => handleShareLink('twitter')}>
                <Twitter  style={{ fontSize: 30 }} />
              </IconButton>
            </div>
            <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", margin: "5px" }}>
              <IconButton aria-label="Share on LinkedIn" style={{ color: "#0077B5" }} onClick={() => handleShareLink('linkedin')}>
                <LinkedIn   style={{ fontSize: 30 }}/>
              </IconButton>
            </div>
            <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", margin: "5px" }}>
              <IconButton aria-label="Share on WhatsApp" style={{ color: "#25D366" }} onClick={() => handleShareLink('whatsapp')}>
                <WhatsApp   style={{ fontSize: 30 }}/>
              </IconButton>
            </div>
          </Box>
        )}
      </DialogContent>
     
    </Dialog>

    <Snackbar open={snackbaropen} autoHideDuration={6000} onClose={() => {
                setSnackbaropen(false);
            }}>
                <Alert
                    onClose={() => {
                        setSnackbaropen(false);
                    }}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Email has been sent successfully!
                </Alert>
            </Snackbar>
            </>
  );
};

export default Recommendations;