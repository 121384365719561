import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = ({ spin }) => {
  if (spin) {
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center"
          id="spinner"
          style={{
            height: "100vh",
            width: "100vw",
            zIndex: "1000",
            position: "fixed",
            top: "0",
            backgroundColor : "rgba(248, 249, 254, 0.2  )",
          }}
        >
       <CircularProgress />
        </div>
      </>
    );
  } else {
    <div>
    </div>;
  }
};

export default Spinner;
