import React from "react";
import { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    getDocs,
    Timestamp,
    where,
    setDoc,
    orderBy,
    limit,
    runTransaction
} from "@firebase/firestore";
import { useParams, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider"
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/Share/share";
import { showdate, getErrorObject, getUserDetails, postlog } from "../../functions/Common";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container, Card, FormControlLabel, Tooltip, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Avatar, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Autocomplete from '@mui/material/Autocomplete';
import SharePopup from "../Reusable/Share/share";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { logDOM } from "@testing-library/react";

const DailyScrum = ({ user }) => {
    const { state } = useGlobalState();
    const [showshare, setShowShare] = useState(false);
    const [showError, setShowError] = useState({ show: false, errordata: {} });
    const [spin, setSpin] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [data, setData] = useState();
    const [showDocumentMissing, setshowDocumentMissing] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [snackbaropen, setSnackbaropen] = useState(false);
    const [showFormValidate, setShowFormValidate] = useState(false);
    const [showFormFilterValidate, setShowFormFilterValidate] = useState(false);
    const [formData, setFormData] = useState({
        haveimpediments: "",
        whatiamplanning: "",
        haveimpediments: "",
        whatidid: "",
        date: "",
        haveimpediments: false,
        team: "",
    });
    const [filter, setFilter] = useState({
        date: new Date().toISOString().slice(0, 10),
        selectedteam: null,
    });
    const [immediates, setImmediates] = useState([]);
    const navigate = useNavigate();



    useEffect(() => {
        if (state && state.currentteam && state.currentteam.teamid != "" && state.currentteam.teamid != "none") {
            setFilter((prevData) => ({
                ...prevData,
                selectedteam: {
                    id: state.currentteam.teamid,
                    name: state.currentteam.teamname,
                },
            }));
        }
    }, [state.currentteam]);

    async function saveDependencies(data) {
        setSpin(true);
        try {

            if (selectedRowData == null) {

                try {
                    const docRef = doc(collection(firestore, "dailyscrums"));
                    await setDoc(docRef, {
                        id: docRef.id,
                        team: formData.team,
                        date: formData.date,
                        whatidid: formData.whatidid,
                        whatiamplanning: formData.whatiamplanning,
                        haveimpediments: formData.haveimpediments,
                        teamid: formData.team.id,
                        level: "restricted",
                        user: getUserDetails(user),
                        users: formData.team.users,
                        usersdetails: formData.team.usersdetails,
                        createddate: Timestamp.fromDate(new Date()),
                        updateddate: Timestamp.fromDate(new Date()),
                        createdbyuserid: user.userid,
                        createdbyusername: user.username,
                        createdbyuserprofile:
                            user.profilepicture != undefined ? user.profilepicture : "",
                        createdbyuseremail: user.useremail,
                        updatedbyuserid: user.userid,
                        updatedbyusername: user.username,
                        updatedbyuserprofile:
                            user.profilepicture != undefined ? user.profilepicture : "",
                        updatedbyuseremail: user.useremail,

                    }).then(() => {
                        if (formData.haveimpediments) {
                            navigate(`/impediments`, { state: { dailyscrum: true, dailyscrumid: docRef.id } });
                        }
                    })

                    
                    setImmediates([]);
                    setSelectedRowData({
                        id: docRef.id,
                        team: formData.team,
                        date: formData.date,
                        whatidid: formData.whatidid,
                        whatiamplanning: formData.whatiamplanning,
                        haveimpediments: formData.haveimpediments,
                        teamid: formData.team.id,
                        level: "restricted",
                        user: getUserDetails(user),
                        users: formData.team.users,
                        usersdetails: formData.team.usersdetails,
                        createddate: Timestamp.fromDate(new Date()),
                        updateddate: Timestamp.fromDate(new Date()),
                        createdbyuserid: user.userid,
                        createdbyusername: user.username,
                        createdbyuserprofile:
                            user.profilepicture != undefined ? user.profilepicture : "",
                        createdbyuseremail: user.useremail,
                        updatedbyuserid: user.userid,
                        updatedbyusername: user.username,
                        updatedbyuserprofile:
                            user.profilepicture != undefined ? user.profilepicture : "",
                        updatedbyuseremail: user.useremail,
                    })
                  //  postlog("write dependencies", { component: "dependencies", function: "saveDependencies", collection: "dependencies" });
        postlog("create dailyscrum", { component: "dailyScrum", function: "saveDepedencies", collection: "dailyscrum" });


                } catch (e) {
                    setShowError({ show: true, errordata: getErrorObject(e, user, "Risks", "saveRisks") });
                }
            } else {
                // Handle updating an existing ticket

                const docRef = doc(collection(firestore, "dailyscrums"), selectedRowData.id);
                await setDoc(docRef, {
                    team: formData.team,
                    date: formData.date,
                    whatidid: formData.whatidid,
                    whatiamplanning: formData.whatiamplanning,
                    haveimpediments: formData.haveimpediments,
                    teamid: formData.team.id,
                    users: [user.userid],
                    usersdetails: { [user.userid]: getUserDetails(user) },
                    updatedbyuserid: user.userid,
                    updatedbyusername: user.username,
                    updatedbyuserprofile:
                        user.profilepicture != undefined ? user.profilepicture : "",
                    updatedbyuseremail: user.useremail,
                },
                    { merge: true }
                );


               // postlog("write dependencies", { component: "dependencies", function: "saveDependencies", collection: "dependencies", uid: data.id });

            }
            setSpin(false);
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Dependencies", "saveDependencies") });
            setSpin(false);
        }
    }

    const showForm = (data) => {
        try {
            if (data != null) {
                const dtt = new Date(data.date.seconds * 1000);
                setFormData({
                    id: data.id,
                    team: data.team,
                    date: data.date,
                    whatidid: data.whatidid,
                    whatiamplanning: data.whatiamplanning,
                    haveimpediments: data.haveimpediments,
                    date: dtt.toISOString().slice(0, 10),
                });
            } else {
                setIsEditMode(true);
                setFormData({
                    id: "",
                    team: "",
                    whatidid: "",
                    whatiamplanning: "",
                    haveimpediments: "",
                    date: new Date().toISOString().slice(0, 10),
                });
                setSelectedRowData(null);
            }
            handleDrawerOpen();
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Dependencies", "showForm") });
            setSpin(false);
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!formData.whatiamplanning.trim() || !formData.date.trim() || formData.whatidid == null) {
            setShowFormValidate(true);
        } else {
            saveDependencies(formData);
            setSnackbaropen(true);
            setIsEditMode(false);
        }

    };


    // Function to handle form input change
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChange2 = (event) => {
        const { name, value } = event.target;
        setFilter((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: checked
        }));
    };

    const handleSidebar = () => {
        try {
            setIsOpen(!isOpen);
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Dependencies", "handleSidebar") });
            setSpin(false);
        }
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        if (data == undefined ||selectedRowData == null) {
            setDrawerOpen(false);
        }
        setIsEditMode(false);
        setShowFormValidate(false);
    };

    const HideSharePopup = () => {
        try {
            setShowShare(false);
        } catch (err) {
            setShowError({
                show: true,
                errordata: getErrorObject(err, user, "Dependencies", "HideSharePopup"),
            });
            setSpin(false);
        }
    };

    const ShowSharePopup = () => {
        try {
            setShowShare(true);
            setShowError({
                show: true,
                errordata: getErrorObject("err", user, "Dependencies", "HideSharePopup"),
            });
        } catch (err) {

            setShowError({
                show: true,
                errordata: getErrorObject(err, user, "Dependencies", "ShowSharePopup"),
            });
            setSpin(false);
        }
    };

    const handleRowClick = async (rowData) => {
        setSelectedRowData(rowData);
        setFormData(rowData);
        handleDrawerOpen();
        const q = await query(collection(firestore, "impediments"), where("dailyscrumid", "==", rowData.id), limit(100));
        const snapshot = await getDocs(q);

        const imprements = [];
        snapshot.forEach((doc) => {
            const newdata = doc.data();
            imprements.push(newdata);
            setSpin(false);
        });
        setImmediates(imprements);

    };

    const toggleEditMode = () => {
        setIsEditMode((prevMode) => !prevMode);
    };

    const showFilteredDocuments = () => {
        if (filter.date == "" || filter.selectedteam == null) {
            setShowFormFilterValidate(true);
            setData();
        } else {
            async function fetchFilteredDailyScrumData() {
                setSpin(true);
                try {
                    const q = await query(
                        collection(firestore, "dailyscrums"),
                        where("teamid", "==", filter.selectedteam.id),
                        where("date", "==", filter.date),
                        orderBy("createddate", "desc"),
                        limit(5)
                    );
                    let unsubscribe = onSnapshot(q, (snapshot) => {
                        const dependenciesData = [];
                        snapshot.forEach((doc) => {
                            const newdata = doc.data();
                            dependenciesData.push(newdata);
                        });
                        setData(dependenciesData);
                        setSpin(false);
                    });
                    setSpin(false);
                } catch (error) {
                    setShowError({ show: true, errordata: getErrorObject(error, user, "Dependencies", "fetchDependencies") });
                    setSpin(false);
                }
            }
            fetchFilteredDailyScrumData();
        }
    }
    return (
        <>
            <Navbar user={user} toggleSidebar={handleSidebar} />
            <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
            <div className={`sideNav-top listPage-transition  ${isOpen ? "side-margin" : "container"}`} style={{ width: '75%' }}>
                <div className="row g-0 mb-3">
                    <div className="d-flex gap-2 align-items-center mb-2" >
                        <div>
                            <Typography variant="h6">
                                Daily Scrum
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Click on 'New Entry' to add a todays scrum
                            </Typography>
                        </div>
                    </div>
                </div>

                <div>
                    <Box className='row' style={{ padding: '0 40px', marginBottom: '20px' }}>
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6 text-end">
                            <Button variant="contained" size="large" sx={{ textTransform: 'capitalize' }} onClick={() => showForm(null)}>
                                New Entry
                            </Button>
                        </div>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                <TextField
                                    name="date"
                                    label="Date"
                                    variant="outlined"
                                    type="date" // Set type to "date"
                                    value={filter.date}
                                    onChange={handleChange2}
                                    fullWidth
                                    required
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    error={showFormFilterValidate && filter.date === ""}
                                    helperText={showFormFilterValidate && filter.date === "" ? 'Date is required' : ''}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {state.teamsglobaldata != null && (

                                    <Autocomplete
                                        options={state.teamsglobaldata.map(team => ({ id: team.id, name: team.name, users: team.users, usersdetails: team.usersdetails }))}
                                        value={filter.selectedteam || null}
                                        onChange={(event, newValue) => {
                                            setFilter((prevData) => ({
                                                ...prevData,
                                                selectedteam: newValue,
                                            }));
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select a team"
                                                margin="normal"
                                                required
                                                error={showFormFilterValidate && filter.selectedteam === null}
                                                helperText={showFormFilterValidate && filter.selectedteam === null ? 'Team is required' : ''}
                                            />
                                        )}
                                    />

                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" size="large" sx={{ textTransform: 'capitalize' }} onClick={() => { showFilteredDocuments() }}>
                                    Show
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {data && data.length != 0 ? (
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow >
                                        <TableCell>Date</TableCell>
                                        <TableCell>Creator</TableCell>
                                        <TableCell>What did i do yesterday?</TableCell>
                                        <TableCell>What i am planning to do today?</TableCell>
                                        <TableCell>Impediments</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((dependency) => (
                                        <TableRow key={dependency.id} onClick={() => handleRowClick(dependency)} hover style={{ height: '50px' }}>
                                            <TableCell>{dependency.date}</TableCell>
                                           
                                            <TableCell>  
                                                <Tooltip title={dependency.user.firstname + " " + dependency.user.lastname} style={{ display: "" }}>
                                                <div className="d-flex gap-2 align-items-center" style={{ padding: '10px' }}>
                                            {state.userglobaldata.profilepicture && state.userglobaldata.profilepicture !== "" ? (
                                                <Avatar alt="Profile Picture" sx={{ width: 34, height: 34 }} src={state.userglobaldata.profilepicture} />
                                            ) : (
                                                <AccountCircleIcon sx={{ fontSize: '2.3rem', color: '#5f6368' }} />
                                            )}
                                            <div>
                                                <Typography >
                                                    {dependency.createdbyusername}
                                                </Typography>
                                            </div>
                                        </div>
                                            </Tooltip>
                                            
                                            </TableCell>

                                            <TableCell>{dependency.whatidid}</TableCell>
                                            <TableCell>{dependency.whatiamplanning}</TableCell>
                                            <TableCell>{dependency.haveimpediments ? "Yes" : "No"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Card style={{ textAlign: 'center', color: '#a9a3b7', padding: '3rem 0rem' }}>
                            <Typography variant="h5" gutterBottom>
                                {data ? "No one has created daily scrum notes for this team and date yet." : "Please select date and team to see daily scrums of your team."}
                            </Typography>
                            <Typography variant="h7" gutterBottom>
                                When someone does, they will be show up here.
                            </Typography>
                        </Card>
                    )}


                    {/* Drawer Component */}
                    <SwipeableDrawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={() => {
                            setDrawerOpen(false)
                            handleDrawerClose();
                        }}
                        onOpen={() => { }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <div style={{ width: "50vw", padding: '20px' }}>

                            <Box sx={{ position: 'fixed', top: '0px', backgroundColor: 'white', width: '50vw', padding: '10px 8px', paddingRight: '35px', zIndex: 999 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="h6" gutterBottom >
                                        <div className="d-flex gap-2 align-items-center" style={{ padding: '10px' }}>
                                            {state.userglobaldata.profilepicture && state.userglobaldata.profilepicture !== "" ? (
                                                <Avatar alt="Profile Picture" sx={{ width: 34, height: 34 }} src={state.userglobaldata.profilepicture} />
                                            ) : (
                                                <AccountCircleIcon sx={{ fontSize: '2.3rem', color: '#5f6368' }} />
                                            )}
                                            <div>
                                                <Typography>
                                                    {`${state.userglobaldata.firstname} ${state.userglobaldata.lastname}`}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                        {isEditMode && (
                                            <Button
                                                onClick={handleDrawerClose}
                                                color='primary'
                                                sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                                                variant="outlined"
                                            >
                                                Cancel
                                            </Button>
                                        )}
                                        {isEditMode && (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                sx={{
                                                    display: 'inline',
                                                    width: 'auto',
                                                    textTransform: 'capitalize',
                                                    marginRight: '14px',
                                                    flex: '0 0 auto'
                                                }}>
                                                Save Scrum
                                            </Button>
                                        )}
                                        {!isEditMode && (<Button
                                            onClick={toggleEditMode}
                                            color='primary'
                                            sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                                            variant="outlined"
                                            size='medium'
                                            startIcon={<EditIcon />}
                                        >
                                            Edit
                                        </Button>)}
                                        {!isEditMode && selectedRowData?.usersdetails?.[user.userid]?.isowner && (
                                            <Button
                                                onClick={ShowSharePopup}
                                                color='primary'
                                                sx={{ display: 'inline', width: 'auto', textTransform: 'capitalize', marginRight: '8px' }}
                                                variant="outlined"
                                                size='medium'
                                                style={{ display: selectedRowData && selectedRowData.usersdetails && selectedRowData.usersdetails[user.userid] && selectedRowData.usersdetails[user.userid].isowner ? 'flex' : 'none' }}
                                                startIcon={<PersonAddAltIcon />}
                                            >
                                                Share
                                            </Button>
                                        )}
                                        {!isEditMode && (<Button
                                            onClick={() => {
                                                setDrawerOpen(false)
                                                handleDrawerClose();
                                            }}
                                            color='primary'
                                            sx={{ width: 'auto', textTransform: 'capitalize', marginRight: '25px' }}
                                            variant="outlined"
                                            size='medium'
                                            startIcon={<CloseIcon />}
                                        >
                                            Close
                                        </Button>)}
                                    </div>
                                </div>
                            </Box>
                            {isEditMode ? (
                                <form onSubmit={handleSubmit}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '45px' }}>
                                        <Grid item xs={6} >
                                            <TextField
                                                name="date"
                                                label="Date"
                                                variant="outlined"
                                                type="date" // Set type to "date"
                                                value={formData.date}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                InputLabelProps={{ shrink: true }}
                                                error={showFormValidate && formData.date === ""}
                                                helperText={showFormValidate && formData.date === "" ? 'Date is required' : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {state.teamsglobaldata != null && (
                                                <Autocomplete
                                                    options={state.teamsglobaldata.map(team => ({ id: team.id, name: team.name, users: team.users, usersdetails: team.usersdetails }))}
                                                    value={formData.team || null}
                                                    onChange={(event, newValue) => {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            team: newValue,
                                                        }));
                                                    }}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Select a team"
                                                            margin="normal"
                                                            required
                                                            error={showFormValidate && formData.team === null}
                                                            helperText={showFormValidate && formData.team === null ? 'Team is required' : ''}
                                                        />
                                                    )}
                                                />)}
                                        </Grid>
                                    </Grid>

                                    <TextField
                                        name="whatidid"
                                        label="What did i do yesterday?"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        value={formData.whatidid}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                        error={showFormValidate && !formData.whatidid.trim()}
                                        helperText={showFormValidate && !formData.whatidid.trim() ? 'This field is required' : ''}
                                    />

                                    <TextField
                                        name="whatiamplanning"
                                        label="What i am planning to do today?"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        value={formData.whatiamplanning}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                        error={showFormValidate && !formData.whatiamplanning.trim()}
                                        helperText={showFormValidate && !formData.whatiamplanning.trim() ? 'This field is required' : ''}
                                    />


                                    {selectedRowData == null && (
                                        <FormControlLabel
                                            control={<Checkbox name="haveimpediments" checked={formData.haveimpediments} onChange={handleCheckboxChange} />}
                                            label="I have impediments"
                                        />
                                    )}
                                </form>
                            ) : (
                                <Container maxWidth="md" sx={{ mt: 6, mb: 4 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                        </Grid>
                                        <Grid item xs={6}>

                                        </Grid>
                                    </Grid>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Date: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{formData.date}</Box></Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Team: <Box component="div" sx={{ display: 'inline', color: 'text.primary' }}>{formData.team && formData.team.name}</Box></Typography>
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>What did i do yesterday?</Typography>
                                        <Typography variant="body2">{formData.whatidid}</Typography>
                                    </Box>

                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>What i am planning to do? </Typography>
                                        <Typography variant="body2">{formData.whatiamplanning}</Typography>
                                    </Box>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Impediments:
                                    </Typography>
                                    {immediates && immediates.length == 0 && (<Typography variant="body2">Not Avalable</Typography>)}
                                    {immediates && immediates.map((e, index) => (
                                        <TableRow hover role="button" sx={{ height: "30px", width: '100%', padding: '10px 0px', display: "flex", alignItems: "center" }} onClick={() => { navigate(`/impediments`, { state: { dailyscrum: false, immediateid: e.id } }); }}>{index + 1}.{e.title}</TableRow>
                                    ))}

                                    <Button sx={{ textTransform: 'capitalize',mt:2 }} variant="outlined" onClick={() => { navigate(`/impediments`, { state: { dailyscrum: true, dailyscrumid: selectedRowData.id } }); }}>Add Impediment</Button>
                                    {formData.description && (
                                        <Box sx={{ mt: 2, mb: 2 }}>
                                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>Description:</Typography>
                                            <Typography variant="body2">{formData.description}</Typography>
                                        </Box>
                                    )}
                                </Container>
                            )}


                        </div>

                    </SwipeableDrawer>
                </div>
            </div>
            <Snackbar open={snackbaropen} autoHideDuration={6000} onClose={() => {
                setSnackbaropen(false);
            }}>
                <Alert
                    onClose={() => {
                        setSnackbaropen(false);
                    }}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Record updated successfully!
                </Alert>
            </Snackbar>
            <ShowError Error={showError} />
            <Spinner spin={spin} />
            <SharePopup
                show={showshare}
                hideForm={HideSharePopup}
                userid={user.userid}
                docid={selectedRowData ? selectedRowData.id : null}
                docData={selectedRowData}
                doctype="dependencies"
                collectionname="dependencies"
                subscription={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription}
                message="Select users and add them to a team, enabling you to share access to other documents with the entire team at once."
            />
        </>
    );
}

export default DailyScrum;