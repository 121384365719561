import React from "react";
import { useEffect } from "react";
import { firestore } from "../../../firebase";
import { storeError } from "../../../functions/Common"
const ShowError = ({ Error }) => {
  useEffect(() => {
    // Check if the state has changed from false to true
    if (Error.show === true) {
      storeError(Error.errordata);
    }
  }, [Error.show]);

  if (Error !== undefined) {
    if (Error.show) {


      return (
        <div className="center-container" style={{ zIndex: 10000, position: "fixed", top: "0", height: "100vh", width: "100vw", background: "white", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="card">
            <div className="card-body">
              <div className="missing-document">
                <div className="message">
                  <h2><i className="fas fa-times-circle error-icon" style={{ fontSize: '2rem', color: "#656668" }}></i> Error</h2>
                  <p style={{ paddingLeft: "40px" }}>Oops! Something went wrong. An error has occurred. Please try again later or refresh the page.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

  }
};

export default ShowError;
