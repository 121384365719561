import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { firestore, functions } from "../../firebase";
import {
  query,
  collection,
  onSnapshot,
  setDoc,
  doc,
  where,
  limit,
} from "@firebase/firestore";
import {
  httpsCallable,
} from "@firebase/functions";
import Spinner from "../Reusable/Spinner/Spinner";
import ShowError from "../Reusable/ShowError/ShowError";
import Sidebar from "../Reusable/Sidebar/Sidebar";
import Navbar from "../Reusable/Navbar/Navbar";
import { getErrorObject, postlog } from "../../functions/Common";
import { useNavigate } from "react-router-dom"
import Offer from "../Reusable/Offer/Offer";
import { useGlobalState } from "../../GlobalProvider";
import { Button, Card, Typography, CardContent, Paper } from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
function PricingComponent({ user }) {
  const [formData, setFormData] = useState({
    quantity: 0,
  });
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [spin, setSpin] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const [subscriptionData, setSubscriptionData] = useState();
  const [activeTab, setActiveTab] = useState('pro');
  const { state } = useGlobalState();

  let unsubscribeCustomer = null;
  useEffect(() => {
    let unsubscribe = null;
    const getSubscription = async () => {
      setSpin(true);
      try {
        const q = await query(
          collection(firestore, "customers/" + user.userid + "/subscriptions"),
          where("status", "==", "active"),
          limit(1),
        );
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = await onSnapshot(q, (snapshot) => {
          snapshot.forEach((doc) => {
            const newdata = doc.data();
            setSubscriptionData(newdata)
          });
        //  postlog("read customers", { component: "subscriptions", function: "getSubscription", collection: "customers", uid: user.userid });

          if (user.userid != undefined && user.userid != "" && user.userid != null) {
            postlog("open subscriptions", { component: "subscriptions", function: "handleSubmit", collection: "customers", uid: user.userid });
          }

        });

        setSpin(false);
      } catch (err) {
        setShowError({
          show: true,
          errordata: getErrorObject(err, user, "Subscription", "getSubscription"),
        });
        setSpin(false);
      }
    };

    getSubscription();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

  }, []);

  const handleSubmitPro = async () => {
    try {
      postlog("visitedStripe subscriptions", { component: "subscriptions", function: "handleSubmit", collection: "customers", uid: user.userid });
      setSpin(true);
      let qty = 1;
      if (parseInt(quantity)) {
        qty = parseInt(quantity);
      }
      const customerdocref = doc(collection(firestore, "customers", user.userid, "checkout_sessions"));
      await setDoc(
        customerdocref,
        {
          price: isYearly ? 'price_1OdoqpHYNsdNf7XODArKPKy9' : 'price_1OdoqpHYNsdNf7XOCl932MEU',
          success_url: window.location.origin + '/subscriptions',
          cancel_url: window.location.origin + '/subscriptions',
          allow_promotion_codes: true,
          quantity: qty,
        },
        { merge: true }
      );
      //postlog("write customers", { component: "subscriptions", function: "handleSubmit", collection: "customers", uid: user.userid });


      if (unsubscribeCustomer) { unsubscribeCustomer(); }
      unsubscribeCustomer = onSnapshot(customerdocref, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          setShowError({
            show: true,
            errordata: getErrorObject(error, user, "Subscription", "handleSubmit"),
          });
          setSpin(false);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
          setSpin(false);
        }
      });
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Subscription", "handlesubmit"),
      });
      setSpin(false);
    }
  };

  const handleSubmitEnt = async () => {
    try {
      postlog("visitedStripe subscriptions", { component: "subscriptions", function: "handleSubmit", collection: "customers", uid: user.userid });
      setSpin(true);
      let qty = 1;
      if (parseInt(quantity)) {
        qty = parseInt(quantity);
      }
      const customerdocref = doc(collection(firestore, "customers", user.userid, "checkout_sessions"));
      await setDoc(
        customerdocref,
        {
          price: isYearly ? 'price_1OJV0KHYNsdNf7XOoZOsXEfW' : 'price_1OJV0KHYNsdNf7XOEMg25zgh',
          success_url: window.location.origin + '/subscriptions',
          cancel_url: window.location.origin + '/subscriptions',
          allow_promotion_codes: true,
          quantity: qty,
        },
        { merge: true }
      );
     // postlog("write customers", { component: "subscriptions", function: "handleSubmit", collection: "customers", uid: user.userid });


      if (unsubscribeCustomer) { unsubscribeCustomer(); }
      unsubscribeCustomer = onSnapshot(customerdocref, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          setShowError({
            show: true,
            errordata: getErrorObject(error, user, "Subscription", "handleSubmit"),
          });
          setSpin(false);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
          setSpin(false);
        }
      });
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Subscription", "handlesubmit"),
      });
      setSpin(false);
    }
  };

  const [isYearly, setIsYearly] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const toggleSubscription = () => {
    setIsYearly(!isYearly);
  };

  const handleQuantityChange = (value) => {
    if (!quantity) {
      setQuantity(parseInt(1, 10));
    } else {
      setQuantity(Math.max(1, quantity + value));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const ManageSubscription = () => {
    try {
      setSpin(true);
      const myFunction = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
      myFunction({
        returnUrl: window.location.origin + '/subscriptions',
        customer: user.tenentid,
      }).then((result) => {
        setSpin(false);
        window.location.assign(result.data.url);
      })
        .catch((err) => {
          setShowError({
            show: true,
            errordata: getErrorObject(err, user, "Subscription", "handlesubmit"),
          });
          setSpin(false);
        });

    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Subscription", "handlesubmit"),
      });
      setSpin(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach(function (item) {
        const toggleIcon = item.querySelector('.toggle-icon');
        const answer = item.querySelector('.faq-answer');

        item.addEventListener('click', function () {
            answer.classList.toggle('show');
            toggleIcon.textContent = answer.classList.contains('show') ? 'remove' : 'add';
        });

        answer.addEventListener('click', function (event) {
            event.stopPropagation();
        });
    });

    // Cleanup event listeners on component unmount
    return () => {
        faqItems.forEach(function (item) {
            item.removeEventListener('click', function () {});
            item.querySelector('.faq-answer').removeEventListener('click', function () {});
        });
    };
}, []);


  let prousers = ['dvelleco@metlife.com',
    'brian.elkington@packsize.com',
    'tlowry19@gmail.com',
    'yanushkevych.alena@gmail.com',
    'jskidmo1@jaguarlandrover.com',
    'brianrspringer@gmail.com',
    'browningaustin@yahoo.com',
    'drisin@suncorp.com.au',
    'philmarit@gmail.com',
    'cuneytoskal@gmail.com',
    'vpalango@cisco.com',
    'martial77@gmail.com'];

  return (
    <div>
      <Navbar user={user} toggleSidebar={handleSidebar} />
      <Sidebar isopen={isOpen} user={user} toggleSidebar={handleSidebar} />
      <div className={`listPage-transition mt-4 ${isOpen ? "side-margin" : ""}`} style={{ overflowX: "hidden" }}>


        {/* ---------------------------------------Old pro users---------------------------------------------------- */}

        {prousers.includes(user.useremail) == true && (<div className="card table-shadow mt-5" style={{ background: "white" }}>
          <div className="card-body">
            <p className="mb-2">Your Subscription Level </p>
            <h3 className="pb-2 fs-4">Agilebin Pro</h3>

            <p className="pb-4 text-secondary" style={{ fontSize: "14px" }}>
              Please Contact Agilebin support team to make changes to your subscription.
            </p>
            <Link
              className="btn btn-primary btn-block"
              to="/support"
            >
              Contact Support
            </Link>
            <div className="form-group mb-4">

            </div>
          </div>
        </div>)}

        <div className="row mt-5">
          <div className="d-flex gap-2 align-items-center" >
            {/* <i
              className={`fas fa-chess-king fs-6`}
            
            /> */}
            {/* <CardMembershipIcon sx={{ marginRight: "10px" }} /> */}
            <div>
              <div className="fs-5"> Subscription </div>
              <p style={{ fontSize: "14px" }}>Manage your agilebin subscription here. </p>
            </div>
          </div>
          
          <div><Offer showButton={false} /></div>
        </div>


        {user.subscription.isactive == true && (
          <Paper variant="outlined" elevation={3}>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                Your subscription level
              </Typography>
              <Typography variant="h4" component="h2">
                {user.subscription.type}
              </Typography>
              {user.subscription.type == "FreeTrial" ? user.subscription.freetrialenddate.toDate() > new Date() ? (
                <Typography variant="subtitle1" color="textSecondary" component="p" sx={{ mb: 1 }}>
                 Your FreeTrial will end on <span style={{ color: 'red' }}>{user.subscription.freetrialenddate.toDate().toLocaleDateString()}</span>. <br></br>
                 Your Enterprise plan billing will commence following the conclusion of the free trial period. For internal use within your organization only.<br></br>
                  Please reach out to Agilebin support for assistance with extending or canceling your free trial.
                </Typography>
              ): (
                <Typography variant="subtitle1" color="textSecondary" component="p" sx={{ mb: 1 }}>
               Your free trial expired on <span style={{ color: 'red' }}>{user.subscription.freetrialenddate.toDate().toLocaleDateString()}</span>. <br></br>To continue using Agilebin services, please consider extending your trial or upgrading to our enterprise plan. <br></br> For assistance, please contact AgileBin support.
               </Typography>
              ) : null}
              {user.subscription.type == "Enterprise" && (
                <Typography variant="subtitle1" color="textSecondary" component="p" sx={{ mb: 1 }}>
                 Alloted user seats: {user.subscription.quantity} <br></br>
                 Your subscription plan is Enterprise, with billing occurring on the {user.subscription.billingday} of each month.For internal use within your organization only.<br></br>
                 If you wish to increase the number of users, please contact our support team.
                </Typography>
              )}

              {user.subscription.type == "Partner" && (
                <Typography variant="subtitle1" color="textSecondary" component="p" sx={{ mb: 1 }}>
                  Alloted user seats: {user.subscription.quantity} <br></br>
                  As a Partner, you enjoy lifetime access to the AgileBin Enterprise plan. For internal use within your organization only. <br></br> Should you wish to expand the number of users, please reach out to our support team for assistance.
                </Typography>
              )}

              {user.subscription.type == "FreeTrial" && (<Button variant="contained" size="large" onClick={()=>{
              navigate('/contact');
              }} sx={{ textTransform: 'capitalize' }}>
              Contact Support
              </Button>)}

              {user.subscription.type != "FreeTrial" && (<Button variant="contained" size="large" onClick={()=>{
              navigate('/contact');
              }} sx={{ textTransform: 'capitalize' }}>
                Contact Support
              </Button>)}

            </CardContent>
          </Paper>
        )}

        {/* ---------------------------------------Pro Users---------------------------------------------------- */}

        {user.subscription.isactive == undefined && prousers.includes(user.useremail) == false && state.subscriptionglobaldata.subscription == "True" && (
          <div>
            <div className="card table-shadow" style={{ background: "white" }}>
              <div className="card-body">
                <p>Your Subscription Level </p>
                <h3 className="pb-2 fs-4">Agilebin Pro</h3>
                <p className="pb-4" style={{ fontSize: "14px" }}>
                  Go to customer portal to manage your subscription
                </p>
                <Button
                  variant="contained"
                  size="large"
                  onClick={ManageSubscription}
                >
                  Customer Portal
                </Button>
                <div className="form-group mb-4">
                </div>
              </div>
            </div>
          </div>)}


        {/* ------------------------------------------ Free users------------------------------------------------------------- */}
        {user.subscription.isactive == undefined && prousers.includes(user.useremail) == false && (!subscriptionData) && (<div className="container mt-5 mb-4">
          <div className="container">
            <div className="row pricing-table">
              <div className="row mb-2">
                <h3 className="col-md-4 card-title mb-2 d-flex align-items-center"><span className="fs-4">Upgrade your plan</span></h3>
                <div className="col-md-4">

                  <div className="input-group mb-3 justify-content-center d-flex align-items-center">
                    <span>How many users? &nbsp;</span>
                    <div className="input-group-prepend justify-content-center">
                      <button
                        className="btn btn-outline-secondary fs-5"
                        type="button"
                        style={{ zIndex: "0", borderRadius: "0px" }}
                        onClick={() => handleQuantityChange(-1)}
                      >
                        -
                      </button>
                    </div>
                    <div className="col-4">
                      <input
                        type="number"
                        className="text-center form-control input-sm quantity-input fs-5 no-spinners"
                        style={{ WebkitAppearance: "none", MozAppearance: "textfield", border: "solid gray 1px", borderRadius: "0px" }}
                        value={quantity}
                        onChange={(e) => setQuantity(parseInt(e.target.value))}
                        required
                      />
                    </div>
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary fs-5"
                        type="button"
                        style={{ zIndex: "0", borderRadius: "0px" }}
                        onClick={() => handleQuantityChange(1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  <div className="toggle-switch d-flex align-items-center mb-3">
                    <span className={`toggle-label mr-2 ${!isYearly ? 'active' : ''}`} onClick={() => toggleSubscription('monthly')}>
                      Monthly
                    </span>
                    <label className="switch" style={{ margin: "0px 10px" }}>
                      <input type="checkbox" checked={isYearly} onChange={() => toggleSubscription()} />
                      <span className="slider round"></span>
                    </label>
                    <span className={`toggle-label ml-2 ${isYearly ? 'active' : ''}`} onClick={() => toggleSubscription('yearly')}>
                      Yearly <span className="text-success">(Save 17%)</span>
                    </span>

                  </div>
                </div>
              </div>

              <div className="col d-flex flex-column p-0">
                <div className="pricing-column flex-fill">
                  <h3 >Free</h3>
                  <div style={{ color: "gray" }} className="mb-3"><span>USD $0/month</span></div>
                  <Button className="btn-block mb-4" variant="contained" size="large" disabled sx={{ width: "100%", textTransform: 'capitalize' }}>
                    Your current plan
                  </Button>
                  <div >Genorous free plan:</div>
                  <ul className="ul-pricing mt-2 fs-6">
                    <li>Retrospective Meetings</li>
                    <li>Poker Games</li>
                    <li>Fist of Five Voting</li>
                    <li>Fishbone Diagrams</li>
                    <li>Fivewhys Analysis</li>
                    <li>30 days data retention </li>
                  </ul>
                </div>
              </div>

              <div className="col d-flex flex-column p-0">
                <div className="pricing-column flex-fill">
                  <h3>Pro</h3>
                  {isYearly ? <div style={{ color: 'gray' }} className="mb-3">USD ${(quantity * 59.50).toFixed(2)} per year</div> : <div style={{ color: 'gray' }} className="mb-3">USD ${(quantity * 5.95).toFixed(2)} per month</div>}
                  <Button className="btn-block subscribe-button mb-4" variant="contained" size="large" onClick={handleSubmitPro} sx={{ width: "100%", textTransform: 'capitalize' }}>
                    Upgrade to Pro
                  </Button>
                  <div>Everything in Free plan &:</div>
                  <ul className="ul-pricing mt-2 fs-6">
                    <li>Secure access to users & teams</li>
                    <li>Premium retrospective templates</li>
                    <li>Customize meeting & board settings</li>
                    <li>Consolidated action items tracking</li>
                    <li>Agile Maturity Assessments</li>
                    <li>Objectives & Key Results (OKRs)</li>
                    <li>Daily Scrum Notes</li>
                    <li>Impediments Tracker</li>
                    <li>Risks Register</li>
                    <li>Depedency Management</li>
                  </ul>
                </div>
              </div>

              <div className="col d-flex flex-column p-0">
                <div className="pricing-column flex-fill">
                  <h3>Enterprise</h3>
                  <div style={{ color: 'gray' }} className="mb-3">Contact sales for pricing & demo</div>
                  {/* {isYearly ? <div style={{ color: 'gray' }} className="mb-3">USD ${(quantity * 100).toFixed(2)} per year</div> : <div style={{ color: 'gray' }} className="mb-3">USD ${(quantity * 10).toFixed(2)} per month</div>} */}

                  {/* <Button  onClick={handleSubmitEnt} sx={{ width: "100%", textTransform: 'capitalize' }}>
                    Upgrade to Enterprise
                  </Button> */}
<Button className="btn-block subscribe-button mb-4" variant="outlined" color="primary" size="large" onClick={()=>{navigate("/contact")}} sx={{ width: "100%", textTransform: 'capitalize' }}>
                  Contact Sales
                  </Button>


                  <div >Everything in Pro plan &:</div>
                  <ul className="ul-pricing mt-2 fs-6">
                    <li>Enterprise grade security</li>
                    <li>Centralized account management</li>
                    <li>Invoice based billing option</li>
                    <li>Priority support</li>
                    <li>Single Sign On</li>
                  </ul>

                </div>
              </div>

            </div>
          </div>

          <section class="py-12 py-md-16 py-lg-24 faq-container">
        <div class="container">
          <div class="text-center mb-5">
            <h2 class="font-heading mb-4">FAQ</h2>
            <p style={{color: "gray"}}>Explore the answers to commonly asked questions below!</p>
          </div>
          <div class="mw-3xl mx-auto">
            <div class="faq-item">
                <div class="d-flex align-items-center p-6 w-100 justify-content-between text-start rounded-3">
                  <span class="h5 mb-0 me-6">How can I get started with Agilebin?</span>
                  <div class="faq-icon-circle">
                    <span class="material-symbols-outlined toggle-icon" data-target="faq-answer-1">add</span>
                  </div>
                </div>
                <div id="faq-answer-1" class="faq-answer">
                  <p>To get started with Agilebin, simply sign up for an account on our website. Once you're logged in, you can create a new meetings or join an existing one. From there, you can start scheduling meetings, inviting team members, and utilizing Agilebin's features to enhance your agile processes.</p>
                </div>
              </div>
            <div class="faq-item">
              <div class="d-flex align-items-center p-6 w-100 justify-content-between text-start text-black rounded-3">
                <span class="h5 mb-0 me-6">What is Agilebin and how does it work?</span>
                <div class="faq-icon-circle">
                  <span class="material-symbols-outlined toggle-icon" data-target="faq-answer-2 ">add</span>
                </div>
              </div>
              <div id="faq-answer-2 " class="faq-answer">
                <p>Agilebin is a collaborative platform designed to facilitate agile team meetings and processes. It allows teams to conduct retrospectives, fist of five, and other agile ceremonies effectively, even in remote or distributed settings. With Agilebin, teams can streamline their agile workflow, improve communication, and enhance productivity.</p>
              </div>
            </div>
              <div class="faq-item">
                <div class="d-flex align-items-center p-6 w-100 justify-content-between text-start rounded-3">
                  <span class="h5 mb-0 me-6">Is Agilebin suitable for remote teams?</span>
                  <div class="faq-icon-circle">
                    <span class="material-symbols-outlined toggle-icon" data-target="faq-answer-4">add</span>
                  </div>
                </div>
                <div id="faq-answer-4" class="faq-answer">
                  <p>Yes, Agilebin is designed to <a href="https://app.agilebin.com/support">support</a> remote and distributed teams. With its asynchronous meeting capabilities and intuitive interface, Agilebin enables teams to collaborate effectively regardless of their location.</p>
                </div>
              </div>
              <div class="faq-item">
                <div class="d-flex align-items-center p-6 w-100 justify-content-between text-start rounded-3">
                  <span class="h5 mb-0 me-6">How secure is Agilebin?</span>
                  <div class="faq-icon-circle">
                    <span class="material-symbols-outlined toggle-icon" data-target="faq-answer-5">add</span>
                  </div>
                </div>
                <div id="faq-answer-5" class="faq-answer">
                  <p>Security is a top priority for Agilebin. We employ industry-standard encryption protocols to protect your data and ensure the confidentiality and integrity of your team's information.</p>
                </div>
              </div>
              <div class="faq-item">
                <div class="d-flex align-items-center p-6 w-100 justify-content-between text-start rounded-3">
                  <span class="h5 mb-0 me-6">Can I customize Agilebin to fit my team's specific needs?</span>
                  <div class="faq-icon-circle">
                    <span class="material-symbols-outlined toggle-icon" data-target="faq-answer-6">add</span>
                  </div>
                </div>
                <div id="faq-answer-6" class="faq-answer">
                  <p>Yes, Agilebin offers customization options to tailor the platform to your team's unique requirements. You can configure meeting templates, adjust settings, and personalize your Agilebin experience to suit your team's preferences and workflows.</p>
                </div>
              </div>
              <div class="faq-item">
                <div class="d-flex align-items-center p-6 w-100 justify-content-between text-start rounded-3">
                  <span class="h5 mb-0 me-6">What are Agilebin's terms and privacy policy?</span>
                  <div class="faq-icon-circle">
                    <span class="material-symbols-outlined toggle-icon" data-target="faq-answer-7">add</span>
                  </div>
                </div>
                <div id="faq-answer-7" class="faq-answer">
                  <p>Agilebin's <a href="https://www.agilebin.com/terms">terms</a> and <a href="https://www.agilebin.com/privacy">privacy policy</a> outline the terms of service, privacy practices, and any other relevant policies that govern the use of our platform. These documents detail important information about user rights, data privacy, security measures, and other legal considerations. You can review Agilebin's terms and privacy policy on our website to ensure compliance and understanding of our services.</p>
                </div>
              </div>
           
          </div>
        </div>
      </section>

        </div>
        )}
      </div>
      <ShowError Error={showError} />
      <Spinner spin={spin} />
    </div>
  );
}

export default PricingComponent;
