import React from 'react';
import { useState } from 'react';
import { firestore } from "../../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  query,
  collection,
  onSnapshot,
  doc,
  setDoc,
  limit
} from "@firebase/firestore";
import { Box, Typography, Button, TextField, InputLabel,CircularProgress , TextareaAutosize, IconButton, FormControl, InputAdornment, Paper, styled, Input, Tooltip,  } from '@mui/material';
import { Upload } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';  
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    border: 'none',
    borderBottom: '1px solid #ccc', // Default bottom border
    '&:focus': {
      borderBottom: '2px solid blue', // Bottom border on focus
    },
  },
});

const BasicTeamInfo = ({ team,user }) => {
  const [editMode, setEditMode] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: team.name,
    purpose: team.purpose != undefined ? team.purpose : '',
    description: team.description != undefined ? team.description : '',
    slogan: team.slogan != undefined ? team.slogan : '',
    email: team.email != undefined ? team.email : '',
  });
  const [logo, setLogo] = React.useState();
  const [logoUrl, setLogourl] = React.useState(team.logo != undefined ? team.logo : '');
  const [uploaderror, setUploaderror] = React.useState(false);
  const storage = getStorage();
  const [showFormValidate, setShowFormValidate] = useState(false);
  const [dod, setDod] = React.useState('');
  const [dodList, setDodList] = React.useState(team.dod || []);
  const [editingIndex, setEditingIndex] = React.useState(null);
  const [editedDod, setEditedDod] = React.useState('');
  const [dor, setDor] = React.useState('');
  const [dorList, setDorList] = React.useState(team.dor || []);
  const [editingdorIndex, setEditingDorIndex] = React.useState(null);
  const [editedDor, setEditedDor] = React.useState('');
  const [workagreement , setWorkagreement ] = React.useState('');
  const [workagreementList, setWorkagreementList] = React.useState(team.workagreement || []);
  const [editingworkagreementIndex, setEditingWorkagreementIndex] = React.useState(null);
  const [editedworkagreement, setEditedworkagreement] = React.useState('');
  const handleChange = (event) => {
    // try {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // } catch (err) {
    //   setShowError({
    //     show: true,
    //     errordata: getErrorObject(
    //       err,
    //       user,
    //       "BasicTeamInfo",
    //       "handleChange"
    //     ),
    //   });
    //   setSpin(false);
    // }
  };

  const handleEditClick = () => {
    setEditMode(true);
    setFormData({
      name: team.name,
      purpose: team.purpose != undefined ? team.purpose : '',
      description: team.description != undefined ? team.description : '',
      slogan: team.slogan != undefined ? team.slogan : '',
       email: team.email != undefined ? team.email : '',
    })
    setUploaderror(false);
    setShowFormValidate(false);
  };

  const handleSaveClick = async () => {
    if (formData.name.trim()) {
    if(logo){
      const mountainsRef = ref(storage, `${team.id}_logo`);
      const uploadTask = uploadBytes(mountainsRef, logo);
      uploadTask.then((snapshot) => {
        getDownloadURL(mountainsRef).then((downloadURL) => {
          saveTeamDetails(downloadURL);
        });
      });
    }else{
      saveTeamDetails(null);
    }
  }else{
    setShowFormValidate(true);
  }
  };

  const handleAddDod = () => {
    if (dod.trim() !== '') {
      const updatedDodList = [...dodList, dod];
      setDodList(updatedDodList);
      setDod('');
  
      const docRef = doc(firestore, "teams", team.id);
      setDoc(docRef, { dod: updatedDodList }, { merge: true });
    }
  };

  const handleEditDod = (index) => {
    setEditingIndex(index);
    setEditedDod(dodList[index]);
  };
  
  const handleSaveEdit = () => {
    const updatedDodList = [...dodList];
    updatedDodList[editingIndex] = editedDod;
    setDodList(updatedDodList);
  
    const docRef = doc(firestore, "teams", team.id);
    setDoc(docRef, { dod: updatedDodList }, { merge: true });

    setEditingIndex(null);
    setEditedDod('');
  };
  
  
  const handleDeleteDod = (index) => {
    const updatedDodList = [...dodList];
    updatedDodList.splice(index, 1);
    setDodList(updatedDodList);
  
    const docRef = doc(firestore, "teams", team.id);
    setDoc(docRef, { dod: updatedDodList }, { merge: true });
  };

  const handleAddDor = () => {
    if (dor.trim() !== '') {
      const updatedDorList = [...dorList, dor];
      setDorList(updatedDorList);
      setDor('');
  
      const docRef = doc(firestore, "teams", team.id);
      setDoc(docRef, { dor: updatedDorList }, { merge: true });
    }
  };

  const handleSavedorEdit = () => {
    const updatedDorList = [...dorList];
    updatedDorList[editingdorIndex] = editedDor;
    setDorList(updatedDorList);
  
    const docRef = doc(firestore, "teams", team.id);
    setDoc(docRef, { dor: updatedDorList }, { merge: true });
  
    setEditingDorIndex(null);
    setEditedDor('');
  };

  const handleEditDor = (index) => {
    setEditingDorIndex(index);
    setEditedDor(dorList[index]);
  };
  
  const handleDeleteDor = (index) => {
    const updatedDorList = [...dorList];
    updatedDorList.splice(index, 1);
    setDorList(updatedDorList);
  
    const docRef = doc(firestore, "teams", team.id);
    setDoc(docRef, { dor: updatedDorList }, { merge: true });
  };

  
  const handleAddworkagreement = () => {
    if (workagreement.trim() !== '') {
      const updatedworkagreementList = [...workagreementList, workagreement];
      setWorkagreementList(updatedworkagreementList);
      setWorkagreement ('');
  
      const docRef = doc(firestore, "teams", team.id);
      setDoc(docRef, { workagreement: updatedworkagreementList }, { merge: true });
    }
  };

  const handleSaveworkagreementEdit = () => {
    const updatedworkagreementList = [...workagreementList];
    updatedworkagreementList[editingworkagreementIndex] = editedworkagreement;
    setWorkagreementList(updatedworkagreementList);
  
    const docRef = doc(firestore, "teams", team.id);
    setDoc(docRef, { workagreement: updatedworkagreementList }, { merge: true });

    setEditingWorkagreementIndex(null);
    setEditedworkagreement('');
  };

  const handleEditWorkagreement = (index) => {
    setEditingWorkagreementIndex(index);
    setEditedworkagreement(workagreementList[index]);
  };
  
  const handleDeleteWorkagreement = (index) => {
    const updatedWorkagreementList = [...workagreementList];
    updatedWorkagreementList.splice(index, 1);
    setWorkagreementList(updatedWorkagreementList);
  
    const docRef = doc(firestore, "teams", team.id);
    setDoc(docRef, { workagreement: updatedWorkagreementList }, { merge: true });
  };

  const saveTeamDetails = async (imageurl) => {
    const docRef = doc(firestore, "teams", team.id);
    if(imageurl != null){
      await setDoc(
        docRef,
        {
          name: formData.name,
          purpose: formData.purpose,
          description: formData.description,
          slogan: formData.slogan,
          logo: imageurl,
          email: formData.email,
          dod: [],
          dor: [],
        },
        { merge: true }
      );
      handleCancelClick();
    }else{
      await setDoc(
        docRef,
        {
          name: formData.name,
          purpose: formData.purpose,
          description: formData.description,
          slogan: formData.slogan,
          email: formData.email,
          dod: [],
          dor: [],
        },
        { merge: true }
      );
    }
    handleCancelClick();
  }
  const handleCancelClick = () => {
    setEditMode(false);
    setUploaderror(false);
    setShowFormValidate(false);
    setLogo();
  };




  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
      setLogo(file);
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const jpegDataURL = canvas.toDataURL('image/jpeg');
          setLogourl(jpegDataURL);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setUploaderror(false);
    } else {
      setUploaderror(true);
    }
  };

  const StyledInput = styled(Input)({
    display: 'none',
  });

  const calculateCompletionPercentage = (formData) => {
    const { name, slogan, email, purpose, description } = formData;
    const filledFields = [name, slogan, email, purpose, description].filter(field => field.trim() !== '').length;
    const totalFields = 5; // Total number of fields
    const percentage = (filledFields / totalFields) * 100;
    return percentage;
  }
  
  const ProfileCompletionCircle = ({ formData }) => {
    const percentage = calculateCompletionPercentage(formData);
  
    return (
      percentage != 100 && (<div style={{ position: 'absolute', top: '5px', right: '10px', textAlign: 'center' }}>
        <Typography variant='h6' color='text.secondary'>Complete Team Details</Typography>
        <CircularProgress variant="determinate" value={percentage} size={60} thickness={3} sx={{ color: '#4caf50' }} />
        <div style={{ color: '#4caf50', fontWeight: 'bold', marginTop: '-48px' }}>
          {Math.round(percentage)}%
        </div>
      </div>)
    );
  };


  return (
    <>
    <Box mt={4}>
<Paper p={3} bgcolor="white" borderRadius={8} sx={{ width: '70vw', mb: 0, p: 2,position:'relative' }}>
{editMode == false && team.usersdetails[user.userid].isowner &&  <ProfileCompletionCircle formData={formData} />}
  <Box display="flex" alignItems="center" mb={0}>
    {(editMode) ? (
      <>
        <label htmlFor="image-upload">
          <Button component="span" sx={{
            border: `dashed ${uploaderror ? '#db2f6b' : '#a19f9f'} 2px`,
          }}>
            {(logoUrl && logoUrl != '') ? (<img src={logoUrl} alt="team logo" style={{ width: '100px' }} />) : <AccountBoxIcon sx={{ fontSize: '5rem', color: 'gray' }} />}
          </Button>
          <StyledInput
            id="image-upload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        </label>
        {uploaderror && (<Typography color='error' variant='subtitle2'> Please upload a PNG, JPG, or JPEG file.</Typography>)}

      </>

    ) : (
      <>
        {(team.logo && team.logo != '') ? (<img src={team.logo} width='100px' alt="team logo" style={{marginRight:'0.5rem', border:'solid gray 1px'}} />) : (<AccountBoxIcon sx={{ fontSize: '5rem', color: 'gray' }} />)}
        <Box ml={1}>
          <Typography variant="h4">{team.name}</Typography>
          <Typography variant="h6">{team.slogan}</Typography>
            <Typography variant="subtitle2" sx={{color:'gray'}}>{team.email}</Typography>
        </Box>
      </>
    )}
  </Box>

  {editMode && (<FormControl fullWidth variant="standard">
  
      <TextField name="name" value={formData.name} sx={{ mb: 2 }} onChange={handleChange} error={showFormValidate && !formData.name.trim()}
        helperText={showFormValidate && !formData.name.trim() ? 'Team name is required.' : ''} placeholder='Team name' />
   
  </FormControl> ) }

  {editMode && (<FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
      <TextField name="slogan" variant="outlined" placeholder='Slogan' value={formData.slogan} onChange={handleChange} />
  </FormControl> )}

  {/* Add email distribution input above slogan when edit mode is on */}
  {editMode && (
    <FormControl fullWidth variant="standard">
      <TextField name="email" variant="outlined" placeholder='Email Distribution' value={formData.email} onChange={handleChange} />
    </FormControl>
  )}

  <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
    <Typography variant="h6" sx={{ fontSize: '14px' }} >Description:</Typography>
    {editMode ? (
      <TextareaAutosize
        name="description"
        value={formData.description}
        onChange={handleChange}
        minRows={3}
        rows={5}
      />
    ) : (
      <Typography variant="body2" >{team.description}</Typography>
    )}
  </FormControl>

  <FormControl fullWidth variant="standard" sx={{ mt: 2,mb: 1, }}>
    <Typography variant="h6" sx={{ fontSize: '14px' }} >Purpose:</Typography>
    {editMode ? (
      <TextareaAutosize
        name='purpose'
        value={formData.purpose}
        onChange={handleChange}
        minRows={3}
        rows={5} // Set the number of rows for the TextArea
      />
    ) : (
      <Typography variant="body2">{team.purpose}</Typography>
    )}
  </FormControl>
  {!editMode && team.usersdetails[user.userid].isowner && (
    <Button variant="contained" startIcon={<EditIcon />} sx={{ borderRadius: '5px', textTransform: 'capitalize' }} size='small' onClick={handleEditClick}>
      Edit Details
    </Button>
  )}
  {editMode && (
    <Box mt={2}>
      <Button variant="outlined" color="primary" size='large' onClick={handleCancelClick} sx={{ textTransform: 'capitalize' }}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" size='large' onClick={handleSaveClick} sx={{ ml: 2, textTransform: 'capitalize' }}>
        Save Details
      </Button>
    </Box>
  )}
</Paper>

<Paper p={3} bgcolor="white" borderRadius={8} sx={{ width: '70vw', mb: 0, p: 2,position:'relative', marginTop: '20px' }}>
  <Typography variant="h6">Definition of Done (DoD)</Typography>
  <FormControl fullWidth variant="standard">
    <InputLabel className='m-0'>New Definition of Done</InputLabel>
    <Input
      value={dod}
      onChange={(e) => setDod(e.target.value)}
      style={{ height: '40px' }}
      endAdornment={
        <InputAdornment position="end">
        <Tooltip title='Add'>
          <Button onClick={handleAddDod} size="small" variant="text" sx={{ textTransform: 'capitalize', background: '#f7f5f5', marginBottom: '20px' }}>
            <AddIcon sx={{marginRight:"5px"}}/>Add new
          </Button>
          </Tooltip>
        </InputAdornment>
      }
    />
  </FormControl>
  <div>
  {dodList.map((item, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flexGrow: 1 }}>
        {editingIndex === index ? (
          <Input
            type="text"
            value={editedDod}
            fullWidth={true}
            style={{ height: '40px' }}
            onChange={(e) => setEditedDod(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
              <Tooltip title='Save'>
                <IconButton onClick={handleSaveEdit} size="small">
                  <CheckIcon />
                </IconButton>
                </Tooltip>
                <Tooltip title='Cancel'>
                <IconButton onClick={() => setEditingIndex(null)} size="small">
                  <CloseIcon />
                </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        ) : (
          <><span style={{color: 'gray'}}>&#8226;</span>&nbsp;&nbsp;{item}</>
        )}
      </div>
      {(editingIndex !== index || !editingIndex === null) && (
        <div>
        <Tooltip title='Edit'>
          <IconButton onClick={() => handleEditDod(index)} size="small">
            <EditOutlinedIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
          <IconButton onClick={() => handleDeleteDod(index)} size="small">
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  ))}
</div>
  </Paper>

  <Paper p={3} bgcolor="white" borderRadius={8} sx={{ width: '70vw', mb: 0, p: 2,position:'relative', marginTop: '20px' }}>
  <Typography variant="h6">Definition of Ready (DoR)</Typography>
  <FormControl fullWidth variant="standard">
    <InputLabel className='m-0'>New Definition of Ready</InputLabel>
    <Input
      value={dor}
      onChange={(e) => setDor(e.target.value)}
      style={{ height: '40px' }}
      endAdornment={
        <InputAdornment position="end">
        <Tooltip title='Add'>
        <Button onClick={handleAddDor} size="small" variant="text" sx={{ textTransform: 'capitalize', background: '#f7f5f5', marginBottom: '20px' }}>
            <AddIcon sx={{marginRight:"5px"}}/>Add new
          </Button>
          </Tooltip>
        </InputAdornment>
      }
    />
  </FormControl>
  <div>
  {dorList.map((item, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flexGrow: 1 }}>
        {editingdorIndex === index ? (
          <Input
            type="text"
            value={editedDor}
            fullWidth={true}
            style={{ height: '40px' }}
            onChange={(e) => setEditedDor(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
              <Tooltip title='Save'>
                <IconButton onClick={handleSavedorEdit} size="small">
                  <CheckIcon />
                </IconButton>
                </Tooltip>
                <Tooltip title='Cancel'>
                <IconButton onClick={() => setEditingDorIndex(null)} size="small">
                  <CloseIcon />
                </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        ) : (
          <><span style={{color: 'gray'}}>&#8226;</span>&nbsp;&nbsp;{item}</>
        )}
      </div>
      {(editingdorIndex !== index || !editingdorIndex === null) && (
        <div>
        <Tooltip title='Edit'>
          <IconButton onClick={() => handleEditDor(index)} size="small">
            <EditOutlinedIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
          <IconButton onClick={() => handleDeleteDor(index)} size="small">
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  ))}
</div>
  </Paper>

  <Paper p={3} bgcolor="white" borderRadius={8} sx={{ width: '70vw', mb: 0, p: 2,position:'relative', marginTop: '20px' }}>
  <Typography variant="h6">Working Agreement </Typography>
  <FormControl fullWidth variant="standard">
    <InputLabel className='m-0'>Add Working Agreement</InputLabel>
    <Input
      value={workagreement}
      onChange={(e) => setWorkagreement(e.target.value)}
      style={{ height: '40px' }}
      endAdornment={
        <InputAdornment position="end">
        <Tooltip title='Add'>
        <Button onClick={handleAddworkagreement} size="small" variant="text" sx={{ textTransform: 'capitalize', background: '#f7f5f5', marginBottom: '20px' }}>
            <AddIcon sx={{marginRight:"5px"}}/>Add new
          </Button>
          </Tooltip>
        </InputAdornment>
      }
    />
  </FormControl>
  <div>
  {workagreementList.map((item, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flexGrow: 1 }}>
        {editingworkagreementIndex === index ? (
          <Input
            type="text"
            value={editedworkagreement}
            fullWidth={true}
            style={{ height: '40px' }}
            onChange={(e) => setEditedworkagreement(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
              <Tooltip title='Save'>
                <IconButton onClick={handleSaveworkagreementEdit} size="small">
                  <CheckIcon />
                </IconButton>
                </Tooltip>
                <Tooltip title='Cancel'>
                <IconButton onClick={() => setEditingWorkagreementIndex(null)} size="small">
                  <CloseIcon />
                </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        ) : (
          <><span style={{color: 'gray'}}>&#8226;</span>&nbsp;&nbsp;{item}</>
        )}
      </div>
      {(editingworkagreementIndex !== index || !editingworkagreementIndex === null) && (
        <div>
        <Tooltip title='Edit'>
          <IconButton onClick={() => handleEditWorkagreement(index)} size="small">
            <EditOutlinedIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
          <IconButton onClick={() => handleDeleteWorkagreement(index)} size="small">
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  ))}
</div>
  </Paper>
</Box>
</>
  );
}

export default BasicTeamInfo;
