import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import {
  query,
  collection,
  onSnapshot,
  doc,
  Timestamp,
  where,
  setDoc,
  limit,
  orderBy,
} from "@firebase/firestore";
import { firestore } from "./firebase";
import { use } from "echarts";
import { storeError, getErrorObject, postlog, posthoginit, Notify } from "./functions/Common";
import { getAuth, getRedirectResult, GoogleAuthProvider } from "firebase/auth";

export const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  let user = {};
  user.userid = "";
  user.role = "administrator";
  user.profilepicture = "";
  user.timezone = "";
  user.tenentid = "";
  const initialState = {
    userglobaldata: user,
    usersglobaldata: null,
    teamsglobaldata: null,
    subscriptionglobaldata: { subscription: "False" },
    agilebinversionglobaldata: "",
    currentteam: { teamname: "", teamid: "" },
    loading: true,
    programboardsglobaldata : null,
  };

  function encodeToBase64(text) {
    return btoa(text);
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "LODING":
        return { ...state, loading: action.payload };
      case "SET_DATA":
        return { ...state, userglobaldata: action.payload, loading: false };
      case "SET_DATA2":
        return { ...state, notificationsglobaldata: action.payload, loading: false };
      case "SET_DATA3":
        return { ...state, usersglobaldata: action.payload, loading: false };
      case "SET_DATA4":
        return { ...state, teamsglobaldata: action.payload, loading: false };
      case "SET_DATA5":
        return { ...state, subscriptionglobaldata: action.payload, loading: false };
      case "SET_DATA6":
        return { ...state, agilebinversionglobaldata: action.payload, loading: false };
      case "SET_DATA7":
        return { ...state, emailpreferencesglobaldata: action.payload, loading: false };
      case "SET_DATA8":
        return { ...state, currentteam: action.payload, loading: false };
      case "SET_DATA9":
        return { ...state, programboardsglobaldata: action.payload, loading: false };
      default:
        return state;
    }
  };

  // Create state and dispatch using useReducer
  const [state, dispatch] = useReducer(reducer, initialState);
  const [spin, setSpin] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  let unsubscribeuser = null;
  let unsubscribenontifications = null;
  let unsubscribesubscription = null;
  let unsubscribesubscription2 = null;
  let unsubscribesubscription3 = null;
  let unsubscribeusers = null;
  let unsubscribeteams = null;
  let unsubscribeagilebinversion = null;
  let unsubscribeemailprefrence = null;
  const auth = getAuth();

  useEffect(() => {
    getAuth().onAuthStateChanged(function (fbUser) {
      if (fbUser) {
        getUser(fbUser.uid, fbUser.email);
        posthoginit(fbUser.uid, fbUser.email, fbUser.displayName);
        postlog("user login", { userid: fbUser.uid, useremail: fbUser.email });
      } else {
        if (unsubscribeuser) { unsubscribeuser(); }
        dispatch({ type: "SET_DATA", payload: user });
        cleanUp();
      }
    });

  }, []);

  getRedirectResult(auth)
    .then(async (result) => {
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      if (result != null && result != undefined) {
        const user = result.user;
        const creationTime = result.user.metadata.creationTime;
        const isNewUser = (Date.now() - new Date(creationTime).getTime()) < 60000;
        if (isNewUser) {
          const docRef = doc(firestore, "users", user.uid);
          const nameParts = user.displayName.split(' ');
          const firstName = nameParts[0];
          const lastName = nameParts.slice(1).join(' ');
          await setDoc(
            docRef,
            {
              userid: user.uid,
              firstname: firstName,
              lastname: lastName,
              useremail: user.email,
              tenentid: "",
              role: "administrator",
              timezone: "",
              createddate: Timestamp.fromDate(new Date()),
              updateddate: Timestamp.fromDate(new Date()),
              isactive: true,
              profilepicture: user.photoURL,
            },
            { merge: true }
          ).then(() => {
            Notify(
              "Welcome to Agilebin",
              `You have successfully created your free account. Try something out today.`,
              user.email,
              'administrator',
              true,
              false,
              "newuser",
              encodeToBase64(user.uid),
              firstName
            );
          })

          postlog("new-user", { component: "globalprovider", function: "getUser", userid: user.uid, useremail: user.email, providerid: result.providerId ? result.providerId : '' });
        }
      } else {
        postlog("login-result-null", { component: "globalprovider", function: "getRedirectResult", userid: user.uid, useremail: user.email, providerid: '' });
      }
    }).catch((err) => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // The email of the user's account used.
      // const email = error.customData.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      if (err.code === "auth/account-exists-with-different-credential") {
        // The pending Microsoft credential.
        let pendingCred = err.credential;
        // Step 3: Save the pending credential in temporary storage,

        // Step 4: Let the user know that they already have an account
        // but with a different provider, and let them choose another
        // sign-in method.
        postlog("account-exist-error", { component: "globalprovider", function: "getRedirectResult", userid: user.uid, useremail: user.email });
      }
      else {
        postlog("login-error-idp", { component: "globalprovider", function: "getRedirectResult", userid: user.uid, useremail: user.email });
      }
      storeError(getErrorObject(err, { userid: "" }, "Global Provider", "getRedirectResult"));
    });

  const cleanUp = () => {
    if (unsubscribenontifications) { unsubscribenontifications(); }
    if (unsubscribesubscription) { unsubscribesubscription(); }
    if (unsubscribesubscription2) { unsubscribesubscription2(); }
    if (unsubscribesubscription3) { unsubscribesubscription3(); }
    if (unsubscribeusers) { unsubscribeusers(); }
    if (unsubscribeteams) { unsubscribeteams(); }
    if (unsubscribeagilebinversion) { unsubscribeagilebinversion(); }
    if (unsubscribeemailprefrence) { unsubscribeemailprefrence(); }
    dispatch({ type: "SET_DATA2", payload: null });
    dispatch({ type: "SET_DATA1", payload: null });
    dispatch({ type: "SET_DATA3", payload: null });
    dispatch({ type: "SET_DATA4", payload: null });
    dispatch({ type: "SET_DATA5", payload: { subscription: "False" } });
    dispatch({ type: "SET_DATA6", payload: null });
    dispatch({ type: "SET_DATA7", payload: null });
    // dispatch({ type: "SET_DATA8", payload: null });
    dispatch({ type: "SET_DATA9", payload: null });
  }


  async function getAgilebinVersion() {
    try {
      dispatch({ type: "LODING", payload: true });
      unsubscribeagilebinversion = onSnapshot(doc(firestore, "agilebinversions", "agilebinversion"),
        (doc) => {
          if (doc.data() != undefined) {
            let agilebinVersion = doc.data().version;
            dispatch({ type: "SET_DATA6", payload: agilebinVersion });
            // postlog("read agilebinversions", { component: "globalprovider", function: "getAgilebinVersion", collection: "agilebinversions" });
          }
        },
        (err) => {
          storeError(getErrorObject(err, { userid: "" }, "Global Provider", "getAgilebinVersion"));
        });
    } catch (err) {
      storeError(getErrorObject(err, { userid: "" }, "Global Provider", "getAgilebinVersion"));
    }
  }


  const getEmailPrefrences = async (uid) => {
    try {
      dispatch({ type: "LODING", payload: true });
      unsubscribeuser = onSnapshot(doc(firestore, "preferences", uid),
        (doc) => {
          let emailPreference = doc.data();
          dispatch({ type: "SET_DATA7", payload: emailPreference });
        },
        (err) => {
          // storeError(getErrorObject(err, { userid: uid }, "Global Provider", "getUser"));
          postlog("new-user", { component: "globalprovider", function: "getUser", userid: uid });
        });
    } catch (err) {
      storeError(getErrorObject(err, { userid: uid }, "Global Provider", "getUser"));
    }
  };


  const getUser = async (uid, uemail) => {
    try {
      dispatch({ type: "LODING", payload: true });
      unsubscribeuser = onSnapshot(doc(firestore, "users", uid),
        (doc) => {
          if (doc.data() != undefined) {
            let userdb = {};
            let userdata = doc.data();
            userdb.role = userdata.role != undefined ? userdata.role : "";
            userdb.profilepicture = userdata.profilepicture != undefined ? userdata.profilepicture : "";
            userdb.timezone = userdata.timezone != undefined ? userdata.timezone : "";
            userdb.tenentid = userdata.tenentid != undefined ? userdata.tenentid : "";
            userdb.subscription = userdata.subscription ? userdata.subscription : "False";
            userdb.firstname = userdata.firstname;
            userdb.lastname = userdata.lastname;
            userdb.userid = userdata.userid;
            userdb.username = userdata.firstname + " " + userdata.lastname;
            userdb.useremail = userdata.useremail;
            userdb.isactive = userdata.isactive;
            userdb.emailpreference = userdata.emailpreference;
            dispatch({ type: "SET_DATA", payload: userdb });
            // postlog("read users", { component: "globalprovider", function: "getUser", collection: "users", uid: userdata.userid });
            cleanUp();
            getNotificationData(userdb);
            getSubscription(userdb);
            getEmailPrefrences(userdb.userid);
            getTeams(userdb);
            getProgramBoards(userdb);
            getAgilebinVersion();

          } else {
            storeError(getErrorObject({ name: "user missing", message: "user missing" }, { userid: uid }, "Global Provider", "getUser"));
          }
        },
        (err) => {
          // storeError(getErrorObject(err, { userid: uid }, "Global Provider", "getUser"));
          postlog("new-user", { component: "globalprovider", function: "getUser", userid: uid, useremail: uemail });
        });
    } catch (err) {
      storeError(getErrorObject(err, { userid: uid }, "Global Provider", "getUser"));
    }
  };

  const getUsers = async (userdata) => {
    try {
      dispatch({ type: "LODING", payload: true });
      let usersdata = [];
      if (userdata.tenentid != "" && userdata.tenentid != undefined && userdata.tenentid != null && userdata.role != "member") {
        const q = query(
          collection(firestore, "users"),
          where("tenentid", "==", userdata.tenentid)
        );
        unsubscribeusers = await onSnapshot(q, (querySnapshot) => {
          usersdata = [];
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            if (userData.tenentid != "") {
              usersdata.push(userData);
            }
          });
          // postlog("read users", { component: "globalprovider", function: "getusers", collection: "users", tenentid: userdata.tenentid });
          dispatch({ type: "SET_DATA3", payload: usersdata });
        });
      } else {
        dispatch({ type: "SET_DATA3", payload: usersdata });
      }

    } catch (err) {
      storeError(getErrorObject(err, { userid: userdata.userid }, "Global Provider", "getUsers"));
      setSpin(false);
    }
  };

  async function getTeams(userdata) {
    try {
      dispatch({ type: "LODING", payload: true });
      setSpin(true);
      const q = await query(
        collection(firestore, "teams"),
        where("users", "array-contains", userdata.userid)
      );
      unsubscribeteams = await onSnapshot(q, (snapshot) => {
        let teamesdata = [];
        snapshot.forEach((doc) => {
          const newdata = doc.data();
          teamesdata.push(newdata);
        });
        // postlog("read teams", { component: "globalprovider", function: "getteams", collection: "teams", uid: userdata.userid });
        dispatch({ type: "SET_DATA4", payload: teamesdata });
      });
    } catch (err) {
      storeError(getErrorObject(err, { userid: userdata.userid }, "Global Provider", "getTeams"));
    }
  }


  // ----get program boards data new function ---------------------

  async function getProgramBoards(userdata) {
    try {
      dispatch({ type: "LODING", payload: true });
      setSpin(true);
      const q = await query(
        collection(firestore, "programboards"),
        where("users", "array-contains", userdata.userid)
      );
      unsubscribeteams = await onSnapshot(q, (snapshot) => {
        let programboards = [];
        snapshot.forEach((doc) => {
          const newdata = doc.data();
          programboards.push(newdata);
        });
        // postlog("read teams", { component: "globalprovider", function: "getteams", collection: "teams", uid: userdata.userid });
        dispatch({ type: "SET_DATA9", payload: programboards });
      });
    } catch (err) {
      storeError(getErrorObject(err, { userid: userdata.userid }, "Global Provider", "getProgramBoards"));
    }
  }


  async function getNotificationData(user) {
    try {
      const q = await query(
        collection(firestore, "notifications"),
        where("email", "==", user.useremail),
        where("status", "==", "active"),
        orderBy("createddate", "desc"),
        limit(10),
      );
      unsubscribenontifications = await onSnapshot(q, (snapshot) => {
        let notifications = [];
        snapshot.forEach((doc) => {
          const newdata = doc.data();
          notifications.push(newdata);
        });
        // postlog("read notifications", { component: "globalprovider", function: "getNotificationData", collection: "notifications", email: user.useremail });
        dispatch({ type: "SET_DATA2", payload: notifications });
      });
    } catch (err) {
      storeError(getErrorObject(err, { userid: user.userid }, "Global Provider", "getNotificationData"));
    }
  }


  const getSubscription = async (userdata) => {
    try {
      dispatch({ type: "LODING", payload: true });
      let prousers = [
        'dvelleco@metlife.com',
        'brian.elkington@packsize.com',
        'tlowry19@gmail.com',
        'yanushkevych.alena@gmail.com',
        'jskidmo1@jaguarlandrover.com',
        'brianrspringer@gmail.com',
        'browningaustin@yahoo.com',
        'drisin@suncorp.com.au',
        'philmarit@gmail.com',
        'cuneytoskal@gmail.com',
        'vpalango@cisco.com',
        'martial77@gmail.com']
      if (prousers.includes(userdata.useremail)) {
        dispatch({ type: "SET_DATA5", payload: { subscription: "True" } });
      } else {

         if (userdata.subscription != undefined && userdata.subscription != "False" && userdata.subscription.isactive) {
          if(userdata.subscription.type != "FreeTrial"){
            dispatch({ type: "SET_DATA5", payload: { subscription: "True", } });
            getUsers(userdata);
          }
          else{
          if(userdata.subscription.freetrialenddate.toDate() > new Date()){
            dispatch({ type: "SET_DATA5", payload: { subscription: "True", } });
            getUsers(userdata);
          }else{
            if (userdata.tenentid == undefined || userdata.tenentid == "" || userdata == null) {
              const q = await query(
                collection(firestore, "customers/" + userdata.userid + "/subscriptions"),
                where("status", "==", "active"),
                limit(1),
              );
              unsubscribesubscription2 = await onSnapshot(q, (snapshot) => {
                snapshot.forEach((docc) => {
  
                  if (userdata.tenentid == undefined || userdata.tenentid == "" || userdata == null) {
                    postlog("new pro user", { component: "subscriptions", function: "getSubscription", collection: "customers", uid: user.userid });
                    const docRef = doc(firestore, "users", userdata.userid);
                    setDoc(
                      docRef,
                      {
                        tenentid: userdata.userid,
                        updateddate: Timestamp.fromDate(new Date()),
                      },
                      { merge: true }
                    );
                  }
                })
                // postlog("read customers", { component: "globalprovider", function: "getSubscription", collection: "customers", uid: userdata.userid });
              })
            }
            else {
              const q = await query(
                collection(firestore, "customers/" + userdata.tenentid + "/subscriptions"),
                where("status", "==", "active"),
                limit(1),
              );
              unsubscribesubscription = await onSnapshot(q, (snapshot) => {
                snapshot.forEach((docc) => {
                  dispatch({ type: "SET_DATA5", payload: { subscription: "True" } });
                  getUsers(userdata);
                });
                // postlog("read customers", { component: "globalprovider", function: "getSubscription", collection: "customers", tenentid: userdata.tenentid });
              });
            }
          }
          }
        } else {
          if (userdata.tenentid == undefined || userdata.tenentid == "" || userdata == null) {
            const q = await query(
              collection(firestore, "customers/" + userdata.userid + "/subscriptions"),
              where("status", "==", "active"),
              limit(1),
            );
            unsubscribesubscription2 = await onSnapshot(q, (snapshot) => {
              snapshot.forEach((docc) => {

                if (userdata.tenentid == undefined || userdata.tenentid == "" || userdata == null) {
                  postlog("new pro user", { component: "subscriptions", function: "getSubscription", collection: "customers", uid: user.userid });
                  const docRef = doc(firestore, "users", userdata.userid);
                  setDoc(
                    docRef,
                    {
                      tenentid: userdata.userid,
                      updateddate: Timestamp.fromDate(new Date()),
                    },
                    { merge: true }
                  );
                }
              })
              // postlog("read customers", { component: "globalprovider", function: "getSubscription", collection: "customers", uid: userdata.userid });
            })
          }
          else {
            // --------------------------------------check if subscription exist in stripe for this tenentid 
            const q = await query(
              collection(firestore, "customers/" + userdata.tenentid + "/subscriptions"),
              where("status", "==", "active"),
              limit(1),
            );
            unsubscribesubscription = await onSnapshot(q, (snapshot) => {
              snapshot.forEach((docc) => {
                dispatch({ type: "SET_DATA5", payload: { subscription: "True" } });
                getUsers(userdata);
              });
              // postlog("read customers", { component: "globalprovider", function: "getSubscription", collection: "customers", tenentid: userdata.tenentid });
            });
            //-----------------------------------------------------------------------
            // --------------------------------------check if subscription exist in users in backend for this tenentid
            unsubscribesubscription3 = onSnapshot(doc(firestore, "users", userdata.tenentid),
            (doc) => {
              if (doc.data() != undefined) {
                if (doc.data().subscription != undefined && doc.data().subscription != "False" && doc.data().subscription.isactive) {
                  if(doc.data().subscription.type != "FreeTrial"){
                    dispatch({ type: "SET_DATA5", payload: { subscription: "True", } });
                    getUsers(userdata);
                  }
                  else{
                  if(doc.data().subscription.freetrialenddate.toDate() > new Date()){
                    dispatch({ type: "SET_DATA5", payload: { subscription: "True", } });
                    getUsers(userdata);
                  }
                }
              }
            }

              // postlog("read users", { component: "globalprovider", function: "getSubscription", collection: "customers", tenentid: userdata.tenentid });
            });

            //------------------------------------------------------------------------------------


          }


        }



      }
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, userdata, "gloabal provider", "getSubscription"),
      });
      setSpin(false);
    }
  };



  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return context;
};

export default GlobalProvider;
