import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "./firebase";
import { useGlobalState } from "../src/GlobalProvider";
import "./App.css";
import { useParams } from "react-router-dom";






// import Redirect from "./components/Reusable/Redirect/Redirect";
import Assessments from "./components/Assessments/Assesments/Assesments";
// import Assessment from "./components/Assessments/Assesment/Assesment";
// import AssessmentResult from "./components/Assessments/AssessmentResult/AssessmentResult";
// import AssessmentResponse from "./components/Assessments/AssessmentResponse/AssessmentResponse";
// import AssessmentTemplates from "./components/Assessments/AssessmentTemplates/AssessmentTemplates";
// import AssessmentTemplate from "./components/Assessments/AssessmentTemplate/AssessmentTemplate";
import Boards from "./components/Boards/Boards/Boards";
import ProgramBoards from "./components/ProgramBoard/ProgramBoards/ProgramBoards"
import KanbanBoards from "./components/Boards/KanbanBoards/KanbanBoards";
import ActionItems from "./components/Boards/ActionItems/ActionItems";
import LeanCoffeeMeetings from "./components/Boards/LeanCoffeeMeetings/LeanCoffeeMeetings"
import Fishbone from "./components/Boards/Fishbone/Fishbone"
// import Board from "./components/Boards/Board/Board";
// import BoardTemplates from "./components/Boards/BoardTemplates/BoardTemplates";
import PokerGames from "./components/Poker/PokerGames/PokerGames";
// import PokerGame from "./components/Poker/PokerGame/PokerGame";
// import PokerResult from "./components/Poker/PokerResult/PokerResult";
// import PokerTemplates from "./components/Poker/PokerTemplates/PokerTemplates";
import Teams from "./components/Teams/Teams/Teams";
import Team from "./components/Teams/Team/Team";
// import VotingResult from "./components/FirstofFive/VotingResult/VotingResult";
// import Vote from "./components/FirstofFive/Vote/Vote";
import Votings from "./components/FirstofFive/Votings/Votings";
import DailyScrum from "./components/DailyScrum/DailyScrum";
// import FistofFiveTemplate from "./components/FirstofFive/FistofFiveTemplate/FistofFiveTemplate";
import Notifications from "./components/Notifications/Notifications";
import Support from "./components/Support/Support";
import Subscriptions from "./components/Subscriptions/Subscriptions";
import Users from "./components/Users/Users";
// import UserInvites from "./components/Users/UserInvites/UserInvites";
import Profile from "./components/Manage Profile/Profile";
// import NotFound from "./components/Reusable/NotFound/NotFound";
// import Loading from "./components/Reusable/Loading/Loading";
// import DeactivatedUser from "./components/Reusable/DeactivatedUser/DeavtivatedUser";
// import Signup from "./components/Signup/Signup";
// import Login from "./components/Login/Login";
// import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
// import ResetPassword from "./components/ResetPassword/ResetPassword";
// import DocumentMissing from "./components/Reusable/DocumentMissing/DocumentMissing";
import Dashboard from "./components/Dashboard/Dashboard";
import SpinWheels from "./components/SpinWheel/SpinWheels/SpinWheels";
import RetrospectiveContainer from "./components/RetrospectiveContainer/RetrospectiveContainer";
import OrgHierarchy from "./components/OrgHierarchy/OrgHierarchy"
import FiveWhy from "./components/FiveWhys/FiveWhy/FiveWhy";
// import SpinWheel from "./components/SpinWheel/SpinWheel/SpinWheel";
// import SupportTicket from "./components/SupportTicket/SupportTicket";
// import Feedbacks from "./components/Feedbacks/Feedbacks";
// import Errors from "./components/Errors/Errors";
// import NewUsers from "./components/NewUsers/NewUsers";
// import Checklists from "./components/Checklists/Checklists"
import Okrs from './components/Okrs/okrs'
import Features from "./components/Features/Features"
import Risks from './components/Risks/Risks'
import Dependency from './components/Dependency/Dependency'
import Impediments from './components/Impediments/Impediments'
const Redirect = React.lazy(() => import('./components/Reusable/Redirect/Redirect'));
const PokerResult = React.lazy(() => import('./components/Poker/PokerResult/PokerResult'));
// const SpinWheels = React.lazy(() => import('./components/SpinWheel/SpinWheels/SpinWheels'));
// const Votings = React.lazy(() => import('./components/FirstofFive/Votings/Votings'));
// const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const PokerGame = React.lazy(() => import('./components/Poker/PokerGame/PokerGame'));
// const PokerGames = React.lazy(() => import('./components/Poker/PokerGames/PokerGames'));
const Board = React.lazy(() => import('./components/Boards/Board/Board'));
const ProgramBoard = React.lazy(()=> import("./components/ProgramBoard/ProgramBoard/ProgramBoard"))
// const Boards = React.lazy(() => import('./components/Boards/Boards/Boards'));
const Vote = React.lazy(() => import('./components/FirstofFive/Vote/Vote'));
const VotingResult = React.lazy(() => import('./components/FirstofFive/VotingResult/VotingResult'));
const SpinWheel = React.lazy(() => import('./components/SpinWheel/SpinWheel/SpinWheel'));
// const Assessments = React.lazy(() => import('./components/Assessments/Assesments/Assesments'));
const Assessment = React.lazy(() => import('./components/Assessments/Assesment/Assesment'));
const AssessmentResult = React.lazy(() => import('./components/Assessments/AssessmentResult/AssessmentResult'));
const AssessmentResponse = React.lazy(() => import('./components/Assessments/AssessmentResponse/AssessmentResponse'));
// const Notifications = React.lazy(() => import('./components/Notifications/Notifications'));
// const Teams = React.lazy(() => import('./components/Teams/Teams/Teams'));
// const Users = React.lazy(() => import('./components/Users/Users'));
// const Subscriptions = React.lazy(() => import('./components/Subscriptions/Subscriptions'));
// const Support = React.lazy(() => import('./components/Support/Support'));
// const Team = React.lazy(() => import('./components/Teams/Team/Team'));
// const Profile = React.lazy(() => import('./components/Manage Profile/Profile'));
const UserInvites = React.lazy(() => import('./components/Users/UserInvites/UserInvites'));
const NotFound = React.lazy(() => import('./components/Reusable/NotFound/NotFound'));
const Loading = React.lazy(() => import('./components/Reusable/Loading/Loading'));
const Signup = React.lazy(() => import('./components/Signup/Signup'));
const FistofFiveTemplate = React.lazy(() => import('./components/FirstofFive/FistofFiveTemplate/FistofFiveTemplate'));
const PokerTemplates = React.lazy(() => import('./components/Poker/PokerTemplates/PokerTemplates'));
const BoardTemplates = React.lazy(() => import('./components/Boards/BoardTemplates/BoardTemplates'));
const DeactivatedUser = React.lazy(() => import('./components/Reusable/DeactivatedUser/DeavtivatedUser'));
const AssessmentTemplate = React.lazy(() => import('./components/Assessments/AssessmentTemplate/AssessmentTemplate'));
const AssessmentTemplates = React.lazy(() => import('./components/Assessments/AssessmentTemplates/AssessmentTemplates'));
const Login = React.lazy(() => import('./components/Login/Login'));
const DocumentMissing = React.lazy(() => import('./components/Reusable/DocumentMissing/DocumentMissing'));
const ResetPassword = React.lazy(() => import('./components/ResetPassword/ResetPassword'));
const EmailPreference = React.lazy(()=> import('./components/EmailPreference/EmailPreference'));
const ForgotPassword = React.lazy(() => import('./components/ForgotPassword/ForgotPassword'));
const SupportTicket = React.lazy(() => import('./components/SupportTicket/SupportTicket'));
const ManageUser = React.lazy(() => import('./components/ManageUser/ManageUser'));
const NewUsers = React.lazy(() => import('./components/NewUsers/NewUsers'));
const Feedbacks = React.lazy(() => import('./components/Feedbacks/Feedbacks'));
const Errors = React.lazy(() => import('./components/Errors/Errors'));
const OldLoginTest = React.lazy(() => import('./components/OldLoginTest/OldLoginTest'));
const Checklists = React.lazy(() => import('./components/Checklists/Checklists/Checklists'));
const Contact = React.lazy(() => import('./components/Contact/Contact'));
// const Risks = React.lazy(() => import('./components/Risks/Risks'));
// const Dependency = React.lazy(() => import('./components/Dependency/Dependency'));
const FiveWhys = React.lazy(() => import('./components/FiveWhys/FiveWhys/FiveWhys'));
// const Okrs = React.lazy(() => import('./components/Okrs/okrs'));
// const Impediments = React.lazy(() => import('./components/Impediments/Impediments'));

function App({ user }) {
  const { state } = useGlobalState();
  return (
    <Router>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/app" />} /> */}
          <Route path="/loading" element={<Loading to="/" />} />
          <Route component={NotFound} />
          <Route
            exact
            path="/fistoffive/vote/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : (
                <Vote user={state.userglobaldata} />
              )
            }
          />
          <Route
            exact
            path="/assessmenttempletes"
            element={
              state.loading == true ? (
                <Loading />
              ) : (
                <AssessmentTemplates user={state.userglobaldata} />
              )
            }
          />
          <Route
            exact
            path="/assessmenttempletes/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : (
                <AssessmentTemplate user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/retrospective/meetings"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Boards user={state.userglobaldata} />

              )
            }
          />

<Route
            path="/programboards"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <ProgramBoards user={state.userglobaldata} />

              )
            }
          />

          {/* <Route
            path="/boards"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <KanbanBoards user={state.userglobaldata} />
              )
            }
          /> */}

          <Route
            path="/actionitems"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <ActionItems user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/leancoffeemeetings"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <LeanCoffeeMeetings user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/fishbones"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Fishbone user={state.userglobaldata} />
              )
            }
          />


          <Route
            exact
            path="/pokertemplates"
            element={
              state.loading == true ? (
                <Loading />
              ) : (
                <PokerTemplates user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/poker/games"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <PokerGames user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/fistoffive/votings"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Votings user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/fistoffive/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <VotingResult user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/dailyscrum"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <DailyScrum user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/spinwheels"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <SpinWheels user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/retrospective/:docid"
            element={state.loading == true ? (
              <Loading />
            ) : (<RetrospectiveContainer user={state.userglobaldata} />)}
          />
            <Route
            path="/programboard/:docid"
            element={state.loading == true ? (
              <Loading />
            ) : (<ProgramBoard user={state.userglobaldata} />)}
          />
          <Route
            path="/board/:docid"
            element={state.loading == true ? (
              <Loading />
            ) : (<Board user={state.userglobaldata} />)}
          />

          <Route
            path="/actionitems/:docid"
            element={state.loading == true ? (
              <Loading />
            ) : (<Board user={state.userglobaldata} />)}
          />

          <Route
            path="/leancoffeemeeting/:docid"
            element={state.loading == true ? (
              <Loading />
            ) : (<Board user={state.userglobaldata} />)}
          />
          
           <Route
            path="/fishbone/:docid"
            element={state.loading == true ? (
              <Loading />
            ) : (<Board user={state.userglobaldata} />)}
          />


          <Route
            path="/poker/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : (
                <PokerGame user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/poker/report/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <PokerResult user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/spinwheels/spinwheel/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : (
                <SpinWheel user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/assessments"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Assessments user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/assessments/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Assessment user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/assessmentresult/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <AssessmentResult user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/assessments/assessmentresponse/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : (
                <AssessmentResponse user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/subscriptions"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : ((state.userglobaldata.role == "administrator" || state.userglobaldata.role == "superadmin") &&
                state.userglobaldata.userid == state.userglobaldata.tenentid) || ((state.userglobaldata.role == "administrator" || state.userglobaldata.role == "superadmin") &&
                  state.userglobaldata.tenentid == "") ?
                (<Subscriptions user={state.userglobaldata} />) : (<DocumentMissing show={true} />)
            }
          />

          <Route
            path="/support"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Support user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/notifications"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Notifications user={state.userglobaldata} />
              )
            }
          />
          <Route
            path="/users"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "administrator" || state.userglobaldata.role == "superadmin" ?
                (<Users user={state.userglobaldata} />) : (<DocumentMissing show={true} />)
            }
          />
          <Route
            path="/teams"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                 <Teams user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/team-dashboard"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
             state &&  state.currentteam && state.currentteam.teamid != "" && state.currentteam.teamid != "none"  ? <Team user={state.userglobaldata} />  : <Teams user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/teams/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Team user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/checklists"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Checklists user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/risks"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Risks user={state.userglobaldata} />
              )
            }
          />

            <Route
              path="/dependencies"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Dependency user={state.userglobaldata} />
              )
            }
          />

          <Route
              path="/fivewhys"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <FiveWhys user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/fivewhys/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <FiveWhy user={state.userglobaldata} />
              )
            }
          />


          <Route
            path="/okrs"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Okrs user={state.userglobaldata} />
              )
            }
          />

<Route
            path="/features"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Features user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/profile"
            element={
              state.loading == true ? (
                <Loading />
                   ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Profile user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/impediments"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Impediments user={state.userglobaldata} />
              )
            }
          />


{/* email preference route  */}
          {/* email preference public route  */}
          <Route
            path="/emailpreference/:docid"
            element={state.loading == true ? (
              <Loading />
            ) : (<EmailPreference user={state.userglobaldata} />)}
          />

          <Route
            path="/users/userinvites/:docid"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <UserInvites user={state.userglobaldata} />
              )
            }
          />

          <Route
            exact
            path="/"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                <Dashboard user={state.userglobaldata} />
              )
            }
          />

          <Route
            path="/boardtemplates"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <BoardTemplates user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/assessmenttemplates"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <AssessmentTemplates user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/fistoffivetemplate"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <FistofFiveTemplate user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/supporttickets"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <SupportTicket user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/manageuser"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <ManageUser user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/feedbacks"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <Feedbacks user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/newusers"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/newusers" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <NewUsers user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />


          <Route
            path="/errors"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <Errors user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/oldlogin"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : state.userglobaldata.role == "superadmin" ? (
                <OldLoginTest user={state.userglobaldata} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/signup"
            element={state.loading == true ? (
              <Loading />
            ) : state.userglobaldata.userid == "" ||
              state.userglobaldata.userid == null ||
              state.userglobaldata.userid == undefined ? <Signup /> : <Navigate to="/" />}
          />

          <Route
            path="/login"
            element={state.loading == true ? (
              <Loading />
            ) : state.userglobaldata.userid == "" ||
              state.userglobaldata.userid == null ||
              state.userglobaldata.userid == undefined ? <Login /> : <Navigate to="/" />}
          />

          <Route
            path="/forgotpassword"
            element={<ForgotPassword />}
          />

         
          <Route
            path="/resetpassword"
            element={<ResetPassword user={state.userglobaldata} />}
          />

          <Route
            path="/contact"
            element={
              state.loading == true ? (
                <Loading />
              ) : (
                <Contact user={state.userglobaldata} />
              )
            }
          />


          <Route
            path="/org-hierarchy"
            element={
              state.loading == true ? (
                <Loading />
              ) : state.userglobaldata.userid == "" ||
                state.userglobaldata.userid == null ||
                state.userglobaldata.userid == undefined ? (
                <Navigate to="/login" />
              ) : state.userglobaldata.isactive != undefined &&
                state.userglobaldata.isactive != true ? (
                <DeactivatedUser />
              ) : (
                 <OrgHierarchy user={state.userglobaldata} />
              )
            }
          />


        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default App;
