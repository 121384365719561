import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App"
import GlobalProvider from '../src/GlobalProvider'; 
import posthog from 'posthog-js'

posthog.init('phc_IuCPouBNbuC4AQhFfqWNwc0PtCoDCn1JvR3i4BnoVGk', { api_host: 'https://us.posthog.com' })

const rt = document.getElementById("root");

// const uid = rt.getAttribute("data-userid");
// const sub = rt.getAttribute("data-subscription");
// const com = rt.getAttribute("data-companyid");
// const firstname = rt.getAttribute("data-firstname");
// const lastname = rt.getAttribute("data-lastname");
// const email = rt.getAttribute("data-email");

// const usr = {
//     userid: uid,   
//   username : firstname + ' ' + lastname,
//   companyid: com,
//  firstname : firstname,
//  lastname : lastname,
//   email: email
// };

const root = ReactDOM.createRoot(rt);

root.render( <GlobalProvider><App /> </GlobalProvider>);
//else if (cmpt == "TeamMembers")
//  root.render(<TeamMembers user={usr} docid={docid}/>);
//else
//Render page not found component here

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
