import React from 'react'
import { useState, useEffect,forwardRef, useImperativeHandle } from "react";
import { Box, Paper, Grid, TextField, Autocomplete , Typography} from '@mui/material';
import { useGlobalState } from "../../../GlobalProvider"

const SelectSprint = ({  programboarddata, isViewMode,SyncWithParentState  }) => {
    const [showFormValidate, setShowFormValidate] = useState(false);
    const [formData, setFormData] = useState({

    })
    const { state } = useGlobalState();

    useEffect(() => {

          setFormData({ });

        if (programboarddata && state.programboardsglobaldata) {
            const programboard = state.programboardsglobaldata.find((programboard) => programboard.id == programboarddata.programboard);
    
            if (programboard && programboard.programincrements) {
                let programincrement = null;
                for (let key in programboard.programincrements) {
                    if (programboard.programincrements[key].id == programboarddata.programincrement) {
                        programincrement = programboard.programincrements[key];
                        break;
                    }
                }
                if (programincrement && programincrement.sprint) {
                    let programsprint = null;
                    for (let key in programincrement.sprint) {
                        if (programincrement.sprint[key].sprintid == programboarddata.programsprint) {
                            programsprint = programincrement.sprint[key];
                            break;
                        }
                    }
                  

                    if (programsprint) {
                        setFormData({
                            selectedprogramboard: { value: programboard.id, label: programboard.name },
                            selectedIncrement: { value: programincrement.id, label: programincrement.name },
                            selectedSprint: { value: programsprint.id, label: programsprint.name },
                        });
                    }
                }
            }
        }
    }, [programboarddata, state.programboardsglobaldata]);

   

    return (
        <div>

            {state && state.programboardsglobaldata != null && state.programboardsglobaldata && (


                <div>
                    {!isViewMode ? (<div>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={2} sm={4} md={4} >
                                <Autocomplete
                                    options={state.programboardsglobaldata.map(item => ({ label: item.name, value: item.id }))}
                                    value={(formData.selectedprogramboard && formData.selectedprogramboard.label) || null}
                                    onChange={(event, newValue) => {
                                        setFormData(prevData => ({
                                            ...prevData,
                                            selectedprogramboard: newValue,
                                            selectedIncrement: null,
                                            selectedSprint: null,
                                        }));
                                     
                                    if(newValue){
                                        SyncWithParentState({
                                            programboard: newValue.value,
                                            programincrement: formData.selectedIncrement && formData.selectedIncrement.value ? formData.selectedIncrement.value : "",
                                            programsprint: formData.selectedSprint && formData.selectedSprint.value ? formData.selectedSprint.value : "",
                                        })
                                    }
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Program Board"
                                            variant="outlined"
                                            margin="normal"
                                            error={showFormValidate && formData.selectedprogramboard == null}
                                            helperText={
                                                showFormValidate && formData.selectedprogramboard == null
                                                    ? 'selected Programboard is required'
                                                    : ''
                                            }
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
  {formData.selectedprogramboard && (
    <Autocomplete
      options={(state && state.programboardsglobaldata != null && state.programboardsglobaldata &&
        Object.values(state.programboardsglobaldata?.find(item => item.id === formData.selectedprogramboard.value)?.programincrements || []) || []
      ).map(item => ({ label: item.name, value: item.id }))}
      value={(formData.selectedIncrement && formData.selectedIncrement.label) || null}
      onChange={(event, newValue) => {
        setFormData(prevData => ({
          ...prevData,
          selectedIncrement: newValue,
          selectedSprint: null,
        }));


        SyncWithParentState({
            programboard: formData.selectedprogramboard && formData.selectedprogramboard.value ? formData.selectedprogramboard.value : "",
            programincrement: newValue ? newValue.value : "", // Ensure newValue is checked before accessing .value
            programsprint: formData.selectedSprint && formData.selectedSprint.value ? formData.selectedSprint.value : "",
        })
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Program Increment"
          variant="outlined"
          margin="normal"
          error={showFormValidate && !formData.selectedIncrement}
          helperText={
            showFormValidate && !formData.selectedIncrement
              ? 'Please select a Program Increment'
              : ''
          }
        />
      )}
    />
  )}
</Grid>
                            <Grid item xs={2} sm={4} md={4} >
                                {formData.selectedIncrement && (
                                    <Autocomplete
                                        options={(Object.values(state.programboardsglobaldata.find(item => item.id === formData.selectedprogramboard.value)?.programincrements[formData.selectedIncrement.value]?.sprint) || []).map(item => ({ label: item.name, value: item.sprintid }))}
                                        value={(formData.selectedSprint && formData.selectedSprint.label) || null}
                                        onChange={(event, newValue) => {

                                            setFormData(prevData => ({
                                                ...prevData,
                                                selectedSprint: newValue
                                            }));
                                            SyncWithParentState({
                                                programboard: formData.selectedprogramboard && formData.selectedprogramboard.value ? formData.selectedprogramboard.value : "",
                                                programincrement: formData.selectedIncrement && formData.selectedIncrement.value ? formData.selectedIncrement.value : "",
                                                programsprint: newValue.value,
                                            })
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Sprint"
                                                variant="outlined"
                                                margin="normal"
                                                error={showFormValidate && !formData.selectedSprint}
                                                helperText={
                                                    showFormValidate && !formData.selectedSprint
                                                        ? 'Please select a Sprint'
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </div>) : (<div>

                        <div>

                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                                <Grid item xs={2} sm={4} md={4}>
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}> Program Board:</Typography>
                                        <Typography variant="body2">{formData.selectedprogramboard && formData.selectedprogramboard.label ? formData.selectedprogramboard.label : "Not Available"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sm={4} md={4}>
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>  Planning Interval:</Typography>
                                        <Typography variant="body2">{formData && formData.selectedIncrement && formData.selectedIncrement.label ? formData.selectedIncrement.label : "Not Available"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sm={4} md={4} >

                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>   Sprint:</Typography>
                                        <Typography variant="body2">{formData.selectedSprint && formData.selectedSprint.label ? formData.selectedSprint.label : "Not Available"}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </div>)}
                </div>

            )}
        </div>
    )
}

export default SelectSprint