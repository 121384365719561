import React from "react";
import { useState, useEffect } from "react";
import Board from "../Boards/Board/Board";
import { useParams } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SuccessToast from "../Reusable/Toasts/SuccessToast";
import ShowError from "../Reusable/ShowError/ShowError";
import Spinner from "../Reusable/Spinner/Spinner";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DocumentMissing from "../Reusable/DocumentMissing/DocumentMissing";
import { firestore } from "../../firebase";
import {
    doc,
    collection,
    setDoc,
    Timestamp,
    query,
    limit,
    where,
    getDocs,
    onSnapshot,
    orderBy
} from "firebase/firestore";
import { getErrorObject, getUserDetails, postlog } from "../../functions/Common"
import { useGlobalState } from "../../GlobalProvider";
import { Button, Card, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ProAlert from "../Reusable/ProAlert/ProAlert"
function RetrospectiveContainer({ user }) {
    const { docid } = useParams();
    const [showBoard, setShowBoard] = useState("retrospective");
    const [value, setValue] = useState(0);
    const [boardTemplates, setBoardTemplates] = useState();
    const [selectedActionBoard, setSelectedActionBoard] = useState("");
    const [customStyle, setCustomStyle] = useState({
        backgroundColor: "#61a1fe",
        border: "2px solid #61a1fe",
    });
    const [containerData, setContainerData] = useState()
    const [spin, setSpin] = useState(false);
    const [showDocumentMissing, setshowDocumentMissing] = useState(false);
    const [showError, setShowError] = useState({ show: false, errordata: {} });
    const [Toast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSubject, setToastSubject] = useState("");
    const [actionItemBoards, setActionItemBoards] = useState([]);
    const [formData, setFormData] = useState({
        id: "",
        name: "",
        layout: "column",
        columns: [],
        backgroundimage: "",
    });
    const { state } = useGlobalState();
    const [showSettings, setShowSettings] = useState(false);
    const [showPro, setShowPro] = useState(false);






    useEffect(() => {
        async function getBoardTemplates() {
            try {
                let docexist = false;
                setSpin(true);
                const q = await query(collection(firestore, "boardtemplates"), where("templatename", "==", "Action Items"), limit(1));
                const snapshot = await getDocs(q);
             //   postlog("read boardtemplates", { component: "ActionItems", function: "getBoardTemplates", collection: "boardtemplates" });
                setBoardTemplates([]);
                let boardTemplatess = [];
                snapshot.forEach((doc) => {
                    docexist = true;
                    const newdata = doc.data();
                    boardTemplatess.push(newdata);
                    setBoardTemplates((prevMember) => {
                        return [...prevMember, newdata];
                    });
                    setSpin(false);
                });

                const sortedArray = boardTemplatess.sort((a, b) => a.displayorder - b.displayorder);
                setBoardTemplates(sortedArray);

                if (!docexist) {
                    setshowDocumentMissing(true);
                }
            } catch (err) {
                setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "getBoardTemplates") });
                setSpin(false);
            }
        }

        async function getActionItemBoard() {
            try {
                setSpin(true);
                const q = await query(
                    collection(firestore, "boards"),
                    where("users", "array-contains", user.userid),
                    where("catogery", "==", "actionitemboard"),
                    orderBy("updateddate", "desc"),
                    limit(5)
                );

                const querySnapshot = await getDocs(q);
                setActionItemBoards([]);

                querySnapshot.forEach((doc) => {
                    const newdata = doc.data();
                    setActionItemBoards((prevBoards) => {
                        return [...prevBoards, newdata];
                    });
                });
              //  postlog("read boards", { component: "retrospectivecontainer", function: "getActionItemBoard", collection: "boards", uid: user.userid });
                setSpin(false);
            } catch (err) {
                setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "getActionItemBoard") });
                setSpin(false);
            }
        }

        getBoardTemplates();

        if (user.userid != "") {
            getActionItemBoard()
        }
    }, [])

    function updateContainerData(retrospectiveboardid, actionitemboardid, retrospectiveboardname, guestusername, isowner) {
        setContainerData({
            retrospectiveboardid: retrospectiveboardid == null ? containerData.retrospectiveboardid : retrospectiveboardid,
            actionitemboardid: actionitemboardid == null ? containerData?.actionitemboardid : actionitemboardid,
            retrospectiveboardname: retrospectiveboardname == null ? containerData.retrospectiveboardname : retrospectiveboardname,
            guestusername: guestusername == null ? containerData.guestusername : guestusername,
            isowner: isowner == null ? containerData?.isowner : isowner
        })
    }

    const handleTabChange = (event, newValue) => {
        event.preventDefault();
        event.stopPropagation();
        setValue(newValue);
        if (newValue == 0) {
            setShowBoard("retrospective")
        } else if (newValue == 1) {
            setShowBoard("actionitem")
        }
    };

    const handleChange = (event) => {
        try {
            const { name, value } = event.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "handleChange") });
            setSpin(false);
        }
    };

    const handleChange2 = (index, event) => {
        try {
            const { name, value } = event.target;
            const updatedColumns = [...formData.columns];
            updatedColumns[index] = { ...updatedColumns[index], columnname: value };
            setFormData({ ...formData, columns: updatedColumns });
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "handleChange2") });
            setSpin(false);
        }
    };

    const addColumn = () => {
        try {
            if (state.subscriptionglobaldata.subscription == "False") {
                setToastMessage("This is an Agilebin Pro feature. Please upgrade your plan to Agilebin Pro to use this feature.")
                setToastSubject("Pro Feature")
                setShowToast(true)
                setTimeout(() => {
                    setShowToast(false);
                }, 1000);
            }
            else {
                let newcolumn = doc(collection(firestore, "boards"));
                newcolumn = { columnname: "", columnid: newcolumn.id, itemorder: "" };
                const newcolumns = [...formData.columns, newcolumn];
                setFormData({ ...formData, columns: newcolumns });
            }
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "addColumn") });
            setSpin(false);
        }
    };


    async function deleteColumn(columnid, boardid) {
        try {
            if (formData.columns.length == 1) {
                alert("At least you shoude have one column.");
            } else {
                const updatedColumns = [...formData.columns];
                const index = updatedColumns.findIndex(
                    (obj) => obj.columnid === columnid
                );
                if (index !== -1) {
                    const newupdatedColumns = [...updatedColumns];
                    newupdatedColumns.splice(index, 1);
                    setFormData({ ...formData, columns: newupdatedColumns });
                }
            }
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "deleteColumn") });
            setSpin(false);
        }
    }

    const hideForm = () => {
        try {
            setFormData({
                id: "",
                name: "",
                columns: [],
                backgroundimage: "",
                layout: "",
                mode: "",
            });

            let model = document.getElementById("createActionItemPopup");
            model.style.display = "none";
            setSpin(false);
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "Boards", "hideForm") });
            setSpin(false);
        }
    };

    const handleSubmit = (event) => {
        try {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                // Process the form data here (e.g., submit to the server)
                event.preventDefault();
                event.stopPropagation();
                saveBoard({
                    id: formData.id,
                    name: formData.name,
                    columns: formData.columns,
                    backgroundimage: formData.backgroundimage,
                    layout: formData.layout,
                    meetingurl: "https://www.agilebin.com/board/",
                    mode: formData.mode,
                    catogery: formData.catogery,
                });

                hideForm();
                event.preventDefault();
            }
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "handleSubmit") });
            setSpin(false);
        }
    };

    function createBoard(boardTemplates) {
        try {
            if (state.subscriptionglobaldata.subscription == "True") {
                const selectedTemplate = boardTemplates.find(
                    (template) => template.id === boardTemplates[0].id
                );
                let columns = [];
                columns = selectedTemplate["columns"].split(",");
                let model = document.getElementById("createActionItemPopup");
                model.style.display = "block";
                let columns2 = [];

                columns.map((colname) => {
                    let newcolumn = doc(collection(firestore, "boards"));
                    newcolumn = {
                        columnname: colname,
                        columnid: newcolumn.id,
                        itemorder: "",
                    };
                    columns2.push(newcolumn);
                });

                setFormData({
                    name: formData.name,
                    columns: columns2,
                    backgroundimage: selectedTemplate.backgroundimage,
                    layout: selectedTemplate.layout,
                    mode: selectedTemplate.mode,
                    catogery: selectedTemplate.catogery,
                });
            }
            else {
                if (actionItemBoards.length > 0) {
                    setShowPro(true);
                } else {
                    const selectedTemplate = boardTemplates.find(
                        (template) => template.id === boardTemplates[0].id
                    );
                    let columns = [];
                    columns = selectedTemplate["columns"].split(",");
                    let model = document.getElementById("createActionItemPopup");
                    model.style.display = "block";
                    let columns2 = [];

                    columns.map((colname) => {
                        let newcolumn = doc(collection(firestore, "boards"));
                        newcolumn = {
                            columnname: colname,
                            columnid: newcolumn.id,
                            itemorder: "",
                        };
                        columns2.push(newcolumn);
                    });

                    setFormData({
                        name: formData.name,
                        columns: columns2,
                        backgroundimage: selectedTemplate.backgroundimage,
                        layout: selectedTemplate.layout,
                        mode: selectedTemplate.mode,
                        catogery: selectedTemplate.catogery,
                    });
                }
            }
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "createBoard") });
            setSpin(false);
        }
    }

    async function saveBoard(board) {
        try {
            //////////////////Build column map/////////////////////////
            let columns = {};
            let columnOrder = "";
            for (const key in board.columns) {
                columnOrder += board.columns[key].columnid;
                columnOrder += ",";
                columns[board.columns[key].columnid] = {
                    columnname: board.columns[key].columnname,
                    columnid: board.columns[key].columnid,
                    itemorder: board.columns[key].itemorder,
                };
            }
            //////////////////////////////////////////////////////////
            if (!board.id) {
                const docref = doc(collection(firestore, "boards"));
                await setDoc(docref, {
                    id: docref.id,
                    name: board.name,
                    createddate: Timestamp.fromDate(new Date()),
                    updateddate: Timestamp.fromDate(new Date()),
                    createdbyuserid: user.userid,
                    createdbyusername: user.username,
                    createdbyuserprofile:
                        user.profilepicture != undefined ? user.profilepicture : "",
                    createdbyuseremail: user.useremail,
                    updatedbyuserid: user.userid,
                    updatedbyusername: user.username,
                    updatedbyuserprofile:
                        user.profilepicture != undefined ? user.profilepicture : "",
                    updatedbyuseremail: user.useremail,
                    columns: columns,
                    columnorder: columnOrder,
                    items: {},
                    level: "public",
                    backgroundimage: board.backgroundimage,
                    layout: board.layout,
                    meetingurl: formData.catogery,
                    users: [user.userid],
                    usersdetails: { [user.userid]: getUserDetails(user) },
                    mode: board.mode,
                    catogery: formData.catogery,
                })

                const docRefboard = doc(firestore, "boards", docid);
                await setDoc(
                    docRefboard,
                    {
                        actionitemsboard: docref.id,
                    },
                    { merge: true }
                );
            }

            hideForm();
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "saveBoard") });
            setSpin(false);
        }
    }

    function actionItemChange(event) {
        setSelectedActionBoard(event.target.value)
    }

    async function linkActionItemBoard(e) {
        try {
            e.preventDefault();
            setSpin(true);
            const docRefboard = doc(firestore, "boards", docid);
            await setDoc(
                docRefboard,
                {
                    actionitemsboard: selectedActionBoard,
                },
                { merge: true }
            );
            setSpin(false);
        } catch (err) {
            setShowError({ show: true, errordata: getErrorObject(err, user, "retrospectivecontainer", "linkActionItemBoard") });
            setSpin(false);
        }
    }
    const handleCloseAlert = () => {
        setShowPro(false);
    };

    return (
        <>

            <>
                <nav
                    style={{ zIndex: "100", position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)" }}
                >
                    <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example" centered>
                        <Tab label="Retrospective" />
                        <Tab label="Action Items" />
                    </Tabs>
                </nav >

                <div style={{
                    display: "flex",
                    overflow: "hidden",
                }}>
                    <div style={{
                        transform: showBoard == "retrospective" ? 'translateX(0%)' : 'translateX(-100%)',
                        transition: 'transform 0.6s ease-in-out',
                    }}>
                        <Board user={user} containerData={containerData} updateContainerData={updateContainerData} />
                    </div>

                    <div style={{
                        transform: showBoard == "actionitem" ? 'translateX(-100%)' : 'translateX(0%)',
                        transition: 'transform 0.6s ease-in-out',
                         height:  "100vh"
                    }}>
                        {containerData != undefined && (
                            containerData.actionitemboardid == "" || containerData.actionitemboardid == undefined || containerData.actionitemboardid == null ? (
                                containerData.isowner ? (<>
                                    <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15vh" }}>
                                        <div className="container" style={{ margin: "2rem auto", maxWidth: "800px" }}>
                                            <div className="instruction">
                                                <Typography variant="h4" gutterBottom>
                                                    Consolidated Action Items Board
                                                </Typography>
                                                <Typography variant="body1" className="text-secondary" paragraph>
                                                    Link your action items board across multiple retrospectives! One consolidated board lets your team track progress, assign owners, and discuss improvements easily. Keep focus on action, not lost items!
                                                </Typography>
                                                <hr />
                                            </div>

                                            <div className="row">
                                                {actionItemBoards.length != 0 && (
                                                    <>
                                                        <Card className="col-md-5 m-3 p-4">
                                                            <form onSubmit={linkActionItemBoard}>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Select Action Items Board
                                                                </Typography>
                                                                <div className="d-flex align-items-center">
                                                                    <FormControl className="mb-2" fullWidth>
                                                                        <InputLabel id="selectActionItem-label">Select Board</InputLabel>
                                                                        <Select
                                                                            labelId="selectActionItem-label"
                                                                            id="selectActionItem"
                                                                            value={selectedActionBoard}
                                                                            onChange={actionItemChange}
                                                                            required
                                                                        >
                                                                            <MenuItem >
                                                                                <em>Select Board</em>
                                                                            </MenuItem>
                                                                            {actionItemBoards.map((actionItemBoard) => (
                                                                                <MenuItem key={actionItemBoard.id} value={actionItemBoard.id}>
                                                                                    {actionItemBoard.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                                <Button variant="contained" type="submit" className="ml-2">
                                                                    Link Board
                                                                </Button>
                                                            </form>
                                                        </Card>

                                                        <div className="col-md-1 d-flex align-items-center">
                                                            <Typography variant="body1" className="mb-2 mt-3">
                                                                O R
                                                            </Typography>
                                                        </div>
                                                    </>
                                                )}


                                                <Card className="col-md-5 m-3 p-4 mx-auto">
                                                    <Typography variant="h6" gutterBottom>
                                                        Create a new Action Items board
                                                    </Typography>
                                                    <Typography variant="body1" paragraph>
                                                        Create a new Action Items board and link to retrospective.
                                                    </Typography>
                                                    <Button variant="contained" onClick={() => createBoard(boardTemplates)}>
                                                        Create Board
                                                    </Button>
                                                </Card>
                                            </div>
                                            {state.subscriptionglobaldata.subscription == "False" && (
                                                <div className="row">
                                                    <hr></hr>
                                                    <Typography className="text-secondary" variant="body1" paragraph>
                                                        With Agilebin free version you can enjoy one action items board. If you want to create more action items board then  <Link to="/subscriptions" underline="hover">
                                                            {'upgrade to PRO version'}
                                                        </Link>
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>

                                        {/* <div className="card" style={{ maxWidth: "600px", padding: "30px", }}>
                <p>This Retrospective is not linked to any Action Item Board.</p>
                <hr />
                {actionItemBoards.length > 0 && (
                    <>
                        <div >
                            <form onSubmit={linkActionItemBoard}>
                                <label htmlFor="" className="mb-1">Select Action Item Board</label>
                                <select id="selectActionItem" className="form-select mb-2" onChange={actionItemChange} value={selectedActionBoard} required style={{ maxWidth: "300px" }}>
                                    <option value="">Select Users</option>
                                    {actionItemBoards.map((actionItemBoard) => {
                                        return <option key={actionItemBoard.id} value={actionItemBoard.id}>{actionItemBoard.name}</option>;
                                    })}
                                </select>

                                <Button variant="contained" type="submit">Link Selected Action Item Board</Button>
                            </form>
                        </div>
                        <p className="mb-2 mt-3">Or</p>
                    </>
                )}

                <div>
                    <p className="mb-1">Create a new Action Item board</p>
                    <Button variant="contained" onClick={() => createBoard(boardTemplates)}>Create Action Item Board</Button>
                </div>
            </div> */}
                                    </div>
                                </>) : (
                                    <>
                                        <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <div className="card" style={{ maxWidth: "600px", padding: "30px", }}>
                                                <p>Looks like no action items board is attached to this retrospective. Please wait while the meeting facilitator gets its ready.</p>
                                            </div>
                                        </div>
                                    </>
                                )

                            ) : (
                                < Board user={user} containerData={containerData} actionboardid={containerData.actionitemboardid} updateContainerData={updateContainerData} />
                            )
                        )}
                    </div>
                </div >
            </>

            <div id="createActionItemPopup" className="modal">
                <div className="modal-content">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-header">
                            <div>
                                <b>Action Items</b>
                            </div>
                            <div className="circleIconContainer" onClick={hideForm}>
                                <span className="circleIcon">&times;</span>
                            </div>
                        </div>

                        <div className="modal-body">
                            <div>
                                <label>
                                    Board Name
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    />
                                </label>

                                <div className="mb-2"></div>
                                <span className="mt-2 showmorebutton" role="button" onClick={() => {
                                    setShowSettings(!showSettings)
                                }}> {showSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}   Show Settings</span>
                                <div className={`scrollable-div showsettings mt-1 ${showSettings ? 'show' : ''}`}>
                                    <div
                                        className="text-end mb-1"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignitems: "center",
                                        }}
                                    >
                                        {state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "False" ? (<div className="mt-3">
                                            Columns <small>(To Customize columns,{" "}
                                                <Link to={`/subscriptions`}>upgrade to </Link> <div className="pro-custom pro-badge"> PRO</div>)</small>
                                        </div>) : (<div className="mt-3 ">Customize Columns  </div>)}
                                        {" "}

                                    </div>

                                    <div className="text-end mb-1">
                                        <button
                                            type="button"
                                            title="Add Column"
                                            onClick={addColumn}
                                            className="btn btn-sm btn-light "
                                        >
                                            <label htmlFor="add-column" role="button"  >
                                                Add Column
                                            </label>
                                            <i
                                                className="fa fa-plus-circle px-0 mx-2 "
                                                id="add-column"
                                                name="add-column"
                                                role="button"
                                                style={{ paddingLeft: "5px" }}
                                            ></i>
                                        </button>
                                    </div>

                                    {formData.columns !== undefined &&
                                        formData.columns.map((elemnt, index) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                }}
                                                key={index}
                                                className="mb-2"
                                            >
                                                <input
                                                    key={index}
                                                    type="text"
                                                    placeholder="Column name"
                                                    name={elemnt.columnid}
                                                    value={elemnt.columnname}
                                                    className="form-control"
                                                    onChange={(event) => handleChange2(index, event)}
                                                    required={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "True"}
                                                    disabled={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "False"}
                                                />
                                                <button
                                                    title="You can only delete a column if it's empty."
                                                    disabled={
                                                        elemnt.itemorder !== "" ||
                                                        formData.columns.length < 2 ||
                                                        state.subscriptionglobaldata && state.subscriptionglobaldata.subscription == "False"
                                                    }
                                                    style={{ border: "none", background: "none" }}
                                                    type="button"
                                                >
                                                    <i
                                                        className="fa fa-trash fs-6"
                                                        role="button"
                                                        onClick={() =>
                                                            deleteColumn(elemnt.columnid, formData.boardid)
                                                        }
                                                    ></i>
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <Button type="submit" variant="contained">
                                {formData.id != "" && formData.id != null && formData.id != undefined ? "Save" : "Create Action Items"}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

            <ProAlert showAlert={showPro} hideAlert={handleCloseAlert} />
            <ShowError Error={showError} />
            <Spinner spin={spin} />
            <DocumentMissing show={showDocumentMissing} />
            <SuccessToast
                show={Toast}
                message={toastMessage}
                subject={toastSubject}
                style={customStyle}
            />

        </>
    )
}

export default RetrospectiveContainer;