import React, { useState,useContext,useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useGlobalState,GlobalContext  } from "../../../GlobalProvider";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import {ListItemText,Tooltip ,AccordionDetails,AccordionSummary,Accordion} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const drawerWidth = 240;

const darkblueTheme = createTheme({
  palette: {
    mode: 'dark', // Set the theme mode to dark
    primary: {
      main: '#24344b', // Adjust the primary color to a lighter shade of dark
    },
    background: {
      default: '#24344b', // Adjust the default background color to a lighter shade of dark
      paper: '#24344b', // Adjust the paper color to a lighter shade of dark
    },
    // You can customize other palette colors here
  },
});

const determinePanel = (pathname) => {
  if (
    ["/okrs",  "/dependencies", "/features", "/risks","/programboards"].some(route => pathname.includes(route))
  ) {
    return "panel1";
  }
  if (
    ["/fistoffive/votings","/impediments", "/dailyscrum", "/poker/games", "/spinwheels"].some(route => pathname.includes(route))
  ) {
    return "panel2";
  }
  if (
    ["/retrospective/meetings", "/fivewhys", "/fishbones", "/actionitems", "/assessments", "/leancoffeemeetings"].some(route => pathname.includes(route))
  ) {
    return "panel3";
  }
  if (
    ["/users", "/teams", "/subscriptions", "/support"].some(route => pathname.includes(route))
  ) {
    return "panel4";
  }
  return "";
};

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2a5298', // A brighter shade of blue
    },
    background: {
      default: '#f0f5f9', // Light gray background
      paper: '#ffffff', // White paper background
    },
    // Adjust the selected color
    action: {
      selected: '#66b3ff', // Adjusted selected color
    },
    // You can customize other palette colors here
  },
});


const darkblackTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1e1e1e', // A dark background color for the sidebar
      paper: '#1e1e1e', // Use the same color for paper to maintain consistency
    },
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const Sidebar = ({ isopen, user,toggleSidebar }) => {




  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    // setOpen(false);
    toggleSidebar();
  };

  const location = useLocation();
  // const { state } = useGlobalState();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const [teamFilter,setTeamFilter]= useState(state.currentteam.teamid);
  const [expanded, setExpanded] = useState(determinePanel(location.pathname));


  // dashboard icon
  // M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z 
  const tools = [
    {
      id: 1,
      name: 'Home',
      link: '/',
      svgpath: "M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z"
    },
    {
      id: 2,
      name: 'Team Dashboard',
      link: '/team-dashboard',
      svgpath: "M0-240v-63q0-43 44-70t116-27q13 0 25 .5t23 2.5q-14 21-21 44t-7 48v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5q72 0 116 26.5t44 70.5v63H780Zm-455-80h311q-10-20-55.5-35T480-370q-55 0-100.5 15T325-320ZM160-440q-33 0-56.5-23.5T80-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T160-440Zm640 0q-33 0-56.5-23.5T720-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T800-440Zm-320-40q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm0-80q17 0 28.5-11.5T520-600q0-17-11.5-28.5T480-640q-17 0-28.5 11.5T440-600q0 17 11.5 28.5T480-560Zm1 240Zm-1-280Z"
    },
    {
      id: 8,
      name: 'Fist of Five',
      link: '/fistoffive/votings',
      svgpath: "M402-40q-30 0-56-13.5T303-92L48-465l24-23q19-19 45-22t47 12l116 81v-383q0-17 11.5-28.5T320-840q17 0 28.5 11.5T360-800v537L212-367l157 229q5 8 14 13t19 5h278q33 0 56.5-23.5T760-200v-560q0-17 11.5-28.5T800-800q17 0 28.5 11.5T840-760v560q0 66-47 113T680-40H402Zm38-440v-400q0-17 11.5-28.5T480-920q17 0 28.5 11.5T520-880v400h-80Zm160 0v-360q0-17 11.5-28.5T640-880q17 0 28.5 11.5T680-840v360h-80ZM486-300Z"     
    },
    
 
    {
      id: 9,
      name: 'Poker Games',
      link: '/poker/games',
      svgpath: "m608-368 46-166-142-98-46 166 142 98ZM160-207l-33-16q-31-13-42-44.5t3-62.5l72-156v279Zm160 87q-33 0-56.5-24T240-201v-239l107 294q3 7 5 13.5t7 12.5h-39Zm206-5q-31 11-62-3t-42-45L245-662q-11-31 3-61.5t45-41.5l301-110q31-11 61.5 3t41.5 45l178 489q11 31-3 61.5T827-235L526-125Zm-28-75 302-110-179-490-301 110 178 490Zm62-300Z"     
    },


    {
      id: 6,
      name: 'Daily Scrum',
      link: '/dailyscrum',
      svgpath: "M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"     
    }, 

      
    
    {
      id: 7,
      name: 'Impediments',
      link: '/impediments',
      svgpath: "M480-800 243-663l237 137 237-137-237-137ZM120-321v-318q0-22 10.5-40t29.5-29l280-161q10-5 19.5-8t20.5-3q11 0 21 3t19 8l280 161q19 11 29.5 29t10.5 40v159h-80v-116L479-434 200-596v274l240 139v92L160-252q-19-11-29.5-29T120-321ZM720-80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm-20-80h40v-160h-40v160ZM720 0q-83 0-141.5-58.5T520-200q0-83 58.5-141.5T720-400q83 0 141.5 58.5T920-200q0 83-58.5 141.5T720 0ZM480-491Z"     
    }, 

    {
      id: 12,
      name: 'SpinWheels',
      link: '/spinwheels',
      svgpath: "m233-80 54-122q-14-11-27-21.5T235-246q-8 3-15.5 4.5T203-240q-33 0-56.5-23.5T123-320q0-20 8.5-36.5T155-384q-8-23-11-46.5t-3-49.5q0-26 3-49.5t11-46.5q-15-11-23.5-27.5T123-640q0-33 23.5-56.5T203-720q9 0 16.5 1.5T235-714q33-36 75.5-60t90.5-36q5-30 27.5-50t52.5-20q30 0 52.5 20.5T561-810q48 12 90.5 35.5T727-716q8-3 15-4.5t15-1.5q33 0 56.5 23.5T837-642q0 20-8 35.5T807-580q8 24 11 49t3 51q0 26-3 50.5T807-382q14 11 22 26.5t8 35.5q0 33-23.5 56.5T757-240q-8 0-15-1.5t-15-4.5q-12 12-24.5 23.5T675-200l52 120h-74l-38-88q-14 6-27 10.5t-27 7.5q-5 29-27.5 49.5T481-80q-30 0-52.5-20T401-150q-15-3-28.5-7.5T345-168l-38 88h-74Zm76-174 62-140q-14-18-22-40t-8-46q0-57 41.5-98.5T481-620q57 0 98.5 41.5T621-480q0 24-8.5 47T589-392l62 138q9-8 17.5-14.5T685-284q-5-8-6.5-17.5T677-320q0-32 22-55t54-25q6-20 9-39.5t3-40.5q0-21-3-41.5t-9-40.5q-32-2-54-25t-22-55q0-9 2.5-17.5T685-676q-29-29-64-49t-74-31q-11 17-28 26.5t-38 9.5q-21 0-38-9.5T415-756q-41 11-76 31.5T275-674q3 8 5.5 16.5T283-640q0 32-21 54.5T209-560q-6 20-9 39.5t-3 40.5q0 21 3 40.5t9 39.5q32 2 53 25t21 55q0 9-1.5 17.5T275-286q8 9 16.5 16.5T309-254Zm60 34q11 5 22.5 9t23.5 7q11-17 28-26.5t38-9.5q21 0 38 9.5t28 26.5q12-3 22.5-7t21.5-9l-58-130q-12 5-25 7.5t-27 2.5q-15 0-28.5-3t-25.5-9l-58 132Zm112-200q24 0 42-17t18-43q0-24-18-42t-42-18q-26 0-43 18t-17 42q0 26 17 43t43 17Zm0-60Z"     
    }, 

    

    {
      id: 3,
      name: 'Okrs',
      link: '/okrs',
      svgpath: "M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-80q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70Zm0-80q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-80q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Z"     
    },
    {
      id: 3.1,
      name: 'Features',
      link: '/features',
      svgpath: "M360-200v-80h480v80H360Zm0-240v-80h480v80H360Zm0-240v-80h480v80H360ZM200-160q-33 0-56.5-23.5T120-240q0-33 23.5-56.5T200-320q33 0 56.5 23.5T280-240q0 33-23.5 56.5T200-160Zm0-240q-33 0-56.5-23.5T120-480q0-33 23.5-56.5T200-560q33 0 56.5 23.5T280-480q0 33-23.5 56.5T200-400Zm0-240q-33 0-56.5-23.5T120-720q0-33 23.5-56.5T200-800q33 0 56.5 23.5T280-720q0 33-23.5 56.5T200-640Z"     
    },

    {
      id: 4,
      name: 'Retrospective',
      link: '/retrospective/meetings',
      svgpath: "M160-240h160v-480H160v480Zm240 0h160v-480H400v480Zm240 0h160v-480H640v480Zm-480 80q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Z"     
    },
    {
      id: 5,
      name: 'Action Items',
      link: '/actionitems',
      svgpath: "M222-200 80-342l56-56 85 85 170-170 56 57-225 226Zm0-320L80-662l56-56 85 85 170-170 56 57-225 226Zm298 240v-80h360v80H520Zm0-320v-80h360v80H520Z"     
    },


      {
      id: 10,
      name: 'Agile Assessments',
      link: '/assessments',
      svgpath: "M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880h40v331q18 11 29 28.5t11 40.5q0 33-23.5 56.5T480-400q-33 0-56.5-23.5T400-480q0-23 11-41t29-28v-86q-52 14-86 56.5T320-480q0 66 47 113t113 47q66 0 113-47t47-113q0-36-14.5-66.5T586-600l57-57q35 33 56 78.5t21 98.5q0 100-70 170t-170 70q-100 0-170-70t-70-170q0-90 57-156.5T440-717v-81q-119 15-199.5 105T160-480q0 134 93 227t227 93q134 0 227-93t93-227q0-69-27-129t-74-104l57-57q57 55 90.5 129.5T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"     
    }, 
 
    {
      id: 11,
      name: 'Lean Coffee',
      link: '/leancoffeemeetings',
      svgpath: "M160-120v-80h640v80H160Zm160-160q-66 0-113-47t-47-113v-400h640q33 0 56.5 23.5T880-760v120q0 33-23.5 56.5T800-560h-80v120q0 66-47 113t-113 47H320Zm0-480h320-400 80Zm400 120h80v-120h-80v120ZM560-360q33 0 56.5-23.5T640-440v-320H400v16l72 58q2 2 8 16v170q0 8-6 14t-14 6H300q-8 0-14-6t-6-14v-170q0-2 8-16l72-58v-16H240v320q0 33 23.5 56.5T320-360h240ZM360-760h40-40Z"     
    }, 

    {
      id: 14,
      name: 'Dependencies',
      link: '/dependencies',
      svgpath: "M600-120v-120H440v-400h-80v120H80v-320h280v120h240v-120h280v320H600v-120h-80v320h80v-120h280v320H600ZM160-760v160-160Zm520 400v160-160Zm0-400v160-160Zm0 160h120v-160H680v160Zm0 400h120v-160H680v160ZM160-600h120v-160H160v160Z"     
    }, 
 

    {
      id: 13,
      name: 'Risks',
      link: '/risks',
      svgpath: "M480-281q14 0 24.5-10.5T515-316q0-14-10.5-24.5T480-351q-14 0-24.5 10.5T445-316q0 14 10.5 24.5T480-281Zm-30-144h60v-263h-60v263ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm25-60h250l175-175v-250L605-780H355L180-605v250l175 175Zm125-300Z"     
    }, 
 
    {
      id: 3.2,
      name: 'Program Board',
      link: '/programboards',
      svgpath: "M160-120q-33 0-56.5-23.5T80-200v-560q0-33 23.5-56.5T160-840h560q33 0 56.5 23.5T800-760v80h80v80h-80v80h80v80h-80v80h80v80h-80v80q0 33-23.5 56.5T720-120H160Zm0-80h560v-560H160v560Zm80-80h200v-160H240v160Zm240-280h160v-120H480v120Zm-240 80h200v-200H240v200Zm240 200h160v-240H480v240ZM160-760v560-560Z"     
    },
  
    {
      id: 15,
      name: 'Fishbones',
      link: '/fishbones',
      svgpath: "M120-360q-33 0-56.5-23.5T40-440v-360q0-33 23.5-56.5T120-880h720q33 0 56.5 23.5T920-800v360q0 33-23.5 56.5T840-360H120Zm0-440v360h720v-360H120Zm3 580-3-60 719-38 3 60-719 38Zm-3 99v-60h720v60H120Zm290-379q74 0 142.5-26T672-606q6 42 44 64t84 22v-200q-46 0-84 22.5T672-632q-53-52-120.5-80T410-740q-79 0-152 27.5T140-620q45 65 118 92.5T410-500ZM120-800v360-360Z"     
    },
    {
      id: 16,
      name: 'Five Whys',
      link: '/fivewhys',
      svgpath: "M320-200v-120h240v-100H320v-340h360v120H440v100h120q50 0 85 35t35 85v100q0 50-35 85t-85 35H320Z"     
    },
  ]

  const superadmintools = [
    {
      id: 0.5,
      name: 'Manage User',
      link: '/manageuser',
      icon: "domino_mask",
    },
    {
      id: 1,
      name: 'Support Tickets',
      link: '/supporttickets',
      icon: 'domino_mask',
    },
    {
      id: 2,
      name: 'Feedbacks',
      link: '/feedbacks',
      icon: 'domino_mask',
      roles: []
    },
    {
      id: 3,
      name: 'Errors',
      link: '/errors',
      icon: 'domino_mask',
      roles: []
    },
    {
      id: 4,
      name: 'New Users',
      link: '/newusers',
      icon: 'domino_mask'
    },
    {
      id: 5,
      name: 'Board Templates',
      link: '/boardtemplates',
      icon: 'domino_mask'
    },
    {
      id: 6,
      name: 'Assessment Templates',
      link: '/assessmenttemplates',
      icon: 'domino_mask'
    },
    {
      id: 7,
      name: 'Poker Templates',
      link: '/pokertemplates',
      icon: 'domino_mask'
    },
    {
      id: 8,
      name: 'Fist of Five Templates',
      link: '/fistoffivetemplate',
      icon: 'domino_mask'
    },
  ]
  const handleTeamSelect = (id, name) => {
    if (id !== undefined && id !== null) {
      dispatch({ type: 'SET_DATA8', payload: { teamname: name, teamid: id } });
    } else {
      dispatch({ type: 'SET_DATA8', payload: { teamname: "", teamid: "" } });
    }
    setTeamFilter(id);
  };


  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (

<ThemeProvider theme={lightTheme}>
<Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={isopen}
    >
      <Box sx={{ height: '58px',display:"flex",justifyContent:"space-between" }}>
        <FormControl sx={{ flexGrow: 1, m: 1, width: 0, minWidth: '150px' }} size="small">
          <InputLabel id="team-filter-label">Select Team</InputLabel>
          <Select
            labelId="team-filter-label"
            id="team-filter"
            value={teamFilter ? teamFilter : "none"}
            label="Select Team"
          >
            <MenuItem value="none" selected onClick={() => handleTeamSelect("none", "none")}>
              All Teams
            </MenuItem>
            {state?.teamsglobaldata?.map((team) => (
              <MenuItem
                value={team.id}
                key={team.id}
                data-name={team.name}
                onClick={() => handleTeamSelect(team.id, team.name)}
              >
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <Tooltip title='Hide Sidebar'>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />

      <List sx={{ padding: 0 }}>
        <div style={{marginBottom:"10px"}}>
      {tools.map((tool) => {
  if (tool.id === 1 || tool.id === 2) { 
    return (
      <Link to={tool.link} style={{ textDecoration: 'none', color: 'inherit' }} key={tool.id}> 
        <ListItem
          disablePadding
          selected={tool.link !== '/' ? location.pathname.includes(tool.link) : location.pathname === tool.link}
          button
        >
          <ListItemButton style={{ padding: '0' }}>
            <ListItemIcon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', padding: '10px', fill: '#727275' }}>
                <path d={tool.svgpath} />
              </svg>
            </ListItemIcon>
            <ListItemText 
              primary={
                <Typography variant="body2" sx={{ fontSize: '14px' }}>
                  {tool.name}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </Link>
    );
  }
  return null;
})}
</div>
<Divider />


        <Accordion  className="AccordionSidebar" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Collaborate</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {tools.filter(tool => [8, 9,6,7, 12].includes(tool.id)).map((tool) => (
              <Link to={tool.link} style={{ textDecoration: 'none', color: 'inherit' }} key={tool.id}>
                <ListItem disablePadding selected={tool.link !== '/' ? location.pathname.includes(tool.link) : location.pathname === tool.link} button>
                  <ListItemButton style={{ padding: '0 8px' }}>
                    <ListItemIcon>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', padding: "10px", fill: "#727275" }}>
                        <path d={tool.svgpath} />
                      </svg>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px' }}>{tool.name}</Typography>} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion  className="AccordionSidebar" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Reflect</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {tools.filter(tool => [4,5, 10, 11, 15, 16].includes(tool.id)).map((tool) => (
              <Link to={tool.link} style={{ textDecoration: 'none', color: 'inherit' }} key={tool.id}>
                <ListItem disablePadding selected={tool.link !== '/' ? location.pathname.includes(tool.link) : location.pathname === tool.link} button>
                  <ListItemButton style={{ padding: '0 8px' }}>
                    <ListItemIcon>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', padding: "10px", fill: "#727275" }}>
                        <path d={tool.svgpath} />
                      </svg>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px' }}>{tool.name}</Typography>} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        
        <Accordion  className="AccordionSidebar" expanded={expanded === 'panel1'}   onChange={handleChange('panel1')} >
          <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
            <Typography>Align</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {tools.filter(tool => [3,3.1, 14, 13,3.2].includes(tool.id)).map((tool) => (
              <Link to={tool.link} style={{ textDecoration: 'none', color: 'inherit' }} key={tool.id}>
                <ListItem disablePadding selected={tool.link !== '/' ? location.pathname.includes(tool.link) : location.pathname === tool.link} button>
                  <ListItemButton style={{ padding: '0 8px' }}>
                    <ListItemIcon>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" style={{ width: '42px', height: '42px', padding: "10px", fill: "#727275" }}>
                        <path d={tool.svgpath} />
                      </svg>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px' }}>{tool.name}</Typography>} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion  className="AccordionSidebar" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Admin</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(user.role == undefined || user.role == "administrator" || user.role == "superadmin" || user.role == "") && (
              <div>
              <Link to='/users' style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem disablePadding selected={location.pathname === '/users'} button>
                  <ListItemButton style={{ padding: '0 8px' }}>
                    <ListItemIcon>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" style={{ width: '42px', height: '42px', padding: "10px", fill: "#727275" }}>
                        <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/>
                      </svg>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px' }}>Users</Typography>} />
                  </ListItemButton>
                </ListItem>
              </Link>
</div>
            )}

            
<Link to='/teams' style={{ textDecoration: 'none', color: 'inherit' }}>
<ListItem disablePadding selected={location.pathname === '/teams'} button>
  <ListItemButton style={{ padding: '0 8px' }}>
    <ListItemIcon>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" style={{ width: '42px', height: '42px', padding: "10px", fill: "#727275" }}><path d="M0-240v-63q0-43 44-70t116-27q13 0 25 .5t23 2.5q-14 21-21 44t-7 48v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5q72 0 116 26.5t44 70.5v63H780Zm-455-80h311q-10-20-55.5-35T480-370q-55 0-100.5 15T325-320ZM160-440q-33 0-56.5-23.5T80-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T160-440Zm640 0q-33 0-56.5-23.5T720-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T800-440Zm-320-40q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm0-80q17 0 28.5-11.5T520-600q0-17-11.5-28.5T480-640q-17 0-28.5 11.5T440-600q0 17 11.5 28.5T480-560Zm1 240Zm-1-280Z"/></svg>
    </ListItemIcon>
    <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px' }}>Teams</Typography>} />
  </ListItemButton>
</ListItem>
</Link>

{(user.role == undefined || (user.role == "administrator" && user.userid == user.tenentid) || (user.role == "administrator" && user.tenentid == "") || user.role == "superadmin" || user.role == "") && (
  <Link to='/subscriptions' style={{ textDecoration: 'none', color: 'inherit' }}>
  <ListItem disablePadding selected={location.pathname === '/subscriptions'} button>
    <ListItemButton style={{ padding: '0 8px' }}>
      <ListItemIcon>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" style={{ width: '42px', height: '42px', padding: "10px", fill: "#727275" }}><path d="M160-440v80h640v-80H160Zm0-440h640q33 0 56.5 23.5T880-800v440q0 33-23.5 56.5T800-280H640v200l-160-80-160 80v-200H160q-33 0-56.5-23.5T80-360v-440q0-33 23.5-56.5T160-880Zm0 320h640v-240H160v240Zm0 200v-440 440Z"/></svg>
      </ListItemIcon>
      <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px' }}>Subscriptions</Typography>} />
    </ListItemButton>
  </ListItem>
  </Link>
)}


  <Link to='/support' style={{ textDecoration: 'none', color: 'inherit' }}>
<ListItem disablePadding selected={location.pathname === '/support'} button>
  <ListItemButton style={{ padding: '0 8px' }}>
    <ListItemIcon>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" style={{ width: '42px', height: '42px', padding: "10px", fill: "#727275" }}><path d="M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z"/></svg>
    </ListItemIcon>
    <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px' }}>Agilebin Support</Typography>} />
  </ListItemButton>
</ListItem>
</Link>

          </AccordionDetails>
        </Accordion>

        {user.role == "superadmin" && (  <Accordion  className="AccordionSidebar" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Super Admin</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {superadmintools.map((tool) => (
              <Link to={tool.link} style={{ textDecoration: 'none', color: 'inherit' }} key={tool.id}>
                <ListItem disablePadding selected={tool.link !== '/' ? location.pathname.includes(tool.link) : location.pathname === tool.link} button>
                  <ListItemButton style={{ padding: '0 8px' }}>
                    <ListItemIcon>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" >
                        <path d={tool.svgpath} />
                      </svg> */}
                      <span class="material-symbols-outlined" style={{ width: '42px', height: '42px', padding: "10px", fill: "#727275" }}>{tool.icon}</span>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px' }}>{tool.name}</Typography>} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion> )}
        {state.subscriptionglobaldata.subscription == "False" && ( <button className={`sidebar-upgrade ${isopen ? 'open' : ''}`} title="Upgrade plan" onClick={()=>{
      navigate("/subscriptions")
     }}>
<CardMembershipIcon /> Upgrade plan
</button>)}

      </List>
    </Drawer>
</ThemeProvider>

   

  );
};

export default Sidebar;
