import {
  collection,
  doc,
  Timestamp,
  setDoc,
} from "@firebase/firestore";
import { firestore } from "../firebase";
import moment from "moment-timezone";
import posthog from 'posthog-js'


const postlog = (event, props) => {
  try {
    posthog.capture(event, props)
  } catch (err) { }
}

const posthoginit = (userid, useremail, username) => {
  try {
    posthog.identify(
       userid,
       { email: useremail, name: username }
     );
  } catch (err) { }

}

const posthogreset = () => {
  try {
     posthog.reset()
  } catch (err) { }
}



async function Notify(
  subject,
  messege,
  useremail,
  role,
  isEmail,
  isNotfication,
  doctype,
  docid,
  inviter
) {
  try {
    if (role == undefined) {
      role = "";
    }

    let emailMessage;
    if(doctype == 'newuser'){
      emailMessage = `<b>Welcome to Agilebin.</b>
      <p>Dear ${inviter},</p>
  <p>Welcome to Agilebin, your go-to platform for enhancing the productivity and effectiveness of your Scrum team! We're thrilled to have you on board and can't wait for you to experience the array of tools and resources we have to offer.</p>
  <p>At Agilebin, we understand the importance of streamlining the Scrum process and empowering teams and Scrum Masters to achieve their goals efficiently. That's why we've curated a suite of tools specifically designed to support every aspect of your Scrum journey.</p>
  <p>Here's a glimpse of what you can expect from Agilebin:</p>
  <ol>
    <li><strong>Retrospective Meetings Boards:</strong> Facilitate insightful and constructive retrospective sessions with our intuitive boards, enabling your team to reflect, learn, and continuously improve.</li>
    <li><strong>Planning Poker for Estimation:</strong> Simplify the estimation process and foster accurate planning by leveraging our Planning Poker tool, ensuring that your team remains aligned on project timelines and deliverables.</li>
    <li><strong>First of Five Voting:</strong> Harness the power of collective decision-making with our First of Five Voting feature, allowing your team to prioritize tasks, identify obstacles, and drive progress collaboratively.</li>
    <li><strong>Agile Maturity Assessment:</strong> Assess and enhance your team's Agile maturity level using our comprehensive assessment tool, enabling you to identify strengths, pinpoint areas for improvement, and elevate your overall performance.</li>
  </ol>
  <p>Whether you're a seasoned Scrum Master or a team member new to the Agile methodology, Agilebin is here to support you every step of the way. Our user-friendly interface, coupled with robust features, ensures a seamless experience for all users.</p>
  <p>To get started, simply log in to your Agilebin account and explore the diverse range of tools at your disposal. If you have any questions or need assistance, our dedicated support team is readily available to help you.</p>
  <p>Thank you for choosing Agilebin as your trusted partner in driving Scrum excellence. We're excited to embark on this journey with you and witness the remarkable progress your team will achieve.</p>
  <p>Warm regards,</p>
  <p>The Agilebin Team</p>
  <hr>
  <p>Agilebin | 6010 W Spring Creek Pkwy, Suite 362, Plano, TX 75024</p>
  <p>Thank you for joining Agilebin! You're receiving this email because you're now a part of our platform. If you wish to opt out of future communications, you can <a href="${window.origin}/emailpreference/${docid}">unsubscribe here</a>.</p>
      `
    }
    
   else if (doctype == "userinvite") {
      emailMessage = `<b>Company Invitation.</b>
      <p>Hello, <br> We are thrilled to share some exciting news with you! You have been invited by ${inviter} to join their company. </p>
      <a href=${window.origin}/users/userinvites/${docid} style="background-color: #0359d9; border-radius:6px; padding: 10px 18px; color: white; text-decoration: none; font-weight:500;font-size:14px;">Open Invite</a>
      <p>Best Regards,<br> Agilebin</p>
      <hr>
      <div style="text-align:center;">
          <small>Thank you for being a valued member of AgileBin! You are receiving this email
              because
              you are a registered user at agilebin.com. If you don't want to receive such emails in the future. You can <a
                  href="app.agilebin.com">unsubscribe</a> here. You can also <a href="app.agilebin.com">manage your email
                  preferences</a> here.</small>
      </div>

      `
    } else if (doctype == true) {
      emailMessage = `<b>Assessment Pending</b>
      <p>Hello, <br> This is a friendly reminder regarding the incomplete assessment that requires your attention.
      Kindly take a moment to complete the remaining sections at your earliest convenience.
      </p>
      <a href='${window.origin}/assessments/assessmentresponse/${docid}' style="background-color: #0359d9; border-radius:6px; padding: 10px 18px; color: white; text-decoration: none; font-weight:500;font-size:14px;">Open Assessment</a>
      <p>Best Regards,<br> Agilebin</p>
      <hr>
      <div style="text-align:center;">
          <small>Thank you for being a valued member of AgileBin! You are receiving this email
              because
              you are a registered user at agilebin.com. If you don't want to receive such emails in the future. You can <a
                  href="app.agilebin.com">unsubscribe</a> here. You can also <a href="app.agilebin.com">manage your email
                  preferences</a> here.</small>
      </div>
      `
    } else if (doctype == "recommendations") {
      emailMessage = `<b>Recommendation for Agilebin</b>
      <p>Hello, <br> <br>Your friend, ${inviter}, has recommended Agilebin to you. Agilebin platform provides useful tools for agile teams. Following is the personal message from  ${inviter}.</p>
      <p><i>"${messege}"</i><br></p>
      <p>To get started free, please visit <a href="https://www.agilebin.com/">Agilebin.com</a>.</p>
      <p>Best Regards,<br> Agilebin Team</p>
      <hr>
      <div >
          <small>You are receiving this email
              because
              your friend who is a registered user at agilebin.com has recommended Agilebin to you.
                 You can <a href="app.agilebin.com">manage your email
                  preferences</a> here after registeration on agilebin platform.</small>
      </div>

      `
    }
    else {
      let heading;
      let link;
      let message;
      if (doctype == "fistoffive") {
        link = `${window.origin}/fistoffive/vote/${docid}`
        heading = "Fist of Five Voting"
        message = ` We would like to inform you that you have been given access to Fist of Five Voting. Participate in the voting now! Click the button below to vote.`

      } else if (doctype == "board") {
        heading = "Retrospective Board"
        link = `${window.origin}/retrospective/${docid}`
        message = ` We would like to inform you that you have been given access to Retrospective Board. Join the retrospective session by clicking the button below!`

      } else if (doctype == "poker") {
        heading = "Planning Poker"
        link = `${window.origin}/poker/${docid}`
        message = ` We would like to inform you that you have been given access to Planning Poker. Ready to join the session? Just click the button below!`


      } else if (doctype == "spinwheel") {
        heading = "Spinwheel"
        link = `${window.origin}/spinwheels/spinwheel/${docid}`
        message = ` We would like to inform you that you have been given access to Spinwheel. Click the button below to join!`

      } else if (doctype == "teams") {
        heading = "Team"
        link = `${window.origin}/teams/${docid}`
        message = ` We're excited to let you know that you're now part of the team!`

      } else if (doctype == "assessements") {
        heading = "Assessment"
        link = `${window.origin}/assessments/assessmentresponse/${docid}`
        message = ` We would like to inform you that you have been given access to the Assessment. Click the below button to submit the assessment`

      }

      emailMessage = `<b>${heading}</b>
      <p>Hello,<br> ${message}</p>
      <a href=${link} style="background-color: #0359d9; border-radius:6px; padding: 10px 18px; color: white; text-decoration: none; font-weight:500;font-size:14px">Open Document</a>


      <p>Best Regards,<br> Agilebin</p>
      <hr>

      <div style="text-align:center;">
          <small>Thank you for being a valued member of AgileBin! You are receiving this email
              because
              you are a registered user at agilebin.com. If you don't want to receive such emails in the future. You can <a
                  href="app.agilebin.com">unsubscribe</a> here. You can also <a href="app.agilebin.com">manage your email
                  preferences</a> here.</small>
      </div>
      `
    }
    if (isEmail) {
      if (useremail) {
        const emailsDocref = doc(collection(firestore, "emails"));

        await setDoc(
          emailsDocref,
          {
            id: emailsDocref.id,
            to: useremail,
            from: "Agilebin <info@agilebin.com>",
            message: {
              html: emailMessage,
              subject: subject,
              // text: messege,
            },
            doctype: doctype,
            docid: docid,
            createddate: Timestamp.fromDate(new Date()),
          },
          { merge: true }
        );
     
       // postlog("write emails", { component: "common", function: "Notify", collection: "emails" });
      }
    }

    if (isNotfication) {
      if (useremail) {
        const notificationsDocref = doc(collection(firestore, "notifications"));
        await setDoc(
          notificationsDocref,
          {
            id: notificationsDocref.id,
            email: useremail,
            subject: subject,
            message: messege,
            role: role.toLowerCase(),
            status: "active",
            doctype: doctype,
            docid: docid,
            createddate: Timestamp.fromDate(new Date()),
            updateddate: Timestamp.fromDate(new Date()),
          },
          { merge: true }
        );
      //  postlog("write notifications", { component: "common", function: "Notify", collection: "notifications" });

      }
    }
  } catch (err) {
    storeError(getErrorObject(err, {}, "Common", "Notify"))
  }
}

const showdate = (datetoconvert, usertimezone) => {
  try {
    if (usertimezone != undefined && usertimezone != "") {
      const dt = datetoconvert.toDate();
      const convertedDatetime = moment(dt).tz(usertimezone);
      return convertedDatetime.toDate().toLocaleDateString();
    } else {
      return datetoconvert.toDate().toLocaleDateString();
    }
  } catch (err) {
    storeError(getErrorObject(err, {}, "Common", "showdate"))
  }
};

const getErrorObject = (err, user, componentname, methodname) => {
  try {
    return {
      createddate: Timestamp.fromDate(new Date()),
      userid: user.userid != undefined ? user.userid : "",
      email: user.useremail != undefined ? user.useremail : "",
      firstname: user.firstname != undefined ? user.firstname : "",
      lastname: user.lastname != undefined ? user.lastname : "",
      errorname: err.name != undefined ? err.name : "",
      errormessage: err.message != undefined ? err.message : "",
      component: componentname != undefined ? componentname : "",
      method: methodname != undefined ? methodname : "",
    };
  } catch (err) {
    storeError({ errname: err.name, errrormessege: err.messege, user: user, component: "Common", method: "getErrorObject" })
  }
};

async function storeError(error) {
  try {
    const docRef = doc(collection(firestore, "errors"));
    await setDoc(docRef, error);
    postlog("error", { createddate: error.createddate, userid: error.userid, email: error.email, firstname: error.firstname, lastname: error.lastname, errorname: error.errorname, errormessage: error.errormessage, component: error.component, method: error.method });
  } catch {
    alert("Something went wrong. Please try to Refresh page.")
  }

}


function getUserDetails(user) {
  return {
    firstname: user.firstname != undefined ? user.firstname : "",
    lastname: user.lastname != undefined ? user.lastname : "",
    isowner: true,
    profilepicture: user.profilepicture != undefined ? user.profilepicture : "",
    role: user.role != undefined ? user.role : "",
    tenentid: user.tenentid != undefined ? user.tenentid : "",
    useremail: user.useremail != undefined ? user.useremail : "",
    userid: user.userid != undefined ? user.userid : "",
  }
}
export { Notify, showdate, getErrorObject, storeError, getUserDetails, postlog, posthoginit, posthogreset };
