import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { getAuth, setPersistence } from "firebase/auth";
import { postlog, posthogreset } from "../../functions/Common"
import { useState } from "react";
import { useGlobalState } from '../../GlobalProvider';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';

import {
    Timestamp,
} from "@firebase/firestore";
export default function MenuListComposition(user) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const navigate = useNavigate();
    const [showError, setShowError] = useState({ show: false, errordata: {} });
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const { state } = useGlobalState();

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    function handleLogout() {
        const auth = getAuth();
        postlog("user logging-out", { userid: state.userglobaldata.userid });
        getAuth().signOut().then(function () {
            navigate(`/login`);
            posthogreset();
        }, function (err) {
            const errorObject = {
                createddate: Timestamp.fromDate(new Date()),
                userid: user.userid,
                email: user.useremail,
                firstname: user.firstname,
                lastname: user.lastname,
                errorname: err.name,
                errormessage: err.message,
            };
            setShowError({ show: true, errordata: errorObject });
        });
    }


    return (
        <Stack direction="row" spacing={2} style={{ display: "inline-block" }}>
            <div>
                <IconButton
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    sx={{ color: '#5f6368' }}
                >
                    {state.userglobaldata.profilepicture &&state.userglobaldata.profilepicture != "" ? (
                          <Avatar alt="Remy Sharp"   sx={{ width: 24, height: 24 }} src={state.userglobaldata.profilepicture} />
                        ) : ( 
                    <AccountCircleIcon></AccountCircleIcon>)}
                </IconButton>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <div className="d-flex gap-2 align-items-center" style={{ padding: '10px' }}>
                                            {state.userglobaldata.profilepicture && state.userglobaldata.profilepicture !== "" ? (
                                                <Avatar  alt="Profile Picture" sx={{ width: 34, height: 34 }} src={state.userglobaldata.profilepicture} />
                                            ) : (
                                                <AccountCircleIcon sx={{ fontSize: '2.3rem', color: '#5f6368' }} />
                                            )}
                                            <div>
                                                <Typography>
                                                    {`${state.userglobaldata.firstname} ${state.userglobaldata.lastname}`}
                                                </Typography>
                                                <Typography variant="subtitle2" gutterBottom style={{color: 'gray'}}>
                                                    {state.userglobaldata.useremail}
                                                </Typography>
                                            </div>
                                        </div>
                                        <MenuItem onClick={() => {
                                            navigate(`/profile`);
                                        }}><SettingsIcon sx={{ color: '#5f6368', marginRight: '15px' }} />Manage Profile</MenuItem>

                                        {(user.role == undefined || (user.role == "administrator" && user.userid == user.tenentid) || (user.role == "administrator" && user.tenentid == "") || user.role == "superadmin" || user.role == "") && (
                                            <MenuItem onClick={() => {
                                                navigate(`/subscriptions`);
                                            }}> <CardMembershipIcon sx={{ color: '#5f6368', marginRight: '15px' }} />Subscription</MenuItem>)}


                                        <MenuItem onClick={() => {
                                            navigate(`/support`);
                                        }}><SupportAgentIcon sx={{ color: '#5f6368', marginRight: '15px' }} />Support</MenuItem>
                                        <MenuItem onClick={handleLogout}><LogoutIcon sx={{ color: '#5f6368', marginRight: '15px' }} />Logout</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Stack>
    );
}   
