import { useState, useEffect } from "react";
import { firestore } from "../../../firebase";
import {
  onSnapshot,
  doc,
  collection,
  setDoc,
  deleteField,
  Timestamp
} from "@firebase/firestore";
import Spinner from "../../Reusable/Spinner/Spinner";
import ShowError from "../../Reusable/ShowError/ShowError";
import DocumentMissing from "../../Reusable/DocumentMissing/DocumentMissing";
import { useParams, useNavigate } from "react-router-dom";
import SharePopup from "../../Reusable/Share/share";
import { getErrorObject, postlog } from "../../../functions/Common";
import { useGlobalState } from "../../../GlobalProvider"
import { Button, Link, Breadcrumbs, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Paper, List, ListItem, Container, ListItemText, Checkbox, TextField, TableContainer, Grid, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Tabs, Tab, Box, Typography, CardActions, CardMedia, CardContent, Card, IconButton } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Feedback from "../../Reusable/Feedback/Feedback";
import EditIcon from '@mui/icons-material/Edit';

function FiveWhy({ user }) {
  const navigate = useNavigate();
  const { docid } = useParams();
  const [data, setData] = useState();
  const [showshare, setShowShare] = useState(false);
  const [spin, setSpin] = useState(false);
  const [showDocumentMissing, setshowDocumentMissing] = useState(false);
  const [showError, setShowError] = useState({ show: false, errordata: {} });
  const { state } = useGlobalState();
  const [showFeedBackForm, setShowFeedBackForm] = useState(false);
  const [whyIndex, setWhyIndex] = useState();
  const [whys, setWhys] = useState(Array(5).fill(''));
  const [rootCauseChecked, setRootCauseChecked] = useState(Array(5).fill(false));
  const [formData, setFormData] = useState({
    why: "",
  });

  const [showFormValidate, setShowFormValidate] = useState(false);
  const [enternextwhy, setEnternextwhy] = useState(false);
  const [enterrootcause, setEnterrootcause] = useState(false);
  const [editWhy, setEditWhy] = useState(false);



  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "Board", "handleChange") });
      setSpin(false);
    }
  };

  const isFinishDisabled = () => {
    return !whys.every((why) => why !== '') || rootCauseChecked.every((checked) => !checked);
  };
  useEffect(() => {
    let unsubscribe = null;
    async function getTeam() {
      try {
        setSpin(true);
        if (unsubscribe) { unsubscribe(); }
        unsubscribe = onSnapshot(doc(firestore, "fivewhys", docid ? docid : state.currentteam.teamid),
          (doc) => {
            setData(doc.data());
            setWhyIndex(Object.keys(doc.data().fivewhys).length + 1)
            // postlog("read teams", { component: "team", function: "getTeam", collection: "fivewhys", docid: docid });
            postlog("open team", { component: "team", function: "getTeam", collection: "fivewhys", docid: docid });
          },
          (error) => {
            setshowDocumentMissing(true);
          });

        setSpin(false);
      } catch (err) {
        setSpin(false);
        setshowDocumentMissing(true);
      }
    }

    getTeam();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user, state.currentteam]);

  function leaveMeeting() {
    try {
      navigate(`/teams`);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Team", "leaveMeeting"),
      });
      setSpin(false);
    }
  }

  const HideSharePopup = () => {
    try {
      setShowShare(false);
    } catch (err) {
      setShowError({
        show: true,
        errordata: getErrorObject(err, user, "Team", "toggleState"),
      });
      setSpin(false);
    }
  };



  const share = () => {
    try {
      setShowShare(true);
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "FiveWhys", "share") });
      setSpin(false);
    }
  };


  const closeFiveWhys = () => {
    try {
      const randomNum = Math.floor(Math.random() * 5) + 1;
      if (randomNum == 2) {
        setShowFeedBackForm(true);
      } else {
        navigate("/fivewhys");
      }
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "FiveWhys", "closeFiveWhys") });
      setSpin(false);
    }
  };

  const hanldeNextwhy = async (whynumber) => {
    try {
      let why = `why${whynumber}`;
      const docRef = doc(firestore, "fivewhys", data.id);
      await setDoc(
        docRef,
        {
          fivewhys: {
            [why]: formData.why,
          }
        },
        { merge: true }
      );
      if (!editWhy) {
        setWhyIndex(whynumber + 1)
      }

      setFormData({
        ...formData,
        why: "",
      });
      setEnternextwhy(false);
      setEnterrootcause(false)
      CloseWhyEditMode();
    } catch (err) {
      setShowError({ show: true, errordata: getErrorObject(err, user, "FiveWhys", "closeFiveWhys") });
      setSpin(false);
    }
  }


  const saveRootCause = async () => {
    const docRef = doc(firestore, "fivewhys", data.id);
    await setDoc(
      docRef,
      {
        rootcause: formData.rootcause
      },
      { merge: true }
    );
    setEnterrootcause(false);
  }

  const EnterRootCause = (rootcause) => {
    setEnterrootcause(true)
    if (rootcause != null) {
      setFormData({
        ...formData,
        rootcause: rootcause,
      })
    }
  }

  const EnterWhy = () => {
    setEnternextwhy(true)
  }

  const EditWhy = (why, number) => {
    setEditWhy(number)
    if (why != null) {
      setFormData({
        ...formData,
        why: why,
      })
    }
  }

  const CloseWhyEditMode = () => {
    setEditWhy(false)
    setFormData({
      ...formData,
      why: "",
    })
  }


  return (
    <>
      <div >

        <nav
          className="navbar navbar-expand-lg fixed-top card-shadow bg-white"
        >

          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse navbar-right"
              id="navbarSupportedContent"
            >
              {data && (<ul className="navbar-nav me-auto mb-2 mb-lg-0">Five whys</ul>)}

              <ul className="navbar-nav mb-2 mb-lg-0 navbar-right">
              {data?.usersdetails?.[user.userid]?.isowner && (  <li className="nav-item">
                <Button
                    variant="outlined"
                    sx={{ margin: "0 2px" }}
                    id="btncopylink"
                    title="Copy Meeting Link"
                    onClick={share}
                    size="medium"
                    startIcon={<PersonAddAltIcon />}
                  >
                    Invite
                  </Button>
                </li>)}
                <li className="nav-item">
                  <Button
                    sx={{ margin: "0 2px" }}
                    variant="outlined"
                    onClick={closeFiveWhys}
                    title="Close"
                    startIcon={<CloseIcon />}
                  >
                    Close
                  </Button>
                </li>

              </ul>
            </div>
          </div>
        </nav>

        {data && (<Container maxWidth="md">
          <Paper elevation={3} style={{ padding: '20px', marginTop: '80px' }}>

            <Typography variant="h6" gutterBottom>
              Problem: {data.problem}
            </Typography>

            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              Why this problem exists? answer this question repeatedly up to five times to find the root cause.
            </Typography>

            {data.fivewhys.why1 && data.fivewhys.why1 != "" && (
              <div>
                {editWhy == 1 ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        name="why"
                        label="Why"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formData.why}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        error={showFormValidate && !formData.why.trim()}
                        helperText={showFormValidate && !formData.why.trim() ? 'why is required' : ''}
                      />
                      <span>
                        <IconButton onClick={() => { CloseWhyEditMode() }}>
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      disabled={formData.why == ""}
                      onClick={() => hanldeNextwhy(1)}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => CloseWhyEditMode()}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Cancel
                    </Button>

                  </div>
                ) : (
                  <TableRow hover style={{ display: 'flex', justifyContent: "space-between", padding: "5px 0px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Why 1: {data.fivewhys.why1}
                    </Typography>
                    <IconButton size="small" onClick={() => { EditWhy(data.fivewhys.why1, 1) }}>
                      <EditIcon />
                    </IconButton>
                  </TableRow>
                )}

              </div>
            )}
            {data.fivewhys.why2 && data.fivewhys.why2 != "" && (
              <div>
                {editWhy == 2 ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        name="why"
                        label="Why"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formData.why}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        error={showFormValidate && !formData.why.trim()}
                        helperText={showFormValidate && !formData.why.trim() ? 'why is required' : ''}
                      />
                      <span>
                        <IconButton onClick={() => { CloseWhyEditMode() }}>
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      disabled={formData.why == ""}
                      onClick={() => hanldeNextwhy(2)}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => CloseWhyEditMode()}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Cancel
                    </Button>

                  </div>
                ) : (
                  <TableRow hover style={{ display: 'flex', justifyContent: "space-between", padding: "5px 0px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Why 2: {data.fivewhys.why2}
                    </Typography>
                    <IconButton size="small" onClick={() => { EditWhy(data.fivewhys.why2, 2) }}>
                      <EditIcon />
                    </IconButton>
                  </TableRow>

                )}
              </div>
            )}
            {data.fivewhys.why3 && data.fivewhys.why3 != "" && (
              <div>
                {editWhy == 3 ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        name="why"
                        label="Why"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formData.why}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        error={showFormValidate && !formData.why.trim()}
                        helperText={showFormValidate && !formData.why.trim() ? 'why is required' : ''}
                      />
                      <span>
                        <IconButton onClick={() => { CloseWhyEditMode() }}>
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      disabled={formData.why == ""}
                      onClick={() => hanldeNextwhy(3)}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => CloseWhyEditMode()}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Cancel
                    </Button>

                  </div>
                ) : (
                  <TableRow hover style={{ display: 'flex', justifyContent: "space-between", padding: "5px 0px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Why 3: {data.fivewhys.why3}
                    </Typography>
                    <IconButton size="small" onClick={() => { EditWhy(data.fivewhys.why3, 3) }}>
                      <EditIcon />
                    </IconButton>
                  </TableRow>
                )}
              </div>
            )}

            {data.fivewhys.why4 && data.fivewhys.why4 != "" && (
              <div>
                {editWhy == 4 ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        name="why"
                        label="Why"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formData.why}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        error={showFormValidate && !formData.why.trim()}
                        helperText={showFormValidate && !formData.why.trim() ? 'why is required' : ''}
                      />
                      <span>
                        <IconButton onClick={() => { CloseWhyEditMode() }}>
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      disabled={formData.why == ""}
                      onClick={() => hanldeNextwhy(4)}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => CloseWhyEditMode()}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Cancel
                    </Button>

                  </div>
                ) : (
                  <TableRow hover style={{ display: 'flex', justifyContent: "space-between", padding: "5px 0px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Why 4: {data.fivewhys.why4}
                    </Typography>
                    <IconButton size="small" onClick={() => { EditWhy(data.fivewhys.why4, 4) }}>
                      <EditIcon />
                    </IconButton>
                  </TableRow>
                )}
              </div>
            )}

            {data.fivewhys.why5 && data.fivewhys.why5 != "" && (
              <div>
                {editWhy == 5 ? (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        name="why"
                        label="Why"
                        variant="outlined"
                        multiline
                        rows={1}
                        value={formData.why}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                        error={showFormValidate && !formData.why.trim()}
                        helperText={showFormValidate && !formData.why.trim() ? 'why is required' : ''}
                      />
                      <span>
                        <IconButton onClick={() => { CloseWhyEditMode() }}>
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      disabled={formData.why == ""}
                      onClick={() => hanldeNextwhy(5)}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => CloseWhyEditMode()}
                      sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                    >
                      Cancel
                    </Button>

                  </div>
                ) : (
                  <TableRow hover style={{ display: 'flex', justifyContent: "space-between", padding: "5px 0px" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Why 5: {data.fivewhys.why5}
                    </Typography>
                    <IconButton size="small" onClick={() => { EditWhy(data.fivewhys.why5, 5) }}>
                      <EditIcon />
                    </IconButton>
                  </TableRow>
                )}
              </div>
            )}



            {(!enternextwhy && !enterrootcause && !editWhy) && (
              <div >
                {whyIndex != 6 && (<Button
                  variant="contained"
                  onClick={() => { setEnternextwhy(true) }}
                  sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                >
                  Enter Why
                </Button>)}

                {(data.rootcause == undefined || data.rootcause == "") && (<Button
                  variant="outlined"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => { EnterRootCause(null) }}
                >
                  Found Root Cause
                </Button>)}
              </div>
            )}

            {data && data.rootcause && data.rootcause != "" && (
              <TableRow hover style={{ display: 'flex', justifyContent: "space-between", padding: "5px 0px", margin: "15px 0px" }}>
                <Typography variant="subtitle1" gutterBottom>
                  Root Cause: {data.rootcause}
                </Typography>
                <IconButton size="small" onClick={() => { EnterRootCause(data.rootcause) }}>
                  <EditIcon />
                </IconButton>
              </TableRow>
            )}
            {whyIndex + 1 > 1 && whyIndex <= 5 && enternextwhy && (
              <div>
                <TextField
                  label={` #${whyIndex} Please answer why`}
                  name="why"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.why}
                  onChange={handleChange}
                  disabled={rootCauseChecked[whyIndex - 1]}
                />

                <Button
                  variant="contained"
                  color="primary"
                  disabled={formData.why == ""}
                  onClick={() => hanldeNextwhy(whyIndex)}
                  sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setEnternextwhy(false)}
                  sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                >
                  Cancel
                </Button>

              </div>

            )}




            {enterrootcause && (
              <div>
                <TextField
                  name="rootcause"
                  label="Root Cause"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={formData.rootcause}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                  error={showFormValidate && !formData.rootcause.trim()}
                  helperText={showFormValidate && !formData.rootcause.trim() ? 'Root cause is required' : ''}
                />
                <Button
                  variant="contained"
                  onClick={saveRootCause}
                  sx={{ marginRight: "10px", textTransform: 'capitalize' }}
                >Save Root Cause</Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setEnterrootcause(false)}
                  sx={{ marginRight: '10px', textTransform: 'capitalize' }}
                >
                  Cancel
                </Button>
              </div>
            )}



          </Paper>
        </Container>)}

      </div>
      <Feedback show={showFeedBackForm} user={user} docid={docid} componentName="fivewhys" />
      <Spinner spin={spin} />
      <DocumentMissing show={showDocumentMissing} />
      <ShowError Error={showError} />
      <SharePopup
        show={showshare}
        hideForm={HideSharePopup}
        userid={user.userid}
        docid={docid}
        docData={data}
        doctype="fivewhys"
        collectionname="fivewhys"
        subscription={state.subscriptionglobaldata && state.subscriptionglobaldata.subscription}
        message="Select users and add them to a team, enabling you to share access to other documents with the entire team at once."
      />
    </>
  );
}

export default FiveWhy;
