import React from "react";
import { firestore } from "../../../firebase";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import {
  doc,
  setDoc,
  deleteField,
} from "@firebase/firestore";
import Spinner from "../../Reusable/Spinner/Spinner";
import { Notify, postlog } from "../../../functions/Common";
import { useGlobalState } from "../../../GlobalProvider"
import CloseIcon from '@mui/icons-material/Close';

import { Button, Radio,Alert,Autocomplete,Snackbar,Grid,IconButton, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, Select, MenuItem, Checkbox, Typography, Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
const Share = ({
  show,
  hideForm,
  userid,
  docid,
  docData,
  collectionname,
  subscription,
  doctype,
  message,
}) => {
  const [spin, setSpin] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState("selectuser");
  const [selectedUserdata, setSelectedUserdata] = useState();
  const [selectedUsersdata, setSelectedUsersdata] = useState({});
  const [originalUsersdata, setOriginalUsersdata] = useState({});
  const [selectedOption, setSelectedOption] = useState("public");
  const [selectedTeam, setSelectedTeam] = useState("selectteam");
  const [selectedteamdata, setSelectedTeamData] = useState([]);
  const [linkedTeamData, setLinkedTeamData] = useState({teamid:'',teamname:''});
  const [documentData, setDocumentData] = useState();
  const { state } = useGlobalState();
  const [documentLink, setDocumentLink] = useState();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState();

  useEffect(() => {
    if (docData !== undefined && docData !== null) {
      if (doctype == "fistoffive") {
        setDocumentLink(`${window.location.origin}/fistoffive/vote/${docid}`)
      } else if (doctype == "board") {
        setDocumentLink(`${window.location.origin}/retrospective/${docid}`)
    } else if (doctype == "leancoffeemeetings") {
      setDocumentLink(`${window.location.origin}/leancoffeemeeting/${docid}`)
  } else if (doctype == "actionitems") {
    setDocumentLink(`${window.location.origin}/actionitems/${docid}`)
  }
      else if (doctype == "poker") {
        setDocumentLink(`${window.location.origin}/poker/${docid}`)
      } else if (doctype == "spinwheel") {
        setDocumentLink(`${window.location.origin}/spinwheels/spinwheel/${docid}`)
      } else if (doctype == "teams") {
        setDocumentLink(`${window.location.origin}/teams/${docid}`)
      } else if (doctype == "assessements") {
        setDocumentLink(`${window.location.origin}/assessments/assessmentresponse/${docid}`)
      }
      else if (doctype == "fivewhys"){
        setDocumentLink(`${window.location.origin}/fivewhys/${docid}`)
      }
      setSelectedUsersdata(deepCopy(docData.usersdetails));
      setOriginalUsersdata(deepCopy(docData.usersdetails));
      setSelectedOption(docData.level);
      setDocumentData(docData);
      if(docData.teamdata){
        setLinkedTeamData(docData.teamsdata)
      }
    }
  }, [docData]);

  function deepCopy(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(deepCopy);
    }

    if (typeof obj === "object") {
      const clone = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          clone[key] = deepCopy(obj[key]);
        }
      }
      return clone;
    }
  }

  function closeShare() {
    if (docData !== undefined) {
      setSelectedUsersdata(deepCopy(docData.usersdetails));
      setOriginalUsersdata(deepCopy(docData.usersdetails));
      setSelectedOption(docData.level);
      setDocumentData(docData);
    }
    setShowSaveButton(false)
    hideForm();
  }

  function handleCheckboxChange(user) {
    const changedUser = { ...selectedUsersdata };
    changedUser[user.userid].isowner = !user.isowner;
    setSelectedUsersdata(changedUser);
    setShowSaveButton(true);
  }



  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(documentLink);
      if (copyMessage === "") {
        setCopyMessage("Link copied to clipboard!");
      }
      setSnackbarOpen(true);
      setTimeout(() => {
        setCopyMessage("");
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  

  const handleDropdownChange2 = (event, newValue) => {
    const selectedUser = newValue ? newValue.userid : '';
    setSelectedUser(selectedUser);
    let userdata = state.usersglobaldata.find((e) => e.userid === selectedUser);
    setSelectedUserdata({ ...userdata, isowner: false });
  };

  const addUser = () => {
    if (selectedUser !== "selectuser") {
      let users = selectedUsersdata;
      if (documentData.usersdetails[selectedUserdata.userid]) {
        users[selectedUserdata.userid] =
          documentData.usersdetails[selectedUserdata.userid];
      } else {
        users[selectedUserdata.userid] = selectedUserdata;
      }

      setSelectedUsersdata(users);
      setSelectedUser("selectuser");
      setShowSaveButton(true);
    }
  };
  const handleDropDownChange1 = (event, newValue) => {
    const selectedTeam = newValue ? newValue.id : '';
    setSelectedTeam(selectedTeam);
    let teamdata = state.teamsglobaldata.find((e) => e.id === selectedTeam);
    setSelectedTeamData(teamdata);
  };

  const addTeamMembers = () => {
    if (selectedTeam !== "selectteam") {
      let usersObj = {};
      setLinkedTeamData({teamid:selectedteamdata.id,teamname:selectedteamdata.name} );
      for (const key in selectedteamdata.usersdetails) {
        if (Object.hasOwnProperty.call(selectedteamdata.usersdetails, key)) {
          if (
            documentData.usersdetails[selectedteamdata.usersdetails[key].userid]
          ) {
            usersObj[selectedteamdata.usersdetails[key].userid] =
              documentData.usersdetails[
              selectedteamdata.usersdetails[key].userid
              ];
          } else {
            usersObj[selectedteamdata.usersdetails[key].userid] =
              selectedteamdata.usersdetails[key];
            usersObj[selectedteamdata.usersdetails[key].userid].isowner = false;
          }
        }
      }
      setSelectedTeam("selectteam");
      setSelectedUsersdata((prevUsersData) => ({
        ...prevUsersData,
        ...usersObj,
      }));
      setShowSaveButton(true)
    }
  };

  function removeUser(user) {
    let userDetail = user;
    const updatedData = { ...selectedUsersdata };
    delete updatedData[[userDetail.userid]];
    setSelectedUsersdata(updatedData);
    setShowSaveButton(true);
  }

  const notifyAllUsers = () => {
    const addedUsers = Object.keys(selectedUsersdata).reduce((result, key) => {
      if (!originalUsersdata.hasOwnProperty(key)) {
        result[key] = selectedUsersdata[key];
      }
      return result;
    }, {});

    for (const key in addedUsers) {
      Notify(
        "Document Access Granted",
        `You have been given access to ${collectionname}`,
        addedUsers[key].useremail,
        addedUsers[key].role,
        true,
        true,
        doctype,
        docid,
      );
    }
  };

  const SaveShare = async () => {
    setSpin(true);
    let userids = [];
    //================== For restricted access================
    if (selectedOption !== "public") {
      //============== Create User array=======================
      for (const key in selectedUsersdata) {
        userids.push(selectedUsersdata[key].userid);
      }
      //============= Delete logic==============================
      let objToDelete = Object.keys(originalUsersdata).filter(
        (key) => !Object.keys(selectedUsersdata).includes(key)
      );
      function createDeleteObj() {
        let deleteObj = {};
        for (let i = 0; i < objToDelete.length; i++) {
          let key = objToDelete[i];
          deleteObj[key] = deleteField();
        }
        return deleteObj;
      }
      const docRef = doc(firestore, `${collectionname}`, docid);

      //====================== Delete logic======================
      //==================== Update logic ==========================
      await setDoc(
        docRef,
        {
          level: selectedOption,
          users: userids,
          usersdetails: { ...selectedUsersdata, ...createDeleteObj() },
          teamid: linkedTeamData.teamid != "" ? linkedTeamData.teamid : docData.teamid != undefined ? docData.teamid : "",
          teamname: linkedTeamData.teamname != "" ? linkedTeamData.teamname : docData.teamname != undefined ? docData.teamname : "",
        },
        { merge: true }
      ).then(() => {
        //postlog(`write ${collectionname}`, { component: "share", function: "SaveShare", collection: `${collectionname}`, docid: docid });
        notifyAllUsers()
      });
      //===================== Restricted user access complete ==========
    }
    //================== For public access ================
    else {
      function createDeleteObj() {
        let deleteObj = {};
        for (const key in documentData.usersdetails) {
          if (key != userid) {
            deleteObj[key] = deleteField();
          }
        }
        if (Object.keys(deleteObj).length == 0) {
          deleteObj[userid] = documentData.usersdetails[userid];
        }
        return deleteObj;
      }

      const docRef = doc(firestore, `${collectionname}`, docid);
      await setDoc(
        docRef,
        {
          level: selectedOption,
          users: [userid],
          usersdetails: createDeleteObj(),
          teamid: linkedTeamData.teamid != "" ? linkedTeamData.teamid : docData.teamid != undefined ? docData.teamid : "",
          teamname: linkedTeamData.teamname != "" ? linkedTeamData.teamname : docData.teamname != undefined ? docData.teamname : "",
        },
        { merge: true }
      );
     // postlog(`write ${collectionname}`, { component: "share", function: "SaveShare", collection: `${collectionname}`, docid: docid });
    }
    //=====================================================================

    hideForm();
    setSpin(false);
    setShowSaveButton(false);
  };

  const handleoptionchange = (event) => {
    if (event.target.value == "restricted") {
      postlog(`restricted selected ${collectionname}`, { component: "share", function: "SaveShare", collection: `${collectionname}`, docid: docid });
    }
    setSelectedOption(event.target.value);
    setShowSaveButton(true);
  };


  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  }
  return (
    <>
        {selectedUsersdata && docData && show && (
        <Modal open={show} onClose={closeShare}>
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2000,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundColor: 'none',
              p: 4,
              width: '80%',
              maxWidth: 800,
              height: 'auto',
              maxHeight: 800,
              overflow: 'hidden'
            }}
          >
            <Box
              id="formPopup"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 1,
                width: '100%',
                height: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2
                }}
              >
                <Typography variant="h6">Invite</Typography>
                <IconButton  onClick={closeShare}>
                <CloseIcon />
                </IconButton>
              </Box>
              <Typography sx={{ fontSize: '1rem', color: '#535353' }}>
                {message}
              </Typography>
              <Box  sx={{  flex: 1, overflowY: 'auto'  }}>
                <FormControl component="fieldset" sx={{ width: '100%' }}>
                  {collectionname !== "teams" && collectionname !== "risks" && collectionname !== "fivewhys" && collectionname !== "impediments" && collectionname !== "dailyscrums" && collectionname !== "okrs" && collectionname !== "features" && collectionname !== "dependencies" && collectionname !== "programboards" &&(
                    <RadioGroup
                      value={selectedOption}
                      onChange={handleoptionchange}
                    >
                      <FormControlLabel
                        value="public"
                        control={<Radio />}
                        label="Public access using link"
                      />
                      {selectedOption === "public" && (
                        <Box sx={{ display: 'flex', alignItems: 'center' ,mb:1  }}>
                          <TextField
                            variant="outlined"
                            className="form-control w-75"
                            value={documentLink}
                            disabled
                            sx={{ marginRight: 2, flexGrow: 1 }}
                          />
                          <Button variant="contained" sx={{textTransform:"capitalize"}} size="large" onClick={copyLink}>Copy Link</Button>
                        </Box>
                      )}
                      {copyMessage && (
                        <Typography sx={{ fontSize: 'small', color: 'success.main', mt: 1 }}>
                          {copyMessage}
                        </Typography>
                      )}
                    </RadioGroup>
                  )}
                  <Box sx={{ mt: 0 }}>
                      <>
                        <RadioGroup
                          value={selectedOption}
                          onChange={handleoptionchange}
                        >
                          <FormControlLabel
                            value="restricted"
                            control={<Radio />}
                            label="Restricted access to users & teams"
                          />
                        </RadioGroup>
                        {selectedOption === "restricted" && state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False" && (
                          <Typography sx={{ fontSize: '12px', mt: 3 }}>
                            (To add more users,
                            <Link to={`/subscriptions`}> upgrade to </Link>
                            <Box component="span" sx={{ display: 'inline-block', ml: 1, p: 0.5, bgcolor: 'primary.main', color: 'white', borderRadius: 1 }}>PRO</Box>
                            )
                          </Typography>
                        )}
               <Box sx={{ mt: 1 }}>
               { selectedOption === "restricted" && ( <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
  <Grid item xs={6}>
  <Box sx={{ display: 'flex', mb: 2 }}>
{state.usersglobaldata != null && (
  <Autocomplete
  fullWidth
  value={state.usersglobaldata.find(user => user.userid === selectedUser) || null}
  onChange={handleDropdownChange2}
  options={state.usersglobaldata ? state.usersglobaldata.filter(user => user.isactive || user.isactive === undefined) : []}
  getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
  isOptionEqualToValue={(option, value) => option.userid === value.userid}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Users"
      variant="outlined"
      sx={{ marginRight: 2 }}
    />
  )}
/>)}
                            <Button variant="outlined" onClick={addUser}>Add</Button>
                          </Box>
  </Grid>
  <Grid item xs={6}>
  {collectionname !== "teams" && (
                            <Box sx={{ display: 'flex', mb: 2 }}>
                           <Autocomplete
  fullWidth
  value={state.teamsglobaldata.find(team => team.id === selectedTeam) || null}
  onChange={handleDropDownChange1}
  options={state.teamsglobaldata || []}
  getOptionLabel={(option) => option.name}
  isOptionEqualToValue={(option, value) => option.id === value.id}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Team"
      variant="outlined"
      sx={{ marginRight: 2 }}
    />
  )}
/>
                              <Button variant="outlined" onClick={addTeamMembers}>Add</Button>
                            </Box>
                          )}
  </Grid>
</Grid> )}
                        </Box>                         
                      </>
                    


                    { selectedOption === "restricted" && (    <TableContainer className="scrollable-div" component={Paper} sx={{  overflowY: 'auto',boxShadow: 0,maxHeight:"250px", }}>
                      <Table stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Contributors</TableCell>
                            <TableCell>Owners</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody >
                          {Object.keys(selectedUsersdata).map((key, index) => {
                            const user = selectedUsersdata[key];
                            return (
                              <TableRow key={`${user.id}-${index}`} >
                                <TableCell>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {user.profilepicture ? (
                                      <Box sx={{ width: 40, height: 40, borderRadius: '50%', overflow: 'hidden', mr: 2 }}>
                                        <img src={user.profilepicture} alt="Profile-Picture" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                      </Box>
                                    ) : (
                                      <Box sx={{ width: 40, height: 40, borderRadius: '50%', bgcolor: 'grey.300', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
                                        <Typography variant="h6">{   user.firstname[0]}</Typography>
                                      </Box>
                                    )}
                                    {user.firstname + " " + user.lastname}
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  {user.userid !== userid ? (
                                    <Checkbox
                                      checked={user.isowner === true}
                                      onChange={() => handleCheckboxChange(user)}
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={user.isowner === true}
                                      disabled
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {user.userid !== userid ? (
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={() => removeUser(user)}
                                    >
                                      Remove
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      disabled
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer> )}
                  </Box>
                </FormControl>
              </Box>
              {showSaveButton && (
                <Box sx={{ display: 'flex', justifyContent: 'end',gap: 2, mt: 1 }}>
                  <Button   onClick={closeShare} sx={{textTransform:"capitalize"}}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={SaveShare} size="large" sx={{textTransform:"capitalize"}}>
                    Save share settings
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
      )}
      <Spinner spin={spin} />
          {/* --------------------------------------snackbar-------------------------------------------- */}
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} sx={{    zIndex: 100000,}}>
              <Alert
                onClose={handleCloseSnackbar}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
              >
            The link has been successfully copied.
              </Alert>
            </Snackbar>
    </>
  );
};

export default Share;
