import * as React from 'react';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { CardActionArea } from '@mui/material';
import { useGlobalState } from '../../../GlobalProvider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function ActionAreaCard({ user, templatedata }) {
    const { state } = useGlobalState();
    const [showSettings, setShowSettings] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.src = templatedata.thumbnail || templatedata.backgroundimage;
        image.onload = () => {
            setImageLoaded(true);
        };
    }, [templatedata.thumbnail, templatedata.backgroundimage]);

    return (
        <Card  sx={{ maxWidth: 200 , minWidth: 200 }} className={`mb-3 ${templatedata.type === 'pro' && state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === 'False' ? 'pro-image' : ''}`} >
            <CardActionArea>
                {imageLoaded ? (
                    <CardMedia
                        component="img"
                        height="140"
                        image={templatedata.thumbnail !== "" && templatedata.thumbnail !== undefined ? templatedata.thumbnail : templatedata.backgroundimage}
                        alt="green iguana"
                    />
                ) : (
                    <Skeleton variant="rectangular" height={140} animation="wave" />
                )}
                <CardContent>
                    <Typography gutterBottom variant="h7" component="div">
                        {templatedata.templatename || <Skeleton variant="text" animation="wave" />}
                    </Typography>

                    {/* {templatedata.description ? (
                        <Typography variant="body2" color="text.secondary" className={`scrollable-div showsettings ${showSettings ? 'show' : ''}`} style={{textAlign:"left"}}>
                            {templatedata.description}
                        </Typography>
                    ) : (
                        <Skeleton variant="text" animation="wave" />
                    )}

                    <span className="showmorebutton text-primary" role="button" onClick={(e) => {
                        e.stopPropagation();
                        setShowSettings(!showSettings);
                    }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {showSettings ? (
                            <span style={{ fontSize: "14px", }}><ExpandLessIcon size="small" />Less</span>
                        ) : (
                            <span style={{ fontSize: "14px", }}><ExpandMoreIcon size="small" />More</span>
                        )}
                    </span> */}
                </CardContent>

                {state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False" && templatedata.type === "pro" && (
                    <i
                        className="fas fa-lock "
                        style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            color: "#414141",
                            zIndex: "1"
                        }}
                    ></i>
                )}

                {state.subscriptionglobaldata && state.subscriptionglobaldata.subscription === "False" && templatedata.type === 'pro' && (
                    <div className="pro-template-card">
                        PRO
                    </div>
                )}
            </CardActionArea>
        </Card>
    );
}
